import { connect } from 'react-redux';
import {
    resetData,
    getList,
    loadmoreList,
} from 'actions/topics';
import {
    getAllPageAds,
} from 'actions/ads';
import Topics from 'views/post/project';

const mapStateToProps = state => ({
    lang: state.main.lang,
    authLevel: state.auth.authLevel,
    loading: state.topics.loading,
    loadingMore: state.topics.loadingMore,
    showLoadingMore: state.topics.showLoadingMore,
    list: state.topics.list,
    hotList: state.topics.hotList,
});

const mapDispatchToProps = {
    resetData,
    getList,
    loadmoreList,
    getAllPageAds,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Topics);

