/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import ChatCard from 'components/sections/chat';

const styles = theme => ({
    card: {
        textAlign: 'center',
        minHeight: 'calc(100vh - 219px)',
        padding: '50px 0 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 419px)',
            padding: '50px 16px',
        },
    },
    container: {
        minHeight: 'calc(100vh - 219px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: 0,
        },
    },
    button: {
        width: 384,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
});

function MyInbox(props) {
    const {
        classes,
        // lang,
        userId,
        userImg,
        userName,
        loading,
        list,
        chat,
        getMyInbox,
        resetData,
        getChat,
        updateInbox,
        updateChat,
        postChat,
        updateInboxSeen,
        creatChatByUserId,
    } = props;

    React.useEffect(() => {
        getMyInbox();
    }, [getMyInbox]);

    return (
        <>
            <TitleComponent title="ข้อความของฉัน - All About Living" />
            {list.length < 1 && !loading ? (
                <Card className={classes.card}>
                    <Box mb="10px">
                        <Typography variant="h3" color="textPrimary">
                            คุณยังไม่มีข้อความ
                        </Typography>
                    </Box>
                    <Box mb={{ xs: '24px', md: '40px' }}>
                        <Typography variant="body1" color="textSecondary">
                            คุณสามารถเลือกดูประกาศและติดต่อผู้ขายได้ทันที
                        </Typography>
                    </Box>
                    <Box className={classes.button}>
                        <Link to="/posts" style={{ textDecoration: 'none' }}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => resetData()}>
                                ดูประกาศ
                            </Button>
                        </Link>
                    </Box>
                </Card>
            ) : (
                <>
                    <Container className={classes.container}>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-end">
                            <Grid item xs={12}>
                                <Box
                                    mb={{ xs: 2, md: 3 }}
                                    mt={{ xs: 2, md: 3 }}>
                                    <Typography
                                        variant="h2"
                                        color="textPrimary">
                                        {loading ? (
                                            <Skeleton width={120} />
                                        ) : (
                                            'ข้อความ'
                                        )}
                                    </Typography>
                                </Box>
                                <Hidden smDown>
                                    <ChatCard
                                        loading={loading}
                                        list={list}
                                        chat={chat}
                                        getMyInbox={getMyInbox}
                                        resetData={resetData}
                                        getChat={getChat}
                                        userId={userId}
                                        userImg={userImg}
                                        userName={userName}
                                        updateInbox={updateInbox}
                                        updateChat={updateChat}
                                        postChat={postChat}
                                        updateInboxSeen={updateInboxSeen}
                                        creatChatByUserId={creatChatByUserId}
                                    />
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Container>
                    <Hidden mdUp>
                        <ChatCard
                            loading={loading}
                            list={list}
                            chat={chat}
                            getMyInbox={getMyInbox}
                            resetData={resetData}
                            getChat={getChat}
                            userId={userId}
                            userImg={userImg}
                            userName={userName}
                            updateInbox={updateInbox}
                            updateChat={updateChat}
                            postChat={postChat}
                            updateInboxSeen={updateInboxSeen}
                            creatChatByUserId={creatChatByUserId}
                        />
                    </Hidden>
                </>
            )}
        </>
    );
}

MyInbox.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    chat: PropTypes.object.isRequired,
    getMyInbox: PropTypes.func.isRequired,
    resetData: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    userImg: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    getChat: PropTypes.func.isRequired,
    updateInbox: PropTypes.func.isRequired,
    updateChat: PropTypes.func.isRequired,
    postChat: PropTypes.func.isRequired,
    updateInboxSeen: PropTypes.func.isRequired,
    creatChatByUserId: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(MyInbox));
