import {
    UPDATE_RESET_PASSWORD,
    RESET_LOADING,
    RESET_PASSWORD,
    RESET_DONE,
    RESET_FAIL,
} from 'actions/resetPassword';

const initialState = {
    isValid: false,
    loading: false,
    data: {
        password: '',
        confirmPassword: '',
    },
    errors: {},
    touched: {},
    successMessage: '',
    failMessage: '',
};

const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_RESET_PASSWORD:
        return {
            ...state,
            data: {
                ...state.data,
                [action.fieldName]: action.newValue
            },
            touched: {
                ...state.touched,
                [action.fieldName]: true,
            },
            errors: action.errors ? action.errors : {},
            isValid: !action.errors,
        };
    case RESET_LOADING:
        return {
            ...state,
            loading: true,
        };
    case RESET_PASSWORD:
        return {
            ...state,
            loading: true,
        };
    case RESET_DONE:
        return {
            ...state,
            loading: false,
            successMessage: action.successMessage,
            isValid: false,
            data: {
                password: '',
                confirmPassword: '',
            },
            errors: {},
            touched: {},
            failMessage: '',
        };
    case RESET_FAIL:
        return {
            ...state,
            loading: false,
            successMessage: '',
            failMessage: action.failMessage,
        };
    default:
        return state;
    }
};
export default resetPasswordReducer;