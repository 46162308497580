import { connect } from 'react-redux';
import {
    getTopicData,
    updateComment,
    postComment,
} from 'actions/topics';
import {
    getSideAds,
} from 'actions/ads';
import Topic from 'views/topic/index';

const mapStateToProps = state => ({
    lang: state.main.lang,
    authLevel: state.auth.authLevel,
    profile: state.auth.profile,
    loading: state.topics.loading,
    data: state.topics.data,
    comment: state.topics.comment,
});

const mapDispatchToProps = {
    getTopicData,
    getSideAds,
    updateComment,
    postComment,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Topic);

