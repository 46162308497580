import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import MenuLang from 'common/menu';

import { Language } from '../../roots/select';
import Social from './social';

const styles = theme => ({
    footer: {
        position: 'relative',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            // padding: '20px 0',
            // background: '#000000',
        }
    },
    default:{
        backgroundColor: "#f9f9f9",
    },
    paper:{
        backgroundColor: "#fff",
    },
    topInner:{
        paddingTop: 48,
        paddingBottom: 16,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            paddingTop: 36,
            paddingBottom: 0,
        }
    },
    list:{
        display: 'inline-block',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            textAlign: 'left',
            marginBottom: 24,
        }
    },
    link:{
        textDecoration: 'none',
    },
    language:{
        textAlign: 'right',
    },
    bottomInner:{
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }
    },
    powered:{
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            margin: '0 0 27px 0',
        }
    }
});

function Footer(props) {
    const { classes, color, lang } = props;

    return (
        <div className={clsx(classes.footer, color === 'paper' ? classes.paper : classes.default )}>
            <Container>
                <Grid
                    className={classes.topInner}
                    spacing={0}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Hidden smDown>
                        <Grid item xs={false} md={2}>
                            <Social />
                        </Grid>
                    </Hidden>
                    <Grid item xs={6} md={8} style={{ textAlign: 'center' }}>
                        <Hidden mdUp>
                            <Box className={classes.list} pt="6px">
                                <Typography variant="subtitle2" color="textPrimary">
                                    All About Living
                                </Typography>
                            </Box>
                        </Hidden>
                        <Box mr={2} className={classes.list}>
                            <Link className={classes.link} to="/termAndCondition">
                                <Typography variant="body2" color="textPrimary">
                                    {MenuLang[lang].termAndCondition}
                                </Typography>
                            </Link>
                        </Box>
                        <Box mr={2} className={classes.list}>
                            <Link className={classes.link} to="/adsvertiseWithUs">
                                <Typography variant="body2" color="textPrimary">
                                    {MenuLang[lang].adsvertiseWithUs}
                                </Typography>
                            </Link>
                        </Box>
                        <Box mr={2} className={classes.list}>
                            <Link className={classes.link} to="/whatIsAALV">
                                <Typography variant="body2" color="textPrimary">
                                    {MenuLang[lang].whatIsAALV}
                                </Typography>
                            </Link>
                        </Box>
                        <Box className={classes.list}>
                            <Link className={classes.link} to="/privacyPolicy">
                                <Typography variant="body2" color="textPrimary">
                                    {MenuLang[lang].privacyPolicy}
                                </Typography>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2} className={classes.language}>
                        <Language lang='th' />
                    </Grid>
                </Grid>
                <Divider />
                <Grid
                    spacing={0}
                    container
                    className={classes.bottomInner}
                >
                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <Box mt={3} mb={1}>
                                <Social />
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={6}>
                        <Box my={2}>
                            <Typography variant="overline" color="textSecondary">
                                Copyright &copy; 2019 All about living | All Rights Reserved
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box my={2} className={classes.powered}>
                            <Typography variant="overline" color="textSecondary">
                                 Powered by birthmarkth.com
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

Footer.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
};

export default withStyles(styles)(Footer);