import { connect } from 'react-redux';
import {
    getMyInbox,
    resetData,
    getChat,
    updateInbox,
    updateChat,
    postChat,
    updateInboxSeen,
    creatChatByUserId,
} from 'actions/inbox';
import MyInbox from 'views/inbox';

const mapStateToProps = state => ({
    userId: state.auth.uid,
    userImg: state.auth.profile.photoURL,
    userName: state.auth.profile.name,
    lang: state.main.lang,
    loading: state.inbox.loading,
    list: state.inbox.list,
    chat: state.inbox.chat,
});

const mapDispatchToProps = {
    getMyInbox,
    resetData,
    getChat,
    updateInbox,
    updateChat,
    postChat,
    updateInboxSeen,
    creatChatByUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInbox);
