import {
    FETCH_TAG,
    FETCH_TAG_DONE,
    FETCH_TAG_FAIL,
    FETCH_TAG_LOADMORE,
    FETCH_TAG_LOADMORE_DONE,
    FETCH_TAG_TOPIC,
    FETCH_TAG_TOPIC_DONE,
    FETCH_TAG_TOPIC_LOADMORE,
    FETCH_TAG_TOPIC_LOADMORE_DONE,
} from 'actions/tag';

const initialState = {
    loading: true,
    loadingMore: false,
    showLoadingMore: true,
    list: [],
    loadingTopic: true,
    loadingTopicMore: false,
    showLoadingTopicMore: true,
    topicList: [],
    name: '',
    failMessage: '',
};

const latestReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_TAG:
        return {
            ...state,
            loading: true,
            loadingMore: false,
            showLoadingMore: true,
        };
    case FETCH_TAG_LOADMORE:
        return {
            ...state,
            loadingMore: true,
        };
    case FETCH_TAG_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_TAG_DONE:
        return {
            ...state,
            loading: false,
            loadingMore: false,
            showLoadingMore: action.list.length > 9,
            list: action.list,
            name: action.name,
        };
    case FETCH_TAG_LOADMORE_DONE:
        return {
            ...state,
            loadingMore: false,
            showLoadingMore: action.list.length === 10,
            list: [
                ...state.list, 
                ...action.list,
            ],
        };
    case FETCH_TAG_TOPIC:
        return {
            ...state,
            loadingTopic: true,
            loadingTopicMore: false,
            showLoadingTopicMore: true,
        };
    case FETCH_TAG_TOPIC_LOADMORE:
        return {
            ...state,
            loadingTopicMore: true,
        };
    case FETCH_TAG_TOPIC_DONE:
        return {
            ...state,
            loadingTopic: false,
            loadingTopicMore: false,
            showLoadingTopicMore: action.list.length > 9,
            topicList: action.list,
        };
    case FETCH_TAG_TOPIC_LOADMORE_DONE:
        return {
            ...state,
            loadingTopicMore: false,
            showLoadingTopicMore: action.list.length === 10,
            topicList: [
                ...state.topicList, 
                ...action.list,
            ],
        };
    default:
        return state;
    }
};
export default latestReducer;
