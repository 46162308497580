import firebase from 'common/firebase';

const functions = firebase.app().functions('asia-east2');

// Action
export const FETCH_POPUP = 'FETCH_POPUP';
export const FETCH_POPUP_DONE = 'FETCH_POPUP_DONE';
export const FETCH_POPUP_FAIL = 'FETCH_POPUP_FAIL';
export const FETCH_ALL_PAGE_ADS = 'FETCH_ALL_PAGE_ADS';
export const FETCH_ALL_PAGE_ADS_DONE = 'FETCH_ALL_PAGE_ADS_DONE';
export const FETCH_SIDE_ADS = 'FETCH_SIDE_ADS';
export const FETCH_SIDE_ADS_DONE = 'FETCH_SIDE_ADS_DONE';
export const FETCH_HOME_ADS = 'FETCH_HOME_ADS';
export const FETCH_HOME_ADS_DONE = 'FETCH_HOME_ADS_DONE';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_POPUP,
    };
};
export function fetchDone(output) {
    const doc = {
        isShow: output.isShow,
        desktop: output.data ? output.data.desktop : '',
        mobile: output.data ? output.data.mobile : '',
        uid: output.data ? output.data.uid : '',
        url: output.data ? output.data.url : '',
    };
    return {
        type: FETCH_POPUP_DONE,
        data: doc,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_POPUP_FAIL,
        message,
    };
};
export function fetchAll() {
    return {
        type: FETCH_ALL_PAGE_ADS,
    };
};
export function fetchAllDone(output) {
    return {
        type: FETCH_ALL_PAGE_ADS_DONE,
        header: {
            ...output.header.data,
            isShow: output.header.isShow,
        },
        footer: {
            ...output.footer.data,
            isShow: output.footer.isShow,
        },
    };
};
export function fetchSide() {
    return {
        type: FETCH_SIDE_ADS,
    };
};
export function fetchSideDone(output) {
    return {
        type: FETCH_SIDE_ADS_DONE,
        header: {
            ...output.header.data,
            isShow: output.header.isShow,
        },
        footer: {
            ...output.footer.data,
            isShow: output.footer.isShow,
        },
        sideTop: {
            ...output.sideTop.data,
            isShow: output.sideTop.isShow,
        },
        sideBottom: {
            ...output.sideBottom.data,
            isShow: output.sideBottom.isShow,
        },
    };
};
export function fetchHome() {
    return {
        type: FETCH_HOME_ADS,
    };
};
export function fetchHomeDone(output) {
    return {
        type: FETCH_HOME_ADS_DONE,
        header: {
            ...output.header.data,
            isShow: output.header.isShow,
        },
        footer: {
            ...output.footer.data,
            isShow: output.footer.isShow,
        },
        sideTop: {
            ...output.sideTop.data,
            isShow: output.sideTop.isShow,
        },
        sideBottom: {
            ...output.sideBottom.data,
            isShow: output.sideBottom.isShow,
        },
        homeH: {
            ...output.homeH.data,
            isShow: output.homeH.isShow,
        },
        homeV: {
            ...output.homeV.data,
            isShow: output.homeV.isShow,
        },
    };
};

// Async Action Creator
export function getData() {
    return async (dispatch) => {
        dispatch(fetch());

        const getPopup = functions.httpsCallable('adsGetPopup');
        try {
            const result = await getPopup();
            const output = {
                data: result.data.data,
                isShow: result.data.isShow,
            };
            console.log(result.data);
            dispatch(fetchDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
export function getAllPageAds() {
    return async (dispatch) => {
        dispatch(fetchAll());

        const getAdsData = functions.httpsCallable('adsGetAllPage');
        try {
            const result = await getAdsData();
            const output = {
                header: result.data.headerAds, 
                footer: result.data.footerAds, 
            };
            dispatch(fetchAllDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
export function getSideAds() {
    return async (dispatch) => {
        dispatch(fetchSide());

        const getAdsData = functions.httpsCallable('adsGetSlimSide');
        try {
            const result = await getAdsData();
            const output = {
                header: result.data.header,
                footer: result.data.footer,
                sideTop: result.data.sideTop,
                sideBottom: result.data.sideBottom,
            };
            dispatch(fetchSideDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
export function getHomeAds() {
    return async (dispatch) => {
        dispatch(fetchHome());

        const getAdsData = functions.httpsCallable('adsGetHome');
        try {
            const result = await getAdsData();
            const output = {
                header: result.data.header,
                footer: result.data.footer,
                sideTop: result.data.sideTop,
                sideBottom: result.data.sideBottom,
                homeH: result.data.homeH,
                homeV: result.data.homeV,
            };
            dispatch(fetchHomeDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}