import { connect } from 'react-redux';
import { 
    anonymousAuth,
    updateNotification, 
} from 'actions/auth';
import {
    setNotFound,
    updateSearch,
} from 'actions/main';
import RouteWithLayout from 'layouts/RouteWithLayout';

const mapStateToProps = (state) => ({
    isNotFound: state.main.isNotFound,
    authLevel: state.auth.authLevel,
});

const mapDispatchToProps = {
    anonymousAuth,
    setNotFound,
    updateSearch,
    updateNotification,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RouteWithLayout);

