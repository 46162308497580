import {
    PROFILE_LOADING,
    PROFILE_UPDATE,
    PROFILE_PASSWORD_UPDATE,
    PROFILE_EMAIL_UPDATE,
    PROFILE_EMAIL_SUCCESS,
    PROFILE_FAIL,
    PROFILE_SUCCESS,
    PROFILE_RESET,
} from 'actions/myAccount';

const initialState = {
    loading: false,
    message: '',
    profile: {
        name: '',
        phoneNumber: '',
        photoURL: '',
    },
    profileErrors: {},
    profileTouched: {},
    profileIsValid: false,
    password: {
        password: '',
        newPassword: '',
        confirmPassword: '',
    },
    passwordErrors: {},
    passwordTouched: {},
    passwordIsValid: false,
    email: {
        email: '',
        password: '',
    },
    emailErrors: {},
    emailTouched: {},
    emailIsValid: false,
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
    case PROFILE_LOADING:
        return {
            ...state,
            loading: true,
        };
    case PROFILE_FAIL:
        return {
            ...state,
            loading: false,
            message: action.message,
        };
    case PROFILE_SUCCESS:
        return {
            ...state,
            loading: false,
            [action.fieldName]: action.data
        };
    case PROFILE_EMAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            email: {
                ...state.email,
                email: action.email,
            },
        };
    case PROFILE_RESET:
        return {
            ...state,
            loading: false,
            message: '',
            profile: {
                name: '',
                phoneNumber: '',
                photoURL: '',
            },
            profileErrors: {},
            profileTouched: {},
            profileIsValid: false,
            password: {
                password: '',
                newPassword: '',
                confirmPassword: '',
            },
            passwordErrors: {},
            passwordTouched: {},
            passwordIsValid: false,
            email: {
                email: '',
                password: '',
            },
            emailErrors: {},
            emailTouched: {},
            emailIsValid: false,
        };
    case PROFILE_UPDATE:
        return {
            ...state,
            profile: {
                ...state.profile,
                [action.fieldName]: action.newValue
            },
            profileErrors: action.errors ? action.errors : {},
            profileTouched: {
                ...state.profileTouched,
                [action.fieldName]: true,
            },
            profileIsValid: !action.errors,
        };
    case PROFILE_PASSWORD_UPDATE:
        return {
            ...state,
            password: {
                ...state.password,
                [action.fieldName]: action.newValue
            },
            passwordErrors: action.errors ? action.errors : {},
            passwordTouched: {
                ...state.passwordTouched,
                [action.fieldName]: true,
            },
            passwordIsValid: !action.errors,
        };
    case PROFILE_EMAIL_UPDATE:
        return {
            ...state,
            email: {
                ...state.email,
                [action.fieldName]: action.newValue
            },
            emailErrors: action.errors ? action.errors : {},
            emailTouched: {
                ...state.emailTouched,
                [action.fieldName]: true,
            },
            emailIsValid: !action.errors,
        };
    default:
        return state;
    }
};
export default accountReducer;