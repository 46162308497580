/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Skeleton from 'react-loading-skeleton';
import parse from 'html-react-parser';
import MenuLang from 'common/menu';
import { Button } from 'components/roots/button';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: 44,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
    },
    submitAds:{
        width: '100%',
        borderRadius: 8,
        border: 'solid 1px #c7c7c7',
        padding: 24,
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            padding: '22.5px 16px',
            borderLeft: 'none',
            borderRight: 'none',
            marginBottom: 24,
        },
    },
    leftMenu:{
        marginTop: theme.spacing(1),
        '& p':{
            marginBottom: theme.spacing(4),
        },
    },
    markdownBox:{
        marginBottom: 28,
        lineHeight: 1.8,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 22,
        },
    },
    link: {
        textDecoration: 'none',
    },
    selectedLink:{
        '& p':{
            color: '#00abbd',
        }
    },
    markdownContent:{
        position: 'relative',
        color: "#2e2e2e",
        width: '100%',
        wordWrap: 'break-word',
        margin: 0,
        '& figure': {
            margin: '1rem 0',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: '0.5rem 0',
            },
            '&.image-style-side':{
                maxWidth: '50%',
                float: 'right',
                marginLeft: '1rem',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '100%',
                    float: 'none',
                    marginLeft: '0',
                },
            },
            '&.media': {
                clear: 'both',
                display: 'block',
                width: '100%',
            },
            '& figcaption':{
                color: '#c7c7c7',
                marginTop: '0.5rem',
                fontSize: "0.875rem",
                fontWeight: "normal",
                fontStyle: "normal",
                fontStretch: "normal",
                lineHeight: "1.71",
                letterSpacing: "normal",
                "@media screen and (max-width: 961px)": {
                    marginTop: '0.25rem',
                    fontSize: "0.75rem",
                    lineHeight: 1.83,
                }
            }
        },
        '& img': {
            display: 'block',
            width:'auto',
            height:'auto',
            maxHeight: 600,
            maxWidth: '100%',
            margin: '0 auto',
        },
        '& h2': {
            margin: 0,
            fontFamily: 'Prompt',
            fontSize: 24,
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: 20,
                lineHeight: 1.6,
            },
        },
        '& h3': {
            margin: 0,
            fontFamily: 'Prompt',
            fontSize: 22,
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.55,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: 18,
                lineHeight: 1.67,
            },
        },
        '& h4': {
            margin: 0,
            fontFamily: 'Prompt',
            fontSize: 18,
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                lineHeight: 1.75,
            },
        },
        '& p': {
            margin: 0,
            fontFamily: 'Sarabun',
            fontSize: 18,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]:{
                fontSize: 16,
                lineHeight: 1.75,
            },
        },
        '& ul':{
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 20,
            },
        },
        '& ol': {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 20,
            },
        },
        '& li': {
            margin: 0,
            fontFamily: 'Sarabun',
            fontSize: 18,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]:{
                fontSize: 16,
                lineHeight: 1.75,
            },
        },
        '& a': {
            color: '#00abbd',
        },
        '& blockquote': {
            margin: 0,
            borderLeft: '4px solid #00abbd',
            paddingLeft: 12,
            fontFamily: 'Prompt',
            fontSize: 18,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.56,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]:{
                fontSize: 16,
                lineHeight: 1.75,
            },
        },
    },
    submitRow:{
        display: 'flex',
        marginTop: 16,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    formControl: {
        width: '100%',
    },
    select:{
        width: '100%',
        margin: 0,
        '& .MuiSelect-icon':{
            paddingRight: 10,
        }
    },
    textField:{
        display: 'inline-block',
        width: '100%',
        margin: '0 24px 0 0',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            margin: '0 0 16px 0',
            '& label':{
                transform: 'translate(14px, 14px) scale(1)',
            },
            '& input':{
                height: 11,
            },
        },
    },
    submit:{
        display: 'inline-block',
        width: 228,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: '100%',
        },
    }
});

function Article(props) {
    const { 
        classes,
        lang,
        title,  
        markdown,
        loading,
        showMailSubmit, 
        email,
        isValid,
        update,
        postCreate,
        submitLoading,
    } = props;

    return (
        <div className={classes.background}>
            <Container>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="flex-start"
                    // alignItems="flex-start"
                    alignItems="stretch"
                >
                    <Hidden smDown>
                        <Grid item xs={3}>
                            <Box className={classes.leftMenu}>
                                <NavLink 
                                    className={classes.link} 
                                    to="/termAndCondition"
                                    activeClassName={classes.selectedLink}
                                >
                                    <Typography variant="body1" color="textPrimary">
                                        {MenuLang[lang].termAndCondition}
                                    </Typography>
                                </NavLink>
                                <NavLink 
                                    className={classes.link} 
                                    to="/adsvertiseWithUs"
                                    activeClassName={classes.selectedLink}
                                >
                                    <Typography variant="body1" color="textPrimary">
                                        {MenuLang[lang].adsvertiseWithUs}
                                    </Typography>
                                </NavLink>
                                <NavLink 
                                    className={classes.link} 
                                    to="/whatIsAALV"
                                    activeClassName={classes.selectedLink}
                                >
                                    <Typography variant="body1" color="textPrimary">
                                        {MenuLang[lang].whatIsAALV}
                                    </Typography>
                                </NavLink>
                                <NavLink 
                                    className={classes.link} 
                                    to="/privacyPolicy"
                                    activeClassName={classes.selectedLink}
                                >
                                    <Typography variant="body1" color="textPrimary">
                                        {MenuLang[lang].privacyPolicy}
                                    </Typography>
                                </NavLink>
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={9}>
                        <Hidden mdUp>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    value={title}
                                    className={classes.select}
                                    IconComponent={KeyboardArrowDownIcon}
                                    input={
                                        <OutlinedInput
                                            id="outlined-page"
                                            className={classes.textField}
                                            value={title}
                                            margin="none"
                                            variant="outlined"
                                        />
                                    }
                                >
                                    <MenuItem value={MenuLang[lang].termAndCondition}>
                                        <Link
                                            className={classes.link}
                                            to="/termAndCondition"
                                        >
                                            <Typography variant="body1" color="textPrimary">
                                                {MenuLang[lang].termAndCondition}
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem value={MenuLang[lang].adsvertiseWithUs}>
                                        <Link
                                            className={classes.link}
                                            to="/adsvertiseWithUs"
                                        >
                                            <Typography variant="body1" color="textPrimary">
                                                {MenuLang[lang].adsvertiseWithUs}
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem value={MenuLang[lang].whatIsAALV}>
                                        <Link
                                            className={classes.link}
                                            to="/whatIsAALV"
                                        >
                                            <Typography variant="body1" color="textPrimary">
                                                {MenuLang[lang].whatIsAALV}
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem value={MenuLang[lang].privacyPolicy}>
                                        <Link
                                            className={classes.link}
                                            to="/privacyPolicy"
                                        >
                                            <Typography variant="body1" color="textPrimary">
                                                {MenuLang[lang].privacyPolicy}
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Hidden>
                        <Box className={classes.title}>
                            <Typography variant="h2" color="textPrimary">
                                {loading ? <Skeleton width="100%" count={1} /> : title}
                            </Typography>
                        </Box>
                        <Box className={classes.markdownBox}>
                            {
                                loading ?
                                    <Skeleton width="100%" count={20} /> :
                                    <div className={classes.markdownContent}>
                                        {parse(markdown)}
                                    </div>
                            }
                        </Box>
                        {
                            showMailSubmit &&
                            <Hidden smDown>
                                <Box className={classes.submitAds}>
                                    <Typography variant="h4" color="textPrimary" style={{textAlign: 'center'}}>
                                        {loading ? <Skeleton width="100%" count={1} /> : title}
                                    </Typography>
                                    <Box className={classes.submitRow}>
                                        <TextField
                                            label="Email"
                                            className={classes.textField}
                                            type="email"
                                            placeholder={MenuLang[lang].emailPlaceHolder}
                                            fullWidth
                                            name="email"
                                            autoComplete="email"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(event) => update(event.target.value)}
                                            value={email}
                                        />
                                        <Box className={classes.submit}>
                                            <Button 
                                                color="primary"
                                                variant="contained"
                                                noShadow
                                                disabled={!isValid}
                                                onClick={() => postCreate(lang,email)}
                                                loading={submitLoading}
                                            >
                                                {MenuLang[lang].submit}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Hidden>
                        }
                    </Grid>
                </Grid>
            </Container>
            {
                showMailSubmit &&
                <Hidden mdUp>
                    <Box className={classes.submitAds}>
                        <Typography variant="h4" color="textPrimary" style={{ textAlign: 'center' }}>
                            {loading ? <Skeleton width="100%" count={1} /> : title}
                        </Typography>
                        <Box className={classes.submitRow}>
                            <TextField
                                label="Email"
                                className={classes.textField}
                                type="email"
                                placeholder={MenuLang[lang].emailPlaceHolder}
                                fullWidth
                                name="email"
                                autoComplete="email"
                                margin="normal"
                                variant="outlined"
                                onChange={(event) => update(event.target.value)}
                                value={email}
                            />
                            <Box className={classes.submit}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    noShadow
                                    disabled={!isValid}
                                    onClick={() => postCreate(lang, email)}
                                    loading={submitLoading}
                                >
                                    {MenuLang[lang].submit}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Hidden>
            }
        </div>
    );
}

Article.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired, 
    markdown: PropTypes.string.isRequired, 
    loading: PropTypes.bool.isRequired,
    showMailSubmit: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    postCreate: PropTypes.func.isRequired,
    submitLoading: PropTypes.bool.isRequired, 
};

export default withStyles(styles)(Article);