import firebase from 'common/firebase';
import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import { showSnack } from './main';

// Action
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_PASSWORD_UPDATE = 'PROFILE_PASSWORD_UPDATE';
export const PROFILE_EMAIL_UPDATE = 'PROFILE_EMAIL_UPDATE';
export const PROFILE_EMAIL_SUCCESS = 'PROFILE_EMAIL_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_RESET = 'PROFILE_RESET';

const profileSchema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    phoneNumber: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

const passwordSchema = {
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
    newPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
    confirmPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};

const emailSchema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
};

// Sync Action Creator
export function updateProfile(oldData, fieldName, newValue) {
    const oldObj = oldData;
    oldObj[fieldName] = newValue;
    const errors = validate(oldData, profileSchema);

    return {
        type: PROFILE_UPDATE,
        fieldName,
        newValue,
        errors,
    };
};
export function profileLoading() {
    return {
        type: PROFILE_LOADING,
    };
};
export function profileSuccess(fieldName,data) {
    return {
        type: PROFILE_SUCCESS,
        fieldName,
        data,
    };
};
export function profileEmailSuccess(email) {
    return {
        type: PROFILE_EMAIL_SUCCESS,
        email,
    };
};
export function profileFail(message) {
    return {
        type: PROFILE_FAIL,
        message,
    };
};
export function resetProfile() {
    return {
        type: PROFILE_RESET,
    };
};
export function updatePassword(oldData, fieldName, newValue) {
    const oldObj = oldData;
    oldObj[fieldName] = newValue;
    const errors = validate(oldData, passwordSchema);

    return {
        type: PROFILE_PASSWORD_UPDATE,
        fieldName,
        newValue,
        errors,
    };
};
export function updateEmail(oldData, fieldName, newValue) {
    const oldObj = oldData;
    oldObj[fieldName] = newValue;
    const errors = validate(oldData, emailSchema);

    return {
        type: PROFILE_EMAIL_UPDATE,
        fieldName,
        newValue,
        errors,
    };
};

// Async Action Creator
export function postImage(file, oldData) {
    if (!file) {
        return {
            type: PROFILE_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return (dispatch) => {
        dispatch(profileLoading());
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`users/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(updateProfile(oldData, 'photoURL' ,downloadURL));
                const data = oldData;
                data.photoURL = downloadURL;
                dispatch(profileSuccess('profile', data));
            });
        });
    };
};
export function getProfile() {
    return (dispatch) => {
        dispatch(profileLoading());
        return firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in.
                const { uid, displayName, photoURL } = user;
                const userData = await firebase.firestore().collection("users").doc(uid).get();
                const profile = {
                    name: displayName,
                    phoneNumber: userData.data() ? userData.data().phoneNumber : '',
                    photoURL,
                };
                dispatch(profileSuccess('profile',profile));
            }
        });
    };
};
export function getEmail() {
    return (dispatch) => {
        dispatch(profileLoading());
        return firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in.
                const { email } = user;
                dispatch(profileEmailSuccess(email));
            }
        });
    };
};
export function postProfile(data) {
    return async (dispatch) => {
        dispatch(profileLoading());

        try {            
            // update profile
            const user = await firebase.auth().currentUser;
            const profile = { 
                displayName: data.name,
                photoURL: data.photoURL,
            };
            await user.updateProfile(profile);
            
            const ref = firebase.firestore().collection("users").doc(user.uid);
            const inputData = {
                phoneNumber: data.phoneNumber,
            };
            await ref.set(inputData, { merge: true });

            dispatch(profileSuccess('profile', data));
            dispatch(showSnack('success', 'Your profile updated successfully.'));
        } catch (error) {
            dispatch(profileFail(error.message));
            dispatch(showSnack('warning', error.message));
        }
    };
};
export function postEmail(data) {
    return async (dispatch) => {
        dispatch(profileLoading());
        try {
            const { email, password } = data;
            const user = await firebase.auth().currentUser;
            const credential = await firebase.auth.EmailAuthProvider.credential(
                user.email,
                password
            );

            // Prompt the user to re-provide their sign-in credentials
            user.reauthenticateWithCredential(credential).then(async () => {
                await user.updateEmail(email);
                dispatch(profileEmailSuccess(email));
                dispatch(showSnack('success', 'Your email updated successfully.'));
            }).catch((error) => {
                dispatch(profileFail(error.message));
                dispatch(showSnack('warning', error.message));
            });
        } catch (error) {
            dispatch(profileFail(error.message));
            dispatch(showSnack('warning', error.message));
        };
    };
};
export function postPassword(data) {
    return async (dispatch) => {
        dispatch(profileLoading());
        if (data.newPassword !== data.confirmPassword) {
            dispatch(showSnack('warning', 'New password and confirm password does not match.'));
            dispatch(profileFail('New password and confirm password does not match.'));
        } else {
            try {
                const { newPassword } = data;
                const user = await firebase.auth().currentUser;
                const credential = await firebase.auth.EmailAuthProvider.credential(
                    user.email,
                    data.password
                );

                // Prompt the user to re-provide their sign-in credentials
                user.reauthenticateWithCredential(credential).then(async()=> {
                    await user.updatePassword(newPassword);
                    dispatch(resetProfile());
                    dispatch(showSnack('success', 'Your password updated successfully.'));
                }).catch((error) => {
                    dispatch(profileFail(error.message));
                    dispatch(showSnack('warning', error.message));
                });
            } catch (error) {
                dispatch(profileFail(error.message));
                dispatch(showSnack('warning', error.message));
            };
        };
    };
};