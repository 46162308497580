import {
    FETCH_LATEST,
    FETCH_LATEST_DONE,
    FETCH_LATEST_FAIL,
    FETCH_LATEST_LOADMORE,
    FETCH_LATEST_LOADMORE_DONE,
} from 'actions/latest';

const initialState = {
    loading: true,
    loadingMore: false,
    showLoadingMore: true,
    list: [],
    failMessage: '',
};

const latestReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_LATEST:
        return {
            ...state,
            loading: true,
            loadingMore: false,
            showLoadingMore: true,
        };
    case FETCH_LATEST_LOADMORE:
        return {
            ...state,
            loadingMore: true,
        };
    case FETCH_LATEST_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_LATEST_DONE:
        return {
            ...state,
            loading: false,
            loadingMore: false,
            showLoadingMore: action.list.length > 24,
            list: action.list,
        };
    case FETCH_LATEST_LOADMORE_DONE:
        return {
            ...state,
            loadingMore: false,
            showLoadingMore: action.list.length === 25,
            list: [
                ...state.list, 
                ...action.list,
            ],
        };
    default:
        return state;
    }
};
export default latestReducer;
