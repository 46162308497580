/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';

const styles = theme => ({
    textField: {
        width: '100%',
        margin: '0',
        [theme.breakpoints.down('sm')]: {
            '& label': {
                transform: 'translate(14px, 14px) scale(1)',
            },
            '& input': {
                height: 11,
            },
        },
    },
});

function RegisterForm(props) {
    const {
        classes,
        loading,
        data,
        errors,
        touched,
        update,
    } = props;

    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return true;
        }
        return false;
    };

    return (
        <>
            <Box mb="35px">
                <TextField
                    error={hasError('email')}
                    helperText={
                        hasError('email') ? errors.email[0] : null
                    }
                    disabled={loading}
                    fullWidth
                    label="อีเมล"
                    className={classes.textField}
                    type="email"
                    name="email"
                    variant="outlined"
                    onChange={(event) => update(data, event.target.name, event.target.value)}
                    value={data.email}
                />
            </Box>
            <Box mb="35px">
                <TextField
                    error={hasError('name')}
                    helperText={
                        hasError('name') ? errors.name[0] : null
                    }
                    disabled={loading}
                    fullWidth
                    label="ชื่อ"
                    className={classes.textField}
                    type="text"
                    name="name"
                    variant="outlined"
                    onChange={(event) => update(data, event.target.name, event.target.value)}
                    value={data.name}
                />
            </Box>
            <Box mb="35px">
                <TextField
                    error={hasError('phoneNumber')}
                    helperText={
                        hasError('phoneNumber') ? errors.phoneNumber[0] : null
                    }
                    disabled={loading}
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    className={classes.textField}
                    type="number"
                    name="phoneNumber"
                    variant="outlined"
                    onChange={(event) => update(data, event.target.name, event.target.value)}
                    value={data.phoneNumber}
                />
            </Box>
            <Box mb="35px">
                <TextField
                    error={hasError('password')}
                    helperText={
                        hasError('password') ? errors.password[0] : null
                    }
                    disabled={loading}
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    label="รหัสผ่าน"
                    name="password"
                    onChange={(event) => update(data, event.target.name, event.target.value)}
                    value={data.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box mb={3}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={data.isAgency}
                            onChange={() => update(data, 'isAgency', !data.isAgency)}
                            // value={data.isAgency}
                        />
                    }
                    label="คุณเป็นเอเจนซี่ใช่ไหม?"
                />
            </Box>
        </> 
    );
}

RegisterForm.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegisterForm);