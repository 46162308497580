/* eslint-disable no-underscore-dangle */
import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import firebase from 'common/firebase';
import moment from 'moment';
import { showSnack } from './main';

const functions = firebase.app().functions('asia-east2');
const { Omise } = window;
Omise.setPublicKey('pkey_test_5izsbfmwl148gdmr9i8');

const schema = {
    creditcardNumber: {
        presence: { allowEmpty: false, message: 'is required' },
        length: { is: 16 },
    },
    name: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    expire: {
        presence: { allowEmpty: false, message: 'is required' },
        length: { is: 4 },
    },
    cvv: {
        presence: { allowEmpty: false, message: 'is required' },
        length: { is: 3 },
    },
};

// Action
// -- CREATE/UPDATE
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const RESET_PACKAGE = 'RESET_PACKAGE';
export const UPDATE_PACKAGE_CHECK = 'UPDATE_PACKAGE_CHECK';
export const FETCH_PACKAGE = 'FETCH_PACKAGE';
export const FETCH_PACKAGE_DATA_DONE = 'FETCH_PACKAGE_DATA_DONE';
export const FETCH_PACKAGE_DONE = 'FETCH_PACKAGE_DONE';
export const FETCH_PACKAGE_FAIL = 'FETCH_PACKAGE_FAIL';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const FETCH_PACKAGE_LIST_DONE = 'FETCH_PACKAGE_LIST_DONE';
export const TOGGLE_CHECKED_PACKAGE = 'TOGGLE_CHECKED_PACKAGE';

// Sync Action Creator
// -- Create/Update --
export function fetch() {
    return {
        type: FETCH_PACKAGE,
    };
}
export function reset() {
    return {
        type: RESET_PACKAGE,
    };
}
export function fetchDataDone() {
    return {
        type: FETCH_PACKAGE_DATA_DONE,
    };
}
export function fetchDone(message) {
    return {
        type: FETCH_PACKAGE_DONE,
        message,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_PACKAGE_FAIL,
        message,
    };
}
export function update(oldData, fieldName, newValue) {
    const newData = oldData;
    newData[fieldName] = newValue;

    const errors = validate(newData, schema);

    return {
        type: UPDATE_PACKAGE,
        fieldName,
        newValue,
        errors,
    };
}

// -- LIST --
export function fetchListDone(data) {
    const checkList = new Array(data.list.length).fill(0);
    _.forEach(data.list, (value, index) => {
        if (_.includes(data.uid, value.uid)) {
            checkList[index] = 1;
        }
    });
    return {
        type: FETCH_PACKAGE_LIST_DONE,
        list: data.list,
        packages: data.packages,
        check: checkList,
    };
}
export function deleteData(uid) {
    return {
        type: DELETE_PACKAGE,
        uid,
    };
}

// -- PROMOTE --
export function toggleCheck(index, number) {
    return {
        type: TOGGLE_CHECKED_PACKAGE,
        index,
        number,
    };
}
export function submitMoreCheck(indexArr) {
    return {
        type: UPDATE_PACKAGE_CHECK,
        indexArr,
    };
}

// Async Action Creator
export function getList(lang, initialUID) {
    return async dispatch => {
        dispatch(fetch());

        const user = firebase.auth().currentUser;
        if (user) {
            const { uid } = user;

            const getListAPI = functions.httpsCallable(
                'getMyPostListWithPackage',
            );
            try {
                const result = await getListAPI({ status: 'open', uid, lang });
                const output = result.data;
                output.uid = initialUID;

                dispatch(fetchListDone(output));
            } catch (error) {
                console.log(error);
                dispatch(fetchFail(error.message));
            }
        } else {
            dispatch(fetchFail('You are not sign in.'));
        }
        return false;
    };
}
export function postCreate(data, amount, check, packages, list) {
    return async dispatch => {
        dispatch(fetch());

        const adminCreator = firebase.auth().currentUser;
        if (adminCreator) {
            const { email } = adminCreator;
            const orderId = uuidv1();

            // Prepare post/order list
            const postList = [];
            let orderName = '';
            check.forEach((packageNumber, index) => {
                if (packageNumber !== 0) {
                    // Post
                    const postData = {
                        uid: list[index].uid,
                        status: 'promote',
                        packageId: packages[packageNumber - 1].uid,
                        reachAfterPromote: 0,
                        maximumReachPromote: packages[packageNumber - 1].views,
                        startPromoteAt: moment().valueOf(),
                        updatedAt: moment().valueOf(),
                    };
                    postList.push(postData);
                    if (orderName) {
                        orderName += `, propId ${list[index].uid} - ${
                            packages[packageNumber - 1].views
                        } views`;
                    } else {
                        orderName += `propId ${list[index].uid} - ${
                            packages[packageNumber - 1].views
                        } views`;
                    }
                }
            });

            // Order Data
            const orderData = {
                propId: orderId,
                price: amount,
                createdBy: email,
                timestamp: moment().valueOf(),
                packageName: orderName,
            };

            // Paid to Omise
            const mStr = data.expire.substr(0, 2);
            const yStr = data.expire.substr(2, 4);
            const month = mStr; // 2
            const year = `20${yStr}`; // 2022

            const tokenParameters = {
                expiration_month: Number(month),
                expiration_year: Number(year),
                name: data.name,
                number: data.creditcardNumber,
                security_code: data.cvv,
            };

            Omise.createToken(
                'card',
                tokenParameters,
                async (statusCode, response) => {
                    console.log(statusCode);
                    if (statusCode !== 200) {
                        dispatch(
                            showSnack(
                                'warning',
                                _.capitalize(response.message),
                            ),
                        );
                    } else {
                        const inputData = {
                            tokenId: response.id,
                            amount: amount * 100,
                            description: `Order-${orderId}`,
                            orderId,
                            postList,
                            orderData,
                        };

                        console.log(inputData);

                        const payAPI = functions.httpsCallable('charge');
                        try {
                            const result = await payAPI(inputData);
                            if (result.data.status === 200) {
                                dispatch(fetchDataDone());
                            } else {
                                dispatch(
                                    showSnack(
                                        'warning',
                                        'Something went wrong. please contact us.',
                                    ),
                                );
                            }
                            dispatch(fetchDataDone());
                        } catch (error) {
                            console.log(error);
                            dispatch(fetchFail(error.message));
                        }
                    }
                },
            );
            return true;
        } 
        dispatch(fetchFail('You are not sign in.'));
        return true;
        
    };
}
