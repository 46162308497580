/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import GoogleMapReact from 'google-map-react';
import Skeleton from 'react-loading-skeleton';
import {
    setCenter,
} from 'actions/article';
import FilterDrawer from './filter';
import Marker from './marker';
import MapStyle from './style.json';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
        backgroundColor: '#f9f9f9',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            backgroundColor: '#fff',
        }
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
    map:{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        backgroundColor: '#fff',
        height: 675,
        border: 'solid 1px #c7c7c7',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#f9f9f9',
            height: 500,
        }
    },
});

function Map(props) {
    const { 
        classes, 
        loading, 
        title,
        list,
        center,
        activeId,
        setMapCenter,
        filter,
    } = props;
    
    function handleClickedMap(uid,lat,lng){
        setMapCenter(uid, {lat,lng});
    };

    return (
        <>
            {list.length > 0 &&
                <div className={classes.background}>
                    <Box className={classes.title}>
                        <Typography variant="h2" color="textPrimary">
                            {loading ? <Skeleton width={150} /> : title }
                        </Typography>
                    </Box>
                    <Container>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >

                            <Grid item xs={12}>
                                {
                                    loading ?
                                        <div>
                                            <Skeleton width="100%" height={500} />
                                        </div>
                                        :
                                        <Box className={classes.map} id="drawer-container">
                                            <GoogleMapReact
                                                options={{
                                                    styles: MapStyle,
                                                }}
                                                bootstrapURLKeys={{ key: "AIzaSyAJw5J77DXKtlw_aXnS8VitWF3Ywjq1aM8" }}
                                                center={[center.lat, center.lng]}
                                                defaultZoom={15}
                                            >
                                                {list.map((item) => (
                                                    (filter[item.categoryId] || item.size=== "l" )&&
                                                    <Marker
                                                        key={item.uid}
                                                        lat={item.lat}
                                                        lng={item.lng}
                                                        size={item.size}
                                                        category={item.categoryId}
                                                        active={item.uid === activeId}
                                                        title={item.title}
                                                        desc={item.desc}
                                                        link={`/location/${item.uid}`}
                                                        onClick={() => handleClickedMap(item.uid, item.lat, item.lng)}
                                                    />
                                                ))}
                                            </GoogleMapReact>

                                            {/* Filter */}
                                            <FilterDrawer
                                                container="drawer-container"
                                            // filterList [live, life, inspi, finance]
                                            />
                                        </Box>
                                }
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            }
        </>
    );
}

Map.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    center: PropTypes.object.isRequired,
    activeId: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired,
    setMapCenter: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    list: state.article.mapList,
    center: state.article.mapCenter,
    filter: state.article.filter,
    activeId: state.article.mapActiveId,
});

const mapDispatchToProps = {
    setMapCenter:setCenter,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Map));