/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { 
    Link,
    Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import {
    toggleDialog,
    postSignOut,
} from 'actions/auth';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Avatar from 'components/roots/avatar';
import LoginRegiterDialog from './loginDialog/index';

const styles = theme => ({
    leftInner: {
        paddingTop: 12,
        paddingBottom: 12,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            // padding: '20px 0',
            // background: '#000000',
        }
    },
    white:{
        color: '#fff',
    },
    rightInner: {
        paddingTop: 12,
        paddingBottom: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    leftList: {
        display: 'inline-block',
    },
    rightList: {
        display: 'inline-block',
    },
    link: {
        textDecoration: 'none',
    }
});

function NavList(props) {
    const { 
        classes, 
        bg, 
        message,
        isOpen,
        toggle,
        signOut,
        authLevel,
        profile,
    } = props;

    const [state, setState] = React.useState({
        dialog: '', // login, register, forgotPass, registerForm
    });

    const handleOpenDialog = (name) => {
        setState({
            ...state,
            dialog: name,
        });
        toggle(true);
    };

    const handleCloseDialog = () => {
        toggle(false);
    };

    if (message === 'signout') {
        return (<Redirect to="/" />);
    }

    return (
        <>
            <Grid item xs={5} className={classes.leftInner}>
                <Box mr={3} className={classes.leftList}>
                    <Link className={classes.link} to="/live">
                        <Typography variant="body2" color="textPrimary" className={clsx(bg !== 'white' && classes.white)}>
                            Live
                        </Typography>
                    </Link>
                </Box>
                <Box mr={3} className={classes.leftList}>
                    <Link className={classes.link} to="/lifestyle">
                        <Typography variant="body2" color="textPrimary" className={clsx(bg !== 'white' && classes.white)}>
                            Lifestyle
                        </Typography>
                    </Link>
                </Box>
                <Box mr={3} className={classes.leftList}>
                    <Link className={classes.link} to="/finance">
                        <Typography variant="body2" color="textPrimary" className={clsx(bg !== 'white' && classes.white)}>
                            Finance
                        </Typography>
                    </Link>
                </Box>
                <Box className={classes.leftList}>
                    <Link className={classes.link} to="/inspiration">
                        <Typography variant="body2" color="textPrimary" className={clsx(bg !== 'white' && classes.white)}>
                            Inspiration
                        </Typography>
                    </Link>
                </Box>
            </Grid>
            <Grid item xs={5} className={classes.rightInner}>
                <Box mr={3} className={classes.rightList}>
                    <Link className={classes.link} to="/webboard">
                        <Typography variant="body2" color="textPrimary" className={clsx(bg !== 'white' && classes.white)}>
                            เว็บบอร์ด
                        </Typography>
                    </Link>
                </Box>
                <Box mr={3} className={classes.rightList}>
                    <Link className={classes.link} to="/posts">
                        <Typography variant="body2" color="textPrimary" className={clsx(bg !== 'white' && classes.white)}>
                            ประกาศซื้อ-ขายอสังหาฯ
                        </Typography>
                    </Link>
                </Box>
                {
                    authLevel >= 2 ?
                        <>
                            <Box mr={3} className={classes.rightList}>
                                <Link className={classes.link} to="/create-post">
                                    <Typography
                                        variant="body2"
                                        color="textPrimary"
                                        className={clsx(bg !== 'white' && classes.white)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        ลงประกาศ
                                    </Typography>
                                </Link>
                            </Box>
                            <Box mr="12px" className={classes.rightList}>
                                <Avatar
                                    name={profile.name}
                                    img={profile.photoURL}
                                    inboxNoti={profile.inboxNoti}
                                    signOut={signOut}
                                />
                            </Box>
                        </>
                        :
                        <>
                            <Box mr={3} className={classes.rightList}>
                                <Typography 
                                    variant="body2" 
                                    color="textPrimary" 
                                    className={clsx(bg !== 'white' && classes.white)}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => handleOpenDialog('login')}
                                >
                                    ลงประกาศ
                                </Typography>
                            </Box>
                            <Box mr={3} className={classes.rightList}>
                                <Typography 
                                    variant="body2" 
                                    color="textPrimary" 
                                    className={clsx(bg !== 'white' && classes.white)}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => handleOpenDialog('login')}
                                >
                                    เข้าสู่ระบบ
                                </Typography>
                            </Box>
                            <Box mr="12px" className={classes.rightList}>
                                <Typography 
                                    variant="body2" 
                                    color="textPrimary" 
                                    className={clsx(bg !== 'white' && classes.white)}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleOpenDialog('register')}
                                >
                                    ลงทะเบียน
                                </Typography>
                            </Box>
                        </>
                }
            </Grid>
            {/* ---- Desktop Dialog ---- */}
            <LoginRegiterDialog
                isOpen={isOpen}
                name={state.dialog}
                handleChangePage={handleOpenDialog}
                handleClose={handleCloseDialog}
            />
        </>
    );
}

NavList.propTypes = {
    classes: PropTypes.object.isRequired,
    bg: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    authLevel: PropTypes.number,
    profile: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
};

NavList.defaultProps = {
    authLevel: 0,
    profile:{
        name: '',
        email: '',
        phoneNumber: '',
        photoURL: '',
        inboxNoti: false,
    }
};

const mapStateToProps = state => ({
    isOpen: state.auth.openDialog,
    message: state.auth.message,
});

const mapDispatchToProps = {
    toggle: toggleDialog,
    signOut: postSignOut,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(NavList));