import React from 'react';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import clsx from 'clsx';

// share icon
import Facebook from './facebook.svg';
import Twitter from './twitter.svg';
import LinkIcon from './link.svg';

const styles = (theme) => ({
    list: {
        justifyContent: 'flex-end',
        display: 'inline-block',
    },
    img: {
        height: 24,
        [theme.breakpoints.down('sm')]: {
            height: 20,
        },
    },
    isLoading: {
        opacity: 0.3,
    },
});

function Share(props) {
    const { location, classes } = props;

    const [state, setState] = React.useState({
        copied: false,
    });

    const { pathname } = location;
    const thisPageUrl = 'https://www.allaboutliving.co'.concat(pathname);

    const copiedFunc = () => {
        setState({
            copied: true
        });
        setTimeout(() => {
            setState({
                copied: false
            });
        }, 500);
    };

    return (
        <>
            <Box mb={1}>
                <Typography variant="subtitle2">
                    แชร์ไปยัง:
                </Typography>
            </Box>
            <Box className={classes.list} mr={1}>
                <FacebookShareButton url={thisPageUrl}>
                    <IconButton>
                        <img className={classes.img} src={Facebook} alt="share to facebook" />
                    </IconButton>
                </FacebookShareButton>
            </Box>
            <Box className={classes.list} mr={1}>
                <TwitterShareButton url={thisPageUrl}>
                    <IconButton>
                        <img className={classes.img} src={Twitter} alt="share to twitter" />
                    </IconButton>
                </TwitterShareButton>
            </Box>
            <Box className={classes.list}>
                <CopyToClipboard
                    text={thisPageUrl}
                    onCopy={copiedFunc}
                >
                    <IconButton disabled={state.copied}>
                        <img
                            className={clsx(classes.img, state.copied && classes.isLoading)}
                            src={LinkIcon}
                            alt="copy link"
                        />
                    </IconButton>
                </CopyToClipboard>
            </Box>
        </>
    );
}

Share.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
};

Share.defaultProps = {
    location: PropTypes.shape({
        pathname: '/',
    }),
};

export default withRouter(withStyles(styles)(Share));