import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Skeleton from 'react-loading-skeleton';

const styles = () => ({
    row:{
        color: '#c7c7c7',
        display: 'flex',
        alignItems: 'center',
    },
});

function DateViewComment(props) {
    const {
        classes,
        date,
        view,
        comment,
        loading,
    } = props;
    return (
        <Box className={classes.row}>
            {loading ?
                <Typography variant="caption">
                    <Skeleton width={180} count={1} />
                </Typography>
                :
                <>
                    <Box mr='auto'>
                        <Typography variant="caption">
                            {date}
                        </Typography>
                    </Box>
                    <Box mr={1}>
                        <VisibilityIcon fontSize="small" />
                    </Box>
                    <Box mr="10px">
                        <Typography variant="caption">
                            {view}
                        </Typography>
                    </Box>
                    <Box mr={1}>
                        <ChatBubbleIcon fontSize="small" />
                    </Box>
                    <Box>
                        <Typography variant="caption">
                            {comment}
                        </Typography>
                    </Box>
                </>
            }
        </Box>
    );
}

DateViewComment.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    date: PropTypes.string,
    view: PropTypes.number,
    comment: PropTypes.number,
    loading: PropTypes.bool,
};

DateViewComment.defaultProps = {
    date: '',
    view: 0,
    comment: 0,
    loading: true,
};

export default withStyles(styles)(DateViewComment);