/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    toggleDialog,
    postSignOut,
} from 'actions/auth';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RoomIcon from '@material-ui/icons/Room';
import LabelIcon from '@material-ui/icons/Label';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Divider from '@material-ui/core/Divider';
import AvatarOnlyImg from 'components/roots/avatar/onlyImg';
import PersonIcon from '@material-ui/icons/Person';
import ForumIcon from '@material-ui/icons/Forum';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuItem from '@material-ui/core/MenuItem';
import {
    connectSearchBox,
    connectHits,
} from 'react-instantsearch-dom';

// Icon
import MenuLang from 'common/menu';
import Search from './search.svg';
import WhtSearch from './whtSearch.svg';
import Menu from './menu.svg';
import WhtMenu from './whtMenu.svg';
import Close from './close.svg';
import BluLogo from './blu.png';
import WhtLogo from './wht.svg';
import LoginRegiterDialog from './loginDialogMobile/index';

const styles = () => ({
    menuInner: {
        textAlign: 'left',
        padding: '4px 0 4px 8px',
    },
    logoInner: {
        textAlign: 'center',
    },
    searchInner: {
        textAlign: 'right',
        padding: '4px 8px 4px 0',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        outline: 'none',
    },
    menu: {
        verticalAlign: 'middle',
        height: 30
    },
    logo: {
        verticalAlign: 'middle',
        height: 28
    },
    search: {
        verticalAlign: 'middle',
        width: 25,
        height: 25,
        cursor: 'pointer',
    },
    close: {
        verticalAlign: 'middle',
        width: 17,
        height: 17,
        cursor: 'pointer',
    },
    iconButton: {
        padding: 8,
    },
    openSearch: {
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.22)',
        }
    },
    menuListInner: {
        position: 'relative',
        width: '100vw',
        minHeight: '100vh',
        height: 'auto',
        overflow: 'scroll',
        boxSizing: 'border-box',
    },
    rightMenuListInner:{
        position: 'relative',
        width: '100vw',
        minHeight: '100vh',
        height: 'auto',
        overflow: 'scroll',
        boxSizing: 'border-box',
        padding: '0px 16px 0 16px',
    },
    textField:{
        width: '100%',
        '& input':{
            fontSize: "0.875rem",
        },
        '& .MuiInputBase-root':{
            padding: '0 4px',
        },
        '& svg': {
            width: 15,
            height: 15,
        }
    },
    lists: {
        padding: 0,
    },
    list: {
        paddingLeft: 8,
        paddingRight: 8,
        '&:hover svg': {
            color: "#00abbd",
        }
    },
    listIcon: {
        minWidth: 43,
    },
    menuItem: {
        padding: '6px 16px',
    },
    menutext: {
        flexGrow: 1,
    },
    menuNoti: {
        width: 10,
        height: 10,
        backgroundColor: "#f06642",
        borderRadius: '50%',
    },
});

const SearchBox = ({ classes, toggleDrawer, currentRefinement, refine, updateSearch }) => (
    <>
        <TextField
            className={classes.textField}
            placeholder={MenuLang.th.search}
            margin="normal"
            variant="outlined"
            onChange={(event) => {
                updateSearch(event.currentTarget.value);
                refine(event.currentTarget.value);
            }}
            value={currentRefinement}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton className={classes.iconButton}>
                            <img className={classes.search} src={Search} alt="Search Icon" />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment 
                        position="end" 
                        onClick={toggleDrawer('right', false)}
                    >
                        <IconButton className={classes.iconButton}>
                            <img className={classes.close} src={Close} alt="Close Icon" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    </>
);
const CustomSearchBox = connectSearchBox(SearchBox);

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const Hits = ({ hits, classList, lang, classListIcon, closeSearch, searchNumber }) => {
    searchNumber(hits.length);
    return (
        <>
            {
                hits.map(hit => {
                    let iconType = <LabelIcon />;
                    if (hit.type === 'article') {
                        iconType = <LibraryBooksIcon />;
                    }
                    if (hit.type === 'location') {
                        iconType = <RoomIcon />;
                    }
                    return (
                        <ListItemLink
                            key={hit.uid}
                            className={classList}
                            href={`/${hit.type}/${hit.uid}`}
                            onClick={closeSearch}
                        >
                            <ListItemIcon className={classListIcon}>
                                {iconType}
                            </ListItemIcon>
                            <ListItemText primary={hit[`name${lang}`]} />
                        </ListItemLink>
                    );
                })
            }
        </>
    );
};
const CustomHits = connectHits(Hits);

function Navbar(props) {
    const { 
        classes, 
        bg, 
        lang,
        updateSearch, 
        isSearching, 
        searchNumber,
        authLevel,
        profile,
        isOpen,
        toggle,
        signOut,
    } = props;

    // Drawer
    const [state, setState] = React.useState({
        left: false,
        right: false,
        dialog: '', // login, register, forgotPass, registerForm
    });
    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [side]: open });
    };

    const handleOpenDialog = (name) => {
        setState({
            ...state,
            dialog: name,
        });
        toggle(true);
    };

    const handleCloseDialog = () => {
        toggle(false);
    };

    return (
        <>  
            <Grid item xs={4} className={classes.menuInner}>
                <IconButton
                    className={clsx(classes.iconButton, bg !== 'white' && classes.openSearch)}
                    aria-label="Search"
                    onClick={toggleDrawer('left', true)}
                >
                    <img className={classes.menu} src={bg === 'white' ? Menu : WhtMenu} alt="Hamberger" />
                </IconButton>
            </Grid>
            <Grid item xs={4} className={classes.logoInner}>
                <Link className={classes.link} to="/">
                    <img className={classes.logo} src={bg === 'white' ? BluLogo : WhtLogo} alt="All About Living Logo" />
                </Link>
            </Grid>
            <Grid item xs={4} className={classes.searchInner}>
                <IconButton 
                    className={clsx(classes.iconButton, bg !== 'white' && classes.openSearch)} 
                    aria-label="Search"
                    onClick={toggleDrawer('right', true)}
                >
                    <img className={classes.search} src={bg === 'white' ? Search : WhtSearch} alt="Search Icon" />
                </IconButton>
            </Grid>
            <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                <div className={classes.menuListInner}>
                    <Grid
                        spacing={0}
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{padding: '6.5px 0px 6.5px 0px'}}
                    >
                        <Grid item xs={4}/>
                        <Grid item xs={4} className={classes.logoInner}>
                            <Link className={classes.link} to="/" onClick={toggleDrawer('left', false)}>
                                <img className={classes.logo} src={BluLogo} alt="All About Living Logo" />
                            </Link>
                        </Grid>
                        <Grid item xs={4} className={classes.searchInner}>
                            <IconButton
                                className={clsx(classes.iconButton)}
                                onClick={toggleDrawer('left', false)}
                            >
                                <img className={classes.close} src={Close} alt="Close Icon" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {
                        authLevel >= 2 &&
                            <>
                                <Box mt={3} ml={2} mb={1}>
                                    <AvatarOnlyImg 
                                        name={profile.name}
                                        img={profile.photoURL}
                                    />
                                </Box>
                                <Link className={classes.link} to="/profile" onClick={toggleDrawer('left', false)}>
                                    <MenuItem className={classes.menuItem} classes={{ selected: classes.selected }}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <PersonIcon />
                                        </ListItemIcon>
                                        <Typography variant="body1" color="textPrimary">
                                            ข้อมูลส่วนตัว
                                        </Typography>
                                    </MenuItem>
                                </Link>
                                <Link className={classes.link} to="/my-posts" onClick={toggleDrawer('left', false)}>
                                    <MenuItem className={classes.menuItem}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <RoomIcon />
                                        </ListItemIcon>
                                        <Typography variant="body1" color="textPrimary">
                                            ประกาศของฉัน
                                        </Typography>
                                    </MenuItem>
                                </Link>
                                <Link className={classes.link} to="/my-topics" onClick={toggleDrawer('left', false)}>
                                    <MenuItem className={classes.menuItem}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <ListAltIcon />
                                        </ListItemIcon>
                                        <Typography variant="body1" color="textPrimary">
                                            กระทู้ของฉัน
                                        </Typography>
                                    </MenuItem>
                                </Link>
                                <Link className={classes.link} to="/inbox" onClick={toggleDrawer('left', false)}>
                                    <MenuItem className={classes.menuItem}>
                                        <ListItemIcon className={classes.listIcon}>
                                            <ForumIcon />
                                        </ListItemIcon>
                                        <Typography variant="body1" color="textPrimary" className={classes.menutext}>
                                            ข้อความ
                                        </Typography>
                                        {
                                            profile.inboxNoti && <div className={classes.menuNoti} />
                                        }
                                    </MenuItem>
                                </Link>
                                <Box ml={2} mr={2} mt={2}>
                                    <Divider />
                                </Box>
                            </>
                    }
                    <Box mt={2} mb={2}>
                        <Link className={classes.link} to="/live" onClick={toggleDrawer('left', false)}>
                            <MenuItem className={classes.menuItem}>
                                <Typography variant="body1" color="textPrimary">
                                    Live
                                </Typography>
                            </MenuItem>
                        </Link>
                        <Link className={classes.link} to="/lifestyle" onClick={toggleDrawer('left', false)}>
                            <MenuItem className={classes.menuItem}>
                                <Typography variant="body1" color="textPrimary">
                                    Lifestyle
                                </Typography>
                            </MenuItem>
                        </Link>
                        <Link className={classes.link} to="/finance" onClick={toggleDrawer('left', false)}>
                            <MenuItem className={classes.menuItem}>
                                <Typography variant="body1" color="textPrimary">
                                    Finance
                                </Typography>
                            </MenuItem>
                        </Link>
                        <Link className={classes.link} to="/inspiration" onClick={toggleDrawer('left', false)}>
                            <MenuItem className={classes.menuItem}>
                                <Typography variant="body1" color="textPrimary">
                                    Inspiration
                                </Typography>
                            </MenuItem>
                        </Link>
                    </Box>
                    <Box ml={2} mr={2}>
                        <Divider />
                    </Box>
                    <Box mt={2} mb={2}>
                        <Link className={classes.link} to="/webboard" onClick={toggleDrawer('left', false)}>
                            <MenuItem className={classes.menuItem}>
                                <Typography variant="body1" color="textPrimary">
                                    เว็บบอร์ด
                                </Typography>
                            </MenuItem>
                        </Link>
                        <Link className={classes.link} to="/posts" onClick={toggleDrawer('left', false)}>
                            <MenuItem className={classes.menuItem}>
                                <Typography variant="body1" color="textPrimary">
                                    ประกาศซื้อ-ขายอสังหาฯ
                                </Typography>
                            </MenuItem>
                        </Link>
                    </Box>
                    <Box ml={2} mr={2}>
                        <Divider />
                    </Box>
                    {
                        authLevel >= 2 ?
                            <Box mt={2} mb={3} style={{textAlign: 'center'}}>
                                <MenuItem 
                                    className={classes.menuItem}
                                    onClick={() => signOut()}
                                    style={{ justifyContent: 'center' }}
                                >
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        style={{ color: '#c7c7c7' }}
                                    >
                                        ออกจากระบบ
                                    </Typography>
                                </MenuItem>
                            </Box>
                            :
                            <Box mt={2} mb={3}>
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={() => {
                                        handleOpenDialog('login');
                                        toggleDrawer('left', false);
                                    }}
                                >
                                    <Typography 
                                        variant="body1" 
                                        color="textPrimary"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        ลงประกาศ
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={() => {
                                        handleOpenDialog('login');
                                        toggleDrawer('left', false);
                                    }}
                                >
                                    <Typography 
                                        variant="body1" 
                                        color="textPrimary"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        เข้าสู่ระบบ
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={() => {
                                        handleOpenDialog('register');
                                        toggleDrawer('left', false);
                                    }}
                                >
                                    <Typography 
                                        variant="body1" 
                                        color="textPrimary" 
                                        style={{ 
                                            color: '#00abbd',
                                            cursor: 'pointer' 
                                        }}
                                    >
                                        ลงทะเบียน
                                    </Typography>
                                </MenuItem>
                            </Box>
                    }
                </div>
            </Drawer>
            <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
                <div className={classes.rightMenuListInner}>
                    <Grid
                        spacing={0}
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <CustomSearchBox 
                                classes={classes}
                                toggleDrawer={toggleDrawer}
                                updateSearch={updateSearch}
                            />
                        </Grid>
                    </Grid>
                    { !isSearching &&
                        <Box mt={1}>
                            <Typography variant="subtitle2">{MenuLang[lang].suggestionSearch}</Typography>
                        </Box>
                    }
                    <List className={classes.lists}>
                        <CustomHits
                            lang={lang}
                            classList={classes.list}
                            classListIcon={classes.listIcon}
                            closeSearch={toggleDrawer('right', false)}
                            searchNumber={searchNumber} 
                        />
                    </List>
                </div>
            </Drawer>
            {/* ---- Mobile Dialog ---- */}
            <LoginRegiterDialog
                isOpen={isOpen}
                name={state.dialog}
                handleChangePage={handleOpenDialog}
                handleClose={handleCloseDialog}
            />
        </>
    );
}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired,
    bg: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    updateSearch: PropTypes.func.isRequired,
    isSearching: PropTypes.bool.isRequired,
    searchNumber: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    authLevel: PropTypes.number.isRequired,
    profile: PropTypes.object.isRequired,
};

SearchBox.propTypes = {
    classes: PropTypes.object.isRequired, 
    toggleDrawer: PropTypes.func.isRequired, 
    currentRefinement: PropTypes.string.isRequired, 
    refine: PropTypes.func.isRequired,
    updateSearch: PropTypes.func.isRequired, 
};

Hits.propTypes = {
    hits: PropTypes.array.isRequired,  
    classList: PropTypes.string.isRequired,  
    classListIcon: PropTypes.string.isRequired,  
    closeSearch: PropTypes.func.isRequired,  
    searchNumber: PropTypes.func.isRequired, 
    lang: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.auth.openDialog,
    message: state.auth.message,
});

const mapDispatchToProps = {
    toggle: toggleDialog,
    signOut: postSignOut,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Navbar));