/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import MenuLang from 'common/menu';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import NotFoundImage from './404.svg';

const styles = (theme) => ({
    card: {
        textAlign: 'center',
        minHeight: 'calc(100vh - 219px)',
        padding: '50px 0 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 292px)',
        },
    },
    media: {
        height: 73,
        width: 'auto',
        margin: '0 auto 30px auto',
    },
    title: {
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        },
    },
    button: {
        width: 350,
        margin: '0 auto',
    },
});


function NotFound(props) {
    const {
        classes,
        lang,
    } = props;

    return (
        <>
            <TitleComponent title="404 - All About Living" />
            <Card className={classes.card}>
                <CardMedia 
                    className={classes.media} 
                    image={NotFoundImage} 
                    component="img"
                    title={MenuLang[lang].notFound}
                />
                <Typography variant="h5" color="textPrimary" className={classes.title}>
                    {MenuLang[lang].notFound}
                </Typography>
                <Box className={classes.button}>
                    <Link to="/" style={{textDecoration: 'none'}}>
                        <Button color="primary" variant="contained">
                            {MenuLang[lang].backHome}
                        </Button>
                    </Link>
                </Box>
            </Card>
        </>
    );
}

NotFound.propTypes = {
    lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(NotFound));
