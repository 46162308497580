/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import RoomIcon from '@material-ui/icons/Room';
import { HAds } from 'components/sections/ads';
import List from 'components/sections/list/latest';
import TitleComponent from 'components/roots/titleComponent';
import MenuText from 'common/menu';

function Location(props) {
    const {
        lang,
        loading,
        loadingMore,
        showLoadingMore,
        list,
        name,
        getList,
        location,
        loadmore,
        getAllPageAds, 
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];
    const lastVisibleUid = list[list.length - 1] ? list[list.length - 1].uid : '';

    React.useEffect(() => {
        getList(lang, uid);
        getAllPageAds();
    }, [getList, lang, uid, getAllPageAds]);

    return (
        <>
            <TitleComponent title={`${name || 'Location'} - All About Living`} />
            <HAds
                color="white"
                position="header"
                pageLoading={loading}
            />
            <List
                loading={loading}
                loadingMore={loadingMore}
                loadmoreFunc={() => loadmore(lang, uid, lastVisibleUid)}
                loadmoreTitle={MenuText[lang].loadmore}
                showLoadingMore={showLoadingMore}
                list={list}
                title={
                    <>
                        <RoomIcon/> {name}
                    </>
                }
            />
            <HAds
                color="white"
                position="footer"
                pageLoading={loading}
            />
        </>
    );
}

Location.propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingMore: PropTypes.bool.isRequired,
    showLoadingMore: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    getList: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    loadmore: PropTypes.func.isRequired, 
    getAllPageAds: PropTypes.func.isRequired,
};

export default withRouter(Location);