import React from 'react';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Skeleton from 'react-loading-skeleton';

const styles = () => ({
    row:{
        color: '#c7c7c7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
    },
    left: {
        display: 'flex',
        alignItems: 'center',
    },
    right:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: "flex-end",
    }
});

function DateViewComment(props) {
    const {
        classes,
        date,
        view,
        comment,
        loading,
        createdBy,
    } = props;
    return (
        <Box className={classes.row}>
            {loading ?
                <>
                    <Box className={classes.left}>
                        <Typography variant="caption">
                            <Skeleton width={180} count={1} />
                        </Typography>
                    </Box>
                    <Box className={classes.right}>
                        <Typography variant="caption">
                            <Skeleton width={180} count={1} />
                        </Typography>
                    </Box>
                </>
                :
                <>
                    <Box className={classes.left}>
                        <Hidden smDown>
                            <Typography variant="caption">
                                {createdBy}
                            </Typography>
                        </Hidden>
                        <Hidden mdUp>
                            <Typography variant="caption">
                                {date}
                            </Typography>
                        </Hidden>
                    </Box>
                    <Box className={classes.right}>
                        <Hidden smDown>
                            <Box mr={{ xs: 'auto', md: "10px" }}>
                                <Typography variant="caption">
                                    {date}
                                </Typography>
                            </Box>
                            <Box mr="10px">
                                <Typography variant="caption">
                                    |
                                </Typography>
                            </Box>
                        </Hidden>
                        <Box mr={1}>
                            <VisibilityIcon fontSize="small" />
                        </Box>
                        <Box mr="10px">
                            <Typography variant="caption">
                                {view}
                            </Typography>
                        </Box>
                        <Box mr={1}>
                            <ChatBubbleIcon fontSize="small" />
                        </Box>
                        <Box>
                            <Typography variant="caption">
                                {comment}
                            </Typography>
                        </Box>
                    </Box>
                </>
            }
        </Box>
    );
}

DateViewComment.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    date: PropTypes.string,
    view: PropTypes.number,
    comment: PropTypes.number,
    loading: PropTypes.bool,
    createdBy: PropTypes.string,
};

DateViewComment.defaultProps = {
    date: '',
    createdBy: '',
    view: 0,
    comment: 0,
    loading: true,
};

export default withStyles(styles)(DateViewComment);