/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
    tabRoot: {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            minWidth: 0,
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        minWidth: 200,
        padding: '20px 12px',
        [theme.breakpoints.down('sm')]: {
            minWidth: 0,
            padding: '16px 20px',
        },
    }
});

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function MyAccountHeader(props) {
    const {
        classes,
        // lang,
        value,
        signInByEmail,
    } = props;

    return (
        <>
            { signInByEmail &&
                <>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab
                            className={classes.tabRoot}
                            label={
                                <Link to="/my-posts" className={classes.link} >
                                    < Typography variant="body1">
                                        ทั้งหมด
                                    </Typography>
                                </Link>
                            }
                            {...a11yProps(0)}
                        />
                        <Tab
                            className={classes.tabRoot}
                            label={
                                <Link to="/my-posts/normal" className={classes.link}>
                                    < Typography variant="body1">
                                        ทั่วไป
                                    </Typography>
                                </Link>
                            }
                            {...a11yProps(1)}
                        />
                        <Tab
                            className={classes.tabRoot}
                            label={
                                <Link to="/my-posts/promote" className={classes.link}>
                                    < Typography variant="body1">
                                        กำลังโปรโมต
                                    </Typography>
                                </Link>
                            }
                            {...a11yProps(2)}
                        />
                        <Tab
                            className={classes.tabRoot}
                            label={
                                <Link to="/my-posts/sold-rented" className={classes.link}>
                                    < Typography variant="body1">
                                        ขาย/เช่าแล้ว
                                    </Typography>
                                </Link>
                            }
                            {...a11yProps(2)}
                        />
                    </Tabs>
                    <Divider />
                </>
            }
        </>
    );
}

MyAccountHeader.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    signInByEmail: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    signInByEmail: state.auth.signInByEmail,
});

export default connect(
    mapStateToProps,
    null
)(withStyles(styles)(withRouter(MyAccountHeader)));