import { connect } from 'react-redux';
import { resetData, getList, getPromote, setSortedBy, loadmore } from 'actions/postsQuery';
import {
    getAllPageAds,
} from 'actions/ads';
import Topics from 'views/post/list';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.postsQuery.loading,
    loadingMore: state.postsQuery.loadingMore,
    showLoadingMore: state.postsQuery.showLoadingMore,
    list: state.postsQuery.list,
    promoteList: state.postsQuery.promoteList,
    cover: state.postsQuery.cover,
    sortBy: state.postsQuery.sortBy,
    purpose: state.postsQuery.filter.purpose,
});

const mapDispatchToProps = {
    resetData,
    getList,
    getPromote,
    setSortedBy,
    loadmore,
    getAllPageAds,
};

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
