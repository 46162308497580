/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import CardPost from 'components/roots/card/cardPost';

const styles = theme => ({
    background: {
        position: 'relative',
        display: 'block',
        paddingTop: 48,
        paddingBottom: 48,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 24,
        },
    },
    default: {
        backgroundColor: '#f9f9f9',
    },
    white: {
        backgroundColor: '#fff',
    },
    green: {
        backgroundColor: '#002023',
        paddingBottom: 60,
    },
    header: {
        textAlign: 'center',
        marginBottom: 24,
    },
});

function SuggestTopics(props) {
    const { classes, bgColor, title, list, pageLoading, loading,name } = props;

    let colorClass = classes.default;
    if (bgColor === 'white') {
        colorClass = classes.white;
    }
    if (bgColor === 'green') {
        colorClass = classes.green;
    }

    const initialArr = [...Array(3).keys()];

    return (
        <div className={clsx(classes.background, colorClass)}>
            <Box className={classes.header}>
                <Typography variant="h2" color="textPrimary">
                    {pageLoading || loading ? <Skeleton width={200} /> : `${title} "${name}"`}
                </Typography>
            </Box>
            <Container>
                <Grid container spacing={3}>
                    {pageLoading || loading
                        ? initialArr.map(item => (
                            <Grid key={item} item xs={12} md={4}>
                                <CardPost />
                            </Grid>
                        ))
                        : list.map(item => (
                            <Grid key={item.uid} item xs={12} md={4}>
                                <CardPost
                                    loading={pageLoading || loading}
                                    isPromote={false}
                                    url={item && `/post/${item.url}/${item.uid}`}
                                    img={item && item.img}
                                    title={item && item.title}
                                    location={item && item.locationText}
                                    sellPrice={item && item.sellPrice}
                                    rentPrice={item && item.rentPrice}
                                    areaSize={item && item.areaSize}
                                    floor={item && item.floor}
                                    bedroomNum={item && item.bedroomNum}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Container>
        </div>
    );
}

SuggestTopics.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    pageLoading: PropTypes.bool,
};

SuggestTopics.defaultProps = {
    bgColor: 'default',
    loading: true,
    pageLoading: true,
};

const mapStateToProps = state => ({
    loading: state.postsQuery.projectLoading,
    list: state.postsQuery.projectList,
    name: state.postsQuery.projectName,
});

export default connect(
    mapStateToProps,
    null,
)(withStyles(styles)(SuggestTopics));
