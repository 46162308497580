import React from 'react';
import Fab from '@material-ui/core/Fab';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Live from './live.svg';

const styles = theme => ({
    button: {
        color: '#fff',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        minHeight: 20,
        '&:active': {
            boxShadow: 'none',
        },
    },
    l: {
        width: 56,
        height: 56,
        '& img': {
            width: 27,
            height: 27,
        },
        [theme.breakpoints.down('sm')]: {
            width: 48,
            height: 48,
            '& img': {
                width: 24,
                height: 24,
            },
        },
    },
    activeL: {
        border: 'solid 8px rgba(255, 255, 255, 0.5)',
        [theme.breakpoints.down('sm')]: {
            border: 'solid 6px rgba(255, 255, 255, 0.5)',
        },
    },
});

function Marker(props) {
    const {
        classes,
    } = props;


    return (
        <Fab
            color="secondary"
            className={clsx(
                classes.button,
                classes.l,
                classes.activeL,
            )}>
            <img src={Live} alt="button" />
        </Fab>
    );
}

Marker.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Marker);
