import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';

import { TagNew } from '../element';

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: 'none',
        borderRadius: 8,
        backgroundColor: 'transparent',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 4,
        }
    },
    media: {
        width: 180,
        height: 135,
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            borderRadius: 4,
            height: 78,
            width: 104,
        }
    },
    loading:{
        width: 180,
        height: 135,
        borderRadius: 8,
        backgroundColor: 'rgb(238, 238, 238)',
        backgroundImage: 'linear-gradient(90deg, rgb(238, 238, 238), rgb(245, 245, 245), rgb(238, 238, 238))',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 4,
            height: 78,
            width: 104,
        }
    },
    tag:{
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        }
    },
    title: {
        marginBottom: '14px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        }
    },
    content: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: 0,
        paddingBottom: '0 !important',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    }
});

function List(props) {
    const { classes, url, tag, img, title, date, loading } = props;
    return (
        <Card className={classes.card}>
            {!loading ?
                <Link to={url} className={classes.link}>
                    <CardMedia component="img" src={img} className={classes.media} />
                </Link>
                :
                <div className={classes.loading} />
            }
            <CardContent className={classes.content}>
                {loading && <Skeleton width={40} />}
                <Box className={classes.tag}>
                    {tag !== '' && <TagNew text={tag} />}
                </Box>
                <Box className={classes.title}>
                    <Link to={url} className={classes.link}>
                        <Typography variant="subtitle2" style={{ color: '#fff' }}>
                            {loading ? <Skeleton width="100%" count={1} /> : title}
                        </Typography>
                    </Link>
                </Box>
                <Box >
                    <Typography variant="caption" style={{ color: '#c7c7c7' }} >
                        {loading ? <Skeleton width={100} /> : date}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

List.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    tag: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    loading: PropTypes.bool,
};

List.defaultProps = {
    url: '',
    tag: '',
    img: '',
    title: '',
    date: '',
    loading: false,
};

export default withStyles(styles)(List);