import _ from 'lodash';
import firebase from 'common/firebase';
import { setNotFound } from './main';

const functions = firebase.app().functions('asia-east2');

// Action
// -- CREATE/UPDATE
export const FETCH_ARTICLE = 'FETCH_ARTICLE';
export const FETCH_ARTICLE_DONE = 'FETCH_ARTICLE_DONE';
export const FETCH_ARTICLE_FAIL = 'FETCH_ARTICLE_FAIL';
export const FETCH_ARTICLE_RELATED = 'FETCH_ARTICLE_RELATED';
export const FETCH_ARTICLE_RELATED_DONE = 'FETCH_ARTICLE_RELATED_DONE';
export const FETCH_ARTICLE_RELATED_FAIL = 'FETCH_ARTICLE_RELATED_FAIL';
export const FETCH_ARTICLE_MAP = 'FETCH_ARTICLE_MAP';
export const FETCH_ARTICLE_MAP_CENTER = 'FETCH_ARTICLE_MAP_CENTER';
export const FETCH_ARTICLE_MAP_DONE = 'FETCH_ARTICLE_MAP_DONE';
export const FETCH_ARTICLE_MAP_FAIL = 'FETCH_ARTICLE_MAP_FAIL';
export const SET_ARTICLE_MAP_FILTER = 'SET_ARTICLE_MAP_FILTER';
export const CLEAR_ARTICLE_MAP_FILTER = 'CLEAR_ARTICLE_MAP_FILTER';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_ARTICLE,
    };
};
export function fetchDone(data) {
    return {
        type: FETCH_ARTICLE_DONE,
        data: data.data,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_ARTICLE_FAIL,
        message,
    };
};
export function fetchRelated() {
    return {
        type: FETCH_ARTICLE_RELATED,
    };
};
export function clearFilter() {
    return {
        type: CLEAR_ARTICLE_MAP_FILTER,
    };
};
export function fetchRelatedDone(data) {
    return {
        type: FETCH_ARTICLE_RELATED_DONE,
        list: data,
    };
};
export function fetchRelatedFail(message) {
    return {
        type: FETCH_ARTICLE_RELATED_FAIL,
        message,
    };
};
export function fetchMap() {
    return {
        type: FETCH_ARTICLE_MAP,
    };
};
export function setCenter(activeId, center) {
    return {
        type: FETCH_ARTICLE_MAP_CENTER,
        center,
        activeId
    };
};
export function setFilter(field, value) {
    return {
        type: SET_ARTICLE_MAP_FILTER,
        field, 
        value,
    };
};
export function fetchMapDone(data) {
    return {
        type: FETCH_ARTICLE_MAP_DONE,
        list: data.list,
        center: data.center,
    };
};
export function fetchMapFail() {
    return {
        type: FETCH_ARTICLE_MAP_FAIL,
    };
};

// Async Action Creator
export function getData(lang,uid) {
    return async (dispatch) => {
        dispatch(fetch());

        const getArticle = functions.httpsCallable('articleGetData');
        try {
            const data = {lang, uid};
            const result = await getArticle(data);
            const output = result.data;
            if (!_.isEmpty(output)) {
                dispatch(fetchDone(output));
            } else {
                dispatch(setNotFound(true));
            }
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function getRelated(lang, uid) {
    return async (dispatch) => {
        dispatch(fetchRelated());

        const getArticle = functions.httpsCallable('articleGetRelated');
        try {
            const data = { lang, uid };
            const result = await getArticle(data);
            const output = result.data;
            dispatch(fetchRelatedDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchRelatedFail(error.message));
        }
    };
}

export function getMap(lang, uid) {
    return async (dispatch) => {
        dispatch(fetchMap());

        const getMapData = functions.httpsCallable('articleGetMap');
        try {
            const data = { lang, uid };
            const result = await getMapData(data);
            const output = result.data;
            if (!_.isEmpty(output)) {
                dispatch(fetchMapDone(output));
            } else {
                dispatch(fetchMapFail());
            }
        }
        catch (error) {
            console.log(error);
            dispatch(fetchMapFail());
        }
    };
}
