import { connect } from 'react-redux';
import { 
    resetProfile,
    updatePassword,
    postPassword,  
} from 'actions/myAccount';
import ChangePassword from 'views/myAccount/password';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.myAccount.loading,
    password: state.myAccount.password,
    errors: state.myAccount.passwordErrors,
    touched: state.myAccount.passwordTouched,
    isValid: state.myAccount.passwordIsValid,
});

const mapDispatchToProps = {
    resetProfile,
    updatePassword,
    postPassword,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);



