import firebase from 'common/firebase';
import validate from 'validate.js';
import moment from 'moment';
import { showSnack } from './main';

// Action
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_UPDATE_LEVEL = 'AUTH_UPDATE_LEVEL';
export const AUTH_UPDATE_PROFILE = 'AUTH_UPDATE_PROFILE';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_DIALOG = 'AUTH_DIALOG';
export const UPDATE_SIGN_UP = 'UPDATE_SIGN_UP';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';
export const UPDATE_SIGN_IN = 'UPDATE_SIGN_IN';
export const SIGN_IN = 'SIGN_IN';
export const GOOGLE_SIGN_IN = 'GOOGLE_SIGN_IN';
export const FACEBOOK_SIGN_IN = 'FACEBOOK_SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
export const UPDATE_FORGOT_PASSWORD = 'UPDATE_FORGOT_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_AUTH_INBOX_NOTI = 'UPDATE_AUTH_INBOX_NOTI';
// export const NOT_SIGN_IN = 'NOT_SIGN_IN';
// export const IS_LOADING = 'IS_LOADING';
// export const LOADING_DONE = 'LOADING_DONE';
export const AUTH_RESET = 'AUTH_RESET';

const signupSchema = {
    name: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64
        }
    },
    phoneNumber: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 12
        }
    },
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
            minimum: 6,
        }
    }
};

const signinSchema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};

const forgotSchema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
};

// Sync Action Creator
export function checkAuth() {
    return {
        type: AUTH_CHECK,
    };
};
export function updateAuthLevel(level, uid, signInByEmail) {
    return {
        type: AUTH_UPDATE_LEVEL,
        level,
        uid,
        signInByEmail,
    };
};
export function updateAuthProfile(profile) {
    return {
        type: AUTH_UPDATE_PROFILE,
        profile,
    };
};
export function updateAuthInboxNoti(status) {
    return {
        type: UPDATE_AUTH_INBOX_NOTI,
        status,
    };
};
export function toggleDialog(value) {
    return {
        type: AUTH_DIALOG,
        value,
    };
};
export function authFail(message) {
    return {
        type: AUTH_FAIL,
        message,
    };
};
export function resetAuth() {
    return {
        type: AUTH_RESET,
    };
};
export function updateSignUp(oldData, fieldName, newValue) {
    const oldObj = oldData;
    oldObj[fieldName] = newValue;
    const errors = validate(oldData, signupSchema);

    return {
        type: UPDATE_SIGN_UP,
        fieldName,
        newValue,
        errors,
    };
};
export function signUp() {
    return {
        type: SIGN_UP,
    };
};
export function signUpSuccess() {
    return {
        type: SIGN_UP_SUCCESS,
    };
};
export function signUpFail(message) {
    return {
        type: SIGN_UP_FAIL,
        message,
    };
};
export function updateSignIn(oldData, fieldName, newValue) {
    const oldObj = oldData;
    oldObj[fieldName] = newValue;
    const errors = validate(oldData, signinSchema);

    return {
        type: UPDATE_SIGN_IN,
        fieldName,
        newValue,
        errors,
    };
};
export function signIn() {
    return {
        type: SIGN_IN,
    };
};
export function signInGoogle() {
    return {
        type: GOOGLE_SIGN_IN,
    };
};
export function signInFacebook() {
    return {
        type: FACEBOOK_SIGN_IN,
    };
};
export function signInSuccess(profile) {
    return {
        type: SIGN_IN_SUCCESS,
        profile
    };
};
export function signInFail(message) {
    return {
        type: SIGN_IN_FAIL,
        message,
    };
};
export function forgotPassword() {
    return {
        type: FORGOT_PASSWORD,
    };
};
export function updateForgot(newValue) {
    const data = {
        email: newValue,
    };
    const errors = validate(data, forgotSchema);

    return {
        type: UPDATE_FORGOT_PASSWORD,
        forgotEmail: newValue,
        errors,
        fieldName: 'email',
    };
};
export function signOut(message) {
    return {
        type: SIGN_OUT,
        message,
    };
};
// export function notSignIn() {
//     return {
//         type: NOT_SIGN_IN,
//     };
// };
// export function isLoading() {
//     return {
//         type: IS_LOADING,
//     };
// };
// export function loadingDone(successMessage) {
//     return {
//         type: LOADING_DONE,
//         successMessage,
//     };
// };

// Async Action Creator
export function anonymousAuth() {
    return (dispatch) => {
        dispatch(checkAuth());
        return firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in.
                const { uid, displayName, email, photoURL } = user;
                
                let signInByEmail = false;
                user.providerData.forEach((providerData) => {
                    const { providerId } = providerData;
                    if (providerId === 'password'){
                        signInByEmail = true;
                    }
                });
                if (user.isAnonymous){
                    dispatch(updateAuthLevel(1, uid, signInByEmail));    
                }else{
                    const userData = await firebase.firestore().collection("users").doc(uid).get();                    
                    const profile = {
                        name: displayName,
                        email,
                        phoneNumber: userData.data() ? userData.data().phoneNumber : '',
                        photoURL,
                        inboxNoti: userData.data() ? userData.data().inboxNoti : false,
                    };
                    dispatch(updateAuthProfile(profile));
                    // email, facebook, google signed in.
                    dispatch(updateAuthLevel(2, uid, signInByEmail));
                }
            } else {
                // No user is signed in.
                firebase.auth().signInAnonymously().catch((error) => {
                    // Handle Errors here.
                    dispatch(authFail(error.message));
                });
            }
        });
    };
};
export function postSignUp(data) {
    return async (dispatch) => {
        dispatch(signUp());

        try {
            // create user
            await firebase.auth().createUserWithEmailAndPassword(data.email, data.password);
            
            // update profile
            const user = await firebase.auth().currentUser;
            const profile = { displayName: data.name };
            await user.updateProfile(profile);

            const { uid = '' } = await firebase.auth().currentUser;
            const ref = firebase.firestore().collection("users").doc(uid);
            const inputData = {
                phoneNumber: data.phoneNumber,
                isAgency: data.isAgency,
                timestamp: moment().valueOf(),
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            };
            await ref.set(inputData);

            dispatch(toggleDialog(false));
            dispatch(updateAuthProfile(data));
            dispatch(signUpSuccess());
        } catch (error) {
            dispatch(toggleDialog(false));
            dispatch(signUpFail(error.message));
            dispatch(showSnack('warning', error.message));
        }
    };
};
export function postSignIn(data) {
    return async (dispatch) => {
        dispatch(signIn());
        try {
            const result = await firebase.auth().signInWithEmailAndPassword(data.email, data.password);

            const output = {
                name: result.user.displayName,
                email: result.user.email,
                phoneNumber: result.user.phoneNumber,
                photoURL: result.user.photoURL,
            };

            dispatch(toggleDialog(false));
            dispatch(signInSuccess(output));
        } catch (error) {
            dispatch(toggleDialog(false));
            dispatch(signInFail(error.message));
            dispatch(showSnack('warning', error.message));
        };
    };
};
export function postForgot(email) {
    return async (dispatch) => {
        dispatch(forgotPassword());
        try {
            await firebase.auth().sendPasswordResetEmail(email);
            dispatch(toggleDialog(false));
            dispatch(showSnack('success', `An email has been sent to ${email}. Click the link in the email to reset your password.`));
            dispatch(resetAuth());
        } catch (error) {
            dispatch(toggleDialog(false));
            dispatch(showSnack('warning', error.message));
            dispatch(resetAuth());
        };
    };
};
export function googleSignIn() {
    return async (dispatch) => {
        dispatch(signInGoogle());
        try {
            // Start a sign in process for an unauthenticated user.
            // console.log('google signin', isNewUser.currentUser);
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');

            // console.log('eiei', provider.credential());

            const currentUser = await firebase.auth().currentUser;
            if(!currentUser) {
                const ref = firebase.firestore().collection("users");
                const inputData = {
                    phoneNumber: null,
                    isAgency: false,
                    timestamp: moment().valueOf(),
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                await ref.add(inputData);
            }
            await firebase.auth().signInWithRedirect(provider);
        } catch (error) {
            dispatch(toggleDialog(false));
            dispatch(signInFail(error.message));
            dispatch(showSnack('warning', error.message));
        };
    };
};
export function facebookSignIn() {
    return async (dispatch) => {
        dispatch(signInFacebook());
        try {
            // Start a sign in process for an unauthenticated user.
            const provider = new firebase.auth.FacebookAuthProvider();

            await firebase.auth().signInWithRedirect(provider);
        } catch (error) {
            dispatch(toggleDialog(false));
            dispatch(signInFail(error.message));
            dispatch(showSnack('warning', error.message));
        };
    };
};
export function postSignOut() {
    return (dispatch) => {
        return firebase.auth().signOut().then(() => {
            dispatch(signOut('signout'));
            dispatch(resetAuth());
        }).catch((error) => {
            dispatch(showSnack('warning', error.message));
        });
    };
}
export function updateNotification() {
    return (dispatch) => {
        return firebase.auth().onAuthStateChanged(async (user) => {
            if (user && !user.isAnonymous) {
                // User is signed in.
                const { uid, displayName, email, photoURL } = user;
                const userData = await firebase.firestore().collection("users").doc(uid).get();
                const profile = {
                    name: displayName,
                    email,
                    phoneNumber: userData.data() ? userData.data().phoneNumber : '',
                    photoURL,
                    inboxNoti: userData.data() ? userData.data().inboxNoti : false,
                };
                dispatch(updateAuthProfile(profile));
            } 
        });
    };
};