import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';

const styles = () => ({
    media: {
        width: 50,
        height: 50,
        textTransform: 'uppercase',
        backgroundColor: "#00abbd",
        cursor: 'pointer',
    },
});

function CardPaper(props) {
    const { 
        classes, 
        img, 
        name,
    } = props;

    return (
        <>
            {
                img ?
                    <Avatar
                        alt={name}
                        src={img}
                        className={classes.media}
                    />
                    :
                    <Avatar
                        color="primary"
                        className={classes.media}
                    >
                        {name.charAt(0)}
                    </Avatar>
            }
        </>
    );
}

CardPaper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    img: PropTypes.string,
    name: PropTypes.string.isRequired,
};

CardPaper.defaultProps = {
    img: '',
};

export default withStyles(styles)(CardPaper);