import React from 'react';
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Live from './live.svg';
import LifeStyle from './lifestyle.svg';
import Finance from './finance.svg';
import Inspiration from './inspiration.svg';

const styles = theme => ({
    button:{
        color: '#fff',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        minHeight: 20,
        '&:active':{
            boxShadow: 'none',
        }
    },
    l: {
        width: 56,
        height: 56,
        '& img':{
            width: 27,
            height: 27,
        },
        [theme.breakpoints.down('sm')]: {
            width: 48,
            height: 48,
            '& img': {
                width: 24,
                height: 24,
            },
        }
    },
    m: {
        width: 48,
        height: 48,
        '& img':{
            width: 24,
            height: 24,
        },
        [theme.breakpoints.down('sm')]: {
            width: 40,
            height: 40,
            '& img':{
                width: 20,
                height: 20,
            },
        }
    },
    s: {
        width: 24,
        height: 24,
        [theme.breakpoints.down('sm')]: {
            width: 20,
            height: 20,
        }
    },
    activeS: {
        border: 'solid 4px rgba(255, 255, 255, 0.5)',
        [theme.breakpoints.down('sm')]: {
            border: 'solid 3px rgba(255, 255, 255, 0.5)',
        }
    },
    activeM: {
        border: 'solid 6px rgba(255, 255, 255, 0.5)',
        [theme.breakpoints.down('sm')]: {
            border: 'solid 4px rgba(255, 255, 255, 0.5)',
        }
    },
    activeL: {
        border: 'solid 8px rgba(255, 255, 255, 0.5)',
        [theme.breakpoints.down('sm')]: {
            border: 'solid 6px rgba(255, 255, 255, 0.5)',
        }
    },
    popper: {
        zIndex: 1,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper:{
        width: 315,
        padding: 16,
    }
});

function Marker(props) {
    const { classes, size, category, active, title, desc, link, onClick } = props;

    // Button
    let classButton = classes.l;
    if (size === 's'){
        classButton = classes.s;
    }
    if (size === 'm') {
        classButton = classes.m;
    }

    // Icon
    let Image = Live;
    if (category === 'lifestyle') {
        Image = LifeStyle;
    }
    if (category === 'finance') {
        Image = Finance;
    }
    if (category === 'inspiration'){
        Image = Inspiration;
    }

    // Active
    let classActive = classes.activeL;
    if (size === 's') {
        classActive = classes.activeS;
    }
    if (size === 'm') {
        classActive = classes.activeM;
    }

    // Popup
    const [anchorEl, setAnchorEl] = React.useState(null);
    function handleClick(event) {
        onClick();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }
    const [arrowRef, setArrowRef] = React.useState(null);
    function handleArrowRef(node) {
        setArrowRef(node);
    };
    function handleClickAway(){
        setAnchorEl(null);
    };

    // Open/Close
    const open = Boolean(anchorEl);
    const id = open ? 'scroll-playground' : null;


    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <Fab
                    onClick={handleClick}
                    color={size === 'l' ? "secondary" : "primary"} 
                    className={clsx(classes.button, classButton, active && classActive)}
                >
                    {   
                        size !== 's' && <img src={Image} alt="button" />
                    }
                </Fab>
                <Popper 
                    id={id}
                    className={classes.popper} 
                    open={open} 
                    anchorEl={anchorEl} 
                    transition
                    placement="top"
                    disablePortal
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'scrollParent',
                        },
                        arrow: {
                            enabled: true,
                            element: arrowRef,
                        },
                    }}
                >
                    <span className={classes.arrow} ref={handleArrowRef} />
                    <Paper className={classes.paper}>
                        <Box mb="4px">
                            <Typography variant="subtitle2">
                                {title}
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="body2" color="textSecondary">
                                {desc}
                            </Typography>
                        </Box>
                        {   link !== "" && 
                            <Box style={{textAlign: 'center'}}>
                                <Link to={link} style={{textDecoration: 'none'}}>
                                    <Button color="primary">
                                        <Typography variant="body2" style={{ color: '#00abbd' }}>
                                            ดูเพิ่มเติม
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        }
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

Marker.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    size: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    active: PropTypes.bool,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired, 
    link: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

Marker.defaultProps = {
    active: false,
    link: "",
};

export default withStyles(styles)(Marker);