import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Skeleton from 'react-loading-skeleton';
import MobileDateViewComment from 'components/roots/status/MobileDateViewComment';

const styles = theme => ({
    card: {
        boxShadow: 'none',
        width: '100%',
        borderRadius: 0,
        marginBottom: 24,
        display: 'flex',
        backgroundColor: '#002023',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        },
    },
    content: {
        boxShadow: 'none',
        width: '100%',
        padding: '0 0 8px 0 !important',
        borderBottom: '1px solid #e9e9e9',
        borderRadius: 0,
    },
    title: {
        width: '100%',
        marginBottom: 8,
    },
    link: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'flex-start',
        color: '#fff',
        '& svg':{
            marginRight: 5,
        }
    },
});

function MyTopic(props) {
    const { 
        classes, 
        url,   
        title, 
        date,
        view,
        comment,
        loading,
        isPin,
    } = props;

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                <Box className={classes.title}>
                    {loading ? 
                        <Typography variant="subtitle2" style={{color: '#fff'}}>
                            <Skeleton width="100%" count={1} />
                        </Typography> 
                        : 
                        <Link to={url} className={classes.link}>
                            {isPin && <BookmarkIcon/>}
                            <Typography variant="subtitle2" style={{color: '#fff'}}>
                                {title}
                            </Typography>
                        </Link>
                    }
                </Box>
                <MobileDateViewComment
                    date={date}
                    view={view}
                    comment={comment}
                    loading={loading}
                />
            </CardContent>
        </Card>
    );
}

MyTopic.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    view: PropTypes.number,
    comment: PropTypes.number,
    loading: PropTypes.bool,
    isPin: PropTypes.bool,
};

MyTopic.defaultProps = {
    url: '',
    title: '',
    date: '',
    view: 0,
    comment: 0,
    loading: true,
    isPin: false,
};

export default withStyles(styles)(MyTopic);