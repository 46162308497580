import { connect } from 'react-redux';
import {
    changeType,
    changeDev,
    changeLogo,
    changeGeo,
    update,
    updateNoLang,
    postCreate,
    postImage,
    postGallery,
    removeGallery,
} from 'actions/project';

import ProjectCreate from 'components/roots/propertyField/ProjectSearch/addProjectDialog';

const mapStateToProps = state => ({
    loading: state.project.loading,
    lang: state.project.lang,
    data: state.project.data,
    isValid: state.project.isValid,
    successMessage: state.project.successMessage,
});

const mapDispatchToProps = {
    changeType,
    changeDev,
    changeLogo,
    changeGeo,
    update,
    updateNoLang,
    postCreate,
    postImage,
    postGallery,
    removeGallery,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreate);
