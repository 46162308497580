import { connect } from 'react-redux';
import {
    getList,
} from 'actions/category';
import {
    getAllPageAds,
} from 'actions/ads';
import Live from 'views/live';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.category.loading,
    cover: state.category.cover,
    topics: state.category.topics,
    subCategory: state.category.subCategory,
    suggestion: state.category.suggestion,
});

const mapDispatchToProps = {
    getList,
    getAllPageAds,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Live);

