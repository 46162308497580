import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: '#0e894f',
    },
    warning: {
        backgroundColor: '#f06642',
    },
    disable: {
        backgroundColor: '#bdbdbd',
    },
    snackbar:{
        width: '100%',
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        transform: 'none',
        zIndex: -1,
    },
    snackbarInner: {
        width: '100%',
        minHeight: 44,
        borderRadius: 0,
        [theme.breakpoints.down('sm')]: {
            minHeight: 36,
        },
        '& .MuiSnackbarContent-message':{
            margin: '0 auto',
            maxWidth: 'calc(100% - 32px)',
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        '& .MuiSnackbarContent-action':{
            marginLeft: 0,
            paddingLeft: 0,
        },
    },
    icon: {
        fontSize: 20,
    },
    iconButton:{
        [theme.breakpoints.down('sm')]: {
            padding: 6,
        },
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
}));

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
}

function PositionedSnackbar(props) {
    const classes = useStyles();
    const { variant, message, open, setClose } = props;
    
    const handleClose = () => {
        setClose();
    };

    return (
        <Snackbar
            className={classes.snackbar}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            TransitionComponent={SlideTransition}
            open={open}
            autoHideDuration={3500}
            onClose={handleClose}
        >
            <SnackbarContent
                open={false}
                className={clsx(classes.snackbarInner, classes[variant])}
                aria-describedby="client-snackbar"
                message={
                    <span 
                        id="client-snackbar" 
                        key="message"
                        className={classes.message}
                    >
                        <Typography variant="button" style={{color: '#fff'}}>
                            {message}
                        </Typography>
                    </span>
                }
                action={[
                    <IconButton 
                        aria-label="close" 
                        key="close-button"
                        color="inherit"
                        onClick={handleClose}
                        className={classes.iconButton}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>     
    );
}

PositionedSnackbar.propTypes = {
    variant: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setClose: PropTypes.func.isRequired,
};

export default PositionedSnackbar;