import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import moment from 'moment';
import firebase from 'common/firebase';
import { showSnack } from './main';
import { changeProject } from './posts';

const functions = firebase.app().functions('asia-east2');

const schema = {
    typeId: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    devId: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    logo: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    lat: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    lng: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    titleTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    descTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
// -- CREATE/UPDATE
export const SET_PROJECT_TYPE = 'SET_PROJECT_TYPE';
export const SET_PROJECT_DEVELOPER = 'SET_PROJECT_DEVELOPER';
export const SET_PROJECT_IMAGE = 'SET_PROJECT_IMAGE';
export const SET_PROJECT_GEO = 'SET_PROJECT_GEO';
export const UPLOAD_PROJECT_GALLERY = 'UPLOAD_PROJECT_GALLERY';
export const UPLOAD_PROJECT_GALLERY_DONE = 'UPLOAD_PROJECT_GALLERY_DONE';
export const REMOVE_PROJECT_GALLERY = 'REMOVE_PROJECT_GALLERY';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_NO_LANG = 'UPDATE_PROJECT_NO_LANG';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_DATA_DONE = 'FETCH_PROJECT_DATA_DONE';
export const FETCH_PROJECT_DONE = 'FETCH_PROJECT_DONE';
export const FETCH_PROJECT_FAIL = 'FETCH_PROJECT_FAIL';
export const RESET_PROJECT = 'RESET_PROJECT';


// Sync Action Creator
// -- Create/Update --
export function changeType(typeId, oldData) {
    const validateData = {
        typeId,
        devId: oldData.devId,
        logo: oldData.img,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        titleTh: oldData.title.th,
        descTh: oldData.desc.th,
    };
    const errors = validate(validateData, schema);

    return {
        type: SET_PROJECT_TYPE,
        typeId,
        errors,
    };
}
export function changeDev(devId, oldData) {
    const validateData = {
        typeId: oldData.typeId,
        devId,
        logo: oldData.img,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        titleTh: oldData.title.th,
        descTh: oldData.desc.th,
    };
    const errors = validate(validateData, schema);
    return {
        type: SET_PROJECT_DEVELOPER,
        devId,
        errors,
    };
}
export function changeLogo(img, oldData) {
    const validateData = {
        typeId: oldData.typeId,
        devId: oldData.devId,
        logo: img,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        titleTh: oldData.title.th,
        descTh: oldData.desc.th,
    };
    const errors = validate(validateData, schema);
    return {
        type: SET_PROJECT_IMAGE,
        img,
        errors,
    };
}
export function changeGeo(lat, lng, oldData) {
    const validateData = {
        typeId: oldData.typeId,
        devId: oldData.devId,
        logo: oldData.img,
        lat,
        lng,
        titleTh: oldData.title.th,
        descTh: oldData.desc.th,
    };
    const errors = validate(validateData, schema);

    return {
        type: SET_PROJECT_GEO,
        lat,
        lng,
        errors,
    };
}
export function resetData() {
    return {
        type: RESET_PROJECT,
    };
}
export function fetch() {
    return {
        type: FETCH_PROJECT,
    };
}
export function fetchGallery() {
    return {
        type: UPLOAD_PROJECT_GALLERY,
    };
}
export function fetchGalleryDone(imgURL, index, oldData) {
    const validateData = {
        typeId: oldData.typeId,
        devId: oldData.devId,
        logo: oldData.img,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        titleTh: oldData.title.th,
        descTh: oldData.desc.th,
    };
    const errors = validate(validateData, schema);

    return {
        type: UPLOAD_PROJECT_GALLERY_DONE,
        imgURL,
        index,
        errors,
    };
}
export function removeGallery(index, oldData) {
    const validateData = {
        typeId: oldData.typeId,
        devId: oldData.devId,
        logo: oldData.img,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        titleTh: oldData.title.th,
        descTh: oldData.desc.th,
    };
    const errors = validate(validateData, schema);

    return {
        type: REMOVE_PROJECT_GALLERY,
        index,
        errors,
    };
}
export function fetchDataDone(data) {
    return {
        type: FETCH_PROJECT_DATA_DONE,
        data,
    };
}
export function fetchDone(message) {
    return {
        type: FETCH_PROJECT_DONE,
        message,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_PROJECT_FAIL,
        message,
    };
}
export function updateNoLang(oldData, fieldName, newValue) {
    const validateData = {
        typeId: oldData.typeId,
        devId: oldData.devId,
        logo: oldData.img,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        titleTh: oldData.title.th,
        descTh: oldData.desc.th,
    };
    const errors = validate(validateData, schema);

    return {
        type: UPDATE_PROJECT_NO_LANG,
        fieldName,
        newValue,
        errors,
    };
}
export function update(oldData, lang, fieldName, newValue) {
    oldData[fieldName][lang] = newValue;

    const validateData = {
        typeId: oldData.typeId,
        devId: oldData.devId,
        logo: oldData.img,
        lat: oldData.location.lat,
        lng: oldData.location.lng,
        titleTh: oldData.title.th,
        descTh: oldData.desc.th,
    };
    const errors = validate(validateData, schema);

    return {
        type: UPDATE_PROJECT,
        lang,
        fieldName,
        newValue,
        errors,
    };
}

// Async Action Creator
export function postImage(file, oldData) {
    if (!file) {
        return {
            type: FETCH_PROJECT_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return (dispatch) => {
        dispatch(fetch());
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef
            .child(`projects/${uuidv1()}-${file.name}`)
            .put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then(downloadURL => {
                dispatch(changeLogo(downloadURL, oldData));
                dispatch(fetchDone(''));
            });
        });
    };
}
export function postGallery(file, index, oldData) {
    if (!file) {
        return {
            type: FETCH_PROJECT_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return (dispatch) => {
        dispatch(fetchGallery(index));
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef
            .child(`projects/${uuidv1()}-${file.name}`)
            .put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then(downloadURL => {
                dispatch(fetchGalleryDone(downloadURL, index, oldData));
            });
        });
    };
}

export function postCreate(data, oldData) {
    return dispatch => {
        dispatch(fetch());

        const adminCreator = firebase.auth().currentUser;
        if (adminCreator) {
            const ref = firebase.firestore().collection('projects');
            ref.where('search', '==', data.search)
                .get()
                .then(searchResults => {
                    if (searchResults.size > 0) {
                        dispatch(
                            showSnack(
                                'fail',
                                'Project name has already been created"',
                            ),
                        );
                        return dispatch(
                            fetchFail('Project name has already been created"'),
                        );
                    }

                    const inputData = {
                        search: data.search,
                        title: data.title,
                        desc: data.desc,
                        img: data.img,
                        typeId: data.typeId,
                        devId: data.devId.uid,
                        yearDone: Number(data.yearDone),
                        unitNumber: Number(data.unitNumber),
                        gallery: data.gallery,
                        location: new firebase.firestore.GeoPoint(
                            data.location.lat,
                            data.location.lng,
                        ),
                        createdBy: adminCreator.uid,
                        timestamp: moment().valueOf(),
                    };

                    return ref
                        .add(inputData)
                        .then(async docSnap => {
                            const projRef = ref.doc(docSnap.id);
                            const docData = await projRef.get();
                            const projInput = {
                                uid: docData.id,
                                nameTh: docData.data().title.th,
                                nameEn: docData.data().title.en,
                                nameCh: docData.data().title.ch,
                                nameJp: docData.data().title.jp,
                            };

                            dispatch(changeProject(projInput, oldData));
                            dispatch(
                                fetchDone('Project created successfully.'),
                            );
                            dispatch(
                                showSnack(
                                    'success',
                                    'Project created successfully.',
                                ),
                            );
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            dispatch(fetchFail('You are not sign in.'));
        }
    };
}

export function getEdit(uid) {
    return (dispatch) => {
        dispatch(fetch());

        const articleGetList = functions.httpsCallable('projectBackendGetId');
        return articleGetList(uid)
            .then((result) => {
                dispatch(fetchDataDone(result.data.data));
            })
            .catch((error) => {
                dispatch(fetchFail(error.message));
            });
    };
}
