import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { CardBigImgBkg, CardPaper, CardBigPaper, CardTran, CardBigTran } from '../../roots/card';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
        paddingBottom: 54,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 24,
        },
    },
    default: {
        backgroundColor: '#f9f9f9',
    },
    white: {
        backgroundColor: '#fff',
    },
    green: {
        backgroundColor: '#002023',
        paddingBottom: 60,
    }
});

function FiveTopics(props) {
    const { 
        classes, 
        bgColor,
        loading, 
        list,
    } = props;

    let colorClass = classes.default;
    if (bgColor === 'white') {
        colorClass = classes.white;
    }
    if (bgColor === 'green') {
        colorClass = classes.green;
    }

    return (
        <div className={clsx(classes.background, colorClass)}>
            <Hidden mdUp>
                <Box mb={3}>
                    <CardBigImgBkg
                        loading={loading}
                        url={list[0] && `article/${list[0].url}/${list[0].uid}`}
                        tag={list[0] && list[0].category}
                        img={list[0] && list[0].img}
                        title={list[0] && list[0].title}
                        desc={list[0] && list[0].desc}
                    />
                </Box>
            </Hidden>
            <Container>
                <Grid 
                    container 
                    spacing={3}
                    alignItems="stretch"
                >
                    <Hidden smDown>
                        <Grid item xs={7}>
                            <CardBigImgBkg
                                loading={loading}
                                url={list[0] && `article/${list[0].url}/${list[0].uid}`}
                                tag={list[0] && list[0].category}
                                img={list[0] && list[0].img}
                                title={list[0] && list[0].title}
                                desc={list[0] && list[0].desc}
                            />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={5}>
                        {
                            bgColor === 'green' ?
                                <CardBigTran
                                    loading={loading}
                                    url={list[1] && `article/${list[1].url}/${list[1].uid}`}
                                    tag={list[1] && list[1].category}
                                    img={list[1] && list[1].img}
                                    title={list[1] && list[1].title}
                                    date={list[1] && list[1].date}
                                />
                                :
                                <CardBigPaper
                                    loading={loading}
                                    url={list[1] && `article/${list[1].url}/${list[1].uid}`}
                                    tag={list[1] && list[1].category}
                                    img={list[1] && list[1].img}
                                    title={list[1] && list[1].title}
                                    date={list[1] && list[1].date}
                                />
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {
                            bgColor === 'green' ?
                                <CardTran
                                    loading={loading}
                                    url={list[2] &&`article/${list[2].url}/${list[2].uid}`}
                                    tag={list[2] && list[2].category}
                                    img={list[2] && list[2].img}
                                    title={list[2] && list[2].title}
                                    date={list[2] && list[2].date}
                                />
                                :
                                <CardPaper
                                    loading={loading}
                                    url={list[2] &&`article/${list[2].url}/${list[2].uid}`}
                                    tag={list[2] && list[2].category}
                                    img={list[2] && list[2].img}
                                    title={list[2] && list[2].title}
                                    date={list[2] && list[2].date}
                                />
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {
                            bgColor === 'green' ?
                                <CardTran
                                    loading={loading}
                                    url={list[3] && `article/${list[3].url}/${list[3].uid}`}
                                    tag={list[3] && list[3].category}
                                    img={list[3] && list[3].img}
                                    title={list[3] && list[3].title}
                                    date={list[3] && list[3].date}
                                />
                                :
                                <CardPaper
                                    loading={loading}
                                    url={list[3] && `article/${list[3].url}/${list[3].uid}`}
                                    tag={list[3] && list[3].category}
                                    img={list[3] && list[3].img}
                                    title={list[3] && list[3].title}
                                    date={list[3] && list[3].date}
                                />
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {
                            bgColor === 'green' ?
                                <CardTran
                                    loading={loading}
                                    url={list[4] && `article/${list[4].url}/${list[4].uid}`}
                                    tag={list[4] && list[4].category}
                                    img={list[4] && list[4].img}
                                    title={list[4] && list[4].title}
                                    date={list[4] && list[4].date}
                                />
                                :
                                <CardPaper
                                    loading={loading}
                                    url={list[4] && `article/${list[4].url}/${list[4].uid}`}
                                    tag={list[4] && list[4].category}
                                    img={list[4] && list[4].img}
                                    title={list[4] && list[4].title}
                                    date={list[4] && list[4].date}
                                />
                        }
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

FiveTopics.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.string,
    loading: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    list: PropTypes.array.isRequired,
};

FiveTopics.defaultProps = {
    bgColor: 'default',
    loading: true,
};

export default withStyles(styles)(FiveTopics);