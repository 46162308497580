import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import configureStore from './store';

import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.css';

const tagManagerArgs = {
    gtmId: 'GTM-MWDBKQZ'
};

ReactPixel.init('4870022913040186', {}, { debug: true, autoConfig: false });
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();