import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'components/roots/button';
import { TagPromote } from 'components/roots/element';
import Price from 'components/roots/price/postPrice';
import BedroomNum from 'common/property/bedroomNumber';
import Area from './area.svg';
import Floor from './floor.svg';
import Room from './room.svg';

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: '10px 10px 40px 0 rgba(0, 32, 35, 0.1)',
        width: '100%',
        borderBottom: '1px solid #e9e9e9',
        borderRadius: 8,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 16,
        },
    },
    media: {
        width: 384,
        height: 288,
        maxWidth: '50%',
        borderRadius: 8,
        backgroundImage:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0) 91%, rgba(0, 0, 0, 0.5))',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
            height: 184,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    mediaLoading: {
        width: 384,
        height: 288,
        maxWidth: '50%',
        borderRadius: 8,
        backgroundColor: 'rgb(238, 238, 238)',
        backgroundImage:
            'linear-gradient(90deg, rgb(238, 238, 238), rgb(245, 245, 245), rgb(238, 238, 238))',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 184,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    truncateText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    truncateSmallText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 50,
    },
    content: {
        overflow: 'hidden',
        padding: 24,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            padding: '16px !important',
        },
    },
    title: {
        width: '100%',
    },
    roomSpecList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    roomSpecItem: {
        marginRight: 16,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& img': {
            marginRight: 8,
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    lower: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    mobileBottom: {
        display: 'block',
        // [theme.breakpoints.down('sm')]: {
        //     display: 'flex',
        //     justifyContent: 'space-between',
        //     alignItems: 'center',
        // },
    },
});

function MyPost(props) {
    const {
        classes,
        url,
        image,
        title,
        textLocation,
        sellPrice,
        rentPrice,
        status,
        loading,
        resetData,
        areaSize,
        bedroomNum,
        floor,
    } = props;

    const BedroomText = _.find(BedroomNum.type, ['uid', bedroomNum]);
    let textRoom = BedroomText ? `${BedroomText.name} ห้อง` : '-';
    if (textRoom !== '-' && BedroomText.uid === 'studio') {
        textRoom = BedroomText.name;
    }
    return (
        <>
            {loading ? (
                <Card className={classes.card}>
                    <div className={classes.mediaLoading} />
                    <CardContent className={classes.content}>
                        <Box>
                            <Box>
                                <Box className={classes.title}>
                                    <Typography variant="subtitle1">
                                        <Skeleton width="100%" count={1} />
                                    </Typography>
                                </Box>
                                <Hidden smDown>
                                    <Box mb={1}>
                                        <Typography variant="body2">
                                            <Skeleton
                                                width="100%"
                                                count={1}
                                            />
                                        </Typography>
                                    </Box>
                                </Hidden>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            ) : (
                <Link to={url} className={classes.link}>
                    <Card className={classes.card}>
                        <CardMedia image={image} className={classes.media}>
                            {status === 'promote' && (
                                <Box mt={1} ml={1}>
                                    <TagPromote text="ประกาศแนะนำ" />
                                </Box>
                            )}
                        </CardMedia>
                        <CardContent className={classes.content}>
                            <Box>
                                <Box>
                                    <Box className={classes.title}>
                                        <Typography
                                            variant="subtitle1"
                                            className={
                                                classes.truncateText
                                            }>
                                            {title}
                                        </Typography>
                                    </Box>
                                    <Hidden smDown>
                                        <Box mb={1}>
                                            <Typography
                                                variant="body2"
                                                className={
                                                    classes.truncateText
                                                }>
                                                {textLocation}
                                            </Typography>
                                        </Box>
                                    </Hidden>
                                    <Box
                                        className={classes.roomSpecList}
                                        mb={{ xs: 2, md: 3 }}>
                                        <div
                                            className={
                                                classes.roomSpecItem
                                            }>
                                            <img src={Area} alt="area" />
                                            <Typography
                                                variant="body2"
                                                className={
                                                    classes.truncateSmallText
                                                }>
                                                {areaSize} ตรม.
                                            </Typography>
                                        </div>
                                        <div
                                            className={
                                                classes.roomSpecItem
                                            }>
                                            <img src={Room} alt="room" />
                                            <Typography
                                                variant="body2"
                                                className={
                                                    classes.truncateSmallText
                                                }>
                                                {textRoom}
                                            </Typography>
                                        </div>
                                        <div
                                            className={
                                                classes.roomSpecItem
                                            }>
                                            <img src={Floor} alt="floor" />
                                            <Typography
                                                variant="body2"
                                                className={
                                                    classes.truncateSmallText
                                                }>
                                                ชั้น {floor}
                                            </Typography>
                                        </div>
                                    </Box>
                                    <Box
                                        mb={{ xs: 0, md: 2 }}
                                        className={classes.mobileBottom}>
                                        <Box
                                            style={{
                                                display: 'inline-block',
                                                maxWidth: 'calc(50% - 17px)',
                                            }}
                                            mr={{ xs: 2, md: '34px' }}>
                                            <Price
                                                page="post"
                                                type="sell"
                                                price={sellPrice}
                                                loading={loading}
                                            />
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'inline-block',
                                                maxWidth: 'calc(50% - 17px)',
                                            }}>
                                            <Price
                                                page="post"
                                                type="rent"
                                                price={rentPrice}
                                                loading={loading}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Hidden smDown>
                                <Box className={classes.lower}>
                                    <Link
                                        to={url}
                                        style={{ textDecoration: 'none' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => resetData()}>
                                            ดูรายละเอียดเพิ่มเติม
                                        </Button>
                                    </Link>
                                </Box>
                            </Hidden>
                        </CardContent>
                    </Card>
                </Link>
            )}
            {/* <Card className={classes.card}>
                {loading ? (
                    <div className={classes.mediaLoading} />
                ) : (
                    <CardMedia image={image} className={classes.media}>
                        {status === 'promote' && (
                            <Box mt={1} ml={1}>
                                <TagPromote text="ประกาศแนะนำ" />
                            </Box>
                        )}
                    </CardMedia>
                )}
                <CardContent className={classes.content}>
                    <Box>
                        <Box>
                            <Box className={classes.title}>
                                {loading ? (
                                    <Typography variant="subtitle1">
                                        <Skeleton width="100%" count={1} />
                                    </Typography>
                                ) : (
                                    <Link to={url} className={classes.link}>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.truncateText}>
                                            {title}
                                        </Typography>
                                    </Link>
                                )}
                            </Box>
                            <Hidden smDown>
                                <Box mb={1}>
                                    {loading ? (
                                        <Typography variant="body2">
                                            <Skeleton width="100%" count={1} />
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            className={classes.truncateText}>
                                            {textLocation}
                                        </Typography>
                                    )}
                                </Box>
                            </Hidden>
                            {!loading && (
                                <Box
                                    className={classes.roomSpecList}
                                    mb={{ xs: 2, md: 3 }}>
                                    <div className={classes.roomSpecItem}>
                                        <img src={Area} alt="area" />
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.truncateSmallText
                                            }>
                                            {areaSize} ตรม.
                                        </Typography>
                                    </div>
                                    <div className={classes.roomSpecItem}>
                                        <img src={Room} alt="room" />
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.truncateSmallText
                                            }>
                                            {textRoom}
                                        </Typography>
                                    </div>
                                    <div className={classes.roomSpecItem}>
                                        <img src={Floor} alt="floor" />
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.truncateSmallText
                                            }>
                                            ชั้น {floor}
                                        </Typography>
                                    </div>
                                </Box>
                            )}
                            <Box
                                mb={{ xs: 0, md: 2 }}
                                className={classes.mobileBottom}>
                                <Box
                                    style={{
                                        display: 'inline-block',
                                        maxWidth: 'calc(50% - 17px)',
                                    }}
                                    mr={{ xs: 2, md: '34px' }}>
                                    <Price
                                        page="post"
                                        type="sell"
                                        price={sellPrice}
                                        loading={loading}
                                    />
                                </Box>
                                <Box
                                    style={{
                                        display: 'inline-block',
                                        maxWidth: 'calc(50% - 17px)',
                                    }}>
                                    <Price
                                        page="post"
                                        type="rent"
                                        price={rentPrice}
                                        loading={loading}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {!loading && (
                        <Hidden smDown>
                            <Box className={classes.lower}>
                                <Link
                                    to={url}
                                    style={{ textDecoration: 'none' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => resetData()}>
                                        ดูรายละเอียดเพิ่มเติม
                                    </Button>
                                </Link>
                            </Box>
                        </Hidden>
                    )}
                </CardContent>
            </Card> */}
        </>
    );
}

MyPost.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    title: PropTypes.string,
    loading: PropTypes.bool,
    resetData: PropTypes.func,
    image: PropTypes.string,
    textLocation: PropTypes.string,
    sellPrice: PropTypes.number,
    rentPrice: PropTypes.number,
    areaSize: PropTypes.number,
    bedroomNum: PropTypes.string,
    floor: PropTypes.number,
    status: PropTypes.string,
};

MyPost.defaultProps = {
    url: '',
    title: '',
    image: '',
    textLocation: '',
    sellPrice: 0,
    rentPrice: 0,
    status: '',
    loading: true,
    areaSize: 1,
    bedroomNum: '',
    floor: 1,
    resetData: () => console.log('Click'),
};

export default withStyles(styles)(MyPost);
