/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import { VSlimAds ,HAds } from 'components/sections/ads';
import ArticleCover from 'components/sections/cover/articleCover';
import SuggestTopics from 'components/sections/suggestTopics';
import Article from 'components/sections/article';
import Map from 'components/sections/map';
import MenuLang from 'common/menu';
import TitleComponent from 'components/roots/titleComponent';

function ArticlePage(props) {
    const { 
        lang,
        loading,
        data,
        getData,
        relatedLoading,
        relatedList,
        getRelated,
        mapLoading,
        getMap,
        getSideAds,
        location,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];

    React.useEffect(() => {
        getData(lang, uid);
        getSideAds();
        getRelated(lang, uid);
        getMap(lang, uid);
    }, [getData, lang, uid, getSideAds, getRelated, getMap]);

    return (
        <>
            <TitleComponent title={`${data.title || 'Article'} - All About Living`} />
            <ArticleCover
                loading={loading}
                subCategory={loading ? 'x' : MenuLang[lang].subCategory[data.subCatId]}
                coverText={data.textOverCover}
                img={data.img}
            />
            <HAds 
                color="white"
                position="header"
                pageLoading={loading}  
            />
            <Hidden mdUp>
                <VSlimAds
                    color="white"
                    position="sideTop"
                    pageLoading={loading}
                />
            </Hidden>
            <Article 
                lang={lang}
                loading={loading}
                category={data.category}
                title={data.title}
                createdAt={data.date}
                markdown={data.desc}
                tags={data.tags}
                writer={data.writer}
            />
            { data.locationId && 
                <Map
                    title={MenuLang[lang].map}
                    loading={loading && mapLoading}
                />
            }
            { relatedList.length !== 0 &&
                <SuggestTopics
                    bgColor="white"
                    title={MenuLang[lang].related}
                    loading={relatedLoading && loading}
                    list={relatedList}
                />
            }
            <Hidden mdUp>
                <VSlimAds
                    color="white"
                    position="sideBottom"
                    pageLoading={loading}
                />
            </Hidden>
            <HAds 
                color="white"
                position="footer"
                pageLoading={loading}
            />
        </>
    );
}

ArticlePage.propTypes = {
    lang: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    getData: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    getSideAds: PropTypes.func.isRequired,
    relatedLoading: PropTypes.bool.isRequired,
    relatedList: PropTypes.array.isRequired,
    getRelated: PropTypes.func.isRequired,
    mapLoading: PropTypes.bool.isRequired,
    getMap: PropTypes.func.isRequired,
};

export default withRouter(ArticlePage);
