import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import ForumIcon from '@material-ui/icons/Forum';
import ListAltIcon from '@material-ui/icons/ListAlt';

const styles = () => ({
    media: {
        width: 30,
        height: 30,
        border: 'solid 1px #ffffff',
        textTransform: 'uppercase',
        backgroundColor: "#00abbd",
        cursor: 'pointer',
    },
    listIcon:{
        minWidth: 0,
        marginRight: 15,
    },
    menuItem:{
        padding: '12px 16px',
        minWidth: 200,
        '&:hover p, &:hover svg': {
            color: '#00abbd',
        }
    },
    login:{
        textAlign: 'center',
        color: '#c7c7c7',
        padding: '16px 0',
        '&:hover':{
            color: '#2e2e2e',
        }
    },
    outer:{
        position: 'relative',
    },
    menutext:{
        flexGrow: 1,
    },
    noti: {
        width: 10,
        height: 10,
        backgroundColor: "#f06642",
        borderRadius: '50%',
        position: 'absolute',
        zIndex: 1,
        right: 0,
        top: 0,
    },
    menuNoti: {
        width: 10,
        height: 10,
        backgroundColor: "#f06642",
        borderRadius: '50%',
    },
});

function MenuItemLink(props) {
    return <MenuItem button component={Link} {...props} />;
}

function CardPaper(props) {
    const { 
        classes, 
        img, 
        name,
        inboxNoti,
        signOut,
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={classes.outer}>
            {
                inboxNoti && <div className={classes.noti} />
            }
            {
                img ?
                    <Avatar
                        alt={name}
                        src={img}
                        className={classes.media}
                        aria-describedby={id}
                        onClick={handleToggle}
                    />
                    :
                    <Avatar
                        color="primary"
                        className={classes.media}
                        aria-describedby={id}
                        onClick={handleToggle}
                    >
                        {name ? name.charAt(0) : ''}
                    </Avatar>
            }
            <Popover 
                id={id}
                open={open} 
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    // vertical: 'bottom',
                    vertical: 48.5,
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper>
                        <MenuList style={{padding: '12px 0 0 0'}}>
                            <MenuItemLink
                                className={classes.menuItem}
                                to="/profile"
                                onClick={handleClose}
                            >
                                <ListItemIcon className={classes.listIcon}>
                                    <PersonIcon />
                                </ListItemIcon>
                                <Typography 
                                    variant="body1" 
                                    color="textPrimary"
                                    className={classes.menutext}
                                >
                                    ข้อมูลส่วนตัว
                                </Typography>
                            </MenuItemLink>
                            <MenuItemLink
                                className={classes.menuItem}
                                to="/my-posts"
                                onClick={handleClose}
                            >
                                <ListItemIcon className={classes.listIcon}>
                                    <RoomIcon />
                                </ListItemIcon>
                                <Typography 
                                    variant="body1" 
                                    color="textPrimary"
                                    className={classes.menutext}
                                >
                                    ประกาศของฉัน
                                </Typography>
                            </MenuItemLink>
                            <MenuItemLink 
                                className={classes.menuItem}
                                to="/my-topics"
                                onClick={handleClose}
                            >
                                <ListItemIcon className={classes.listIcon}>
                                    <ListAltIcon />
                                </ListItemIcon>
                                <Typography 
                                    variant="body1" 
                                    color="textPrimary"
                                    className={classes.menutext}
                                >
                                    กระทู้ของฉัน
                                </Typography>
                            </MenuItemLink>
                            <MenuItemLink
                                className={classes.menuItem}
                                to="/inbox"
                                onClick={handleClose}
                            >
                                <ListItemIcon className={classes.listIcon}>
                                    <ForumIcon />
                                </ListItemIcon>
                                <Typography 
                                    variant="body1" 
                                    color="textPrimary"
                                    className={classes.menutext}
                                >
                                    ข้อความ
                                </Typography>
                                {
                                    inboxNoti && <div className={classes.menuNoti} />
                                }
                            </MenuItemLink>
                            <Box pt="12px">
                                <Divider />    
                            </Box>
                            <MenuItem 
                                className={classes.login}
                                onClick={handleClose}
                            >
                                <Typography 
                                    variant="body2" 
                                    style={{ margin: 'auto' }}
                                    onClick={() => signOut()}
                                >
                                    ออกจากระบบ
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popover>
        </div>
    );
}

CardPaper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    img: PropTypes.string,
    name: PropTypes.string,
    inboxNoti: PropTypes.bool,
    signOut: PropTypes.func.isRequired,
};

CardPaper.defaultProps = {
    img: '',
    name: '',
    inboxNoti: false,
};

export default withStyles(styles)(CardPaper);