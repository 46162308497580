/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import { ListBig } from '../../roots/list';
import { Button } from '../../roots/button';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
    },
    default: {
        backgroundColor: '#f9f9f9',
    },
    white: {
        backgroundColor: '#fff',
    },
    green: {
        backgroundColor: '#002023',
    },
    header: {
        marginBottom: 24,
    },
    notFound:{
        marginBottom: 8,
        marginTop: 60,
    },
    notFoundDesc: {
        marginBottom: 400,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 200,
        },
    },
    footer: {
        margin: '0 auto',
        width: 180,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '16px auto 28px auto',
        },
    },
});

function LatestTopics(props) {
    const { 
        classes, 
        bgColor,
        title, 
        loading,
        loadingMore,
        showLoadingMore,
        list, 
        loadmoreTitle,
        loadmoreFunc,
    } = props;

    let colorClass = classes.default;
    if (bgColor === 'white') {
        colorClass = classes.white;
    }
    if (bgColor === 'green') {
        colorClass = classes.green;
    }

    const initialArr = [...Array(10).keys()];

    return (
        <div className={clsx(classes.background, colorClass)}>
            <Container>
                <Grid 
                    container 
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12} md={8}>
                        <Typography variant="h1">
                            {loading ? <Skeleton width="35%" /> : title}
                        </Typography>
                    </Grid>
                    {loading ?
                        <>
                            {initialArr.map((item) =>
                                <Grid item xs={12} md={8} key={item}>
                                    <ListBig loading={loading} />
                                </Grid>
                            )}
                        </>
                        :
                        <>
                            {list.map((item) =>
                                <Grid item xs={12} md={8} key={item.uid}>
                                    <ListBig
                                        loading={loading}
                                        url={`/article/${item.url}/${item.uid}`}
                                        tag={item.category}
                                        img={item.img}
                                        title={item.title}
                                        desc={item.desc}
                                        date={item.date}
                                    />
                                </Grid>
                            )}
                        </>
                    }
                    {loadingMore ?
                        <>
                            {initialArr.map((item) =>
                                <Grid item xs={12} md={8} key={item}>
                                    <ListBig loading={loadingMore} />
                                </Grid>
                            )}
                        </>
                        :
                        <>
                            {(!loading && showLoadingMore) && 
                                <Grid item xs={12} md={8} style={{ textAlign: 'center' }}>
                                    <Box className={classes.footer}>
                                        <Button
                                            color="default"
                                            variant="outlined"
                                            onClick={() => loadmoreFunc()}
                                        >
                                            {loadmoreTitle}
                                        </Button>
                                    </Box>
                                </Grid>
                            }
                        </>
                    }
                </Grid>
            </Container>
        </div>
    );
}

LatestTopics.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.string,
    title: PropTypes.any.isRequired,
    loading: PropTypes.bool,
    loadingMore: PropTypes.bool.isRequired,
    loadmoreTitle: PropTypes.string.isRequired,
    showLoadingMore: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    loadmoreFunc: PropTypes.func.isRequired,
};

LatestTopics.defaultProps = {
    bgColor: 'white',
    loading: true,
};

export default withStyles(styles)(LatestTopics);