/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

const styles = theme => ({
    textField: {
        width: '100%',
        margin: '0',
        [theme.breakpoints.down('sm')]: {
            '& label': {
                transform: 'translate(14px, 14px) scale(1)',
            },
            '& input': {
                height: 11,
            },
        },
    },
});

function ForgotForm(props) {
    const {
        classes,
        loading,
        email,
        errors,
        update,
    } = props;

    const hasError = field => {
        if (_.has(errors, field)) {
            return true;
        }
        return false;
    };

    return (
        <Box mb={3}>
            <TextField
                error={hasError('email')}
                helperText={
                    hasError('email') ? errors.email[0] : null
                }
                disabled={loading}
                fullWidth
                label="อีเมล"
                className={classes.textField}
                type="email"
                name="email"
                variant="outlined"
                onChange={(event) => update(event.target.value)}
                value={email}
            />
        </Box>
    );
}

ForgotForm.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
};

export default withStyles(styles)(ForgotForm);