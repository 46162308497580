/* eslint-disable react/forbid-prop-types */
import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from 'react-loading-skeleton';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Slide from 'components/roots/slide';
import RoomSpec from 'components/roots/status/room';
import Price from 'components/roots/price/postPrice';
import Fab from '@material-ui/core/Fab';
import PhoneIcon from '@material-ui/icons/Phone';
import ForumIcon from '@material-ui/icons/Forum';
import BRN from 'common/property/bedroomNumber';
import BathRN from 'common/property/bathroomNumber';
import Furniture from 'common/property/furniture';
import Direction from 'common/property/roomDirection';
import Status from 'common/property/status';

const styles = theme => ({
    stat: {
        marginLeft: 33,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    roomPrice: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
    },
    contact: {
        display: 'flex',
    },
    fab: {
        width: 50,
        height: 50,
    },
    rowSpec: {
        display: 'flex',
    },
    roomSpec: {
        width: 200,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
    },
});

function Cover(props) {
    const { classes, data, loading, userId } = props;

    const bedroomObj = _.find(BRN.type, ['uid', _.get(data, 'bedroomNum')]);
    const bathObj = _.find(BathRN.type, ['uid', _.get(data, 'toiletNum')]);
    const furObj = _.find(Furniture.type, ['uid',_.get(data, 'furniture')]);
    const direcObj = _.find(Direction.type, ['uid', _.get(data, 'direction')]);
    const statusObj = _.find(Status.type, ['uid', _.get(data, 'projectStatus')]);

    return (
        <>
            <Hidden mdUp>
                <Slide data={_.get(data, 'gallery') ? _.get(data, 'gallery') : []} />
            </Hidden>
            <Container>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="flex-start">
                    <Hidden smDown>
                        <Grid item xs={12} md={6}>
                            <Slide data={_.get(data, 'gallery') ? _.get(data, 'gallery') : []} />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={4}>
                        <Box mt={{ xs: 2, md: 0 }} mb={1}>
                            <Typography variant="h5" color="textPrimary">
                                {loading ? (
                                    <Skeleton width="100%" count={2} />
                                ) : (
                                    _.get(data, 'title')
                                )}
                            </Typography>
                        </Box>
                        <Box mb={{ xs: 2, md: 3 }}>
                            <Typography variant="body2" color="textSecondary">
                                {loading ? (
                                    <Skeleton width="100%" />
                                ) : (
                                    _.get(data, 'locationText')
                                )}
                            </Typography>
                        </Box>
                        {!loading && (
                            <RoomSpec
                                areaSize={_.get(data, 'areaSize')}
                                bedroomNum={_.get(data, 'bedroomNum')}
                                floor={_.get(data, 'floor')}
                            />
                        )}
                        <Box className={classes.roomPrice} mb={2}>
                            <Box mr={3}>
                                {loading ? (
                                    <Skeleton width="120px" height="54px" />
                                ) : (
                                    <Price
                                        page="post"
                                        type="sell"
                                        price={Math.round(_.get(data, 'sellPrice'))}
                                    />
                                )}
                            </Box>
                            <Box mr={3}>
                                {loading ? (
                                    <Skeleton width="120px" height="54px" />
                                ) : (
                                    <Price
                                        page="post"
                                        type="sellperarea"
                                        price={Math.round(
                                            _.get(data, 'sellPricePerArea')
                                        )}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Box className={classes.roomPrice} mb={3}>
                            <Box mr={3}>
                                {loading ? (
                                    <Skeleton width="120px" height="54px" />
                                ) : (
                                    <Price
                                        page="post"
                                        type="rent"
                                        price={Math.round(_.get(data, 'rentPrice'))}
                                    />
                                )}
                            </Box>
                            <Box mr={3}>
                                {loading ? (
                                    <Skeleton width="120px" height="54px" />
                                ) : (
                                    <Price
                                        page="post"
                                        type="rentperarea"
                                        price={Math.round(
                                            _.get(data, 'rentPricePerArea')
                                        )}
                                    />
                                )}
                            </Box>
                        </Box>
                        {loading ? (
                            <Skeleton width="40%" height="54px" />
                        ) : (
                            <>
                                {
                                    _.get(data, 'createdBy') !== userId &&
                                        <>
                                            <Box mb={2}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary">
                                                    ติดต่อผู้ลงประกาศ
                                                </Typography>
                                            </Box>
                                            <Box className={classes.contact}>
                                                {_.get(data, 'phoneNumber') && (
                                                    <Box mr={2}>
                                                        <a
                                                            href={`tel:${_.get(data, 'phoneNumber')}`}
                                                        >
                                                            <Fab
                                                                color="primary"
                                                                className={classes.fab}>
                                                                <PhoneIcon />
                                                            </Fab>
                                                        </a>
                                                    </Box>
                                                )}
                                                <Box>
                                                    <Link to={`/inbox?userId=${_.get(data, 'createdBy')},${userId}`} target="__blank"> 
                                                        <Fab
                                                            color="primary"
                                                            className={classes.fab}>
                                                            <ForumIcon />
                                                        </Fab>
                                                    </Link>
                                                </Box>
                                            </Box>
                                        </>
                                }
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="flex-start">
                    <Grid item xs={12} md={10}>
                        {!loading && (
                            <>
                                <Box mt="12px" mb={2}>
                                    <Typography
                                        variant="h5"
                                        color="textPrimary">
                                        รายละเอียด
                                    </Typography>
                                </Box>
                                <Box className={classes.rowSpec} mb={3}>
                                    <Box className={classes.roomSpec}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            จำนวนห้องนอน
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary">
                                            {bedroomObj ? bedroomObj.name : '-'}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.roomSpec}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            จำนวนห้องน้ำ
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary">
                                            {bathObj ? bathObj.name : '-'}
                                        </Typography>
                                    </Box>
                                    <Hidden smDown>
                                        <Box className={classes.roomSpec}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                ทิศ
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary">
                                                {direcObj ? direcObj.name : '-'}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.roomSpec}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                พื้นที่ใช้สอย
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary">
                                                {_.get(data, 'areaSize')
                                                    ? `${_.get(data, 'areaSize')} ตร.ม.`
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                    </Hidden>
                                </Box>
                                <Hidden mdUp>
                                    <Box className={classes.rowSpec} mb={3}>
                                        <Box className={classes.roomSpec}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                ทิศ
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary">
                                                {direcObj ? direcObj.name : '-'}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.roomSpec}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                พื้นที่ใช้สอย
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary">
                                                {_.get(data, 'areaSize')
                                                    ? `${_.get(data, 'areaSize')} ตร.ม.`
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Hidden>
                                <Box
                                    className={classes.rowSpec}
                                    mb={{ xs: 3, md: 4 }}>
                                    <Box className={classes.roomSpec}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            เฟอร์นิเจอร์
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary">
                                            {furObj ? furObj.name : '-'}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.roomSpec}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary">
                                            ปีสร้างเสร็จ
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary">
                                            {_.get(data, 'yearDone')
                                                ? _.get(data, 'yearDone')
                                                : '-'}
                                        </Typography>
                                    </Box>
                                    <Hidden smDown>
                                        <Box className={classes.roomSpec}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                รูปแบบโครงการ
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary">
                                                {statusObj
                                                    ? statusObj.name
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.roomSpec}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                ขนาดที่ดิน
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary">
                                                {_.get(data, 'landSize')
                                                    ? _.get(data, 'landSize')
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                    </Hidden>
                                </Box>
                                <Hidden mdUp>
                                    <Box className={classes.rowSpec} mb={3}>
                                        <Box className={classes.roomSpec}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                รูปแบบโครงการ
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary">
                                                {_.get(data, 'projectStatus')
                                                    ? _.get(data, 'projectStatus')
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.roomSpec}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary">
                                                ขนาดที่ดิน
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary">
                                                {_.get(data, 'landSize')
                                                    ? _.get(data, 'landSize')
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Hidden>
                            </>
                        )}
                        {loading ? (
                            <Box mt="12px" mb={6}>
                                <Typography variant="body1" color="textPrimary">
                                    <Skeleton width="100%" count={10} />
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <Box mb={2}>
                                    <Typography
                                        variant="h5"
                                        color="textPrimary">
                                        ข้อมูลเพิ่มเติม
                                    </Typography>
                                </Box>
                                <Box mb={6}>
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        dangerouslySetInnerHTML={{
                                            __html: _.get(data, 'desc')
                                                ? _.get(data, 'desc').replace(
                                                    /\n\r?/g,
                                                    '<br />',
                                                )
                                                : '-',
                                        }}
                                    />
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

Cover.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    // subCategory: PropTypes.string.isRequired,
    // img: PropTypes.string.isRequired,
    // loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Cover);
