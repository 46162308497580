import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';

// Image
import LangData from 'common/lang';
import Down from './down.svg';

const styles = theme => ({
    avatar:{
        marginRight: theme.spacing(1),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
});

function showComponent(classes,lang){
    return (
        <Box style={{ display: 'inherit' }} mr={1}>
            <Avatar alt="language logo" src={LangData[lang].icon} className={classes.avatar} />
            <Typography variant="body2" color="textPrimary">{LangData[lang].name}</Typography>
        </Box>
    );
}

function Lang(props) {
    const { classes, lang } = props;
    const LangComp = showComponent(classes, lang);
    const keyLang = _.keys(LangData);

    // Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose(event) {
        console.log('Change Language to >> ',event);
        setAnchorEl(null);
    }

    return (
        <>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {LangComp}
                <img src={Down} alt="arrow down" />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    keyLang.map((item) => (
                        <MenuItem key={item} onClick={() => handleClose(item)}>
                            <Avatar alt="language icon" src={LangData[item].icon} className={classes.avatar} />
                            <Typography variant="body2" color="textPrimary">{LangData[item].name}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
}

Lang.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

export default withStyles(styles)(Lang);