import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    tag: {
        padding: '5px 10px',
        boxShadow: 'none',
        cursor: 'default',
        '&:hover': {
            boxShadow: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 0,
        },
    },
});

function Tag(props) {
    const { text, classes } = props;

    return (
        <Button variant="contained" color="secondary" className={classes.tag}>
            <Typography variant="body2">{text}</Typography>
        </Button>
    );
}

Tag.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
};

export default withStyles(styles)(Tag);
