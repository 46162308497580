import React from 'react';
import { 
    Route, 
    Redirect, 
} from 'react-router-dom';
import PropTypes from 'prop-types';
import ScrollToTop from 'react-router-scroll-top';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
    },
}));

const RouteWithLayout = props => {
    const classes = useStyles();

    const {
        layout: Layout,
        component: Component,
        isNotFound,
        setNotFound,
        updateSearch,
        authLevel,
        anonymousAuth,
        updateNotification,
        noAuthRequired,
        authRequired,
    } = props;
    // console.log(authLevel);

    let outputComponent = (
        <ScrollToTop>
            <Layout>
                <Component />
            </Layout>
        </ScrollToTop>
    );

    // update user notification
    updateNotification();

    // case
    if (authLevel < 2 && authRequired) {
        outputComponent = (
            <Redirect to="/" />
        );
    }
    if (authLevel >= 2 && noAuthRequired) {
        outputComponent = (
            <Redirect to="/" />
        );
    }
    if (isNotFound) {
        setNotFound(false);
        outputComponent = (
            <Redirect to="/not-found" />
        );
    }
    if (authLevel < 1) {
        outputComponent = (
            <CircularProgress className={classes.progress} />
        );
    }

    React.useEffect(() => {
        anonymousAuth();
        // clear search value
        updateSearch('');
    }, [updateSearch, anonymousAuth]);

    

    return (
        <Route render={ () => (outputComponent) } />
    );
};

RouteWithLayout.propTypes = {
    /* eslint-disable react/forbid-prop-types */
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    isNotFound: PropTypes.bool.isRequired,
    setNotFound: PropTypes.func.isRequired,
    updateSearch: PropTypes.func.isRequired,
    authLevel: PropTypes.number.isRequired,
    anonymousAuth: PropTypes.func.isRequired,
    updateNotification: PropTypes.func.isRequired,
    noAuthRequired: PropTypes.bool,
    authRequired: PropTypes.bool,
};

RouteWithLayout.defaultProps = {
    noAuthRequired: false,
    authRequired: false,
};

export default RouteWithLayout;
