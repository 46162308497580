import {
    SET_TOPIC_TAG,
    REMOVE_TOPIC_TAG,
    UPDATE_TOPIC,
    UPDATE_TOPIC_COMMENT,
    ADD_TOPIC_COMMENT,
    FETCH_TOPIC_COMMENT,
    RESET_TOPIC,
    FETCH_TOPIC,
    FETCH_TOPIC_DATA_DONE,
    FETCH_TOPIC_DONE,
    FETCH_TOPIC_FAIL,
    FETCH_TOPIC_LIST_DONE,
    DELETE_TOPIC,
    FETCH_TOPIC_LOADMORE,
    FETCH_TOPIC_LOADMORE_DONE,
} from 'actions/topics';

const initialState = {
    loading: false,
    list: [],
    hotList: [],
    loadingMore: false,
    showLoadingMore: false,
    data: {
        title: '',
        desc: '',
        tagId: [],
        accept: false,
        writer: {
            name: '',
            img: '',
        },
        comments: [],
        date: '',
        view: 0,
        comment: 0,
    },
    comment:{
        loading: false,
        desc: '',
        errors: {},
        touched: {},
        isValid: false,
    },
    errors: {},
    touched: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const topicReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_TOPIC_TAG:
        return {
            ...state,
            data: {
                ...state.data,
                tagId: action.tags,
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    case REMOVE_TOPIC_TAG: {
        const tagList = [...state.data.tagId];
        tagList.splice(action.index, 1);
        return {
            ...state,
            data: {
                ...state.data,
                tagId: tagList,
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    }
    case RESET_TOPIC:
        return {
            ...initialState,
            loading: false,
            data: {
                title: '',
                desc: '',
                tagId: [],
                accept: false,
            },
            errors: {},
            touched: {},
            isValid: false,
            successMessage: '',
            failMessage: '',
        };
    case UPDATE_TOPIC:
        return {
            ...state,
            data: {
                ...state.data,
                [action.fieldName]: action.newValue,
            },
            errors: action.errors,
            touched: {
                ...state.touched,
                [action.fieldName]: true,
            },
            isValid: !action.errors,
        };
    case UPDATE_TOPIC_COMMENT:
        return {
            ...state,
            comment: {
                ...state.comment,
                desc: action.newValue,
                errors: action.errors,
                touched: {
                    desc: true,
                },
                isValid: !action.errors,
            },
        };
    case FETCH_TOPIC:
        return {
            ...state,
            loading: true,
            loadingMore: false,
            showLoadingMore: true,
        };
    case FETCH_TOPIC_COMMENT:
        return {
            ...state,
            comment: {
                ...state.comment,
                loading: true,
            },
        };
    case FETCH_TOPIC_LOADMORE:
        return {
            ...state,
            loadingMore: true,
        };
    case FETCH_TOPIC_DATA_DONE:
        return {
            ...state,
            loading: false,
            data: action.data,
        };
    case FETCH_TOPIC_DONE:
        return {
            ...state,
            loading: false,
            successMessage: action.message,
        };
    case FETCH_TOPIC_FAIL:
        return {
            ...state,
            loading: false,
            failMessage: action.message,
        };
    case FETCH_TOPIC_LIST_DONE:
        return {
            ...state,
            loading: false,
            list: action.list,
            hotList: action.hotList ? action.hotList : [],
            loadingMore: false,
            showLoadingMore: action.list.length >= 5,
        };
    case ADD_TOPIC_COMMENT:
        return {
            ...state,
            comment: {
                ...state.comment,
                loading: false,
                desc: '',
                errors: {},
                touched: {},
                isValid: false,
            },
            data:{
                ...state.data,
                comments: [
                    ...state.data.comments,
                    action.data,
                ],
            }
        };
    case FETCH_TOPIC_LOADMORE_DONE:
        return {
            ...state,
            loadingMore: false,
            showLoadingMore: action.list.length >= 5,
            list: [
                ...state.list,
                ...action.list,
            ],
        };
    case DELETE_TOPIC: {
        const index = state.list.findIndex(data => data.uid === action.uid);
        const newList = [...state.list];
        newList.splice(index, 1);
        return {
            ...state,
            list: newList
        };
    }
    default:
        return state;
    }
};
export default topicReducer;
