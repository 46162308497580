/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import Cover from 'components/sections/cover';
import { HAds, VSlimAds } from 'components/sections/ads';
import FiveTopics from 'components/sections/fiveTopics';
// import LatestTopics from 'components/sections/latestTopics';
import PostsAndTopics from 'components/sections/postsAndTopics';
import TitleComponent from 'components/roots/titleComponent';
import AdsPopup from 'components/roots/popup';

function Home(props) {
    const {
        lang,
        loading,
        cover,
        topics,
        // latest,
        getList,
        getHomeAds,
        getPostList,
        getTopicList,
        getCheckIsNewUser,
    } = props;

    React.useEffect(() => {
        getCheckIsNewUser();
        getList(lang);
        getHomeAds();
        getTopicList();
        getPostList(lang);
    }, [getList, lang, getHomeAds, getPostList, getTopicList]);

    return (
        <>
            <AdsPopup pageLoading={loading} />
            <TitleComponent />
            <Cover
                loading={loading}
                title={cover.title}
                desc={cover.desc}
                img={cover.img}
            />
            <HAds position="header" pageLoading={loading} />
            <Hidden mdUp>
                <VSlimAds position="sideTop" pageLoading={loading} />
            </Hidden>
            <FiveTopics loading={loading} list={topics} />
            {/* <LatestTopics loading={loading} list={latest} cta="/latest" /> */}
            <PostsAndTopics loading={loading} />
            <Hidden mdUp>
                <VSlimAds position="sideBottom" pageLoading={loading} />
            </Hidden>
            <HAds position="footer" pageLoading={loading} />
        </>
    );
}

Home.propTypes = {
    loading: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    cover: PropTypes.object.isRequired,
    topics: PropTypes.array.isRequired,
    // latest: PropTypes.array.isRequired,
    getList: PropTypes.func.isRequired,
    getCheckIsNewUser: PropTypes.func.isRequired,
    getHomeAds: PropTypes.func.isRequired,
    getPostList: PropTypes.func.isRequired,
    getTopicList: PropTypes.func.isRequired,
};

export default Home;
