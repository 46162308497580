import {
    FETCH_CATEGORY_PAGE,
    FETCH_CATEGORY_PAGE_DONE,
    FETCH_CATEGORY_PAGE_FAIL,
} from 'actions/category';

const initialState = {
    loading: true,
    cover: {
        title: '',
        desc: '',
        img: '',
    },
    topics: [],
    subCategory: {},
    suggestion: [],
    failMessage: '',
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_CATEGORY_PAGE:
        return {
            ...state,
            loading: true,
            cover: {
                title: '',
                desc: '',
                img: '',
            },
            topics: [],
            subCategory: {},
            suggestion: [],
        };
    case FETCH_CATEGORY_PAGE_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_CATEGORY_PAGE_DONE:
        return {
            ...state,
            loading: false,
            cover: action.cover,
            topics: action.topics,
            subCategory: action.subCategory,
            suggestion: action.suggestion,
        };
    default:
        return state;
    }
};
export default homeReducer;
