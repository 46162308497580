/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Cover from 'components/sections/cover';
import { HAds } from 'components/sections/ads';
import FiveTopics from 'components/sections/fiveTopics';
import SubCategory from 'components/sections/subCategory';
import SuggestTopics from 'components/sections/suggestTopics';
import SubCategoryName from 'common/subCategory';
import MenuLang from 'common/menu';
import TitleComponent from 'components/roots/titleComponent';

function Inspiration(props) {
    const {
        lang,
        loading,
        cover,
        topics,
        subCategory,
        suggestion,
        getList,
        getAllPageAds,
    } = props;

    React.useEffect(() => {
        getList(lang, 'inspiration');
        getAllPageAds();
    }, [getList, lang, getAllPageAds]);

    const initialArr = [...Array(3).keys()];

    return (
        <>
            <TitleComponent title="Inspiration - All About Living" />
            <Cover
                loading={loading}
                title={cover.title}
                desc={cover.desc}
                img={cover.img}
            />
            <HAds 
                color="green"
                position="header"
                pageLoading={loading}
            />
            <FiveTopics
                bgColor="green"
                loading={loading}
                list={topics}
            />
            {
                loading ?
                    initialArr.map((item) => (
                        <SubCategory
                            key={item}
                            loading
                            bgColor="white"
                        />
                    ))
                    :
                    SubCategoryName.inspiration.map((subCatId) => (
                        <SubCategory
                            key={subCatId}
                            loading={loading}
                            title={MenuLang[lang].subCategory[subCatId]}
                            bgColor="white"
                            cta={`/subcategory/${subCatId}`}
                            list={subCategory[subCatId]}
                        />
                    ))
            }
            <SuggestTopics
                title={MenuLang[lang].suggestion}
                bgColor="default"
                list={suggestion}
                loading={loading}
            />
            <HAds
                position="footer"
                pageLoading={loading}
            />
        </>
    );
}

Inspiration.propTypes = {
    loading: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    cover: PropTypes.object.isRequired,
    topics: PropTypes.array.isRequired,
    subCategory: PropTypes.object.isRequired,
    getList: PropTypes.func.isRequired,
    getAllPageAds: PropTypes.func.isRequired,
    suggestion: PropTypes.array.isRequired,
};

export default Inspiration;