export default {
    type: [
        {
            uid: '5000',
            name: '5,000 บาท',
        },
        {
            uid: '10000',
            name: '10,000 บาท',
        },
        {
            uid: '15000',
            name: '15,000 บาท',
        },
        {
            uid: '20000',
            name: '20,000 บาท',
        },
        {
            uid: '25000',
            name: '25,000 บาท',
        },
        {
            uid: '30000',
            name: '30,000 บาท',
        },
        {
            uid: '35000',
            name: '35,000 บาท',
        },
        {
            uid: '40000',
            name: '40,000 บาท',
        },
        {
            uid: '45000',
            name: '45,000 บาท',
        },
        {
            uid: '50000',
            name: '50,000 บาท',
        },
        {
            uid: '55000',
            name: '55,000 บาท',
        },
        {
            uid: '60000',
            name: '60,000 บาท',
        },
        {
            uid: '65000',
            name: '65,000 บาท',
        },
        {
            uid: '70000',
            name: '70,000 บาท',
        },
        {
            uid: '75000',
            name: '75,000 บาท',
        },
        {
            uid: '80000',
            name: '80,000 บาท',
        },
        {
            uid: '85000',
            name: '55,000 บาท',
        },
        {
            uid: '90000',
            name: '90,000 บาท',
        },
        {
            uid: '95000',
            name: '95,000 บาท',
        },
        {
            uid: '100000',
            name: '100,000 บาท',
        },
        {
            uid: '110000',
            name: '110,000 บาท',
        },
        {
            uid: '120000',
            name: '120,000 บาท',
        },
        {
            uid: '130000',
            name: '130,000 บาท',
        },
        {
            uid: '140000',
            name: '140,000 บาท',
        },
        {
            uid: '150000',
            name: '150,000 บาท',
        },
        {
            uid: '200000',
            name: '200,000 บาท',
        },
    ],
};
