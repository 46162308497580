import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import Marker from './marker';
import SearchBox from './searchBox';

const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.5,
        background: '#CCC',
    },
    progress: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
    },
}));

const MapComponent = props => {
    const classes = useStyles();
    const { category, loading, lat, lng, changeGeo } = props;

    // Map
    const handleClickedMap = e => {
        const latitude = e.lat;
        const longtitude = e.lng;
        changeGeo(latitude, longtitude);
    };

    return (
        <div
            className={clsx(loading ? classes.disabled : '')}
            style={{ position: 'relative', width: '100%' }}>
            <SearchBox changeGeo={changeGeo} />
            <div style={{ position: 'relative', width: '100%', height: 400 }}>
                {loading && (
                    <CircularProgress
                        className={classes.progress}
                        color="inherit"
                    />
                )}
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: 'AIzaSyCoKTlqbwnFRJqTgwMVxV2I2muPaluuUnY',
                    }}
                    defaultCenter={{
                        lat: 13.74285,
                        lng: 100.5485,
                    }}
                    center={[lat, lng]}
                    defaultZoom={16}
                    onClick={handleClickedMap}
                    yesIWantToUseGoogleMapApiInternals>
                    {category !== '' && (
                        <Marker lat={lat} lng={lng} category={category} />
                    )}
                </GoogleMapReact>
            </div>
        </div>
    );
};

MapComponent.propTypes = {
    category: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    changeGeo: PropTypes.func.isRequired,
};

export default MapComponent;
