/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Skeleton from 'react-loading-skeleton';
import TopicAvatar from 'components/roots/avatar/topic';
import ViewComment from 'components/roots/status/ViewComment';
import { TagKeyword } from '../../roots/element';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
        paddingTop: 24,
    },
    card:{
        borderRadius: 8,
        border: 'solid 1px #c7c7c7',
        backgroundColor: '#fff',
        boxShadow: 'none',
        padding: '36px 24px 24px 24px',
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        },
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    markdownBox:{
        marginBottom: 32,
        lineHeight: 1.8,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 24,
        },
    },
    markdownContent:{
        position: 'relative',
        color: "#2e2e2e",
        width: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line',
        margin: 0,
        fontFamily: 'Sarabun',
        fontSize: 18,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.67,
        letterSpacing: 'normal',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: 1.75,
        },
    },
    tag:{
        '& button':{
            marginRight: 8,
        }
    },
    writer:{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
});

function TopicData(props) {
    const { 
        classes, 
        title, 
        createdAt, 
        markdown,
        loading, 
        tags,
        writer,
        view,
        comment,
    } = props;

    return (
        <div className={classes.background}>
            <Container>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    
                    <Grid item xs={12} md={10}>
                        <Card className={classes.card}>
                            <Box className={classes.title}>
                                <Typography variant="h5" color="textPrimary">
                                    {loading ? <Skeleton width="100%" count={2} /> : title}
                                </Typography>
                            </Box>
                            <Box className={classes.tag} mb={{xs:1,md:2}}>
                                {
                                    loading ?
                                        <Skeleton width="150px" />
                                        :
                                        tags.map((item) => (
                                            <TagKeyword
                                                key={item.uid}
                                                url={`/tag/${item.uid}`}
                                                text={item.nameTh}
                                            />
                                        ))
                                }
                            </Box>
                            <Box className={classes.markdownBox}>
                                {
                                    loading ?
                                        <Skeleton width="100%" count={5} /> :
                                        <div className={classes.markdownContent}>
                                            {markdown}
                                        </div>
                                }
                            </Box>
                            <Box className={classes.writer}>
                                <Box>
                                    {
                                        loading ?
                                            <Skeleton width="180px" height={50} />
                                            :
                                            <TopicAvatar
                                                img={writer.img}
                                                name={writer.name}
                                                desc={createdAt}
                                            />
                                    }
                                </Box>
                                <Box>
                                    <ViewComment 
                                        loading={loading}
                                        view={view}
                                        comment={comment}
                                    />
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

TopicData.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired, 
    createdAt: PropTypes.string.isRequired, 
    markdown: PropTypes.string.isRequired, 
    loading: PropTypes.bool.isRequired, 
    tags: PropTypes.array.isRequired, 
    writer: PropTypes.object.isRequired, 
    view: PropTypes.number.isRequired, 
    comment: PropTypes.number.isRequired, 
};

export default withStyles(styles)(TopicData);