import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinearProgress from '@material-ui/core/LinearProgress';
import Hidden from '@material-ui/core/Hidden';

const styles = (theme) => ({
    box: {
        width: '90%',
        maxWidth: 486,
        marginRight: 24,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        }
    },
    label: {
        color: '#00abbd',
        display: 'flex',
        alignItems: 'center',
    },
    icon:{
        marginRight: 8,
    }
});

function CardPaper(props) {
    const {
        classes,
        currentView,
        maxView,
    } = props;

    const thName = 'จำนวนคนเข้าดูประกาศ';
    const completed = 100*currentView/maxView;

    return (
        <Box className={classes.box}>
            <Hidden smDown>
                <Box mb={2}>
                    <Typography variant="body2" className={classes.label}>
                        <VisibilityIcon 
                            className={classes.icon} 
                        />
                        {`${thName} ${currentView}/${maxView}`}
                    </Typography>
                </Box>
                <LinearProgress variant="determinate" value={completed} />
            </Hidden>
            <Hidden mdUp>
                <Typography variant="body2" className={classes.label}>
                    <VisibilityIcon
                        className={classes.icon}
                    />
                    {`${currentView}/${maxView}`}
                </Typography>
            </Hidden>
        </Box>
    );
}

CardPaper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    currentView: PropTypes.number.isRequired,
    maxView: PropTypes.number.isRequired,
};

export default withStyles(styles)(CardPaper);