import { connect } from 'react-redux';
import { 
    getProfile,
    updateProfile,
    postProfile,
    postImage, 
} from 'actions/myAccount';
import Profile from 'views/myAccount/profile';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.myAccount.loading,
    profile: state.myAccount.profile,
    errors: state.myAccount.profileErrors,
    touched: state.myAccount.profileTouched,
    isValid: state.myAccount.profileIsValid,
});

const mapDispatchToProps = {
    getData:getProfile,
    updateProfile,
    postProfile,
    postImage,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);



