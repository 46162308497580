/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import moment from 'moment';
import VisibilitySensor from 'react-visibility-sensor';
import firebase from 'common/firebase';
import WhtLogo from './wht.svg';

const styles = (theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        borderRadius: 0,
        width: '100%',
        padding: '36px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 0',
        },
    },
    default: {
        backgroundColor: '#f9f9f9',
    },
    white:{
        backgroundColor: '#fff',
    },
    green:{
        backgroundColor: '#002023',
    },
    media: {
        width: '100%',
        height: 'auto',
        boxSizing: 'border-box',
        borderRadius: 0,
    },
    desktop: {
        maxWidth: 728,
        maxHeight: 90,
    },
    mobile: {
        maxWidth: 360,
        maxHeight: 64,
    },
    outerImage:{
        maxWidth: 728,
        width: '100%',
        height: 90,
        boxSizing: 'border-box',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#e9e9e9',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 360,
            height: 64,
        },
    },
});

const seenRef = firebase.firestore().collection("adsSeen");
const clickRef = firebase.firestore().collection("adsClick");

const handleClick = (adsId, userId) => {
    const data = {
        userId,
        adsId,
        timestamp: moment().valueOf(),
    };

    clickRef.add(data).then((docRef) => {
        console.log('click:', docRef.id);
    }).catch((error) => {
        console.log(error);
    });
};

function Ads(props) {
    const { 
        classes, 
        color,
        pageLoading,
        data,
        userId,
        position,
    } = props;

    let colorClass = classes.default;
    if (color === 'white'){
        colorClass = classes.white;
    } 
    if (color === 'green') {
        colorClass = classes.green;
    }

    const [seen, setSeen] = React.useState(false);
    const onChange = (isVisible) => {
        if (isVisible){
            const input = {
                userId,
                adsId: data[position].uid,
                timestamp: moment().valueOf(),
            };
            seenRef.add(input).then((docRef) => {
                console.log('seen:', docRef.id);
                setSeen(true); 
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    return (        
        <Card className={clsx(classes.card, colorClass)}>
            <Hidden smDown>
                {data[position].desktop === '' || pageLoading || data[position].uid === '' || userId === '' ?
                    <div className={classes.outerImage}>
                        <img src={WhtLogo} alt="All About Living Logo" />
                    </div>
                    :
                    <VisibilitySensor 
                        onChange={onChange} 
                        active={!seen}
                        delayedCall
                    >
                        <a href={data[position].url} target="_blank" rel="noopener noreferrer" onClick={() => handleClick(data[position].uid, userId)}>
                            <CardMedia component='img' src={data[position].desktop} className={clsx(classes.media, classes.desktop)} />
                        </a>
                    </VisibilitySensor>
                }
            </Hidden>
            <Hidden mdUp>
                {data[position].mobile === '' || pageLoading || data[position].uid === '' || userId === '' ?
                    <div className={classes.outerImage}>
                        <img src={WhtLogo} alt="All About Living Logo" />
                    </div>
                    :
                    <VisibilitySensor 
                        onChange={onChange} 
                        active={!seen}
                        delayedCall
                    >
                        <a href={data[position].url} target="_blank" rel="noopener noreferrer" onClick={() => handleClick(data[position].uid, userId)}>
                            <CardMedia component='img' src={data[position].mobile} className={clsx(classes.media, classes.mobile)} />
                        </a>
                    </VisibilitySensor>
                }
            </Hidden>
        </Card>
    );
}

Ads.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
    pageLoading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
};

Ads.defaultProps = {
    color: 'default',
};

const mapStateToProps = (state) => ({
    userId: state.auth.uid,
    data: state.ads,
});

export default connect(mapStateToProps, null)(withStyles(styles)(Ads));