/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import { MyTopic } from 'components/roots/list';

const styles = (theme) => ({
    card: {
        textAlign: 'center',
        minHeight: 'calc(100vh - 219px)',
        padding: '50px 0 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 419px)',
            padding: '50px 16px',
        },
    },
    container: {
        minHeight: 'calc(100vh - 219px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 419px)',
        },
    },
    footer: {
        margin: '8px auto',
        width: 180,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '16px auto 28px auto',
        },
    },
    button: {
        width: 384,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    createBtn: {
        maxWidth: 180,
        marginLeft: 'auto',
    },
});

function MyTopics(props) {
    const {
        classes,
        // lang,
        loading,
        loadingMore,
        showLoadingMore,
        list,
        getMyList,
        resetData,
        postDelete,
        loadmore,
    } = props;

    const initialArr = [...Array(5).keys()];
    const lastVisibleUid = list[list.length - 1] ? list[list.length - 1].uid : '';

    React.useEffect(() => {
        getMyList();
    }, [getMyList]);

    return (
        <>
            <TitleComponent title="กระทู้ของฉัน - All About Living" />
            {list.length < 1 && !loading ? (
                <Card className={classes.card}>
                    <Box mb="10px">
                        <Typography variant="h3" color="textPrimary">
                            คุณยังไม่มีกระทู้
                        </Typography>
                    </Box>
                    <Box mb={{ xs: '24px', md: '40px' }}>
                        <Typography variant="body1" color="textSecondary">
                            คุณสามารถตั้งกระทู้เพื่อสอบถามความรู้เกี่ยวกับอสังหาฯ
                            ได้
                        </Typography>
                    </Box>
                    <Box className={classes.button}>
                        <Link
                            to="/create-topic"
                            style={{ textDecoration: 'none' }}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => resetData()}>
                                สร้างกระทู้
                            </Button>
                        </Link>
                    </Box>
                </Card>
            ) : (
                <Container className={classes.container}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end">
                        <Grid item xs={6}>
                            <Box mb={{ xs: 2, md: 3 }} mt={{ xs: 2, md: 3 }}>
                                <Typography variant="h2" color="textPrimary">
                                    {loading ? (
                                        <Skeleton width={150} />
                                    ) : (
                                        'กระทู้ของฉัน'
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                mb={{ xs: 2, md: 3 }}
                                mt={{ xs: 2, md: 3 }}
                                className={classes.createBtn}>
                                {loading ? (
                                    <>
                                        <Hidden smDown>
                                            <Skeleton width={180} height={56} />
                                        </Hidden>
                                        <Hidden mdUp>
                                            <Skeleton
                                                width="100%"
                                                height={48}
                                            />
                                        </Hidden>
                                    </>
                                ) : (
                                    <Link
                                        to="/create-topic"
                                        style={{ textDecoration: 'none' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => resetData()}>
                                            ตั้งกระทู้
                                        </Button>
                                    </Link>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {loading ? (
                                <>
                                    {initialArr.map(item => (
                                        <MyTopic key={item} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {list.map(item => (
                                        <MyTopic
                                            key={item.uid}
                                            loading={loading}
                                            uid={item.uid}
                                            url={`/topic/${item.url}/${item.uid}`}
                                            title={item.title}
                                            desc={item.desc}
                                            date={item.date}
                                            view={item.view}
                                            comment={item.comment}
                                            deleteTopic={() =>
                                                postDelete(item.uid)
                                            }
                                            resetData={resetData}
                                        />
                                    ))}
                                </>
                            )}
                            {loadingMore ? (
                                <>
                                    {initialArr.map(item => (
                                        <MyTopic key={item} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {!loading && showLoadingMore && (
                                        <Box className={classes.footer}>
                                            <Button
                                                color="default"
                                                variant="outlined"
                                                onClick={() =>
                                                    loadmore(lastVisibleUid)
                                                }>
                                                ดูรายละเอียดเพิ่มเติม
                                            </Button>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            )}
        </>
    );
}

MyTopics.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    // lang: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMore: PropTypes.bool.isRequired,
    showLoadingMore: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    getMyList: PropTypes.func.isRequired,
    resetData: PropTypes.func.isRequired,
    postDelete: PropTypes.func.isRequired,
    loadmore: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(MyTopics));
