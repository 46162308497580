import { connect } from 'react-redux';
import {
    getData,
    getRelated,
    getMap,
} from 'actions/article';
import {
    getSideAds,
} from 'actions/ads';
import ArticlePage from 'views/article';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.article.loading,
    data: state.article.data,
    relatedLoading: state.article.relatedLoading,
    relatedList: state.article.relatedList,
    mapLoading: state.article.mapLoading,
});

const mapDispatchToProps = {
    getData,
    getRelated,
    getMap,
    getSideAds,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ArticlePage);

