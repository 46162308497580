/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import algoliasearch from 'algoliasearch/lite';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';
import _ from 'lodash';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocationCityIcon from '@material-ui/icons/LocationCity';

const searchClient = algoliasearch(
    'SJS3S5FAVR',
    'fbf789ef62160863f04f7f39da3e7fe7',
);

const styles = () => ({
    noOptions: {
        padding: '8px 0',
    },
    icon: {
        minWidth: 0,
        marginRight: 15,
    },
    list: {
        // padding: '0',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        '&:hover svg': {
            color: '#00abbd',
        },
    },
    root: {
        margin: 0,
    },
});

const MyAutocomplete = ({
    classList,
    loading,
    oldData,
    update,
    selectedValue,
    hits,
    refine,
}) => {
    const unselected = _(hits)
        .differenceBy(selectedValue, 'uid')
        .map(
            _.partial(_.pick, _, 'uid', 'nameTh', 'nameEn', 'nameCh', 'nameJp'),
        )
        .value();

    return (
        <Autocomplete
            className={classList.searchResult}
            classes={{
                noOptions: classList.noOptions,
            }}
            noOptionsText="ไม่พบสถานที่ที่คุณค้นหา"
            loading={loading}
            options={unselected}
            renderOption={option =>
                option.nameTh ? (
                    <Box className={classList.list}>
                        <ListItemIcon className={classList.icon}>
                            <LocationCityIcon />
                        </ListItemIcon>
                        <ListItemText primary={option.nameTh} />
                    </Box>
                ) : (
                    ''
                )
            }
            filterSelectedOptions
            getOptionLabel={option => (option.nameTh ? option.nameTh : '')}
            renderInput={params => (
                <TextField
                    classes={{
                        root: classList.root,
                    }}
                    {...params}
                    variant="outlined"
                    label="ค้นหาชื่อผู้พัฒนาโครงการ"
                    margin="normal"
                    fullWidth
                    required
                    disabled={loading}
                    onChange={event => {
                        refine(event.currentTarget.value);
                    }}
                />
            )}
            value={selectedValue}
            onChange={(event, value) => {
                update(value, oldData);
            }}
        />
    );
};
const ConnectedAutocomplete = connectAutoComplete(MyAutocomplete);

function DeveloperSearch(props) {
    const { classes, loading, oldData, update, selectedValue } = props;

    return (
        <div style={{ flexGrow: 1 }}>
            <InstantSearch indexName="developers" searchClient={searchClient}>
                <ConnectedAutocomplete
                    classList={classes}
                    loading={loading}
                    oldData={oldData}
                    update={update}
                    selectedValue={selectedValue}
                />
            </InstantSearch>
        </div>
    );
}

MyAutocomplete.propTypes = {
    classList: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    hits: PropTypes.array.isRequired,
    refine: PropTypes.func.isRequired,
    selectedValue: PropTypes.func.isRequired,
};

DeveloperSearch.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    selectedValue: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeveloperSearch);
