import { connect } from 'react-redux';
import {
    getList,
    loadmore,
} from 'actions/subCategory';
import {
    getAllPageAds,
} from 'actions/ads';
import SubCategory from 'views/subCategory';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.subCategory.loading,
    loadingMore: state.subCategory.loadingMore,
    showLoadingMore: state.subCategory.showLoadingMore,
    list: state.subCategory.list,
    name: state.subCategory.name,
});

const mapDispatchToProps = {
    getList,
    loadmore,
    getAllPageAds,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubCategory);

