import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { makeStyles } from '@material-ui/styles';
import {
    TextField,
    Paper,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        '& .location-search-input': {
            margin: 0,
        },
        '& .autocomplete-dropdown-container': {
            position: 'absolute',
            right: 0,
            left: 0,
            '& nav': {
                padding: 0,
            },
        },
    },
    progress: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
    },
}));

function LocationSearchInput(props) {
    const classes = useStyles();
    const { changeGeo } = props;

    const [state, setState] = React.useState({
        address: '',
    });

    const handleChange = address => {
        setState({ address });
    };

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng);
                setState({ address });
                changeGeo(latLng.lat, latLng.lng);
            })
            .catch(error => console.error('Error', error));
    };

    return (
        <PlacesAutocomplete
            value={state.address}
            onChange={handleChange}
            onSelect={handleSelect}>
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
            }) => (
                <div
                    className={classes.root}
                    style={{ margin: 0, width: '100%', zIndex: 10 }}>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="place"
                        onChange={handleChange}
                        disabled={loading}
                        value={state.address}
                        variant="outlined"
                        {...getInputProps({
                            placeholder: 'เลือกสถานที่...',
                            className: 'location-search-input',
                        })}
                    />
                    <Paper className="autocomplete-dropdown-container">
                        <List component="nav">
                            {loading
                                ? _.times(3, i => (
                                    <ListItem key={i} button>
                                        <Skeleton width={200} />
                                    </ListItem>
                                ))
                                : suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                        }
                                        : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                        };
                                    return (
                                        <ListItem
                                            button
                                            {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                    className,
                                                    style,
                                                },
                                            )}>
                                            <ListItemText
                                                primary={
                                                    suggestion.description
                                                }
                                            />
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </Paper>
                </div>
            )}
        </PlacesAutocomplete>
    );
}

LocationSearchInput.propTypes = {
    changeGeo: PropTypes.func.isRequired,
};

export default LocationSearchInput;
