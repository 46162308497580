/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { 
    withRouter, 
    Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import queryString from 'query-string';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
// import MenuLang from 'common/menu';

const styles = (theme) => ({
    card: {
        textAlign: 'center',
        minHeight: 'calc(100vh - 219px)',
        padding: '50px 0 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 384,
        margin: '0 auto',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            minHeight: 0,
            padding: '40px 16px',
        },
    },
});


function ResetPassword(props) {
    const {
        classes,
        location,
        // lang,
        isValid,
        loading,
        data,
        errors,
        touched,
        successMessage,
        updatePassword,
        postResetPassword,
    } = props;

    const params = queryString.parse(location.search);

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return true;
        }
        return false;
    };

    const handleChange = event => {
        event.persist();
        updatePassword(data, event.target.name, event.target.value);
    };

    const handleSignIn = () => {
        postResetPassword(data, params.oobCode, params.continueUrl, params.lang);
    };

    if (!_.isEmpty(successMessage)){
        return (<Redirect to="/" />);
    }

    return (
        <>
            <TitleComponent title="Reset Password - All About Living" />
            <Card className={classes.card}>
                <Box mb={2}>
                    <Typography
                        variant="h2"
                        color="textPrimary"
                        align="center"
                    >

                        รีเซทรหัสผ่าน
                    </Typography>
                </Box>
                <Box mb={3}>
                    <Typography
                        variant="body2"
                        color="textPrimary"
                        align="center"
                    >
                        กรุณากรอกรหัสผ่านใหม่เพื่อ Reset รหัสผ่าน
                    </Typography>
                </Box>
                <Box mb={2}>
                    <TextField
                        error={hasError('password')}
                        helperText={
                            hasError('password') ? errors.password[0] : null
                        }
                        disabled={loading}
                        fullWidth
                        label="รหัสผ่านใหม่"
                        className={classes.textField}
                        type="password"
                        name="password"
                        variant="outlined"
                        onChange={handleChange}
                        value={data.password}
                    />
                </Box>
                <Box mb={3}>
                    <TextField
                        error={hasError('confirmPassword')}
                        helperText={
                            hasError('confirmPassword') ? errors.confirmPassword[0] : null
                        }
                        disabled={loading}
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        type='password'
                        label="ยืนยันรหัสผ่านใหม่"
                        name="confirmPassword"
                        onChange={handleChange}
                        value={data.confirmPassword}
                    />
                </Box>
                <Box style={{ textAlign: 'center' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        noShadow
                        onClick={() => handleSignIn()}
                        disabled={!isValid || loading}
                        loading={loading}
                    >
                        รีเซท
                    </Button>
                </Box>
            </Card>
        </>
    );
}

ResetPassword.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    successMessage: PropTypes.string.isRequired,
    updatePassword: PropTypes.func.isRequired,
    postResetPassword: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(ResetPassword));
