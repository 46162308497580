import validate from 'validate.js';
// import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import moment from 'moment';
import stripHtml from "string-strip-html";
import firebase from 'common/firebase';
import { showSnack } from './main';

const functions = firebase.app().functions('asia-east2');

const schema = {
    title: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    desc: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

const commentSchema = {
    desc: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
// -- CREATE/UPDATE
export const SET_TOPIC_TAG = 'SET_TOPIC_TAG';
export const REMOVE_TOPIC_TAG = 'REMOVE_TOPIC_TAG';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const UPDATE_TOPIC_COMMENT = 'UPDATE_TOPIC_COMMENT';
export const ADD_TOPIC_COMMENT = 'ADD_TOPIC_COMMENT';
export const FETCH_TOPIC_COMMENT = 'FETCH_TOPIC_COMMENT';
export const RESET_TOPIC = 'RESET_TOPIC';
export const FETCH_TOPIC = 'FETCH_TOPIC';
export const FETCH_TOPIC_DATA_DONE = 'FETCH_TOPIC_DATA_DONE';
export const FETCH_TOPIC_DONE = 'FETCH_TOPIC_DONE';
export const FETCH_TOPIC_FAIL = 'FETCH_TOPIC_FAIL';

// -- LIST
export const FETCH_TOPIC_LIST_DONE = 'FETCH_TOPIC_LIST_DONE';
export const DELETE_TOPIC = 'DELETE_TOPIC';
export const FETCH_TOPIC_LOADMORE = 'FETCH_TOPIC_LOADMORE';
export const FETCH_TOPIC_LOADMORE_DONE = 'FETCH_TOPIC_LOADMORE_DONE';

// Sync Action Creator
// -- Create/Update --
export function changeTag(tags,oldData) {
    const validateData = {
        title: oldData.title,
        desc: oldData.desc,
    };
    const errors = validate(validateData, schema);
    return {
        type: SET_TOPIC_TAG,
        tags,
        errors,
    };
};
export function removeTag(index, oldData) {
    const validateData = {
        title: oldData.title,
        desc: oldData.desc,
    };
    const errors = validate(validateData, schema);
    return {
        type: REMOVE_TOPIC_TAG,
        index,
        errors,
    };
};
export function update(oldData, fieldName, newValue) {
    const data = oldData;
    data[fieldName] = newValue;

    const validateData = {
        title: data.title,
        desc: data.desc,
    };

    const errors = validate(validateData, schema);
    return {
        type: UPDATE_TOPIC,
        fieldName,
        newValue,
        errors,
    };
};

export function updateComment(newValue) {
    const validateData = {
        desc: newValue,
    };

    const errors = validate(validateData, commentSchema);
    return {
        type: UPDATE_TOPIC_COMMENT,
        newValue,
        errors,
    };
};

export function resetData() {
    return {
        type: RESET_TOPIC,
    };
};
export function fetch() {
    return {
        type: FETCH_TOPIC,
    };
};
export function fetchComment() {
    return {
        type: FETCH_TOPIC_COMMENT,
    };
};
export function addCommentDone(data) {
    return {
        type: ADD_TOPIC_COMMENT,
        data,
    };
};
export function fetchDataDone(data) {
    return {
        type: FETCH_TOPIC_DATA_DONE,
        data,
    };
};
export function fetchDone(message) {
    return {
        type: FETCH_TOPIC_DONE,
        message,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_TOPIC_FAIL,
        message,
    };
};

// -- LIST --
export function fetchListDone(list,hotList) {
    return {
        type: FETCH_TOPIC_LIST_DONE,
        list,
        hotList,
    };
};
export function deleteData(uid) {
    return {
        type: DELETE_TOPIC,
        uid,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_TOPIC_LOADMORE,
    };
};
export function fetchLoadmoreDone(list) {
    return {
        type: FETCH_TOPIC_LOADMORE_DONE,
        list,
    };
};

// Async Action Creator
function topicFormatData(doc) {
    const docData = doc.data();
    const data = {
        uid: doc.id,
        url: docData.url ? docData.url : 'no-title',
        title: docData.title ? _.truncate(docData.title, { 'length': 80, 'separator': ' ' }) : _.truncate(docData.title, { 'length': 80, 'separator': ' ' }),
        desc: docData.shortDesc,
        date: moment(docData.timestamp).format('D MMM YYYY'),
        view: docData.reachNumber,
        comment: docData.commentNumber,
        isPin: docData.isPin,
        createdBy: docData.createdBy,
    };
    return data;
};
export function getMyList() {
    return async (dispatch) => {
        dispatch(fetch());
        const user = firebase.auth().currentUser;
        if (user) {
            const { uid } = user;

            const ref = firebase.firestore().collection("topics");
            const query = ref.where("createdBy", "==", uid).orderBy('timestamp', 'desc').limit(5);
            try {
                const querySnapshot = await query.get();
                const list = [];
                querySnapshot.forEach((doc) => {
                    const docData = topicFormatData(doc);
                    list.push(docData);
                });

                dispatch(fetchListDone(list,[]));
            } catch (error){
                dispatch(showSnack('warning', error.message));
                dispatch(fetchFail(error.message));
            }
            return true;
        } 
        dispatch(fetchFail("You are not sign in."));
        return false;
    };
};
export function getList() {
    return async (dispatch) => {
        dispatch(fetch());

        const getListAPI = functions.httpsCallable('getTopicList');
        return getListAPI().then((result) => {
            const hotTopic = _.uniqBy(result.data.hot, 'uid');
            dispatch(fetchListDone(result.data.list, hotTopic));
        }).catch((error) => {
            dispatch(fetchFail(error.message));
        });
    };
}

function slugify(text) {
    const uri = text.toString().toLowerCase().trim()
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single _
        .replace(/^-+|-+$/g, ''); // remove leading, trailing -

    return encodeURIComponent(uri);
}

export function postCreate(data) {
    return (dispatch) => {
        dispatch(fetch());
        const creator = firebase.auth().currentUser;
        if (creator) {
            const shortDesc = _.truncate(stripHtml(data.desc), { 'length': 120, 'separator': ' ' });
            const urlString = slugify(data.title);

            const inputData = {
                ...data,
                shortDesc,
                url: urlString,
                tagId: _.isEmpty(data.tagId) ? [] : _.map(data.tagId, 'uid'),
                isHide: false,
                isPin: false,
                reachNumber: 0,
                commentNumber: 0,
                createdBy: creator.uid,
                isCreatedByAdmin: false,
                timestamp: moment().valueOf(),
                updatedAt: moment().valueOf(),
            };

            const ref = firebase.firestore().collection("topics");
            return ref.add(inputData).then(() => {
                dispatch(fetchDone('Your topic created successfully.'));
                dispatch(showSnack('success', 'Your topic created successfully.'));
            }).catch((error) => {
                dispatch(showSnack('warning', error.message));
            });
        } 
        dispatch(fetchFail("You are not sign in."));
        return true;  
    };
};
export function postComment(topicId,desc) {
    return (dispatch) => {
        dispatch(fetchComment());
        const creator = firebase.auth().currentUser;
        if (creator) {
            const inputData = {
                desc,
                topicId,
                createdBy: creator.uid,
                timestamp: moment().valueOf(),
            };

            const ref = firebase.firestore().collection("comments");
            return ref.add(inputData).then(() => {
                const outputData = {
                    ...inputData,
                    uid: inputData.timestamp,
                    date: moment(inputData.timestamp).format('D MMM YYYY'),
                    writer: {
                        name: creator.displayName,
                        img: creator.photoURL,
                    },
                };
                dispatch(addCommentDone(outputData));
                dispatch(showSnack('success', 'Your comment created successfully.'));
            }).catch((error) => {
                dispatch(showSnack('warning', error.message));
            });
        }
        dispatch(fetchFail("You are not sign in."));
        return true;
    };
};
export function postDelete(uid) {
    return (dispatch) => {
        const ref = firebase.firestore().collection("topics").doc(uid);
        return ref.delete().then(() => {
            dispatch(deleteData(uid));
            dispatch(fetchDone('Your topic deleted successfully.'));
            dispatch(showSnack('success', 'Your topic deleted successfully.'));
        }).catch((error) => {
            dispatch(showSnack('warning', error.message));
        });
    };
};
export function loadmore(lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const user = firebase.auth().currentUser;
        if (user) {
            const { uid } = user;

            const ref = firebase.firestore().collection("topics");
            try {
                const lastVisibleSnap = await ref.doc(lastVisibleUid).get();

                // Next List
                const nextListSnap = await ref.where("createdBy", "==", uid).orderBy('timestamp', 'desc').startAfter(lastVisibleSnap).limit(5).get();
                const nextList = [];
                nextListSnap.forEach((doc) => {
                    const nextListData = topicFormatData(doc);
                    nextList.push(nextListData);
                });
                dispatch(fetchLoadmoreDone(nextList));
            } catch (error) {
                dispatch(fetchFail(error.message));
            }
        }
        dispatch(fetchFail("You are not sign in."));
        return false;
    };
};
export function loadmoreList(lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const getListAPI = functions.httpsCallable('loadmoreTopicList');
        try {
            const nextList = await getListAPI(lastVisibleUid);
            dispatch(fetchLoadmoreDone(nextList.data));
        } catch (error) {
            dispatch(fetchFail(error.message));
        }
    };
};
export function getEdit(uid) {
    return (dispatch) => {
        dispatch(fetch());

        const getData = functions.httpsCallable('topicGetCreateData');
        return getData(uid).then((result) => {
            dispatch(fetchDataDone(result.data));
        }).catch((error) => {
            dispatch(fetchFail(error.message));
        });
    };
};
export function getTopicData(uid) {
    return (dispatch) => {
        dispatch(fetch());

        const getData = functions.httpsCallable('getTopicData');
        return getData(uid).then((result) => {
            dispatch(fetchDataDone(result.data));
        }).catch((error) => {
            dispatch(fetchFail(error.message));
        });
    };
};
export function postEdit(uid, data) {
    return (dispatch) => {
        dispatch(fetch());
        const creator = firebase.auth().currentUser;
        if (creator) {
            const shortDesc = _.truncate(stripHtml(data.desc), { 'length': 120, 'separator': ' ' });
            const urlString = slugify(data.title);

            const inputData = {
                ...data,
                shortDesc,
                url: urlString,
                tagId: _.isEmpty(data.tagId) ? [] : _.map(data.tagId, 'uid'),
                updatedAt: moment().valueOf(),
            };

            const ref = firebase.firestore().collection("topics");
            return ref.doc(uid).set(inputData, { merge: true }).then(() => {
                dispatch(fetchDone('Your topic updated successfully.'));
                dispatch(showSnack('success', 'Your topic updated successfully.'));
            }).catch((error) => {
                dispatch(showSnack('warning', error.message));
            });
        }
        dispatch(fetchFail("You are not sign in."));
        return true;
    };
};