/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import _ from 'lodash';

const styles = theme => ({
    textField: {
        width: '100%',
        margin: '0',
        [theme.breakpoints.down('sm')]: {
            '& label': {
                transform: 'translate(14px, 14px) scale(1)',
            },
            '& input': {
                height: 11,
            },
        },
    },
});

function LoginForm(props) {
    const {
        classes,
        loading,
        data,
        errors,
        touched,
        update,
        handleChangePage,
    } = props;

    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return true;
        }
        return false;
    };

    return (
        <>
            <Box mb="35px">
                <TextField
                    error={hasError('email')}
                    helperText={
                        hasError('email') ? errors.email[0] : null
                    }
                    disabled={loading}
                    fullWidth
                    label="อีเมล"
                    className={classes.textField}
                    type="email"
                    name="email"
                    variant="outlined"
                    onChange={(event) => update(data, event.target.name, event.target.value)}
                    value={data.email}
                />
            </Box>
            <Box mb={2}>
                <TextField
                    error={hasError('password')}
                    helperText={
                        hasError('password') ? errors.password[0] : null
                    }
                    disabled={loading}
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    label="รหัสผ่าน"
                    name="password"
                    value={data.password}
                    onChange={(event) => update(data, event.target.name, event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box mb={2} style={{ textAlign: 'center' }}>
                <Typography
                    variant="button"
                    color="primary"
                    align="center"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleChangePage('forgotPass')}
                >
                    ลืมรหัสผ่าน?
                </Typography>
            </Box>
        </>
    );
}

LoginForm.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    handleChangePage: PropTypes.func.isRequired,
};

export default withStyles(styles)(LoginForm);