import {
    SHOW_SNACK,
    HIDE_SNACK,
    SET_LANG,
    SET_NOT_FOUND,
    IS_SEARCHING,
    SEARCH_LENGTH,
} from 'actions/main';

const initialState = {
    lang: 'th',
    isNotFound: false,
    isShow: false,
    message: '',
    status: 'info', // warning, success, info
    isSearching: false,
    searchLength: 0,
};

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
    case SHOW_SNACK:
        return {
            ...state,
            isShow: true,
            message: action.message,
            status: action.status,
        };
    case HIDE_SNACK:
        return {
            ...state,
            isShow: false,
            message: '',
            status: '',
        };
    case SET_LANG:
        return {
            ...state,
            lang: action.lang,
        };
    case SET_NOT_FOUND:
        return {
            ...state,
            isNotFound: action.value,
        };
    case IS_SEARCHING:
        return {
            ...state,
            isSearching: action.newValue !== '',
        };
    case SEARCH_LENGTH:
        return {
            ...state,
            searchLength: action.newValue,
        };
    default:
        return state;
    }
};
export default mainReducer;