/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
    bg: {
        marginRight: 24,
        marginBottom: 24,
        width: 180,
        height: 180,
        position: 'relative',
        // display: 'inline-block',
    },
    btn: {
        width: 180,
        height: 180,
        position: 'relative',
        borderRadius: 10,
        border: 'solid 1px #00abbd',
        textAlign: 'center',
    },
}));

export default function Image(props) {
    const classes = useStyles();
    const { postGallery, index, oldData } = props;

    const GalRef = React.useRef(null);
    const handleClick = () => {
        GalRef.current.click();
    };

    const handleSelect = event => {
        const file = event.target.files[0];
        postGallery(file, index, oldData);
    };

    return (
        <Box className={classes.bg}>
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="gallery"
                name="gallery"
                ref={GalRef}
                type="file"
                onChange={handleSelect}
            />
            <Box onClick={handleClick}>
                <CardActionArea className={classes.btn}>
                    <AddPhotoAlternateIcon color="primary" fontSize="large" />
                </CardActionArea>
            </Box>
        </Box>
    );
}

Image.propTypes = {
    index: PropTypes.number.isRequired,
    postGallery: PropTypes.func.isRequired,
    oldData: PropTypes.object.isRequired,
};
