/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import { withStyles } from '@material-ui/styles';

// const styles = theme => ({
//     textField: {
//         width: '100%',
//         margin: '0',
//         [theme.breakpoints.down('sm')]: {
//             '& label': {
//                 transform: 'translate(14px, 14px) scale(1)',
//             },
//             '& input': {
//                 height: 11,
//             },
//         },
//     },
// });

function FooterDialog(props) {
    const {
        // classes,
        name,
        handleChangePage,
        // loading,
        reset,
    } = props;

    const handleClick = (page) => {
        handleChangePage(page);
        reset();
    };

    return (
        <>
            {
                name === 'login' &&
                <Box
                    style={{
                        textAlign: 'center',
                        display: 'inline-block',
                        width: '100%',
                    }}
                >
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{
                            display: 'inline-block',
                            paddingRight: 8,
                        }}
                    >
                        ยังไม่ได้เป็นสมาชิก?
                    </Typography>
                    <Typography
                        variant="button"
                        color="primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick('register')}
                    >
                        ลงทะเบียน
                    </Typography>
                </Box>
            }
            {
                (name === 'register' || name === 'registerForm') &&
                <Box
                    style={{
                        textAlign: 'center',
                        display: 'inline-block',
                        width: '100%',
                    }}
                >
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{
                            display: 'inline-block',
                            paddingRight: 8,
                        }}
                    >
                        เป็นสมาชิกแล้ว?
                    </Typography>
                    <Typography
                        variant="button"
                        color="primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick('login')}
                    >
                        เข้าสู่ระบบ
                    </Typography>
                </Box>
            }
        </>
    );
}

FooterDialog.propTypes = {
    // classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    // loading: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
};

export default FooterDialog;
// )(withStyles(styles)(FooterDialog));