import { connect } from 'react-redux';
import { 
    getEmail,
    updateEmail,
    postEmail,  
} from 'actions/myAccount';
import ChangeEmail from 'views/myAccount/email';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.myAccount.loading,
    data: state.myAccount.email,
    errors: state.myAccount.emailErrors,
    touched: state.myAccount.emailTouched,
    isValid: state.myAccount.emailIsValid,
});

const mapDispatchToProps = {
    getEmail,
    updateEmail,
    postEmail,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeEmail);



