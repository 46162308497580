/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

const styles = (theme) => ({
    box:{
        position: 'relative',
    },
    media: {
        width: 120,
        height: 120,
        textTransform: 'uppercase',
        backgroundColor: "#00abbd",
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: 84,
            height: 84,
        },
    },
    icon:{
        position: 'absolute',
        right: 0,
        bottom: 0,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        color: '#00abbd',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: 25,
            height: 25,
            '& svg':{
                width: '1rem',
            }
        },
    }
});

function CardPaper(props) {
    const { 
        classes, 
        img, 
        name,
        fieldName,
        loading,
        uploadFunc,
        oldData,
    } = props;

    const handleSelect = (event) => {
        const file = event.target.files[0];
        uploadFunc(file, oldData);
    };

    return (
        <label htmlFor={fieldName}>
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id={fieldName}
                name={fieldName}
                type="file"
                disabled={loading}
                onChange={handleSelect}
            />
            <div className={classes.box}>
                {
                    img ?
                        <Avatar
                            alt={name}
                            src={img}
                            className={classes.media}
                        />
                        :
                        <Avatar
                            color="primary"
                            className={classes.media}
                        >
                            {name.charAt(0)}
                        </Avatar>
                }
                <Avatar className={classes.icon}>
                    <CameraAltOutlinedIcon />
                </Avatar>
            </div>
        </label>
    );
}

CardPaper.propTypes = {
    classes: PropTypes.object.isRequired,
    img: PropTypes.string,
    name: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    uploadFunc: PropTypes.func.isRequired,
    oldData: PropTypes.object.isRequired,
};

CardPaper.defaultProps = {
    img: '',
};

export default withStyles(styles)(CardPaper);