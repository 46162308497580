import { createBrowserHistory } from 'history';
// import { anonymousAuth } from 'actions/auth';

const history = createBrowserHistory();

// Get the current location.
// const location = history.location;

// Listen for changes to the current location.
// const unlisten = history.listen((location, action) => {
//     console.log(action, location.pathname);
//     anonymousAuth();
// });

// // Use push, replace, and go to navigate around.
// history.push('/home', { some: 'state' });

// To stop listening, call the function returned from listen().
// unlisten();
export default history;