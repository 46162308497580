/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HAds } from 'components/sections/ads';
import TermAndCondition from 'components/sections/termAndCondition';
import MenuLang from 'common/menu';
import TitleComponent from 'components/roots/titleComponent';

function TermConditionPage(props) {
    const { 
        lang,
        loading,
        desc,
        getData,
        getAllPageAds,
        location,
        email,
        isValid,
        update,
        postCreate,
        submitLoading,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];
    const title = MenuLang[lang][uid];

    React.useEffect(() => {
        getAllPageAds();
        getData(lang, uid);
    }, [getData, lang, uid, getAllPageAds]);

    return (
        <>
            <TitleComponent title={`${title} - All About Living`} />
            <HAds 
                color="white"
                position="header"
                pageLoading={loading}  
            />
            <TermAndCondition
                lang={lang} 
                loading={loading}
                title={title}
                markdown={desc}
                showMailSubmit={uid === 'adsvertiseWithUs'}
                email={email}
                isValid={isValid}
                update={update}
                postCreate={postCreate}
                submitLoading={submitLoading}
            />
            <HAds 
                color="white"
                position="footer"
                pageLoading={loading}
            />
        </>
    );
}

TermConditionPage.propTypes = {
    lang: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    desc: PropTypes.string.isRequired,
    getData: PropTypes.func.isRequired,
    getAllPageAds: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    postCreate: PropTypes.func.isRequired,
    submitLoading: PropTypes.bool.isRequired,
};

export default withRouter(TermConditionPage);
