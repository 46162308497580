/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';
import Box from '@material-ui/core/Box';
import { HotTopic } from '../../roots/list';

const styles = () => ({
    background: {
        position: 'relative',
        display: 'block',
    },
});

function HotTopicList(props) {
    const { 
        classes, 
        loading,
        list, 
    } = props;

    const initialArr = [...Array(5).keys()];

    return (
        <div className={classes.background}>
            <Box mb={3}>
                {loading ? (
                    <Typography variant="h4" style={{ width: '100%' }}>
                        <Skeleton width="100%" count={1} />
                    </Typography>
                ) : (
                    <Typography variant="h4">กระทู้ยอดนิยม</Typography>
                )}
            </Box>
            {loading ? (
                <>
                    {initialArr.map(item => (
                        <HotTopic loading={loading} key={item} />
                    ))}
                </>
            ) : (
                <>
                    {list.map((item, index) => (
                        <HotTopic
                            key={item.uid}
                            loading={loading}
                            url={`/topic/${item.url}/${item.uid}`}
                            title={item.title}
                            date={item.date}
                            view={item.view}
                            comment={item.comment}
                            number={index + 1}
                        />
                    ))}
                </>
            )}
        </div>
    );
}

HotTopicList.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    list: PropTypes.array.isRequired,
};

HotTopicList.defaultProps = {
    loading: true,
};

export default withStyles(styles)(HotTopicList);