/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LabelIcon from '@material-ui/icons/Label';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { HAds } from 'components/sections/ads';
import List from 'components/sections/list/latest';
import ListTopic from 'components/sections/list/topics';
import TitleComponent from 'components/roots/titleComponent';
import MenuText from 'common/menu';

function Tag(props) {
    const {
        lang,
        loading,
        loadingMore,
        showLoadingMore,
        list,
        name,
        getList,
        location,
        loadmore,
        getAllPageAds, 
        loadingTopic,
        loadingTopicMore,
        showLoadingTopicMore,
        topicList,
        getTopicList,
        loadmoreTopic,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];
    const lastVisibleUid = list[list.length - 1] ? list[list.length - 1].uid : '';
    const lastTopicUid = topicList[topicList.length - 1] ? topicList[topicList.length - 1].uid : '';

    React.useEffect(() => {
        getList(lang, uid);
        getTopicList(uid);
        getAllPageAds();
    }, [getList, lang, uid, getAllPageAds, getTopicList]);

    return (
        <>
            <TitleComponent title={`${name || 'Tag'} - All About Living`} />
            <HAds
                color="white"
                position="header"
                pageLoading={loading}
            />
            <List
                loading={loading}
                loadingMore={loadingMore}
                loadmoreFunc={() => loadmore(lang, uid, lastVisibleUid)}
                loadmoreTitle={MenuText[lang].loadmore}
                showLoadingMore={showLoadingMore}
                list={list}
                title={
                    <>
                        <LabelIcon/> {name}
                    </>
                }
            />
            <Box pt={5} />
            <Container>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12} md={8}>
                        <ListTopic
                            loading={loadingTopic}
                            loadingMore={loadingTopicMore}
                            loadmoreFunc={() => loadmoreTopic(uid, lastTopicUid)}
                            loadmoreTitle={MenuText[lang].loadmore}
                            showLoadingMore={showLoadingTopicMore}
                            list={topicList}
                        />
                    </Grid>
                </Grid>
            </Container>
            <HAds
                color="white"
                position="footer"
                pageLoading={loading}
            />
        </>
    );
}

Tag.propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingMore: PropTypes.bool.isRequired,
    showLoadingMore: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    getList: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    loadmore: PropTypes.func.isRequired, 
    getAllPageAds: PropTypes.func.isRequired,
    loadingTopic: PropTypes.bool.isRequired,
    loadingTopicMore: PropTypes.bool.isRequired,
    showLoadingTopicMore: PropTypes.bool.isRequired,
    topicList: PropTypes.array.isRequired,
    getTopicList: PropTypes.func.isRequired,
    loadmoreTopic: PropTypes.func.isRequired,
};

export default withRouter(Tag);