import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';
import { TagNew } from '../element';

const styles = theme => ({
    card: {
        boxShadow: 'none',
        borderRadius: 8,
        backgroundColor: 'transparent',
    },
    media: {
        width: '100%',
        height: 288,
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            height: 246,
        }
    },
    loading: {
        width: '100%',
        height: 288,
        borderRadius: 8,
        backgroundColor: 'rgb(238, 238, 238)',
        backgroundImage: 'linear-gradient(90deg, rgb(238, 238, 238), rgb(245, 245, 245), rgb(238, 238, 238))',
        [theme.breakpoints.down('sm')]: {
            height: 246,
        }
    },
    tag: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        }
    },
    content: {
        paddingTop: '16px',
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: '0 !important',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    }
});

function CardPaper(props) {
    const {
        classes,
        url,
        tag,
        img,
        title,
        date,
        loading,
    } = props;
    return (
        <Link to={url} className={classes.link}>
            <Card className={classes.card}>
                {!loading ?
                    <CardMedia component="img" src={img} className={classes.media} />
                    :
                    <div className={classes.loading} />
                }
                <CardContent className={classes.content}>
                    {loading && <Skeleton width={50} />}
                    {
                        (tag !== '' && !loading) &&
                        <Box className={classes.tag}>
                            <TagNew text={tag} />
                        </Box>
                    }
                    <Box mb={1} width="100%">
                        <Typography variant="subtitle1" style={{ color: '#fff' }}>
                            {loading ? <Skeleton width="100%" count={1} /> : title}
                        </Typography>
                    </Box>
                    <Box width="100%">
                        <Typography variant="caption" style={{ color: '#c7c7c7' }} >
                            {loading ? <Skeleton width="100%" count={2} /> : date}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Link>
    );
}

CardPaper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    tag: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    loading: PropTypes.bool,
};

CardPaper.defaultProps = {
    url: '',
    tag: '',
    img: '',
    title: '',
    date: '',
    loading: true,
};

export default withStyles(styles)(CardPaper);