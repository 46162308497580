import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from 'react-loading-skeleton';
import DateViewCommentName from 'components/roots/status/DateViewCommentName';
import { TagPin } from 'components/roots/element';

const styles = theme => ({
    card: {
        boxShadow: 'none',
        width: '100%',
        padding: '0 0 8px 0',
        borderBottom: '1px solid #e9e9e9',
        borderRadius: 0,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '8px',
        }
    },
    header: {
        display: 'flex',
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        }
    },
    content: {
        width: '100%',
    },
    title: {
        width: '100%',
        marginBottom: 8,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        }
    },
    link:{
        textDecoration: 'none',
        color: 'inherit',
    },
});

function MyTopic(props) {
    const { 
        classes, 
        url,   
        title, 
        desc, 
        date,
        view,
        comment,
        loading,
        createdBy,
        isPin,
    } = props;

    return (
        <Card className={classes.card}>
            <Box className={classes.header}>
                <Box className={classes.content}>
                    <Box className={classes.title}>
                        {loading ? 
                            <Typography variant="subtitle1" style={{ width: '100%' }}>
                                <Skeleton width="100%" count={1} />
                            </Typography> 
                            : 
                            <>
                                {
                                    isPin &&
                                    <Box 
                                        mb={{ xs: 1, md: 0 }} 
                                        mr={{xs:0,md:2}} 
                                    >
                                        <TagPin
                                            text="แนะนำโดยแอดมิน"
                                        />
                                    </Box>
                                }
                                <Box>
                                    <Link to={url} className={classes.link}>
                                        <Typography variant="subtitle1" >
                                            {title}
                                        </Typography>
                                    </Link>
                                </Box>
                            </>
                        }
                    </Box>
                    <Hidden smDown>
                        <Typography variant="body2" color="textSecondary" >
                            {loading ? <Skeleton width="100%" count={2} /> : desc}
                        </Typography>
                    </Hidden>
                </Box>
            </Box>
            <DateViewCommentName
                createdBy={createdBy}
                date={date}
                view={view}
                comment={comment}
                loading={loading}
            />
        </Card>
    );
}

MyTopic.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    date: PropTypes.string,
    view: PropTypes.number,
    comment: PropTypes.number,
    loading: PropTypes.bool,
    createdBy: PropTypes.string,
    isPin: PropTypes.bool,
};

MyTopic.defaultProps = {
    url: '',
    title: '',
    desc: '',
    date: '',
    view: 0,
    comment: 0,
    loading: true,
    createdBy: '',
    isPin: false,
};

export default withStyles(styles)(MyTopic);