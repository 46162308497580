/* eslint-disable no-underscore-dangle */
import firebase from 'common/firebase';

const functions = firebase.app().functions('asia-east2');

// Action
// -- CREATE/UPDATE
export const SORTED_POST_BY = 'SORTED_POST_BY';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_POST_DATA_DONE = 'FETCH_POST_DATA_DONE';
export const FETCH_POST_DONE = 'FETCH_POST_DONE';
export const FETCH_POST_FAIL = 'FETCH_POST_FAIL';
export const RESET_POST = 'RESET_POST';

// -- LIST
export const FETCH_POST_LIST_DONE = 'FETCH_POST_LIST_DONE';
export const FETCH_POST_LOADMORE = 'FETCH_POST_LOADMORE';
export const FETCH_POST_LOADMORE_DONE = 'FETCH_POST_LOADMORE_DONE';
export const FETCH_POST_SHOWLESS = 'FETCH_POST_SHOWLESS';
export const FETCH_PROMOTE_POST = 'FETCH_PROMOTE_POST';
export const FETCH_PROMOTE_POST_DATA_DONE = 'FETCH_PROMOTE_POST_DATA_DONE';
export const FETCH_PROJECT_POST = 'FETCH_PROJECT_POST';
export const FETCH_PROJECT_POST_DATA_DONE = 'FETCH_PROJECT_POST_DATA_DONE';

// Sync Action Creator
export function resetData() {
    return {
        type: RESET_POST,
    };
}
export function setSortedBy(sortBy) {
    return {
        type: SORTED_POST_BY,
        sortBy,
    };
}
export function fetch() {
    return {
        type: FETCH_POST,
    };
}
export function fetchPromote() {
    return {
        type: FETCH_PROMOTE_POST,
    };
}
export function fetchProject() {
    return {
        type: FETCH_PROJECT_POST,
    };
}
export function fetchDataDone(data) {
    return {
        type: FETCH_POST_DATA_DONE,
        data,
    };
}
export function fetchPromoteDone(list) {
    return {
        type: FETCH_PROMOTE_POST_DATA_DONE,
        list,
    };
}
export function fetchProjectDone(data) {
    return {
        type: FETCH_PROJECT_POST_DATA_DONE,
        list:data.list,
        name:data.name,
    };
}
export function fetchDone(message) {
    return {
        type: FETCH_POST_DONE,
        message,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_POST_FAIL,
        message,
    };
}
// export function update(fieldName, newValue) {
//     // const validateData = {
//     //     type: oldData.type,
//     //     projectId: oldData.projectId,
//     //     lat: oldData.location.lat,
//     //     lng: oldData.location.lng,
//     //     title: oldData.title,
//     //     purpose: oldData.purpose,
//     //     sellPrice: oldData.sellPrice,
//     //     rentPrice: oldData.rentPrice,
//     //     areaSize: oldData.areaSize,
//     //     gallery: oldData.gallery,
//     // };

//     // if (fieldName === 'title') {
//     //     validateData.title = newValue;
//     // }
//     // if (fieldName === 'purpose') {
//     //     validateData.purpose = newValue;
//     // }
//     // if (fieldName === 'sellPrice') {
//     //     validateData.sellPrice = newValue;
//     //     if (oldData.purpose === 'sell') {
//     //         validateData.rentPrice = 'xxx';
//     //     }
//     // }
//     // if (fieldName === 'rentPrice') {
//     //     validateData.rentPrice = newValue;
//     //     if (oldData.purpose === 'rent') {
//     //         validateData.sellPrice = 'xxx';
//     //     }
//     // }
//     // if (fieldName === 'areaSize') {
//     //     validateData.areaSize = newValue;
//     // }

//     // const errors = validate(validateData, schema);

//     return {
//         type: UPDATE_POST,
//         fieldName,
//         newValue,
//     };
// }

// -- LIST --
export function fetchListDone(data) {
    return {
        type: FETCH_POST_LIST_DONE,
        list: data.list,
        cover: data.cover,
    };
}
export function fetchLoadmore() {
    return {
        type: FETCH_POST_LOADMORE,
    };
}
export function showless() {
    return {
        type: FETCH_POST_SHOWLESS,
    };
}
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_POST_LOADMORE_DONE,
        list: data,
    };
}

// Async Action Creator
export function getList(purpose, sortBy, lang, filters) {
    return async dispatch => {
        dispatch(fetch());
        const getListAPI = functions.httpsCallable('getPostList');
        try {
            const result = await getListAPI({ purpose, sortBy, lang, filters });
            const output = result.data;
            dispatch(fetchListDone(output));
        } catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
export function loadmore(purpose, sortBy, lang, lastVisibleUid) {
    return async dispatch => {
        dispatch(fetchLoadmore());

        const getLoadmore = functions.httpsCallable('loadmorePostList');
        try {
            const data = {
                purpose,
                sortBy,
                lang,
                lastVisibleUid,
            };
            const result = await getLoadmore(data);
            const output = result.data;
            dispatch(fetchLoadmoreDone(output));
        } catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
export function getData(lang, uid) {
    return dispatch => {
        dispatch(fetch());

        const articleGetList = functions.httpsCallable('getPostData');
        return articleGetList({ lang, uid })
            .then(result => {
                dispatch(fetchDataDone(result.data.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(fetchFail(error.message));
            });
    };
}

export function getPromote(lang) {
    return dispatch => {
        dispatch(fetchPromote());

        const articleGetList = functions.httpsCallable('getPromoteList');
        return articleGetList({ lang })
            .then(result => {
                dispatch(fetchPromoteDone(result.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(fetchFail(error.message));
            });
    };
}

export function getProject(lang, uid) {
    return dispatch => {
        dispatch(fetchProject());

        const articleGetList = functions.httpsCallable('getProjectList');
        return articleGetList({ lang, uid })
            .then(result => {
                dispatch(fetchProjectDone(result.data));
            })
            .catch(error => {
                // console.log(error);
                dispatch(fetchFail(error.message));
            });
    };
}
