import validate from 'validate.js';
import firebase from 'common/firebase';
import { showSnack } from './main';

// Action
export const UPDATE_RESET_PASSWORD = 'UPDATE_RESET_PASSWORD';
export const RESET_LOADING = 'RESET_LOADING';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_DONE = 'RESET_DONE';
export const RESET_FAIL = 'RESET_FAIL';

const schema = {
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    },
    confirmPassword: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};

// Sync Action Creator
export function updatePassword(oldData, fieldName, newValue) {
    const dataObj = oldData;
    dataObj[fieldName] = newValue;
    const errors = validate(oldData, schema);

    return {
        type: UPDATE_RESET_PASSWORD,
        fieldName,
        newValue,
        errors,
    };
};
export function isLoading() {
    return {
        type: RESET_LOADING,
    };
};
export function resetPassword() {
    return {
        type: RESET_PASSWORD,
    };
};
export function loadingDone(successMessage) {
    return {
        type: RESET_DONE,
        successMessage,
    };
};
export function loadingFail(failMessage) {
    return {
        type: RESET_FAIL,
        failMessage,
    };
};

// Async Action Creator
export function postResetPassword(data, actionCode, continueUrl, lang) {
    return (dispatch) => {
        dispatch(isLoading());
        if (data.password !== data.confirmPassword) {
            dispatch(showSnack('warning', 'Password and confirm password does not match.'));
            dispatch(loadingFail('Password and confirm password does not match.'));
        }else{
            return firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
                const accountEmail = email;
                
                // Change Password 
                firebase.auth().confirmPasswordReset(actionCode, data.password).then( async () => {
                    // Sign In
                    await firebase.auth().signInWithEmailAndPassword(accountEmail, data.password);
                    dispatch(showSnack('success', 'Your password has already been reset.'));
                    dispatch(loadingDone('Your password has already been reset.'));
                }).catch((error) => {
                    dispatch(showSnack('warning', error.message));
                    dispatch(loadingFail(error.message));
                });
            }).catch((error) => {
                dispatch(showSnack('warning', error.message));
                dispatch(loadingFail(error.message));
            });
        };
    };
}