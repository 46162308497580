import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const TitleComponent = ({ title }) => {
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

TitleComponent.propTypes = {
    title: PropTypes.string,
};

TitleComponent.defaultProps = {
    title: 'All About Living - Live, Lifestyle, Finance, and Inspiration',
};

export default TitleComponent;