/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PromoteDialog from 'components/roots/dialog/morePromote';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import PackageList from './packageList';
import Visa from './visa.svg';
import Mastercard from './mastercard.svg';

const styles = theme => ({
    root: {
        backgroundColor: '#f9f9f9',
    },
    listPrice: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    card: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& img': {
            marginLeft: 9,
        },
    },
    lastLine: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    cardName: {
        width: '100%',
    },
    cardExp: {
        maxWidth: 171,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '48%',
        },
    },
    submit: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& button': {
            maxWidth: 360,
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
});

function CreditCardFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            format="#### #### #### ####"
        />
    );
}

CreditCardFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

function ExpFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            format="##/##"
        />
    );
}

ExpFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

function CCVFormat(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            format="###"
        />
    );
}

CCVFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

function PromotePage(props) {
    const {
        classes,
        successMessage,
        lang,
        loading,
        packages,
        list,
        check,
        getList,
        toggleCheck,
        data,
        location,
        submitMoreCheck,
        update,
        isValid,
        postCreate,
    } = props;

    const pickedPosts = [];
    const unpickedPosts = [];
    let sumPrice = 0;
    _.forEach(check, (value, index) => {
        const newValue = list[index];
        newValue.index = index;
        if (value !== 0) {
            newValue.packageNumber = value;
            pickedPosts.push(newValue);
            sumPrice += packages[value - 1].price;
        } else {
            newValue.packageNumber = 0;
            unpickedPosts.push(newValue);
        }
    });

    const handleChange = name => event => {
        update(data, name, event.target.value);
    };

    // Promote
    const [promote, setPromote] = React.useState(false);
    const handleOpenPromote = () => {
        setPromote(true);
    };

    const handleClosePromote = () => {
        setPromote(false);
    };

    React.useEffect(() => {
        const params = queryString.parse(location.search, {
            arrayFormat: 'comma',
        });
        const initialPosts = params.posts;
        getList(lang, initialPosts);
    }, [getList, lang, location]);

    if (successMessage) {
        return <Redirect to="/paid-success" />;
    }

    return (
        <>
            <TitleComponent title="โปรโมทประกาศ - All About Living" />
            <div className={classes.root}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box mb={{ xs: 2, md: 3 }} mt={{ xs: 2, md: 3 }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        to="/my-posts"
                                        className={classes.link}>
                                        <Typography
                                            variant="button"
                                            color="textSecondary">
                                            ประกาศของฉัน
                                        </Typography>
                                    </Link>
                                    <Typography
                                        variant="button"
                                        color="textPrimary"
                                        style={{ fontWeight: 600 }}>
                                        โปรโมทประกาศ
                                    </Typography>
                                </Breadcrumbs>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box mb={{ xs: 2, md: 3 }}>
                                <Typography variant="h2" color="textPrimary">
                                    โปรโมทประกาศ
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Paper elevation={0}>
                                <Box p={{ xs: 2, md: 3 }}>
                                    <Box mb={2}>
                                        <Typography
                                            variant="h5"
                                            color="textPrimary">
                                            เลือกแพคเกจ
                                        </Typography>
                                    </Box>
                                    {loading ? (
                                        <Box style={{
                                            position: 'relative',
                                            textAlign: 'center',
                                            margin: 24,
                                        }}>
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <>
                                            {pickedPosts.map(item => (
                                                <>
                                                    {item !== 0 && (
                                                        <Box>
                                                            <Box
                                                                mb={1}
                                                                className={
                                                                    classes.head
                                                                }>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    color="textPrimary">
                                                                    หมายเลข{' '}
                                                                    {item.uid}
                                                                </Typography>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        toggleCheck(
                                                                            item.index,
                                                                            0,
                                                                        )
                                                                    }
                                                                    aria-label="delete"
                                                                    size="small">
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                            <Box mb={2}>
                                                                <PackageList
                                                                    packages={
                                                                        packages
                                                                    }
                                                                    activeNumber={
                                                                        item.packageNumber
                                                                    }
                                                                    lang={lang}
                                                                    packageIndex={
                                                                        item.index
                                                                    }
                                                                    handleChange={
                                                                        toggleCheck
                                                                    }
                                                                />
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}

                                    {unpickedPosts.length > 0 && (
                                        <Box pt={1}>
                                            <Typography
                                                variant="subtitle1"
                                                color="primary"
                                                onClick={() =>
                                                    handleOpenPromote()
                                                }
                                                style={{ cursor: 'pointer' }}>
                                                + เลือกประกาศเพิ่ม
                                            </Typography>
                                            {/* -----Promote----- */}
                                            <PromoteDialog
                                                isOpen={promote}
                                                list={unpickedPosts}
                                                submitFunc={submitMoreCheck}
                                                loading={false}
                                                handleClose={() =>
                                                    handleClosePromote()
                                                }
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Paper>
                            <Box mt={2} mb={{ xs: 0, md: 5 }}>
                                <Paper elevation={0}>
                                    <Box p={{ xs: 2, md: 3 }}>
                                        <Box mb={3}>
                                            <Typography
                                                variant="h5"
                                                color="textPrimary">
                                                ชำระเงิน
                                            </Typography>
                                        </Box>
                                        <Box mb={2} className={classes.head}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textPrimary">
                                                Debit/Credit Card
                                            </Typography>
                                            <Box className={classes.card}>
                                                <img
                                                    src={Mastercard}
                                                    alt="mastercard"
                                                />
                                                <img src={Visa} alt="visa" />
                                            </Box>
                                        </Box>
                                        <Box mb={2}>
                                            <TextField
                                                disabled={loading}
                                                label="หมายเลขบัตร"
                                                variant="outlined"
                                                fullWidth
                                                value={data.creditcardNumber}
                                                onChange={handleChange(
                                                    'creditcardNumber',
                                                )}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CreditCardIcon />
                                                        </InputAdornment>
                                                    ),
                                                    inputComponent: CreditCardFormat,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            mb={{ xs: 2, md: 4 }}
                                            className={classes.lastLine}>
                                            <Box className={classes.cardName}>
                                                <TextField
                                                    disabled={loading}
                                                    type="text"
                                                    label="ชื่อบนบัตร"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={data.name}
                                                    onChange={handleChange(
                                                        'name',
                                                    )}
                                                />
                                            </Box>
                                            <Hidden smDown>
                                                <Box
                                                    className={classes.cardExp}
                                                    mr={2}
                                                    ml={2}>
                                                    <TextField
                                                        disabled={loading}
                                                        fullWidth
                                                        type="text"
                                                        label="วันหมดอายุ"
                                                        variant="outlined"
                                                        InputProps={{
                                                            inputComponent: ExpFormat,
                                                        }}
                                                        value={data.expire}
                                                        onChange={handleChange(
                                                            'expire',
                                                        )}
                                                    />
                                                </Box>
                                                <Box
                                                    className={classes.cardExp}>
                                                    <TextField
                                                        disabled={loading}
                                                        fullWidth
                                                        label="CVV"
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="The 3 digit number on your card">
                                                                        <HelpOutlineIcon />
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                            inputComponent: CCVFormat,
                                                        }}
                                                        value={data.cvv}
                                                        onChange={handleChange(
                                                            'cvv',
                                                        )}
                                                    />
                                                </Box>
                                            </Hidden>
                                        </Box>
                                        <Hidden smDown>
                                            <Box className={classes.submit}>
                                                <Button
                                                    variant="contained"
                                                    noShadow
                                                    color="primary"
                                                    disabled={
                                                        !isValid || loading
                                                    }
                                                    onClick={() =>
                                                        postCreate(
                                                            data,
                                                            sumPrice,
                                                            check,
                                                            packages,
                                                            list,
                                                        )
                                                    }>
                                                    ชำระเงิน
                                                </Button>
                                            </Box>
                                        </Hidden>
                                        <Hidden mdUp>
                                            <Box
                                                mb={1}
                                                className={classes.lastLine}>
                                                <Box
                                                    className={classes.cardExp}
                                                    mr={2}>
                                                    <TextField
                                                        disabled={loading}
                                                        fullWidth
                                                        type="text"
                                                        label="วันหมดอายุ"
                                                        variant="outlined"
                                                        InputProps={{
                                                            inputComponent: ExpFormat,
                                                        }}
                                                        value={data.expire}
                                                        onChange={handleChange(
                                                            'expire',
                                                        )}
                                                    />
                                                </Box>
                                                <Box
                                                    className={classes.cardExp}>
                                                    <TextField
                                                        disabled={loading}
                                                        fullWidth
                                                        label="CVV"
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="The 3 digit number on your card">
                                                                        <HelpOutlineIcon />
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                            inputComponent: CCVFormat,
                                                        }}
                                                        value={data.cvv}
                                                        onChange={handleChange(
                                                            'cvv',
                                                        )}
                                                    />
                                                </Box>
                                            </Box>
                                        </Hidden>
                                    </Box>
                                </Paper>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={0}>
                                <Box p={{ xs: 2, md: 3 }} pb={{ xs: 3, md: 4 }}>
                                    <Box mb={3}>
                                        <Typography
                                            variant="h5"
                                            color="textPrimary">
                                            สรุปรายการทั้งหมด
                                        </Typography>
                                    </Box>
                                    {loading ? 
                                        <Box style={{
                                            position: 'relative',
                                            textAlign: 'center',
                                            margin: 24,
                                        }}>
                                            <CircularProgress />
                                        </Box>
                                        :
                                        <>
                                            {pickedPosts.map(item => (
                                                <Box
                                                    key={item.uid}
                                                    mb={2}
                                                    className={classes.listPrice}>
                                                    <Typography
                                                        variant="body1"
                                                        color="textPrimary">
                                                        หมายเลข{' '}
                                                        {_.truncate(item.uid, {
                                                            length: 16,
                                                        })}{' '}
                                                        (
                                                        {
                                                            packages[
                                                                item.packageNumber -
                                                                    1
                                                            ].name[lang]
                                                        }
                                                        )
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        color="textPrimary">
                                                        ฿
                                                        {
                                                            packages[
                                                                item.packageNumber -
                                                                    1
                                                            ].price
                                                        }
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </>
                                    }
                                    <Box pt={1} pb={3}>
                                        <Divider />
                                    </Box>
                                    <Box className={classes.listPrice}>
                                        <Typography
                                            variant="body1"
                                            color="textPrimary">
                                            ราคารวม
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary">
                                            ฿{sumPrice}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                            <Hidden mdUp>
                                <Box pt={2} pb={2}>
                                    <Button
                                        variant="contained"
                                        noShadow
                                        color="primary"
                                        disabled={!isValid || loading}
                                        onClick={() =>
                                            postCreate(
                                                data,
                                                sumPrice,
                                                check,
                                                packages,
                                                list,
                                            )
                                        }>
                                        ชำระเงิน
                                    </Button>
                                </Box>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
}

PromotePage.propTypes = {
    successMessage: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    packages: PropTypes.array.isRequired,
    list: PropTypes.array.isRequired,
    check: PropTypes.array.isRequired,
    update: PropTypes.func.isRequired,
    getList: PropTypes.func.isRequired,
    toggleCheck: PropTypes.func.isRequired,
    postCreate: PropTypes.func.isRequired,
    submitMoreCheck: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(PromotePage));
