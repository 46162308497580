import { connect } from 'react-redux';
import { getList, getPostList, getTopicList, getCheckIsNewUser } from 'actions/home';
import { getHomeAds } from 'actions/ads';
import Home from 'views/home';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.home.loading,
    cover: state.home.cover,
    topics: state.home.topics,
    latest: state.home.latest,
});

const mapDispatchToProps = {
    getList,
    getHomeAds,
    getPostList,
    getTopicList,
    getCheckIsNewUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
