import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import Types from 'common/property/area';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginRight: 8,
        // marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginRight: 0,
            marginBottom: 8,
            // marginRight: 0,
            // marginBottom: 16,
        },
        '& input': {
            [theme.breakpoints.down('sm')]: {
                height: 48,
                boxSizing: 'border-box',
            },
        },
    },
    menu: {
        maxHeight: 400,
    },
    maxmin: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
    },
    min: {
        marginRight: 6,
        maxWidth: 120,
    },
    max: {
        marginLeft: 6,
        maxWidth: 120,
    },
    right: {
        textAlign: 'right',
    },
}));

const CategoryCom = props => {
    const classes = useStyles();
    const {
        // value,
        loading,
        changeValue,
        label,
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [left, setLeft] = React.useState(true);
    const [min, setMin] = React.useState('');
    const [max, setMax] = React.useState('');
    React.useEffect(() => {
        changeValue([min, max]);
        // eslint-disable-next-line
    }, [min, max]);

    // const [bathNum, setBathNum] = React.useState([]);
    // const handleChange = event => {
    //     changeValue(event.target.value);
    //     setBathNum(event.target.value);
    // };
    const handleClick = event => {
        if (!loading) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFocus = side => {
        if (!loading) {
            setLeft(side);
        }
    };

    const setArea = (value) => {
        if (left) {
            setMin(value);
            if (max && Number(value) > Number(max)) {
                setMax(value);
            }
        } else {
            setMax(value);
            if (min && Number(value) < Number(min)) {
                setMin(value);
            }
        }
    };

    const areaList = Types.type
        .filter(item => left
            ? (!max || (Number(item.uid) <= Number(max)))
            : (!min || (Number(item.uid) >= Number(min)))
        );

    return (
        <>
            <TextField
                // label="Read Only"
                onClick={handleClick}
                margin="none"
                fullWidth
                className={classes.root}
                defaultValue="ขนาดพื้นที่ใช้สอย"
                value={label || 'ขนาดพื้นที่ใช้สอย'}
                // placeholder="ราคา"
                InputProps={{
                    readOnly: true,
                }}
                type="text"
                name="price"
                variant="outlined"
                disabled={loading}
            />
            <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}>
                <Box className={classes.maxmin}>
                    <TextField
                        // label="Read Only"
                        margin="none"
                        fullWidth
                        className={classes.min}
                        placeholder="ต่ำสุด"
                        type="number"
                        name="minimum"
                        variant="outlined"
                        size="small"
                        onClick={() => handleFocus(true)}
                        onBlur={() => {
                            if (max && min && Number(min) > Number(max)) {
                                setMax(min);
                            }
                        }}
                        onChange={e => {
                            const { value } = e.target;

                            if (!value || Number(value) > 0) {
                                setMin(value);
                            }
                        }}
                        value={min}
                    />
                    -
                    <TextField
                        // label="Read Only"
                        margin="none"
                        fullWidth
                        className={classes.max}
                        placeholder="สูงสุด"
                        type="number"
                        name="maximum"
                        variant="outlined"
                        size="small"
                        onClick={() => handleFocus(false)}
                        onBlur={() => {
                            if (max && min && Number(max) < Number(min)) {
                                setMin(max);
                            }
                        }}
                        onChange={e => {
                            const { value } = e.target;

                            if (!value || Number(value) > 0) {
                                setMax(value);
                            }
                        }}
                        value={max}
                    />
                </Box>
                <div>
                    {areaList
                        .map(item => (
                            <MenuItem
                                key={item.uid}
                                value={item.uid}
                                className={clsx(!left && classes.right)}
                                onClick={() => setArea(item.uid)}
                            >
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))
                    }
                </div>
            </Menu>
        </>
    );
};

CategoryCom.propTypes = {
    // value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    changeValue: PropTypes.func.isRequired,
};

export default CategoryCom;
