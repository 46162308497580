export default {
    type: [
        {
            uid: '500000',
            name: '500,000 บาท',
        },
        {
            uid: '1000000',
            name: '1,000,000 บาท',
        },
        {
            uid: '1500000',
            name: '1,500,000 บาท',
        },
        {
            uid: '2000000',
            name: '2,000,000 บาท',
        },
        {
            uid: '2500000',
            name: '2,500,000 บาท',
        },
        {
            uid: '3000000',
            name: '3,000,000 บาท',
        },
        {
            uid: '3500000',
            name: '3,500,000 บาท',
        },
        {
            uid: '4000000',
            name: '4,000,000 บาท',
        },
        {
            uid: '4500000',
            name: '4,500,000 บาท',
        },
        {
            uid: '5000000',
            name: '5,000,000 บาท',
        },
        {
            uid: '5500000',
            name: '5,500,000 บาท',
        },
        {
            uid: '6000000',
            name: '6,000,000 บาท',
        },
        {
            uid: '6500000',
            name: '6,500,000 บาท',
        },
        {
            uid: '7000000',
            name: '7,000,000 บาท',
        },
        {
            uid: '7500000',
            name: '7,500,000 บาท',
        },
        {
            uid: '8000000',
            name: '8,000,000 บาท',
        },
        {
            uid: '8500000',
            name: '5,500,000 บาท',
        },
        {
            uid: '9000000',
            name: '9,000,000 บาท',
        },
        {
            uid: '9500000',
            name: '9,500,000 บาท',
        },
        {
            uid: '10000000',
            name: '10,000,000 บาท',
        },
        {
            uid: '11000000',
            name: '11,000,000 บาท',
        },
        {
            uid: '12000000',
            name: '12,000,000 บาท',
        },
        {
            uid: '13000000',
            name: '13,000,000 บาท',
        },
        {
            uid: '14000000',
            name: '14,000,000 บาท',
        },
        {
            uid: '15000000',
            name: '15,000,000 บาท',
        },
        {
            uid: '20000000',
            name: '20,000,000 บาท',
        },
    ],
};
