import { connect } from 'react-redux';
import { 
    getMyList, 
    resetData,
    postDelete,
    loadmore,
} from 'actions/topics';
import MyTopics from 'views/topic/myTopics';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.topics.loading,
    loadingMore: state.topics.loadingMore,
    showLoadingMore: state.topics.showLoadingMore,
    list: state.topics.list,
});

const mapDispatchToProps = {
    getMyList,
    resetData,
    postDelete,
    loadmore,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyTopics);



