/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    OutlinedInput,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
} from '@material-ui/core';
import Types from 'common/projectType';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
        // marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginRight: 0,
            marginBottom: 16,
        },
    },
}));

const CategoryCom = props => {
    const classes = useStyles();
    const { value, loading, oldData, changeValue } = props;

    const handleChange = event => {
        changeValue(event.target.value, oldData);
    };

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="propertyType">
                ประเภทอสังหา*
            </InputLabel>
            <Select
                disabled={loading}
                value={value}
                onChange={handleChange}
                input={
                    <OutlinedInput
                        labelWidth={labelWidth}
                        name="propertyType"
                        id="propertyType"
                    />
                }>
                {Types.type.map(item => (
                    <MenuItem key={item.uid} value={item.uid}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

CategoryCom.propTypes = {
    value: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    changeValue: PropTypes.func.isRequired,
};

export default CategoryCom;
