import { connect } from 'react-redux';
import {
    getEdit,
    changeType,
    changeProject,
    changeGeo,
    update,
    postEdit,
    postGallery,
    removeGallery,
    resetData,
    postDelete,
} from 'actions/posts';
import CreateTopic from 'views/post/edit';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.posts.loading,
    data: state.posts.data,
    erros: state.posts.errors,
    isValid: state.posts.isValid,
    successMessage: state.posts.successMessage,
});

const mapDispatchToProps = {
    getEdit,
    changeType,
    changeProject,
    changeGeo,
    update,
    postEdit,
    postGallery,
    removeGallery,
    resetData,
    postDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTopic);
