import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from 'react-loading-skeleton';

import { TagNew } from '../element';

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: 'none',
        borderRadius: 8,
    },
    media: {
        width: 282,
        height: 212,
        borderRadius: 8,
        backgroundColor: 'rgb(238, 238, 238)',
        backgroundImage: 'linear-gradient(90deg, rgb(238, 238, 238), rgb(245, 245, 245), rgb(238, 238, 238))',
        [theme.breakpoints.down('sm')]: {
            width: 86,
            height: 65,
        }
    },
    tag:{
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        }
    },
    title: {
        marginBottom: 9,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8,
        }
    },
    content: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 0 16px !important',
        }
    },
    link:{
        textDecoration: 'none',
        color: 'inherit',
    },
    loadingLink: {
        textDecoration: 'none',
        color: 'inherit',
        pointerEvents: 'none',
        cursor: 'default',
    }
});

function List(props) {
    const { 
        classes, 
        url, 
        tag, 
        img, 
        title, 
        desc, 
        date,
        loading, 
    } = props;
    return (
        <Card className={classes.card}>
            {!loading ?
                <Link to={url} className={classes.link}>
                    <CardMedia component="img" src={img} className={classes.media} />
                </Link>
                :
                <a href="/#" className={classes.loadingLink}>
                    <div className={classes.media} />
                </a>
            }
            <CardContent className={classes.content}>
                <Box className={classes.tag} >
                    {loading && <Skeleton width={50} />}
                    {(tag !== '' && !loading)  && <TagNew text={tag} />}
                </Box>
                <Box className={classes.title}>
                    {loading ?
                        <a href="/#" className={classes.loadingLink}>
                            <Typography variant="subtitle1" >
                                <Skeleton width="100%" count={1} />
                            </Typography>
                        </a>
                        :
                        <Link to={url} className={classes.link}>
                            <Typography variant="subtitle1" >
                                {title}
                            </Typography>
                        </Link>
                    }
                </Box>
                <Hidden smDown>
                    <Box mb="17px">
                        <Typography variant="body2" color="textSecondary" >
                            {loading ? <Skeleton width="100%" count={2} /> : desc}
                        </Typography>
                    </Box>
                </Hidden>
                <Box>
                    <Typography variant="caption" style={{ color: '#c7c7c7'}} >
                        {loading ? <Skeleton width={100} count={1} /> : date}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

List.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    tag: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    date: PropTypes.string,
    loading: PropTypes.bool,
};

List.defaultProps = {
    url: '',
    tag: '',
    img: '',
    title: '',
    desc: '',
    date: '',
    loading: true,
};

export default withStyles(styles)(List);