/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Avatar from 'components/roots/avatar/topicComment';
import _ from 'lodash';
import { Button } from 'components/roots/button';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
    },
    card:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderRadius: 8,
        boxShadow: '0 20px 20px 0 rgba(0, 32, 35, 0.05)',
        backgroundColor: '#f9f9f9',
        border: 'none',
        padding: '16px 24px',
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        },
    },
    textArea:{
        width: '100%',
    },
    textField:{
        position: 'relative',
        color: "#2e2e2e",
        width: '100%',
        wordWrap: 'break-word',
        margin: 0,
        '& .MuiInputBase-root':{
            margin: 0,
            fontFamily: 'Sarabun',
            fontSize: 18,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                lineHeight: 1.75,
            },
        }
    },
    submit:{
        minWidth: 170,
        width: 170,
    },
    cardMobile: {
        borderRadius: 0,
        boxShadow: 'none',
        backgroundColor: '#f9f9f9',
        borderTop: '1px solid #c7c7c7',
        borderBottom: '1px solid #c7c7c7',
        padding: 16,
    },
});

function AddComment(props) {
    const { 
        classes,
        loading,
        data,
        update,
        postComment,
        profile,
        topicId,  
    } = props;

    const hasError = field => {
        if (_.has(data.errors, field) && _.has(data.touched, field)) {
            return true;
        }
        return false;
    };

    return (
        <div className={classes.background}>
            <Hidden smDown>
                <Container>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        
                        <Grid item xs={12} md={10}>
                            <Card className={classes.card}>
                                <Box mr='30px'>
                                    <Avatar
                                        img={profile.photoURL}
                                        name={profile.name}
                                    />
                                </Box>
                                <Box className={classes.textArea} mr={2}>
                                    <TextField
                                        placeholder="แสดงความคิดเห็น"
                                        multiline
                                        fullWidth
                                        type="text"
                                        name="desc"
                                        className={classes.textField}
                                        variant="outlined"
                                        disabled={loading || data.loading}
                                        error={hasError('desc')}
                                        onChange={(event) => update(event.target.value)}
                                        value={data.desc}
                                    />
                                </Box>
                                <Box className={classes.submit}>
                                    <Button
                                        variant="contained"
                                        noShadow
                                        color="primary"
                                        onClick={() => postComment(topicId,data.desc)}
                                        disabled={loading || !data.isValid || data.loading}
                                    >
                                        ตอบ
                                    </Button>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Hidden>
            <Hidden mdUp>
                <Card className={classes.cardMobile}>
                    <Box className={classes.header}>
                        <Box mr={1} mb={2}>
                            <Avatar
                                img={profile.photoURL}
                                name={profile.name}
                            />
                        </Box>
                        <Box className={classes.textArea} mb={2}>
                            <TextField
                                placeholder="แสดงความคิดเห็น"
                                multiline
                                fullWidth
                                type="text"
                                name="desc"
                                className={classes.textField}
                                variant="outlined"
                                disabled={loading || data.loading}
                                error={hasError('desc')}
                                onChange={(event) => update(event.target.value)}
                                value={data.desc}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.submit}>
                        <Button
                            variant="contained"
                            noShadow
                            color="primary"
                            onClick={() => postComment(topicId, data.desc)}
                            disabled={loading || !data.isValid || data.loading}
                        >
                            ตอบ
                        </Button>
                    </Box>
                </Card>
            </Hidden>
        </div>
    );
}

AddComment.propTypes = {
    classes: PropTypes.object.isRequired, 
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    topicId: PropTypes.string.isRequired,
};

export default withStyles(styles)(AddComment);