import { connect } from 'react-redux';
import NotFound from 'views/notFound';

const mapStateToProps = state => ({
    lang: state.main.lang,
});

export default connect(
    mapStateToProps,
    null
)(NotFound);

