/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
// import { withStyles } from '@material-ui/styles';
import { SocialButton } from 'components/roots/button';

// const styles = theme => ({
//     textField: {
//         width: '100%',
//         margin: '0',
//         [theme.breakpoints.down('sm')]: {
//             '& label': {
//                 transform: 'translate(14px, 14px) scale(1)',
//             },
//             '& input': {
//                 height: 11,
//             },
//         },
//     },
// });

function HeaderDialog(props) {
    const {
        // classes,
        googleLoading,
        facebookLoading,
        name,
        handleChangePage,
        googleSignIn,
        facebookSignIn,
    } = props;

    let data = {
        title: 'เข้าสู่ระบบ',
        desc: 'เข้าสู่ระบบเพื่อรับสิทธิพิเศษมากมาย',
        facebook: 'เข้าสู่ระบบด้วย Facebook',
        google: 'เข้าสู่ระบบด้วย Google',
    };
    if (name === 'register') {
        data = {
            title: 'ลงทะเบียน',
            desc: 'ลงทะเบียนเพื่อรับสิทธิพิเศษมากมาย',
            facebook: 'ลงทะเบียนด้วย Facebook',
            google: 'ลงทะเบียนด้วย Google',
        };
    }
    if (name === 'forgotPass') {
        data = {
            title: 'ลืมรหัสผ่าน',
            desc: 'กรุณากรอกอีเมลเราจะส่ง Link ไปให้คุณเพื่อ Reset รหัสผ่าน',
            facebook: '',
            google: '',
        };
    }

    return (
        <>
            {
                name !== 'registerForm' ?
                    <>
                        <Box mb={2}>
                            <Typography
                                variant="h2"
                                color="textPrimary"
                                align="center"
                            >

                                {data.title}
                            </Typography>
                        </Box>
                        <Box mb="20px">
                            <Typography
                                variant="body2"
                                color="textPrimary"
                                align="center"
                            >
                                {data.desc}
                            </Typography>
                        </Box>
                    </>
                    :
                    <>
                        <Box
                            mb="27px"
                            style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                width: '100%',
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                style={{
                                    display: 'inline-block',
                                    paddingRight: 8,
                                }}
                            >
                                ลงทะเบียนโดย
                            </Typography>
                            <Typography
                                variant="button"
                                color="primary"
                                style={{
                                    cursor: 'pointer',
                                    paddingRight: 8,
                                }}
                                onClick={() => handleChangePage('register')}
                            >
                                Facebook
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                style={{
                                    display: 'inline-block',
                                    paddingRight: 8,
                                }}
                            >
                                หรือ
                            </Typography>
                            <Typography
                                variant="button"
                                color="primary"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleChangePage('register')}
                            >
                                Google
                            </Typography>
                        </Box>
                        <Box mb="35px">
                            <Divider />
                        </Box>
                    </> 
            }
            {
                (name === 'login' || name === 'register') &&
                <>
                    <Box mb={2}>
                        <SocialButton
                            social="facebook"
                            disabled={googleLoading}
                            onClick={() => facebookSignIn()}
                            loading={facebookLoading}
                        >
                            {data.facebook}
                        </SocialButton>
                    </Box>
                    <Box mb="35px">
                        <SocialButton
                            social="google"
                            disabled={facebookLoading}
                            loading={googleLoading}
                            onClick={() => googleSignIn()}
                        >
                            {data.google}
                        </SocialButton>
                    </Box>
                    <Box mb="35px">
                        <Divider />
                    </Box>
                </>
            }
        </>
    );
}

HeaderDialog.propTypes = {
    // classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    googleLoading: PropTypes.bool.isRequired,
    facebookLoading: PropTypes.bool.isRequired,
    googleSignIn: PropTypes.func.isRequired,
    facebookSignIn: PropTypes.func.isRequired,
};

export default HeaderDialog;
// )(withStyles(styles)(HeaderDialog));