/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import { Post } from 'components/roots/list';
import HomeTopicList from 'components/sections/list/homeTopics';
import { VFatAds, HFatAds } from '../ads';
import { Readmore } from '../../roots/button';

const styles = theme => ({
    background: {
        position: 'relative',
        display: 'block',
        paddingTop: 0,
        paddingBottom: 60,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    default: {
        backgroundColor: '#f9f9f9',
    },
    white: {
        backgroundColor: '#fff',
    },
    green: {
        backgroundColor: '#002023',
    },
    header: {
        marginBottom: 36,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 24,
        },
    },
    list: {
        marginTop: 70,
        [theme.breakpoints.down('sm')]: {
            marginTop: 32,
        },
    },
    mobileList: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 32,
        },
    },
    footer: {
        marginTop: 30,
        marginBottom: 16,
        textAlign: 'center',
    },
});

function LatestTopics(props) {
    const {
        classes,
        bgColor,
        loading,
        topicList,
        topicLoading,
        postList,
        postLoading,
    } = props;

    let colorClass = classes.default;
    if (bgColor === 'white') {
        colorClass = classes.white;
    }
    if (bgColor === 'green') {
        colorClass = classes.green;
    }

    const initialArr = [...Array(10).keys()];

    return (
        <div className={clsx(classes.background, colorClass)}>
            <Hidden mdUp>
                <HFatAds pageLoading={loading} position="homeH" />
            </Hidden>
            <Container>
                <Hidden smDown>
                    <HFatAds pageLoading={loading} position="homeH" />
                </Hidden>
                <Box className={classes.header}>
                    <Typography variant="h2" color="textPrimary">
                        ประกาศซื้อ-ขายอสังหาฯ
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        {loading || postLoading ? (
                            <>
                                {initialArr.map(item => (
                                    <Post loading key={item} />
                                ))}
                            </>
                        ) : (
                            <>
                                {postList.map(item => (
                                    <Post
                                        loading={loading || postLoading}
                                        url={`/post/${item.url}/${item.uid}`}
                                        image={item.img}
                                        title={item.title}
                                        textLocation={item.locationText}
                                        sellPrice={item.sellPrice}
                                        rentPrice={item.rentPrice}
                                        status={item.status}
                                        areaSize={item.areaSize}
                                        bedroomNum={item.bedroomNum}
                                        floor={item.floor}
                                    />
                                ))}
                            </>
                        )}
                        {!(loading || postLoading) && (
                            <Box className={classes.footer}>
                                <Link
                                    to="/posts"
                                    style={{ textDecoration: 'none' }}>
                                    <Readmore color="primary">
                                        ดูประกาศทั้งหมด
                                    </Readmore>
                                </Link>
                            </Box>
                        )}
                        <Hidden mdUp>
                            <Box>
                                <VFatAds
                                    position="homeV"
                                    pageLoading={loading}
                                />
                            </Box>
                        </Hidden>
                    </Grid>
                    <Hidden smDown>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ textAlign: 'right' }}>
                            <Box mb={{ xs: 0, md: '36px' }}>
                                <HomeTopicList
                                    loading={loading || topicLoading}
                                    list={topicList}
                                />
                            </Box>
                            <Box>
                                <VFatAds
                                    position="homeV"
                                    pageLoading={loading}
                                />
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
            <Hidden mdUp>
                <HomeTopicList
                    loading={loading || topicLoading}
                    list={topicList}
                />
            </Hidden>
        </div>
    );
}

LatestTopics.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.string,
    topicList: PropTypes.array,
    postList: PropTypes.array,
    loading: PropTypes.bool,
    topicLoading: PropTypes.bool.isRequired,
    postLoading: PropTypes.bool.isRequired,
};

LatestTopics.defaultProps = {
    bgColor: 'default',
    loading: true,
    topicList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    postList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
};

const mapStateToProps = state => ({
    topicLoading: state.home.topicLoading,
    topicList: state.home.topicList,
    postList: state.home.postList,
    postLoading: state.home.postLoading,
});

export default connect(mapStateToProps, null)(withStyles(styles)(LatestTopics));
