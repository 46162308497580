import { connect } from 'react-redux';
import { reset } from 'actions/packages';
import PaidSuccess from 'views/notFound/paidSuccess';

const mapStateToProps = state => ({
    lang: state.main.lang,
});

const mapDispatchToProps = {
    reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaidSuccess);
