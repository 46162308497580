import {
    FETCH_HOME,
    FETCH_HOME_DONE,
    FETCH_HOME_FAIL,
    FETCH_HOME_TOPICS,
    FETCH_HOME_TOPICS_DONE,
    FETCH_HOME_TOPICS_FAIL,
    FETCH_HOME_POSTS,
    FETCH_HOME_POSTS_DONE,
    FETCH_HOME_POSTS_FAIL,
} from 'actions/home';

const initialState = {
    loading: true,
    cover: {
        title: '',
        desc: '',
        img: '',
    },
    topics: [],
    latest: [],
    failMessage: '',
    topicLoading: true,
    topicList: [],
    postLoading: true,
    postList: [],
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_HOME:
        return {
            ...state,
            loading: true,
        };
    case FETCH_HOME_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_HOME_DONE:
        return {
            ...state,
            loading: false,
            cover: action.cover,
            topics: action.topics,
            latest: action.latest,
        };
    case FETCH_HOME_TOPICS:
        return {
            ...state,
            topicLoading: true,
        };
    case FETCH_HOME_TOPICS_FAIL:
        return {
            ...state,
            topicLoading: true,
            failMessage: action.message,
        };
    case FETCH_HOME_TOPICS_DONE:
        return {
            ...state,
            topicLoading: false,
            topicList: action.list,
        };
    case FETCH_HOME_POSTS:
        return {
            ...state,
            postLoading: true,
        };
    case FETCH_HOME_POSTS_FAIL:
        return {
            ...state,
            postLoading: true,
            failMessage: action.message,
        };
    case FETCH_HOME_POSTS_DONE:
        return {
            ...state,
            postLoading: false,
            postList: action.list,
        };
    default:
        return state;
    }
};
export default homeReducer;
