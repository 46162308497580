import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { 
    connectSearchBox,
} from 'react-instantsearch-dom';
import MenuLang from 'common/menu';

// Icon
import Search from './search.svg';
import WhtSearch from './whtSearch.svg';

const styles = theme => ({
    transparent: {
        backgroundColor: "#f9f9f9",
    },
    searchInner: {
        paddingTop: 8,
        paddingBottom: 8,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            // padding: '20px 0',
            // background: '#000000',
        }
    },
    bar: {
        width: '100%',
    },
    search: {
        verticalAlign: 'middle',
        width: 25,
        height: 25,
    },
    white:{
        color: '#fff',
    },
    input: {
        width: '100%',
        fontSize: "0.875rem",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "1.71",
        letterSpacing: "normal",
    },
    iconButton: {
        padding: 12,
    },
    openSearch: {
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.22)',
        }
    }
});

const SearchBox = ({ className, bg, lang, currentRefinement, refine, updateSearch }) => (
    <>
        <InputBase
            className={clsx(className.input, bg !== 'white' && className.white)}
            placeholder={MenuLang[lang].search}
            onChange={(event) => {
                updateSearch(event.currentTarget.value);
                refine(event.currentTarget.value);
            }}
            value={currentRefinement}
            autoFocus
            // reset={() => refine('')}
            // onKeyDown={handleEnter}
        />
    </>
);
const CustomSearchBox = connectSearchBox(SearchBox);

function Navbar(props) {
    const { 
        classes, 
        bg, 
        lang,
        updateSearch, 
    } = props;

    return (
        <>
            <Grid item xs={10} className={classes.searchInner}>
                <Box mr="10px">
                    <IconButton className={clsx(classes.iconButton, bg !== 'white' && classes.openSearch )} aria-label="Search">
                        <img className={classes.search} src={bg === 'white' ? Search : WhtSearch} alt="search" />
                    </IconButton>
                </Box>
                <Box mr={1} className={classes.bar}>
                    <CustomSearchBox 
                        lang={lang}
                        className={classes}
                        updateSearch={updateSearch}
                        bg={bg}
                    />
                </Box>
            </Grid>
        </>
    );
}

Navbar.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    bg: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    updateSearch: PropTypes.func.isRequired,
    // searchText: PropTypes.string,
};

SearchBox.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    className: PropTypes.object.isRequired,
    bg: PropTypes.string.isRequired,
    currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
    updateSearch: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
};

// Navbar.defaultProps = {
//     searchText: '',
// };

export default withStyles(styles)(Navbar);