/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { HAds } from 'components/sections/ads';
import TopicList from 'components/sections/list/topics';
import HotTopicList from 'components/sections/list/hotTopics';
import { withStyles } from '@material-ui/styles';
import MenuText from 'common/menu';
import TitleComponent from 'components/roots/titleComponent';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'components/roots/button';

const styles = () => ({
    createBtn: {
        maxWidth: 180,
        marginLeft: 'auto',
    },
});

function Topics(props) {
    const { 
        classes,
        lang,
        authLevel,
        loading,
        loadingMore,
        showLoadingMore,
        list,
        hotList,
        getList,
        loadmoreList, 
        getAllPageAds,
        resetData,
    } = props;

    const lastVisibleUid = list[list.length - 1] ? list[list.length - 1].uid : '';

    React.useEffect(() => {
        getList();
        getAllPageAds();
    }, [getList, getAllPageAds]);

    return (
        <>
            <TitleComponent title="Webboard - All About Living" />
            <HAds 
                color="white" 
                position="header"
                pageLoading={loading}
            />
            <Container>
                <Grid 
                    container 
                    spacing={3}
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <Typography variant="h2">
                            {loading ? <Skeleton width="35%" /> : MenuText[lang].webboard}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        { authLevel >= 2 &&
                            <Box className={classes.createBtn}>
                                {
                                    loading ?
                                        <>
                                            <Hidden smDown>
                                                <Skeleton width={180} height={56} />
                                            </Hidden>
                                            <Hidden mdUp>
                                                <Skeleton width="100%" height={48} />
                                            </Hidden>
                                        </>
                                        :
                                        <Link to="/create-topic" style={{ textDecoration: 'none' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => resetData()}
                                            >
                                                ตั้งกระทู้
                                            </Button>
                                        </Link>
                                }
                            </Box>
                        }
                    </Grid>
                </Grid>
                <Box mb="12px"/>
                <Grid
                    spacing={3}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} md={7}>
                        <TopicList
                            loading={loading}
                            loadingMore={loadingMore}
                            loadmoreFunc={() => loadmoreList(lastVisibleUid)}
                            loadmoreTitle={MenuText[lang].loadmore}
                            showLoadingMore={showLoadingMore}
                            list={list}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HotTopicList 
                            loading={loading}
                            list={hotList}
                        />
                    </Grid>
                </Grid>
            </Container>
            <HAds 
                color="white"
                position="footer"
                pageLoading={loading} 
            />
        </>
    );
}

Topics.propTypes = {
    classes: PropTypes.object.isRequired,
    authLevel: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMore: PropTypes.bool.isRequired,
    showLoadingMore: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    hotList: PropTypes.array.isRequired,
    getList: PropTypes.func.isRequired, 
    loadmoreList: PropTypes.func.isRequired, 
    getAllPageAds: PropTypes.func.isRequired,
    resetData: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(Topics));