import React from 'react';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Radio from '@material-ui/core/Radio';
import Skeleton from 'react-loading-skeleton';
import Price from 'components/roots/price/postPrice';

const styles = theme => ({
    card: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        borderRadius: 10,
        border: 'solid 1px #c7c7c7',
        boxShadow: 'none',
        padding: '16px 16px 16px 8px',
        marginBottom: 16,
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 0 8px',
        },
    },
    media: {
        width: 202,
        height: 151,
        borderRadius: 8,
        backgroundColor: 'rgb(238, 238, 238)',
        backgroundImage:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0) 91%, rgba(0, 0, 0, 0.5))',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 184,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    loadingMedia: {
        backgroundImage: 'none',
    },
    content: {
        padding: '0 0 0 24px !important',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            padding: '12px !important',
        },
    },
    title: {
        width: '100%',
    },
    roomSpecList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    roomSpecItem: {
        marginRight: 16,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& img': {
            marginRight: 8,
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    lower: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    mobileBottom: {
        display: 'block',
        // [theme.breakpoints.down('sm')]: {
        //     display: 'flex',
        //     justifyContent: 'space-between',
        //     alignItems: 'center',
        // },
    },
});

function MyPost(props) {
    const {
        classes,
        uid,
        image,
        title,
        textLocation,
        sellPrice,
        rentPrice,
        loading,
        checked,
        handleChange,
    } = props;

    return (
        <Card className={classes.card}>
            <Box mr={1}>
                <Radio
                    checked={checked}
                    color="primary"
                    onClick={handleChange}
                    value={uid}
                />
            </Box>
            <Hidden smDown>
                {!loading ? (
                    <CardMedia image={image} className={classes.media} />
                ) : (
                    <div
                        className={clsx(classes.media, classes.loadingMedia)}
                    />
                )}
            </Hidden>
            <CardContent className={classes.content}>
                <Box>
                    <Box mb={1}>
                        {loading ? (
                            <Typography variant="body2">
                                <Skeleton width="200px" count={1} />
                            </Typography>
                        ) : (
                            <Typography
                                variant="body2"
                                style={{ color: '#666666' }}>
                                {`หมายเลข ${uid}`}
                            </Typography>
                        )}
                    </Box>
                    <Box className={classes.title}>
                        {loading ? (
                            <Typography variant="subtitle1">
                                <Skeleton width="100%" count={1} />
                            </Typography>
                        ) : (
                            <Typography variant="subtitle1">{title}</Typography>
                        )}
                    </Box>
                    <Hidden smDown>
                        <Box mb={1}>
                            {loading ? (
                                <Typography variant="body2">
                                    <Skeleton width="100%" count={1} />
                                </Typography>
                            ) : (
                                <Typography variant="body2">
                                    {textLocation}
                                </Typography>
                            )}
                        </Box>
                    </Hidden>
                    <Box className={classes.mobileBottom}>
                        {!loading && (
                            <>
                                <Box
                                    style={{ display: 'inline-block' }}
                                    mr={{ xs: 2, md: '34px' }}>
                                    <Price
                                        page="post"
                                        type="sell"
                                        price={sellPrice}
                                    />
                                </Box>
                                <Box style={{ display: 'inline-block' }}>
                                    <Price
                                        page="post"
                                        type="rent"
                                        price={rentPrice}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

MyPost.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    uid: PropTypes.string,
    title: PropTypes.string,
    loading: PropTypes.bool,
    image: PropTypes.string,
    textLocation: PropTypes.string,
    sellPrice: PropTypes.number,
    rentPrice: PropTypes.number,
    checked: PropTypes.bool,
    handleChange: PropTypes.func,
};

MyPost.defaultProps = {
    uid: '',
    title: '',
    image: '',
    textLocation: '',
    sellPrice: '',
    rentPrice: '',
    loading: true,
    checked: false,
    handleChange: () => console.log(''),
};

export default withStyles(styles)(MyPost);
