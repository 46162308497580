/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from 'react-loading-skeleton';
// import _ from 'lodash';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import { MyPost } from 'components/roots/list';
import HeaderTabs from './header';

const styles = theme => ({
    card: {
        textAlign: 'center',
        minHeight: 'calc(100vh - 400px)',
        padding: '50px 0 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 619px)',
            padding: '50px 16px',
        },
    },
    button: {
        width: 384,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    createBtn: {
        maxWidth: 180,
        marginLeft: 'auto',
    },
});

function MyPostsAll(props) {
    const {
        classes,
        list,
        lang,
        loading,
        getMyList,
        resetData,
        postDelete,
        postClose,
        postBringBack,
    } = props;

    const initialArr = [...Array(5).keys()];

    React.useEffect(() => {
        getMyList('all', lang);
    }, [getMyList, lang]);

    return (
        <>
            <TitleComponent title="ประกาศของฉัน - All About Living" />
            <Container>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end">
                    <Grid item xs={6}>
                        <Box mb={{ xs: 2, md: 3 }} mt={{ xs: 2, md: 3 }}>
                            <Typography variant="h2" color="textPrimary">
                                {loading ? (
                                    <Skeleton width={150} />
                                ) : (
                                    'ประกาศของฉัน'
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            mb={{ xs: 2, md: 3 }}
                            mt={{ xs: 2, md: 3 }}
                            className={classes.createBtn}>
                            {loading ? (
                                <>
                                    <Hidden smDown>
                                        <Skeleton width={180} height={56} />
                                    </Hidden>
                                    <Hidden mdUp>
                                        <Skeleton width="100%" height={48} />
                                    </Hidden>
                                </>
                            ) : (
                                <Link
                                    to="/create-post"
                                    style={{ textDecoration: 'none' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => resetData()}>
                                        ลงประกาศ
                                    </Button>
                                </Link>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box mb={3}>
                            <HeaderTabs value={0} />
                        </Box>
                        {loading ? (
                            <>
                                {initialArr.map(item => (
                                    <MyPost key={item} />
                                ))}
                            </>
                        ) : (
                            <>
                                {list.length === 0 ? (
                                    <Card className={classes.card}>
                                        <Box mb="10px">
                                            <Typography
                                                variant="h3"
                                                color="textPrimary">
                                                เริ่มสร้างประกาศ
                                            </Typography>
                                        </Box>
                                        <Box mb={{ xs: '24px', md: '40px' }}>
                                            <Typography
                                                variant="body1"
                                                color="textSecondary">
                                                สร้างประกาศเพื่อเป็นตัวช่วยในการขายอสังหาฯ
                                                ของคุณ
                                            </Typography>
                                        </Box>
                                        <Box className={classes.button}>
                                            <Link
                                                to="/create-post"
                                                style={{
                                                    textDecoration: 'none',
                                                }}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={() => resetData()}>
                                                    เริ่มลงประกาศ
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Card>
                                ) : (
                                    <>
                                        {list.map(item => (
                                            <MyPost
                                                key={item.uid}
                                                loading={loading}
                                                uid={item.uid}
                                                url={`/post/${item.url}/${item.uid}`}
                                                image={item.img}
                                                title={item.title}
                                                textLocation={item.locationText}
                                                sellPrice={item.sellPrice}
                                                rentPrice={item.rentPrice}
                                                status={item.status}
                                                createdAt={`วันที่สร้าง ${item.date}`}
                                                view={item.view}
                                                maxView={item.maxView}
                                                deleteTopic={() =>
                                                    postDelete(item.uid)
                                                }
                                                resetData={resetData}
                                                postClose={(uid, language) => postClose(uid, language, 'all')}
                                                postBringBack={(uid, language) => postBringBack(uid, language, 'all')}
                                            />
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

MyPostsAll.propTypes = {
    lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    resetData: PropTypes.func.isRequired,
    postDelete: PropTypes.func.isRequired,
    getMyList: PropTypes.func.isRequired,
    postClose: PropTypes.func.isRequired,
    postBringBack: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(MyPostsAll));
