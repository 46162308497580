import { connect } from 'react-redux';
import { updatePassword, postResetPassword } from 'actions/resetPassword';
import ResetPassword from 'views/resetPassword';

const mapStateToProps = state => ({
    lang: state.main.lang,
    isValid: state.resetPassword.isValid,
    loading: state.resetPassword.loading,
    data: state.resetPassword.data,
    errors: state.resetPassword.errors,
    touched: state.resetPassword.touched,
    successMessage: state.resetPassword.successMessage,
    failMessage: state.resetPassword.failMessage,
});

const mapDispatchToProps = {
    updatePassword,
    postResetPassword,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);



