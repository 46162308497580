/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import CardMedia from '@material-ui/core/CardMedia';
import Slider from 'react-slick';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import 'slick-carousel/slick/slick.css';

// import Zero from './0.jpg';
// import One from './1.jpg';
// import Three from './3.jpg';
// import Four from './4.jpg';
// import Opt1 from './1_optimized.jpg';
// import Opt2 from './2_optimized.jpg';
// import Opt3 from './3_old.jpg';
// import Opt4 from './4_optimized.jpg';

const styles = theme => ({
    root: {
        position: 'relative',
        backgroundColor: 'rgb(238, 238, 238)',
        width: '100%',
        height: 441,
        padding: 0,
        boxSizing: 'border-box',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 202,
            minHeight: 0,
        },
    },
    slick: {
        position: 'absolute',
        width: '100%',
        height: 441,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            height: 202,
        },
        '& .slick-list': {
            position: 'relative',
            height: '100%',
            // minHeight: 650,
            '& .slick-slide': {
                height: 441,
                // minHeight: 650,
                [theme.breakpoints.down('sm')]: {
                    height: 202,
                },
                '& div': {
                    height: 441,
                    [theme.breakpoints.down('sm')]: {
                        height: 202,
                    },
                },
            },
        },
        '& .slick-dots': {
            listStyleType: 'none',
            position: 'absolute',
            bottom: 8,
            margin: '0 auto',
            padding: '0px 20px',
            width: '100%',
            boxSizing: 'border-box',
            '& li': {
                display: 'inline-block',
                margin: 6,
                [theme.breakpoints.down('sm')]: {
                    margin: 4,
                },
                '& div': {
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    opacity: 0.5,
                    backgroundColor: '#ffffff',
                    [theme.breakpoints.down('sm')]: {
                        width: 6,
                        height: 6,
                    },
                },
            },
            '& li.slick-active': {
                '& div': {
                    opacity: 1,
                },
            },
        },
        '& .slick-prev': {
            position: 'absolute',
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
            top: 0,
            bottom: 0,
            paddingLeft: 8,
        },
        '& .slick-next': {
            position: 'absolute',
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
            top: 0,
            bottom: 0,
            right: 0,
            paddingRight: 8,
        },
    },
    slide: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '100vw',
        },
    },
});

function Home(props) {
    const { classes, data } = props;

    const settings = {
        customPaging() {
            return <div></div>;
        },
        dots: true,
        fade: true,
        swipeToSlide: true,
        lazyLoad: true,
        infinite: true,
        speed: 700,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: (
            <Box>
                <IconButton style={{ color: '#fff' }}>
                    <NavigateNextIcon style={{ color: '#fff' }} />
                </IconButton>
            </Box>
        ),
        prevArrow: (
            <Box>
                <IconButton style={{ color: '#fff' }}>
                    <NavigateBeforeIcon style={{ color: '#fff' }} />
                </IconButton>
            </Box>
        ),
    };

    return (
        <div className={classes.root}>
            <Slider {...settings} className={classes.slick}>
                {data.map(imgUrl => (
                    <CardMedia
                        key={imgUrl}
                        className={classes.slide}
                        image={imgUrl}
                    />
                ))}
            </Slider>
        </div>
    );
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

export default withStyles(styles)(Home);
