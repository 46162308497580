/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import VisibilitySensor from 'react-visibility-sensor';
import moment from 'moment';
import firebase from 'common/firebase';
// action
import {
    getData,
} from 'actions/ads';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            right: 0,
            top: 0,
        },
    },
});

const DialogContent = withStyles(() => ({
    root: {
        padding: 0,
    },
}))(MuiDialogContent);

const seenRef = firebase.firestore().collection("adsSeen");
const clickRef = firebase.firestore().collection("adsClick");

const handleClick = (adsId, userId) => {
    const data = {
        userId,
        adsId,
        timestamp: moment().valueOf(),
    };

    clickRef.add(data).then((docRef) => {
        console.log('click:', docRef.id);
    }).catch((error) => {
        console.log(error);
    });
};

function MyDialog(props) {
    const {
        classes,
        pageLoading,
        data,
        userId,
        getPopup,
    } = props;

    // handle open/close
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    // handle seen event
    const [seen, setSeen] = React.useState(false);
    const onChange = (isVisible) => {
        if (isVisible) {
            const seenData = {
                userId,
                adsId: data.uid,
                timestamp: moment().valueOf(),
            };
            seenRef.add(seenData).then((docRef) => {
                console.log('seen:', docRef.id);
                setSeen(true);
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    React.useEffect(() => {
        getPopup();
    }, [getPopup]);

    return (
        <Dialog 
            onClose={handleClose} 
            open={(!pageLoading && data.isShow) && open}
            disableBackdropClick
        >
            <DialogContent>
                <IconButton 
                    aria-label="close" 
                    className={classes.closeButton} 
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <Hidden smDown>
                    <VisibilitySensor
                        onChange={onChange}
                        active={!seen}
                        delayedCall
                    >
                        <a href={data.url} target="_blank" rel="noopener noreferrer" onClick={() => handleClick(data.uid, userId)}>
                            <CardMedia
                                component="img"
                                alt="popup ads"
                                image={data.desktop}
                                title="popup ads"
                            />
                        </a>
                    </VisibilitySensor>
                </Hidden>
                <Hidden mdUp>
                    <VisibilitySensor
                        onChange={onChange}
                        active={!seen}
                        delayedCall
                    >
                        <a href={data.url} target="_blank" rel="noopener noreferrer" onClick={() => handleClick(data.uid, userId)}>
                            <CardMedia
                                component="img"
                                alt="popup ads"
                                image={data.mobile}
                                title="popup ads"
                            />
                        </a>
                    </VisibilitySensor>
                </Hidden>
            </DialogContent>
        </Dialog>
    );
}

MyDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    pageLoading: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    getPopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    userId: state.auth.uid,
    data: state.ads.popup,
});

const mapDispatchToProps = {
    getPopup:getData,
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withStyles(styles)(MyDialog));