import { connect } from 'react-redux';
import {
    getList,
    toggleCheck,
    submitMoreCheck,
    update,
    postCreate,
} from 'actions/packages';
import PromotePost from 'views/post/promote/index';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.packages.loading,
    packages: state.packages.packages,
    list: state.packages.list,
    check: state.packages.check,
    data: state.packages.data,
    errors: state.packages.errors,
    isValid: state.packages.isValid,
    successMessage: state.packages.successMessage,
});

const mapDispatchToProps = {
    getList,
    toggleCheck,
    submitMoreCheck,
    update,
    postCreate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotePost);
