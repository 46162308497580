import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-loading-skeleton';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    grid: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: 'none',
        borderRadius: 0,
        marginTop: 24,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    media: {
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 0,
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        backgroundColor: 'rgba(177, 177, 177, 0.42)',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            height: 270,
        },
        '&:before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'block',
            zIndex: 0,
            backgroundImage: 'rgba(0, 0, 0, 0.2)',
        }
    },
});

function Cover(props) {
    const { 
        classes, 
        coverText, 
        subCategory, 
        img,
        loading,
    } = props;

    return (
        <Container className={classes.container}>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} className={classes.grid}>
                    <Card className={classes.card}>
                        {
                            loading ?
                                <div className={classes.media}>
                                    <Box zIndex={1} mb="20px">
                                        <Typography variant="subtitle2" style={{ color: '#fff' }}>
                                            <Skeleton width={150} />
                                        </Typography>
                                    </Box>
                                    <Box style={{ width: 32 }} zIndex={1} mb="20px">
                                        <Divider style={{ backgroundColor: '#fff', height: 2 }} />
                                    </Box>
                                    <Box zIndex={1}>
                                        <Typography variant="h3" style={{ color: '#fff' }}>
                                            <Skeleton width={250} />
                                        </Typography>
                                    </Box>
                                </div>
                                :
                                <CardMedia image={img} className={classes.media}>
                                    <Box zIndex={1} mb="20px">
                                        <Typography variant="subtitle2" style={{ color: '#fff' }}>
                                            {subCategory}
                                        </Typography>
                                    </Box>
                                    <Box style={{ width: 32 }} zIndex={1} mb="20px">
                                        <Divider style={{ backgroundColor: '#fff', height: 2 }} />
                                    </Box>
                                    <Box zIndex={1}>
                                        <Typography variant="h3" style={{ color: '#fff', textAlign: 'center', padding: '0 16px' }}>
                                            {coverText}
                                        </Typography>
                                    </Box>
                                </CardMedia>
                        }
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

Cover.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    coverText: PropTypes.string.isRequired,
    subCategory: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Cover);