export default {
    type: [
        {
            uid: 'project',
            name: 'คอนโด / หมู่บ้าน',
        },
        {
            uid: 'personal',
            name: 'ที่ดิน/บ้าน',
        },
    ],
};
