import firebase from 'common/firebase';

const functions = firebase.app().functions('asia-east2');

// Action
// -- CREATE/UPDATE
export const FETCH_LATEST = 'FETCH_LATEST';
export const FETCH_LATEST_DONE = 'FETCH_LATEST_DONE';
export const FETCH_LATEST_FAIL = 'FETCH_LATEST_FAIL';
export const FETCH_LATEST_LOADMORE = 'FETCH_LATEST_LOADMORE';
export const FETCH_LATEST_LOADMORE_DONE = 'FETCH_LATEST_LOADMORE_DONE';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_LATEST,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_LATEST_LOADMORE,
    };
};
export function fetchDone(data) {
    return {
        type: FETCH_LATEST_DONE,
        list: data.list,
    };
};
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_LATEST_LOADMORE_DONE,
        list: data.list,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_LATEST_FAIL,
        message,
    };
};

// Async Action Creator
export function getList(lang) {
    return async (dispatch) => {
        dispatch(fetch());

        const latestGetList = functions.httpsCallable('latestGetList');
        try {
            const result = await latestGetList(lang);
            const output = {
                list: result.data,
            };
            dispatch(fetchDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function loadmore(lang, lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const latestLoadmore = functions.httpsCallable('latestLoadmore');
        try {
            const result = await latestLoadmore({lang, lastVisibleUid});
            const output = {
                list: result.data,
            };
            dispatch(fetchLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
