/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/styles';
// import _ from 'lodash';
import _get from 'lodash/get';
import { HAds } from 'components/sections/ads';
import TitleComponent from 'components/roots/titleComponent';
import PostDetail from 'components/sections/detail/property';
import Map from 'components/sections/mapProp';
import PromotePosts from 'components/sections/suggestPosts/promote';
import ProjectPosts from 'components/sections/suggestPosts/project';
// import MenuLang from 'common/menu';

function PostsAll(props) {
    const {
        loading,
        data,
        getData,
        getAllPageAds,
        lang,
        location,
        getPromote,
        getProject,
        userId,
    } = props;

    React.useEffect(() => {
        const uid = location.pathname.split('/').slice(-1)[0];

        getData(lang, uid);
        getAllPageAds();
        getPromote(lang);
        getProject(lang, uid);
    }, [getData, getAllPageAds, lang, location, getPromote, getProject]);

    return (
        <>
            <TitleComponent title={`${_get(data, 'title')} - All About Living`} />
            <HAds color="white" position="header" pageLoading={loading} />
            <PostDetail data={data} loading={loading} userId={userId} />
            <Map
                title="สิ่งอำนวยความสะดวกโดยรอบ"
                center={_get(data, 'location')}
                loading={loading}
            />
            <PromotePosts
                bgColor="white"
                title="ประกาศที่ได้รับการสนับสนุน"
                pageLoading={loading}
            />
            {_get(data, 'projectId') && 
                <ProjectPosts
                    bgColor="white"
                    title="ประกาศอื่นๆ ในโครงการ"
                    pageLoading={loading}
                />
            }
            <HAds color="white" position="footer" pageLoading={loading} />
        </>
    );
}

PostsAll.propTypes = {
    lang: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    getData: PropTypes.func.isRequired,
    getAllPageAds: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    getPromote: PropTypes.func.isRequired,
    getProject: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
};

// export default withStyles(styles)(withRouter(PostsAll));
export default withRouter(PostsAll);
