import MenuLang from 'common/menu';
import firebase from 'common/firebase';

const functions = firebase.app().functions('asia-east2');

// Action
export const FETCH_SUB_CATEGORY = 'FETCH_SUB_CATEGORY';
export const FETCH_SUB_CATEGORY_DONE = 'FETCH_SUB_CATEGORY_DONE';
export const FETCH_SUB_CATEGORY_FAIL = 'FETCH_SUB_CATEGORY_FAIL';
export const FETCH_SUB_CATEGORY_LOADMORE = 'FETCH_SUB_CATEGORY_LOADMORE';
export const FETCH_SUB_CATEGORY_LOADMORE_DONE = 'FETCH_SUB_CATEGORY_LOADMORE_DONE';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_SUB_CATEGORY,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_SUB_CATEGORY_LOADMORE,
    };
};
export function fetchDone(data) {
    return {
        type: FETCH_SUB_CATEGORY_DONE,
        list: data.list,
        name: data.name,
    };
};
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_SUB_CATEGORY_LOADMORE_DONE,
        list: data.list,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_SUB_CATEGORY_FAIL,
        message,
    };
};

// Async Action Creator
export function getList(lang, subCatId) {
    return async (dispatch) => {
        dispatch(fetch());

        const subCatGetList = functions.httpsCallable('subCategoryGetList');
        try {
            const data = {
                lang,
                subCatId,
            };
            const result = await subCatGetList(data);
            const output = {
                ...result.data,
                name: MenuLang[lang].subCategory[subCatId],
            };
            dispatch(fetchDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function loadmore(lang, subCatId, lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const getLoadmore = functions.httpsCallable('subCategoryLoadmore');
        try {
            const result = await getLoadmore({ lang, subCatId, lastVisibleUid });
            const output = {
                list: result.data,
            };
            dispatch(fetchLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
