/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'components/sections/navbar';
import Footer from 'components/sections/footer';

const Main = props => {
    const {
        children,
        authLevel,
        profile,
        snackIsShow,
        snackMessage,
        snackStatus,
        hideSnack,
        isSearching,
        updateSearch,
        searchLength,
        searchNumber,
    } = props;

    return (
        <>
            <Navbar 
                bg="transparent" 
                lang="th"
                authLevel={authLevel}
                profile={profile}
                isSearching={isSearching}
                updateSearch={updateSearch}
                searchLength={searchLength}
                searchNumber={searchNumber}
                snackIsShow={snackIsShow}
                snackMessage={snackMessage}
                snackStatus={snackStatus}
                snackClose={hideSnack}
            />
            {children}
            <Footer color="default" lang="th" />
        </>
    );
};

Main.propTypes = {
    children: PropTypes.node.isRequired,
    authLevel: PropTypes.number.isRequired,
    profile: PropTypes.object.isRequired,
    snackIsShow: PropTypes.bool.isRequired,
    snackMessage: PropTypes.string.isRequired,
    snackStatus: PropTypes.string.isRequired,
    hideSnack: PropTypes.func.isRequired,
    isSearching: PropTypes.bool.isRequired,
    updateSearch: PropTypes.func.isRequired,
    searchLength: PropTypes.number.isRequired,
    searchNumber: PropTypes.func.isRequired,
};

export default Main;
