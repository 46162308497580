export default {
    live: [
        'review',
        'location',
        'prNews',
    ],
    lifestyle:[
        'airline',
        'travel',
        'eat',
    ],
    finance:[
        'investment',
        'news',
        'livingForward'
    ],
    inspiration:[
        'businessTalk',
        'homeDecor',
        'others'
    ],
};