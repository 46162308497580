import {
    SORTED_POST_BY,
    FETCH_POST,
    FETCH_POST_DATA_DONE,
    FETCH_POST_DONE,
    FETCH_POST_FAIL,
    RESET_POST,
    FETCH_POST_LIST_DONE,
    FETCH_POST_LOADMORE,
    FETCH_POST_LOADMORE_DONE,
    FETCH_PROMOTE_POST,
    FETCH_PROMOTE_POST_DATA_DONE,
    FETCH_PROJECT_POST,
    FETCH_PROJECT_POST_DATA_DONE,
} from 'actions/postsQuery';

const initialState = {
    loading: false,
    sortBy: 'time',
    list: [],
    cover: {
        title: '',
        desc: '',
        img: '',
    },
    data: {},
    loadingMore: false,
    showLoadingMore: true,
    filter: {
        location: '',
        brand: '',
        project: '',
        purpose: 'buy',
        type: '',
        maxPrice: '',
        minPrice: '',
        bedroomNum: '',
        toiletNum: '',
        furniture: '',
        maxAreaSize: '',
        minAreaSize: '',
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
    promoteLoading: false,
    promoteList: [],
    projectLoading: false,
    projectList: [],
    projectName: '',
};

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
    case RESET_POST:
        return {
            ...initialState,
            loading: false,
            sortBy: 'time',
            list: [],
            loadingMore: false,
            showLoadingMore: true,
            filter: {
                location: '',
                brand: '',
                project: '',
                purpose: 'sell',
                type: '',
                maxPrice: '',
                minPrice: '',
                bedroomNum: '',
                toiletNum: '',
                furniture: '',
                maxAreaSize: '',
                minAreaSize: '',
            },
            errors: {},
            isValid: false,
            successMessage: '',
            failMessage: '',
        };
    case SORTED_POST_BY:
        return {
            ...state,
            sortBy: action.sortBy,
        };
    case FETCH_POST:
        return {
            ...state,
            loading: true,
            loadingMore: false,
            showLoadingMore: true,
        };
    case FETCH_PROMOTE_POST:
        return {
            ...state,
            promoteLoading: true,
        };
    case FETCH_PROJECT_POST:
        return {
            ...state,
            projectLoading: true,
        };
    case FETCH_POST_LOADMORE:
        return {
            ...state,
            loadingMore: true,
        };
    case FETCH_POST_DATA_DONE:
        return {
            ...state,
            loading: false,
            data: action.data,
        };
    case FETCH_POST_DONE:
        return {
            ...state,
            loading: false,
            successMessage: action.message,
        };
    case FETCH_POST_FAIL:
        return {
            ...state,
            loading: false,
            failMessage: action.message,
        };
    case FETCH_POST_LIST_DONE:
        return {
            ...state,
            loading: false,
            list: action.list,
            cover: action.cover,
            loadingMore: false,
            showLoadingMore: action.list.length > 9,
            startIndex: 1,
            endIndex: action.list.length,
        };
    case FETCH_PROMOTE_POST_DATA_DONE:
        return {
            ...state,
            promoteLoading: false,
            promoteList: action.list,
        };
    case FETCH_PROJECT_POST_DATA_DONE:
        return {
            ...state,
            projectLoading: false,
            projectList: action.list,
            projectName:action.name,
        };
    case FETCH_POST_LOADMORE_DONE:
        return {
            ...state,
            loadingMore: false,
            endIndex: state.endIndex + action.list.length,
            showLoadingMore: action.list.length === 10,
            list: [...state.list, ...action.list],
        };
    default:
        return state;
    }
};
export default projectsReducer;
