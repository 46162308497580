/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import HeaderTabs from './header';

const styles = (theme) => ({
    card: {
        textAlign: 'center',
        padding: '60px 0 100px 0',
        boxSizing: 'border-box',
        maxWidth: 384,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0',
        },
    },
});

function MyAccount(props) {
    const {
        classes,
        // lang,
        isValid,
        loading,
        data,
        errors,
        touched,
        updateEmail,
        postEmail,
        getEmail,
    } = props;

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return true;
        }
        return false;
    };

    const handleChange = (event) => {
        event.persist();
        updateEmail(data, event.target.name, event.target.value);
    };

    const handleSubmit = () => {
        postEmail(data);
    };

    React.useEffect(() => {
        getEmail();
    }, [getEmail]);

    return (
        <>
            <TitleComponent title="My Account - Change Password" />
            <Container>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Box mt={3} mb={{ xs: 2, md: 3 }}>
                            <Typography variant="h2" color="textPrimary">
                                ข้อมูลส่วนตัว
                            </Typography>
                        </Box>
                        <Box>
                            <HeaderTabs value={1} />
                            <Box className={classes.card}>
                                {/* Profile */}
                                <Box mb={{ xs: 3, md: 4 }}>
                                    <TextField
                                        error={hasError('email')}
                                        helperText={
                                            hasError('email') ? errors.email[0] : null
                                        }
                                        disabled={loading}
                                        fullWidth
                                        label="อีเมล"
                                        className={classes.textField}
                                        type="text"
                                        name="email"
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={data.email}
                                    />
                                </Box>
                                <Box mb={{ xs: 3, md: 4 }}>
                                    <TextField
                                        error={hasError('password')}
                                        helperText={
                                            hasError('password') ? errors.password[0] : null
                                        }
                                        disabled={loading}
                                        fullWidth
                                        label="รหัสผ่านปัจจุบัน"
                                        className={classes.textField}
                                        type="password"
                                        name="password"
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={data.password}
                                    />
                                </Box>
                                <Box style={{ textAlign: 'center' }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        noShadow
                                        onClick={() => handleSubmit()}
                                        disabled={!isValid || loading}
                                        loading={loading}
                                    >
                                        บันทึก
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

MyAccount.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    updateEmail: PropTypes.func.isRequired,
    postEmail: PropTypes.func.isRequired,
    getEmail: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(MyAccount));
