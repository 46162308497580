/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import DeveloperSearch from 'components/roots/projectField/DeveloperSearch';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import TypeField from 'components/roots/projectField/type';
import Map from 'components/roots/mapField';
import { Button } from 'components/roots/button';
import GalleryField from 'components/roots/galleryField';
import ImageField from 'components/roots/imageField';
import CloseBar from './closeBar';

const styles = theme => ({
    dialogPaper: {
        padding: 40,
        maxHeight: '80vh',
        overflowY: 'scroll',
    },
    dialogTitle: {
        width: 916,
        textAlign: 'center',
    },
    formRow: {
        display: 'flex',
    },
    textField: {
        marginRight: 16,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0,
        },
    },
    cancelBtn: {
        width: 180,
        marginLeft: 'auto',
    },
    submitBtn: {
        width: 180,
        marginLeft: 24,
    },
    rightBottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    drawer: {
        '& .MuiDrawer-paperAnchorRight': {
            [theme.breakpoints.down('sm')]: {
                left: 0,
            },
        },
    },
    innerDrawer: {
        backgroundColor: '#fff',
        width: 360,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
    },
    message: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        flexGrow: 1,
    },
});

function AddProjectDialog(props) {
    const {
        classes,
        onClose,
        open,
        oldData,

        // redux
        // -- data
        loading,
        lang,
        data,
        isValid,
        successMessage,

        // -- func
        changeType,
        changeDev,
        changeLogo,
        changeGeo,
        update,
        updateNoLang,
        postCreate,
        postImage,
        postGallery,
        removeGallery,
    } = props;

    // Typing
    const handleChange = event => {
        update(data, lang, event.target.name, event.target.value);
    };
    const handleNoLangChange = event => {
        updateNoLang(data, event.target.name, event.target.value);
    };

    // Submit
    const handleSave = () => {
        postCreate(data, oldData);
    };

    if (!_.isEmpty(successMessage)) {
        onClose();
    }

    return (
        <>
            <Hidden smDown>
                <Dialog
                    onClose={onClose}
                    open={open}
                    maxWidth="md"
                    classes={{
                        paper: classes.dialogPaper,
                    }}>
                    <Box mb={5}>
                        <Typography
                            className={classes.dialogTitle}
                            variant="h2">
                            เพิ่มสถานที่
                        </Typography>
                    </Box>
                    <Box className={classes.formRow}>
                        <TypeField
                            loading={loading}
                            value={data ? data.typeId : ''}
                            oldData={data}
                            changeValue={changeType}
                        />
                        <DeveloperSearch
                            loading={loading}
                            oldData={data}
                            update={dev => changeDev(dev, data)}
                            selectedValue={data.devId}
                        />
                    </Box>
                    <Box className={classes.formRow}>
                        <TextField
                            variant="outlined"
                            label="ชื่อโครงการ"
                            margin="normal"
                            name="title"
                            fullWidth
                            required
                            disabled={loading}
                            onChange={handleChange}
                            value={data.title[lang]}
                        />
                    </Box>
                    <Box mt={1} className={classes.formRow}>
                        <Map
                            loading={loading}
                            category="live"
                            lat={data.location.lat}
                            lng={data.location.lng}
                            changeGeo={(lat, lng) => changeGeo(lat, lng, data)}
                        />
                    </Box>
                    <Box className={classes.formRow}>
                        <TextField
                            variant="outlined"
                            label="ปีที่สร้างเสร็จ"
                            margin="normal"
                            required
                            disabled={loading}
                            classes={{
                                root: classes.textField,
                            }}
                            type="number"
                            name="yearDone"
                            onChange={handleNoLangChange}
                            value={data.yearDone}
                        />
                        <TextField
                            variant="outlined"
                            label="จำนวนยูนิต"
                            margin="normal"
                            required
                            disabled={loading}
                            classes={{
                                root: classes.textField,
                            }}
                            type="number"
                            value={data.unitNumber}
                            name="unitNumber"
                            onChange={handleNoLangChange}
                        />
                    </Box>
                    <Box className={classes.formRow}>
                        <TextField
                            variant="outlined"
                            label="คำอธิบายเพิ่ทเติม"
                            margin="normal"
                            fullWidth
                            required
                            disabled={loading}
                            multiline
                            rows={3}
                            name="desc"
                            onChange={handleChange}
                            value={data.desc[lang]}
                        />
                    </Box>
                    <ImageField
                        title="อัพโหลดโลโก้โครงการ*"
                        width={300}
                        height={300}
                        loading={loading}
                        fieldName="logo"
                        url={data.img}
                        uploadFunc={file => postImage(file, data)}
                        oldData={data}
                        removeFunc={() => changeLogo('', data)}
                    />
                    <Box mb={2}>
                        <Box mt={1} mb={2}>
                            <Typography variant="h5" color="textPrimary">
                                อัพโหลดรูปภาพ (อัปโหลดรูปภาพได้สูงสุด 10 รูป)
                            </Typography>
                        </Box>
                        <GalleryField
                            list={data.gallery}
                            oldData={data}
                            postGallery={postGallery}
                            removeGallery={removeGallery}
                        />
                    </Box>
                    <Box
                        className={classes.rightBottom}
                        mb={{ xs: 1, md: 0 }}
                        mt={{ xs: 1, md: 0 }}>
                        <Box className={classes.cancelBtn}>
                            <Button
                                variant="outlined"
                                color="default"
                                disabled={loading}
                                onClick={() => onClose()}>
                                ยกเลิก
                            </Button>
                        </Box>
                        <Box className={classes.submitBtn}>
                            <Button
                                variant="contained"
                                noShadow
                                color="primary"
                                onClick={() => handleSave()}
                                disabled={loading || !isValid}>
                                บันทึก
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </Hidden>
            <Hidden mdUp>
                <Drawer
                    className={classes.drawer}
                    anchor="right"
                    open={open}
                    onClose={() => onClose()}
                    variant="temporary"
                    transitionDuration={500}
                    closeAfterTransition
                    disableEnforceFocus
                    disableAutoFocus
                    disableRestoreFocus>
                    <div className={classes.innerDrawer}>
                        <CloseBar
                            title="เพิ่มสถานที่"
                            onClick={() => onClose()}
                        />
                        <Box p={2} className={classes.message}>
                            <Box className={classes.formRow}>
                                <TypeField
                                    loading={loading}
                                    value={data ? data.typeId : ''}
                                    oldData={data}
                                    changeValue={changeType}
                                />
                            </Box>
                            <Box className={classes.formRow}>
                                <DeveloperSearch
                                    loading={loading}
                                    oldData={data}
                                    update={dev => changeDev(dev, data)}
                                    selectedValue={data.devId}
                                />
                            </Box>
                            <Box className={classes.formRow}>
                                <TextField
                                    variant="outlined"
                                    label="ชื่อโครงการ"
                                    margin="normal"
                                    name="title"
                                    fullWidth
                                    required
                                    disabled={loading}
                                    onChange={handleChange}
                                    value={data.title[lang]}
                                />
                            </Box>
                            <Box mt={1} className={classes.formRow}>
                                <Map
                                    loading={loading}
                                    category="live"
                                    lat={data.location.lat}
                                    lng={data.location.lng}
                                    changeGeo={(lat, lng) =>
                                        changeGeo(lat, lng, data)
                                    }
                                />
                            </Box>
                            <Box className={classes.formRow}>
                                <TextField
                                    variant="outlined"
                                    label="ปีที่สร้างเสร็จ"
                                    margin="normal"
                                    required
                                    disabled={loading}
                                    classes={{
                                        root: classes.textField,
                                    }}
                                    type="number"
                                    name="yearDone"
                                    onChange={handleNoLangChange}
                                    value={data.yearDone}
                                />
                            </Box>
                            <Box className={classes.formRow}>
                                <TextField
                                    variant="outlined"
                                    label="จำนวนยูนิต"
                                    margin="normal"
                                    required
                                    disabled={loading}
                                    classes={{
                                        root: classes.textField,
                                    }}
                                    type="number"
                                    value={data.unitNumber}
                                    name="unitNumber"
                                    onChange={handleNoLangChange}
                                />
                            </Box>
                            <Box className={classes.formRow}>
                                <TextField
                                    variant="outlined"
                                    label="คำอธิบายเพิ่ทเติม"
                                    margin="normal"
                                    fullWidth
                                    required
                                    disabled={loading}
                                    multiline
                                    rows={3}
                                    name="desc"
                                    onChange={handleChange}
                                    value={data.desc[lang]}
                                />
                            </Box>
                            <ImageField
                                title="อัพโหลดโลโก้โครงการ*"
                                width={300}
                                height={300}
                                loading={loading}
                                fieldName="logo"
                                url={data.img}
                                uploadFunc={file => postImage(file, data)}
                                oldData={data}
                                removeFunc={() => changeLogo('', data)}
                            />
                            <Box mb={2}>
                                <Box mt={1} mb={2}>
                                    <Typography
                                        variant="h5"
                                        color="textPrimary">
                                        อัพโหลดรูปภาพ (อัปโหลดรูปภาพได้สูงสุด 10
                                        รูป)
                                    </Typography>
                                </Box>
                                <GalleryField
                                    list={data.gallery}
                                    oldData={data}
                                    postGallery={postGallery}
                                    removeGallery={removeGallery}
                                />
                            </Box>
                            <Box className={classes.rightBottom} mb={1} mt={1}>
                                <Box className={classes.cancelBtn}>
                                    <Button
                                        variant="outlined"
                                        color="default"
                                        disabled={loading}
                                        onClick={() => onClose()}>
                                        ยกเลิก
                                    </Button>
                                </Box>
                                <Box className={classes.submitBtn}>
                                    <Button
                                        variant="contained"
                                        noShadow
                                        color="primary"
                                        onClick={() => handleSave()}
                                        disabled={loading || !isValid}>
                                        บันทึก
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </Drawer>
            </Hidden>
        </>
    );
}

AddProjectDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    successMessage: PropTypes.string.isRequired,
    changeType: PropTypes.func.isRequired,
    changeDev: PropTypes.func.isRequired,
    changeLogo: PropTypes.func.isRequired,
    changeGeo: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    updateNoLang: PropTypes.func.isRequired,
    postCreate: PropTypes.func.isRequired,
    postImage: PropTypes.func.isRequired,
    postGallery: PropTypes.func.isRequired,
    removeGallery: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddProjectDialog);
