import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
// import Skeleton from 'react-loading-skeleton';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Avatar from 'components/roots/avatar/projectLogo';
import ImageDialog from './imageWithDialog';
import CloseSVG from './close.svg';

const styles = (theme) => ({
    dialogPaper: {
        borderRadius: 0
    },
    closeDialog: {
        margin: 'auto',
        position: 'absolute',
        top: 15,
        right: 15,
        zIndex: 2,
        '& img':{
            width: 24,
            height: 'auto',
        }
    },
    fullSize: {
        borderRadius: 0,
        maxWidth: '100%',
        height: 'auto',
        width: '100%',
        maxHeight: '80vh',
        position: 'relative',
    },
    leftImage:{
        margin: 'auto',
        position: 'absolute',
        zIndex: 2,
        top: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 15,
    },
    rightImage: {
        margin: 'auto',
        position: 'absolute',
        zIndex: 2,
        top: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        paddingRight: 15,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    projectLogo:{
        display: 'flex',
    },
    header:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    // headerLeft:{
    //     [theme.breakpoints.down('sm')]: {
    //         display: 'flex',
    //     },
    // },
    headerRight:{
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'space-evenly',
        },
    },
    stat:{
        marginLeft: 33,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    }
});

function Cover(props) {
    const {
        classes,
    } = props;

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <Hidden smDown>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12} md={6}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="330"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="330"
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="134"
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="134"
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="134"
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="134"
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="134"
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            isLast
                        />
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="202"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="67"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="67"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            height="67"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ImageDialog
                            onClick={() => setOpen(true)}
                            isLast
                        />
                    </Grid>
                </Grid>
            </Hidden>
            <Dialog
                onClose={handleClose}
                open={open}
                maxWidth="md"
                classes={{
                    paper: classes.dialogPaper,
                }}
            >
                <CardMedia
                    className={classes.fullSize}
                    src="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/projects%2FBalmoral%20Hero%208-5.jpg?alt=media&token=a67a9d05-7836-42a6-979c-9e88ac027203"
                    component="img"
                    title="Project Image"
                />
                <Box className={classes.leftImage}>
                    <IconButton
                        style={{ color: '#fff' }}
                        // onClick={() => setOpen(false)}
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} />
                    </IconButton>
                </Box>
                <Box className={classes.rightImage}>
                    <IconButton
                        style={{ color: '#fff' }}
                    // onClick={() => setOpen(false)}
                    >
                        <ArrowForwardIcon style={{ color: '#fff' }} />
                    </IconButton>
                </Box>
                <IconButton
                    style={{ color: '#fff' }}
                    className={classes.closeDialog}
                    onClick={() => setOpen(false)}
                >
                    <img src={CloseSVG} alt="close" />
                </IconButton>
            </Dialog>
            <Grid
                container
                spacing={3}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} md={1}>
                    <Box mt="12px" className={classes.projectLogo}>
                        <Avatar 
                            img="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/projects%2FSansiri-logo.png?alt=media&token=5dfc72b2-9463-4d1a-80c2-7d785505e8f5" 
                            name="Sansiri"            
                        />
                        <Hidden mdUp>
                            <Box ml={3}>
                                <Box mb={{xs:0,md:1}}>
                                    <Typography variant="h2" color="textPrimary">
                                        Noble Around อารีย์
                                    </Typography>
                                </Box>
                                <Typography variant="body1" color="textSecondary">
                                    วัฒนา, กรุงเทพ
                                </Typography>
                            </Box>
                        </Hidden>
                    </Box>
                </Grid>
                <Grid item xs={12} md={11}>
                    <Box mt={{xs:0,md:"12px"}} mb={{xs:4,md:5}}>
                        <Box className={classes.header} mb={3}>
                            <Hidden smDown>
                                <Box className={classes.headerLeft}>
                                    <Box mb={1}>
                                        <Typography variant="h2" color="textPrimary">
                                            Noble Around อารีย์
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1" color="textSecondary">
                                        วัฒนา, กรุงเทพ
                                    </Typography>
                                </Box>
                            </Hidden>
                            <Box className={classes.headerRight}>
                                <Box className={classes.stat}>
                                    <Box mb={1}>
                                        <Typography variant="h5" color="textPrimary">
                                            2020
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1" color="textSecondary">
                                        ปีที่สร้างเสร็จ
                                    </Typography>
                                </Box>
                                <Box className={classes.stat}>
                                    <Box mb={1}>
                                        <Typography variant="h5" color="textPrimary">
                                            1,000
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1" color="textSecondary">
                                        จำนวนยูนิต
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant="body1" color="textPrimary">
                            รายละเอียดบทความแนะนำของ Admin รายละเอียดบทความแนะนำของ Adminรายละเอียดบทความแนะนำของ Adminรายละเอียดบทความ รายละเอียดบทความแนะนำของ Admin รายละเอียดบทความแนะนำของ Adminรายละเอียดบทความแนะนำของ Adminรายละเอียดบทความรายละเอียดบทความแนะนำของ Admin รายละเอียดบทความแนะนำของ Adminรายละเอียดบทความแนะนำของ Adminรายละเอียดบทความ.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

Cover.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    // coverText: PropTypes.string.isRequired,
    // subCategory: PropTypes.string.isRequired,
    // img: PropTypes.string.isRequired,
    // loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Cover);