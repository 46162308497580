import _ from 'lodash';
import SubCategory from 'common/subCategory';
import firebase from 'common/firebase';

const functions = firebase.app().functions('asia-east2');


// Action
export const FETCH_CATEGORY_PAGE = 'FETCH_CATEGORY_PAGE';
export const FETCH_CATEGORY_PAGE_DONE = 'FETCH_CATEGORY_PAGE_DONE';
export const FETCH_CATEGORY_PAGE_FAIL = 'FETCH_CATEGORY_PAGE_FAIL';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_CATEGORY_PAGE,
    };
};
export function fetchDone(data) {
    return {
        type: FETCH_CATEGORY_PAGE_DONE,
        cover: data.cover,
        topics: data.topics,
        subCategory: data.subCategory,
        suggestion: data.suggestion,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_CATEGORY_PAGE_FAIL,
        message,
    };
};

// Async Action Creator
export function getList(lang, categoryId) {
    return async (dispatch) => {
        dispatch(fetch());

        const categoryGetList = functions.httpsCallable('categoryGetList');
        try {
            const otherCategoryIdArr = _.remove(_.keys(SubCategory), (catId) => {
                return catId !== categoryId;
            });

            const data = {
                lang, 
                categoryId, 
                subCatIdArr: SubCategory[categoryId],
                otherCategoryIdArr,
            };
            const result = await categoryGetList(data);
            const output = result.data;
            dispatch(fetchDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
