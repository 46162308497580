import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const styles = () => ({
    card: {
        borderRadius: 0,
        boxShadow: 'none',
        display: 'flex',
    },
    media: {
        width: 50,
        height: 50,
        textTransform: 'uppercase',
        backgroundColor: "#00abbd",
    },
    content: {
        padding: '0 0 0 14px !important',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
});

function CardPaper(props) {
    const { classes, img, name, desc } = props;
    return (
        <Card className={classes.card}>
            <div className={classes.profile}>
                {
                    img ?
                        <Avatar alt={name} src={img} className={classes.media} />
                        :
                        <Avatar color="primary" className={classes.media}>{name.charAt(0)}</Avatar>
                }
            </div>
            <CardContent className={classes.content}>
                <Box>
                    <Typography variant="body2" color="textSecondary">
                        {name}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="caption" style={{ color: '#c7c7c7' }}>
                        {desc}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

CardPaper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    img: PropTypes.string,
    name: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
};

CardPaper.defaultProps = {
    img: '',
};

export default withStyles(styles)(CardPaper);