import {
    FETCH_POPUP,
    FETCH_POPUP_DONE,
    FETCH_POPUP_FAIL,
    FETCH_ALL_PAGE_ADS,
    FETCH_ALL_PAGE_ADS_DONE,
    FETCH_SIDE_ADS,
    FETCH_SIDE_ADS_DONE,
    FETCH_HOME_ADS,
    FETCH_HOME_ADS_DONE,
} from 'actions/ads';

const initialState = {
    popup: {
        isShow: false,
        desktop: '',
        mobile: '',
        uid: '',
        url: '',
    },
    header: {
        isShow: false,
        desktop: '',
        mobile: '',
        uid: '',
        url: '',
    },
    footer: {
        isShow: false,
        desktop: '',
        mobile: '',
        uid: '',
        url: '',
    },
    sideTop: {
        isShow: false,
        desktop: '',
        mobile: '',
        uid: '',
        url: '',
    },
    sideBottom: {
        isShow: false,
        desktop: '',
        mobile: '',
        uid: '',
        url: '',
    },
    homeH: {
        isShow: false,
        desktop: '',
        mobile: '',
        uid: '',
        url: '',
    },
    homeV: {
        isShow: false,
        desktop: '',
        mobile: '',
        uid: '',
        url: '',
    },
    failMessage: '',
};

const emptyAds = {
    isShow: false,
    desktop: '',
    mobile: '',
    uid: '',
    url: '',
};

const adsReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_POPUP:
        return {
            ...state,
            popup: {
                ...state.popup,
                isShow: false,
            },
        };
    case FETCH_POPUP_FAIL:
        return {
            ...state,
            popup: {
                ...state.popup,
                isShow: false,
            },
            failMessage: action.message,
        };
    case FETCH_POPUP_DONE:
        return {
            ...state,
            popup: action.data.isShow ? action.data : state.popup,
        };
    case FETCH_ALL_PAGE_ADS:
        return {
            ...state,
            header: {
                ...state.header,
                isShow: false,
            },
            footer: {
                ...state.footer,
                isShow: false,
            },
        };
    case FETCH_ALL_PAGE_ADS_DONE:
        return {
            ...state,
            header: action.header.isShow ? action.header : emptyAds,
            footer: action.footer.isShow ? action.footer : emptyAds,
        };
    case FETCH_SIDE_ADS:
        return {
            ...state,
            header: {
                ...state.header,
                isShow: false,
            },
            footer: {
                ...state.footer,
                isShow: false,
            },
            sideTop: {
                ...state.sideTop,
                isShow: false,
            },
            sideBottom: {
                ...state.sideBottom,
                isShow: false,
            },
        };
    case FETCH_SIDE_ADS_DONE:
        return {
            ...state,
            header: action.header.isShow ? action.header : emptyAds,
            footer: action.footer.isShow ? action.footer : emptyAds,
            sideTop: action.sideTop.isShow ? action.sideTop : emptyAds,
            sideBottom: action.sideBottom.isShow
                ? action.sideBottom
                : emptyAds,
        };
    case FETCH_HOME_ADS:
        return {
            ...state,
            header: {
                ...state.header,
                isShow: false,
            },
            footer: {
                ...state.footer,
                isShow: false,
            },
            sideTop: {
                ...state.sideTop,
                isShow: false,
            },
            sideBottom: {
                ...state.sideBottom,
                isShow: false,
            },
            homeH: {
                ...state.homeH,
                isShow: false,
            },
            homeV: {
                ...state.homeV,
                isShow: false,
            },
        };
    case FETCH_HOME_ADS_DONE:
        return {
            ...state,
            header: action.header.isShow ? action.header : emptyAds,
            footer: action.footer.isShow ? action.footer : emptyAds,
            sideTop: action.sideTop.isShow ? action.sideTop : emptyAds,
            sideBottom: action.sideBottom.isShow ? action.sideBottom : emptyAds,
            homeH: action.homeH.isShow ? action.homeH : emptyAds,
            homeV: action.homeV.isShow ? action.homeV : emptyAds,
        };
    default:
        return state;
    }
};
export default adsReducer;
