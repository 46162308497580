import { connect } from 'react-redux';
import { resetData, getData, getPromote, getProject } from 'actions/postsQuery';
import { getAllPageAds } from 'actions/ads';
import Topics from 'views/post/post';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.postsQuery.loading,
    data: state.postsQuery.data,
    userId: state.auth.uid,
});

const mapDispatchToProps = {
    resetData,
    getData,
    getAllPageAds,
    getPromote,
    getProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
