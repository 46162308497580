import {
    AUTH_CHECK,
    AUTH_FAIL,
    AUTH_UPDATE_LEVEL,
    AUTH_UPDATE_PROFILE,
    AUTH_DIALOG,
    UPDATE_SIGN_UP,
    SIGN_UP,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAIL,
    UPDATE_SIGN_IN,
    SIGN_IN,
    GOOGLE_SIGN_IN,
    FACEBOOK_SIGN_IN,
    SIGN_IN_SUCCESS,
    SIGN_IN_FAIL,
    UPDATE_FORGOT_PASSWORD,
    FORGOT_PASSWORD,
    SIGN_OUT,
    UPDATE_AUTH_INBOX_NOTI,
    // NOT_SIGN_IN,
    // IS_LOADING,
    // LOADING_DONE,
    AUTH_RESET,
} from 'actions/auth';

const initialState = {
    loading: false,
    googleLoading: false,
    facebookLoading: false,
    authLevel: 0, // 0(not thing), 1(anonymous), 2(email)
    uid: '',
    signInByEmail: false,
    message: '',
    openDialog: false,
    profile: {
        name: '',
        email: '',
        phoneNumber: '',
        photoURL: '',
        inboxNoti: false,
    },
    signUp:{
        name: '',
        email: '',
        phoneNumber: '',
        password: '',
        isAgency: false,
    },
    signUpErrors: {},
    signUpTouched: {},
    signUpIsValid: false,
    signIn: {
        email: '',
        password: '',
    },
    signInErrors: {},
    signInTouched: {},
    signInIsValid: false,
    forgotEmail: '',
    forgotErrors: {},
    forgotIsValid: false,
    // submitDone: false,
    // data: {
    //     email: '',
    //     password: '',
    // },
    // errors: {},
    // isValid: false,
    // user: {
    //     name: '',
    //     profile: '',
    //     uid: '',
    // },
    // forgotEmail: '',
    // successMessage: '',
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
    case AUTH_CHECK:
        return {
            ...state,
            loading: true,
        };
    case AUTH_FAIL:
        return {
            ...state,
            loading: false,
            message: action.message,
        };
    case AUTH_UPDATE_LEVEL:
        return {
            ...state,
            loading: false,
            authLevel: action.level,
            uid: action.uid,
            signInByEmail: action.signInByEmail,
        };
    case AUTH_UPDATE_PROFILE:
        return {
            ...state,
            loading: false,
            profile: action.profile,
        };
    case UPDATE_AUTH_INBOX_NOTI:
        return {
            ...state,
            profile: {
                ...state.profile,
                inboxNoti: action.status,
            },
        };
    case AUTH_DIALOG:
        return {
            ...state,
            openDialog: action.value,
        };
    case AUTH_RESET:
        return {
            ...state,
            loading: false,
            message: '',
            signUp: {
                name: '',
                email: '',
                phoneNumber: '',
                password: '',
                isAgency: false,
            },
            signIn: {
                email: '',
                password: '',
            },
            forgotEmail: '',
            signUpErrors: {},
            signUpIsValid: false,
        };
    case UPDATE_SIGN_UP:
        return {
            ...state,
            signUp: {
                ...state.signUp,
                [action.fieldName]: action.newValue
            },
            signUpErrors: action.errors ? action.errors : {},
            signUpTouched: {
                ...state.signUpTouched,
                [action.fieldName]: true,
            },
            signUpIsValid: !action.errors,
        };
    case SIGN_UP:
        return {
            ...state,
            loading: true,
        };
    case SIGN_UP_SUCCESS:
        return {
            ...state,
            loading: false,
            authLevel: 2,
        };
    case SIGN_UP_FAIL:
        return {
            ...state,
            message: action.message,
            successMessage: '',
            loading: false,
        };
    case UPDATE_SIGN_IN:
        return {
            ...state,
            signIn: {
                ...state.signIn,
                [action.fieldName]: action.newValue
            },
            signInErrors: action.errors ? action.errors : {},
            signInTouched: {
                ...state.signInTouched,
                [action.fieldName]: true,
            },
            signInIsValid: !action.errors,
        };
    case SIGN_IN:
        return {
            ...state,
            loading: true,
        };
    case GOOGLE_SIGN_IN:
        return {
            ...state,
            googleLoading: true,
        };
    case FACEBOOK_SIGN_IN:
        return {
            ...state,
            facebookLoading: true,
        };
    case SIGN_IN_SUCCESS:
        return {
            ...state,
            loading: false,
            authLevel: 2,
            profile: action.profile,
        };
    case SIGN_IN_FAIL:
        return {
            ...state,
            message: action.message,
            successMessage: '',
            loading: false,
            googleLoading: false,
            facebookLoading: false,
        };
    case UPDATE_FORGOT_PASSWORD:
        return {
            ...state,
            forgotEmail: action.forgotEmail,
            forgotErrors: action.errors ? action.errors : {},
            forgotIsValid: !action.errors,
        };
    case FORGOT_PASSWORD:
        return {
            ...state,
            loading: true,
        };
    case SIGN_OUT:
        return {
            ...state,
            loading: true,
            message: action.message,
        };
    // case NOT_SIGN_IN:
    //     return {
    //         ...state,
    //         loading: false,
    //         isLogin: 0,
    //     };
    // case IS_LOADING:
    //     return {
    //         ...state,
    //         loading: true,
    //     };
    // case LOADING_DONE:
    //     return {
    //         ...state,
    //         loading: false,
    //         successMessage: action.successMessage,
    //         message: '',
    //     };
    default:
        return state;
    }
};
export default authReducer;