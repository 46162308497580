/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import _kebab from 'lodash/kebabCase';
import {
    connectHits,
} from 'react-instantsearch-dom';
import RoomIcon from '@material-ui/icons/Room';
import LabelIcon from '@material-ui/icons/Label';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const styles = () => ({
    searchResult: {
        borderRadius: 4,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #e9e9e9',
        maxHeight: '80vh',
        overflow: 'scroll',
        padding: 0,
    },
    notFound: {
        boxShadow: 'none',
        border: 'none',
    },
    list:{
        padding: '12px 30px',
        '&:hover svg':{
            color: "#00abbd",
        }
    },
    lists: {
        padding: 0,
    }
});

function ListItemLink(props) {
    return <ListItem button component={Link} {...props} />;
}

const Hits = ({ hits, lang, classList, closeSearch, searchNumber }) => {
    searchNumber(hits.length);
    return (
        <>
            {
                hits.map(hit => {
                    let iconType = <LabelIcon />;
                    if (hit.type === 'article'){
                        iconType = <LibraryBooksIcon />;
                    }
                    if (hit.type === 'location') {
                        iconType = <RoomIcon />;
                    }
                    return (
                        <ListItemLink 
                            key={hit.uid}
                            className={classList} 
                            to={`/${hit.type}/${_kebab(hit[`name${lang}`]) || 'no-title'}/${hit.uid}`}
                            onClick={closeSearch}
                        >
                            <ListItemIcon>
                                {iconType}
                            </ListItemIcon>
                            <ListItemText primary={hit[`name${lang}`]} />
                        </ListItemLink>
                    );
                })
            }
        </>
    );
};
const CustomHits = connectHits(Hits);

function SearchResult(props) {
    const {
        classes,
        closeSearch,
        searchNumber,
        searchLength,
        lang,
    } = props;

    return (
        <Paper className={clsx(classes.searchResult, searchLength === 0 ? classes.notFound : '')}>
            <List component="nav" className={classes.lists}>
                <CustomHits 
                    lang={lang}
                    classList={classes.list} 
                    closeSearch={closeSearch}
                    searchNumber={searchNumber}
                />
            </List>
        </Paper>        
    );
}

SearchResult.propTypes = {
    lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    closeSearch: PropTypes.func.isRequired,
    searchNumber: PropTypes.func.isRequired,
    searchLength: PropTypes.number.isRequired,
};

Hits.propTypes = {
    lang: PropTypes.string.isRequired,
    classList: PropTypes.string.isRequired,
    hits: PropTypes.array.isRequired,
    closeSearch: PropTypes.func.isRequired,
    searchNumber: PropTypes.func.isRequired,
};

export default withStyles(styles)(SearchResult);