/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HAds } from 'components/sections/ads';
import TopicData from 'components/sections/topic';
import AddComment from 'components/sections/topic/addComment';
import TitleComponent from 'components/roots/titleComponent';
import Comment from 'components/sections/topic/comment';

function TopicPage(props) {
    const {
        loading,
        data,
        comment,
        authLevel,
        profile,
        getTopicData,
        getSideAds,
        updateComment,
        postComment,
        location,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];

    React.useEffect(() => {
        getTopicData(uid);
        getSideAds();
    }, [getTopicData, uid, getSideAds]);

    return (
        <>
            <TitleComponent title={`${data.title || 'Topic'} - All About Living`} />
            <TopicData
                loading={loading}
                title={data.title}
                markdown={data.desc}
                tags={data.tagId}
                writer={data.writer}
                createdAt={data.date}
                view={data.view}
                comment={data.comment}
            />
            <HAds
                color="white"
                position="header"
                pageLoading={loading}
            />
            {
                loading ?
                    <Comment
                        loading={loading}
                        markdown={data.desc}
                        writer={data.writer}
                        createdAt={data.date}
                    />
                    :
                    data.comments.map((item) => (
                        <Comment
                            key={item.uid}
                            loading={loading}
                            markdown={item.desc}
                            writer={item.writer}
                            createdAt={item.date}
                        />
                    ))
            }
            {
                authLevel >= 2 && 
                <AddComment
                    loading={loading}
                    data={comment}
                    update={updateComment}
                    postComment={postComment}
                    profile={profile}
                    topicId={uid}
                />
            }
            <HAds
                color="white"
                position="footer"
                pageLoading={loading}
            />
        </>
    );
}

TopicPage.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    comment: PropTypes.object.isRequired,
    getTopicData: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    getSideAds: PropTypes.func.isRequired,
    authLevel: PropTypes.number.isRequired,
    updateComment: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    postComment: PropTypes.func.isRequired,
};

export default withRouter(TopicPage);
