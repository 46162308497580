import { connect } from 'react-redux';
import { 
    getMyList, 
    resetData,
    postDelete,
    postClose,
    postBringBack,
} from 'actions/posts';
import MyPostsNormal from 'views/post/myNormal';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.posts.loading,
    loadingMore: state.posts.loadingMore,
    showLoadingMore: state.posts.showLoadingMore,
    list: state.posts.list,
});

const mapDispatchToProps = {
    getMyList,
    resetData,
    postDelete,
    postClose,
    postBringBack,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyPostsNormal);



