/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { OutlinedInput, MenuItem, Select } from '@material-ui/core';
import Types from 'common/projectType';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 135,
        minWidth: 135,
        marginRight: 8,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 120,
            minWidth: 0,
            padding: '15px 14px',
            height: 48,
            boxSizing: 'border-box',
            // marginRight: 0,
        },
        '& .MuiSelect-select': {
            [theme.breakpoints.down('sm')]: {
                padding: '0 8px 0 0',
            },
        },
    },
}));

const CategoryCom = props => {
    const classes = useStyles();
    const { value, loading, oldData, changeValue } = props;

    const handleChange = event => {
        changeValue(event.target.value, oldData);
    };

    return (
        <Select
            className={classes.root}
            disabled={loading}
            value={value}
            onChange={handleChange}
            defaultValue="condo"
            input={<OutlinedInput name="propertyType" id="propertyType" />}>
            {Types.type.map(item => (
                <MenuItem key={item.uid} value={item.uid}>
                    {item.name}
                </MenuItem>
            ))}
        </Select>
    );
};

CategoryCom.propTypes = {
    value: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    changeValue: PropTypes.func.isRequired,
};

export default CategoryCom;
