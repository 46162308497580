import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';

const styles = (theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: 'none',
        borderRadius: 0,
    },
    media: {
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: 0,
        height: 496,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            height: 270,
        },
        '&:before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'block',
            zIndex: 0,
            backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3))',
        }
    },
    title: {
        marginBottom: 32,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        },
    },
});

function Cover(props) {
    const { 
        classes, 
        loading,
        title, 
        desc, 
        img 
    } = props;

    return (
        <Card className={classes.card}>
            {
                loading ?
                    <div className={classes.media}>
                        <Box zIndex={1} className={classes.title}>
                            <Typography variant="h1" style={{ color: '#fff' }}>
                                <Skeleton width={220} />
                            </Typography>
                        </Box>
                        <Box zIndex={1}>
                            <Typography variant="h5" style={{ color: '#fff' }}>
                                <Skeleton width={180} />
                            </Typography>
                        </Box>
                    </div>
                    :
                    <CardMedia image={img} className={classes.media}>
                        <Box zIndex={1} className={classes.title}>
                            <Typography variant="h1" style={{ color: '#fff' }}>
                                {title}
                            </Typography>
                        </Box>
                        <Box zIndex={1}>
                            <Typography variant="h5" style={{ color: '#fff', textAlign: 'center', padding: '0 16px' }}>
                                {desc}
                            </Typography>
                        </Box>
                    </CardMedia>
            }
        </Card>
    );
}

Cover.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    loading: PropTypes.bool,
};

Cover.defaultProps = {
    loading: true,
};

export default withStyles(styles)(Cover);