import { connect } from 'react-redux';
import {
    getList,
    loadmore,
} from 'actions/location';
import {
    getAllPageAds,
} from 'actions/ads';
import Location from 'views/location';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.location.loading,
    loadingMore: state.location.loadingMore,
    showLoadingMore: state.location.showLoadingMore,
    list: state.location.list,
    name: state.location.name,
});

const mapDispatchToProps = {
    getList,
    loadmore,
    getAllPageAds,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Location);

