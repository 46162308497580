import { connect } from 'react-redux';
import {
    getList,
    loadmore,
    getTopicList,
    loadmoreTopic,
} from 'actions/tag';
import {
    getAllPageAds,
} from 'actions/ads';
import Tag from 'views/tag';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.tag.loading,
    loadingMore: state.tag.loadingMore,
    showLoadingMore: state.tag.showLoadingMore,
    list: state.tag.list,
    loadingTopic: state.tag.loadingTopic,
    loadingTopicMore: state.tag.loadingTopicMore,
    showLoadingTopicMore: state.tag.showLoadingTopicMore,
    topicList: state.tag.topicList,
    name: state.tag.name,
});

const mapDispatchToProps = {
    getList,
    loadmore,
    getTopicList,
    loadmoreTopic,
    getAllPageAds,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tag);

