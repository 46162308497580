/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { HAds } from 'components/sections/ads';
import List from 'components/sections/list/latest';
import MenuText from 'common/menu';
import TitleComponent from 'components/roots/titleComponent';

function Latest(props) {
    const { 
        lang,
        loading,
        loadingMore,
        showLoadingMore,
        list,
        getList,
        loadmore, 
        getAllPageAds,
    } = props;

    const lastVisibleUid = list[list.length - 1] ? list[list.length - 1].uid : '';

    React.useEffect(() => {
        getList(lang);
        getAllPageAds();
    }, [getList, lang, getAllPageAds]);

    return (
        <>
            <TitleComponent title="Latest - All About Living" />
            <HAds 
                color="white" 
                position="header"
                pageLoading={loading}
            />
            <List
                loading={loading}
                loadingMore={loadingMore}
                loadmoreFunc={() => loadmore(lang, lastVisibleUid)}
                loadmoreTitle={MenuText[lang].loadmore}
                showLoadingMore={showLoadingMore}
                list={list}
                title={MenuText[lang].latest}
            />
            <HAds 
                color="white"
                position="footer"
                pageLoading={loading} 
            />
        </>
    );
}

Latest.propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingMore: PropTypes.bool.isRequired,
    showLoadingMore: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    getList: PropTypes.func.isRequired, 
    loadmore: PropTypes.func.isRequired, 
    getAllPageAds: PropTypes.func.isRequired,
};

export default Latest;