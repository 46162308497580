import {
    FETCH_WEBDATA,
    FETCH_WEBDATA_DONE,
    FETCH_WEBDATA_FAIL,
    UPDATE_WEBDATA_EMAIL,
    FETCH_WEBDATA_EMAIL,
    FETCH_WEBDATA_EMAIL_DONE,
    FETCH_WEBDATA_EMAIL_FAIL,
} from 'actions/termAndCondition';

const initialState = {
    loading: true,
    desc: '',
    submitLoading: false,
    email: '',
    errors: {},
    isValid: false,
    failMessage: '',
};

const termReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_WEBDATA:
        return {
            ...state,
            loading: true,
        };
    case FETCH_WEBDATA_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_WEBDATA_DONE:
        return {
            ...state,
            loading: false,
            desc: action.desc,
        };
    case UPDATE_WEBDATA_EMAIL:
        return {
            ...state,
            email: action.email,
            errors: action.errors,
            isValid: !action.errors,
        };
    case FETCH_WEBDATA_EMAIL:
        return {
            ...state,
            submitLoading: true,
        };
    case FETCH_WEBDATA_EMAIL_FAIL:
        return {
            ...state,
            submitLoading: false,
        };
    case FETCH_WEBDATA_EMAIL_DONE:
        return {
            ...state,
            submitLoading: false,
            email: '',
            errors: {},
            isValid: false,
        };
    default:
        return state;
    }
};
export default termReducer;
