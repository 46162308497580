/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import algoliasearch from 'algoliasearch/lite';
import { 
    connectAutoComplete, 
    InstantSearch,
} from 'react-instantsearch-dom';
import _ from 'lodash';
import TagChip from 'components/roots/element/tagChip';

const searchClient = algoliasearch(
    'SJS3S5FAVR',
    'fbf789ef62160863f04f7f39da3e7fe7'
);

const MyAutocomplete = ({ loading, oldData, update, remove, selectedValue, hits, refine }) => {

    const unselected = _(hits).differenceBy(selectedValue, 'uid').map(
        _.partial(_.pick, _, 'uid', 'nameTh', 'nameEn', 'nameCh', 'nameJp')
    ).value();

    return (
        <Autocomplete
            multiple
            noOptionsText="No suggestions"
            loading={loading}
            options={unselected}
            getOptionLabel={option => option.nameTh}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="แท็ก"
                    margin="normal"
                    fullWidth
                    disabled={loading}
                    onChange={(event) => {
                        refine(event.currentTarget.value);
                    }}
                />
            )}
            value={selectedValue}
            onChange={(event, value) => {
                update(value, oldData);
            }}
            renderTags={(value) =>
                value.map((option, index) => (
                    <TagChip
                        key={option.uid}
                        text={option.nameTh}
                        index={index}
                        onDelete={() => remove(index)}
                    />
                ))
            }
        />
    );
};
const ConnectedAutocomplete = connectAutoComplete(MyAutocomplete);

function Tags(props) {
    
    const {
        loading,
        oldData,
        update,
        remove,
        selectedValue,
    } = props;

    return (
        <InstantSearch indexName="tags" searchClient={searchClient}>
            <ConnectedAutocomplete
                loading={loading}
                oldData={oldData}
                update={update}
                remove={remove}
                selectedValue={selectedValue}
            />
        </InstantSearch>
    );
}

Tags.propTypes = {
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    selectedValue: PropTypes.array.isRequired,
};

MyAutocomplete.propTypes = {
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    selectedValue: PropTypes.array.isRequired,
    hits: PropTypes.array.isRequired,
    refine: PropTypes.func.isRequired,
};

export default Tags;