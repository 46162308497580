/* eslint-disable react/forbid-prop-types */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components/roots/button';
import PostPromote from 'components/roots/list/post/promote';

const styles = theme => ({
    root: {
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            '& .MuiDialog-paper': {
                margin: 0,
            },
            '& .MuiDialog-paperScrollPaper': {
                maxHeight: '100%',
                width: '100%',
            },
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#fff',
        padding: 7,
        // [theme.breakpoints.down('sm')]: {
        //     display: 'none',
        // },
    },
    chosePromote: {
        width: '50%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
            margin: '16px auto',
        },
    },
    content: {
        maxHeight: '60vh',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '70vh',
            minHeight: 400,
            padding: 16,
        },
    },
});

const DialogContent = withStyles(theme => ({
    root: {
        width: '100%',
        padding: '40px !important',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: '100vh',
            padding: '0px !important',
        },
    },
}))(MuiDialogContent);

function MyDialog(props) {
    const { classes, isOpen, handleClose, list, loading, submitFunc } = props;

    const initialValue = new Array(list.length).fill(false);

    const [state, setState] = React.useState({
        check: initialValue,
        realCheck: [],
    });
    const handleCheck = (index, realIndex) => {
        const newCheck = state.check;
        newCheck[index] = !newCheck[index];

        const newRealCheck = [...state.realCheck];
        if (_.includes(state.realCheck, realIndex)) {
            // remove
            _.remove(newRealCheck, n => {
                return n === realIndex;
            });
        } else {
            // add
            newRealCheck.push(realIndex);
        }

        setState({
            ...state,
            check: newCheck,
            realCheck: newRealCheck,
        });
    };

    const handleSubmit = () => {
        submitFunc(state.realCheck);
        handleClose();
        setState({
            ...state,
            check: initialValue,
            realCheck: [],
        });
    };

    const handleInnerClose = () => {
        handleClose();
        setState({
            ...state,
            check: initialValue,
            realCheck: [],
        });
    };

    return (
        <Dialog
            onClose={handleInnerClose}
            open={isOpen}
            className={classes.root}
            fullWidth
            maxWidth="md">
            <DialogContent>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleInnerClose}>
                    <CloseIcon style={{ color: '#666666' }} />
                </IconButton>
                <Box
                    style={{ textAlign: 'center' }}
                    mb={4}
                    mt={{ xs: 2, md: 0 }}>
                    <Typography variant="h2">โปรโมทประกาศ</Typography>
                </Box>
                <Box className={classes.content}>
                    {list.map((item, index) => (
                        <PostPromote
                            key={item.uid}
                            checked={state.check[index]}
                            handleChange={() => handleCheck(index, item.index)}
                            loading={loading}
                            uid={item.uid}
                            image={item.img}
                            title={item.title}
                            sellPrice={item.sellPrice}
                            rentPrice={item.rentPrice}
                            createdAt={item.createdAt}
                        />
                    ))}
                </Box>
                <Box
                    mt={2}
                    style={{
                        textAlign: 'center',
                        display: 'flex',
                    }}>
                    <Box className={classes.chosePromote}>
                        <Button
                            variant="contained"
                            noShadow
                            color="primary"
                            disabled={state.realCheck.length === 0}
                            onClick={handleSubmit}>
                            เลือกประกาศ
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

MyDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    submitFunc: PropTypes.func.isRequired,
};

export default withStyles(styles)(MyDialog);
