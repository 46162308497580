/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HAds } from 'components/sections/ads';
// import InnerCover from 'components/sections/cover/innerCover';
import ListNoTag from 'components/sections/list/listNoTag';
import TitleComponent from 'components/roots/titleComponent';
import MenuText from 'common/menu';

function Category(props) {
    const {
        lang,
        loading,
        loadingMore,
        showLoadingMore,
        list,
        name,
        getList,
        location,
        loadmore,
        getAllPageAds,
    } = props;

    const subCatId = location.pathname.split('/').slice(-1)[0];
    const lastVisibleUid = list[list.length - 1] ? list[list.length - 1].uid : '';

    React.useEffect(() => {
        getList(lang, subCatId);
        getAllPageAds();
    }, [getList, lang, subCatId, getAllPageAds]);

    return (
        <>
            <TitleComponent title={`${name || 'Category'} - All About Living`} />
            {/* <InnerCover
                title="Preview Location"
                img="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/web%2Fsean-pollock-PhYq704ffdA-unsplash.jpg?alt=media&token=779625ce-cc9a-4fb8-a1cc-880835ecf12c"
            /> */}
            <HAds
                color="white"
                position="header"
                pageLoading={loading}
            />
            <ListNoTag
                loading={loading}
                loadingMore={loadingMore}
                loadmoreFunc={() => loadmore(lang, subCatId, lastVisibleUid)}
                loadmoreTitle={MenuText[lang].loadmore}
                showLoadingMore={showLoadingMore}
                list={list}
                title={name}
                number={list.length}
            />
            <HAds
                color="white"
                position="footer"
                pageLoading={loading}
            />
        </>
    );
}

Category.propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingMore: PropTypes.bool.isRequired,
    showLoadingMore: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    name: PropTypes.string,
    list: PropTypes.array.isRequired,
    getList: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    loadmore: PropTypes.func.isRequired, 
    getAllPageAds: PropTypes.func.isRequired,
};

Category.defaultProps = {
    name: '',
};

export default withRouter(Category);