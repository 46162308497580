import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';
import { TagNew } from '../element';

const styles = theme => ({
    card: {
        borderRadius: 8,
        height: '100%',
        boxShadow: '12px 12px 40px 0 rgba(0, 32, 35, 0.1)',
        '&:hover': {
            boxShadow: 'none',
        }
    },
    media: {
        width: '100%',
        height: 288,
        [theme.breakpoints.down('sm')]: {
            height: 246,
        }
    },
    content: {
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '20px !important',
        [theme.breakpoints.down('sm')]: {
            padding: '16px !important',
        }
    },
    title: {
        marginBottom: 14,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 4,
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    }
});

function CardPaper(props) {
    const {
        classes,
        url,
        tag,
        img,
        title,
        date,
        loading,
    } = props;
    return (
        <Link to={url} className={classes.link}>
            <Card className={classes.card}>
                {!loading ?
                    <CardMedia component="img" src={img} className={classes.media} />
                    :
                    <div className={classes.media} />
                }
                <CardContent className={classes.content}>
                    {loading && <Skeleton width={50} />}
                    {
                        (tag !== '' && !loading) &&
                        <Box mb={1}>
                            <TagNew text={tag} />
                        </Box>
                    }
                    <Box className={classes.title}>
                        <Typography variant="subtitle1" >
                            {loading ? <Skeleton width="100%" count={1} /> : title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="caption" style={{ color: '#c7c7c7' }} >
                            {loading ? <Skeleton width="100%" count={2} /> : date}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Link>
    );
}

CardPaper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    tag: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    loading: PropTypes.bool,
};

CardPaper.defaultProps = {
    url: '',
    tag: '',
    img: '',
    title: '',
    date: '',
    loading: true,
};


export default withStyles(styles)(CardPaper);