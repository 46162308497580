/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    list: {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    package: {
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        padding: '16px 16px 16px 7px',
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            padding: '10px 16px 10px 3px',
            boxSizing: 'border-box',
            marginBottom: 16,
        },
    },
    active: {
        border: 'solid 1px #00abbd',
    },
    middle: {
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
    },
    head: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
});

function CreateTopic(props) {
    const {
        classes,
        activeNumber,
        packages,
        lang,
        handleChange,
        packageIndex,
    } = props;

    return (
        <Box className={classes.list}>
            {packages.map((item, index) => (
                <>
                    {index !== 1 ? (
                        <Paper
                            variant="outlined"
                            color="primary"
                            className={clsx(
                                classes.package,
                                activeNumber === index + 1 && classes.active,
                            )}>
                            <Box className={classes.head}>
                                <Radio
                                    color="primary"
                                    checked={activeNumber === index + 1}
                                    onClick={() =>
                                        handleChange(packageIndex, index + 1)
                                    }
                                />
                                <Box ml={1}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary">
                                        {item.name[lang]}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary">
                                        {item.views} วิว
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant="subtitle1" color="textPrimary">
                                ฿{item.price}
                            </Typography>
                        </Paper>
                    ) : (
                        <Box mr={1} ml={1} className={classes.middle}>
                            <Paper
                                variant="outlined"
                                color="primary"
                                className={clsx(
                                    classes.package,
                                    activeNumber === index + 1 &&
                                        classes.active,
                                )}>
                                <Box className={classes.head}>
                                    <Radio
                                        color="primary"
                                        checked={activeNumber === index + 1}
                                        onClick={() =>
                                            handleChange(
                                                packageIndex,
                                                index + 1,
                                            )
                                        }
                                    />
                                    <Box ml={1}>
                                        <Typography
                                            variant="subtitle1"
                                            color="textPrimary">
                                            {item.name[lang]}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary">
                                            {item.views} วิว
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    variant="subtitle1"
                                    color="textPrimary">
                                    ฿{item.price}
                                </Typography>
                            </Paper>
                        </Box>
                    )}
                </>
            ))}
        </Box>
    );
}

CreateTopic.propTypes = {
    activeNumber: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    packages: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    packageIndex: PropTypes.number.isRequired,
};

export default withStyles(styles)(CreateTopic);
