import {
    FETCH_ARTICLE,
    FETCH_ARTICLE_DONE,
    FETCH_ARTICLE_FAIL,
    FETCH_ARTICLE_RELATED,
    FETCH_ARTICLE_RELATED_DONE,
    FETCH_ARTICLE_RELATED_FAIL,
    FETCH_ARTICLE_MAP,
    FETCH_ARTICLE_MAP_DONE,
    FETCH_ARTICLE_MAP_FAIL,
    FETCH_ARTICLE_MAP_CENTER,
    SET_ARTICLE_MAP_FILTER,
    CLEAR_ARTICLE_MAP_FILTER,
} from 'actions/article';

const initialState = {
    loading: true,
    data: {
        title: '',
        category: '',
        subCatId: '',
        img: '',
        date: '',
        desc: '',
        textOverCover: '',
        tags: [],
        writer: {},
        locationId: '',
    },
    relatedLoading: true,
    relatedList: [],
    relatedMessage: '',
    mapLoading: true,
    mapList: [],
    mapCenter:{
        lat: 13.74285,
        lng: 100.5485,
    },
    mapActiveId: '',
    filter: {
        finance: true,
        inspiration: true,
        lifestyle: true,
        live: true,
    },
    failMessage: '',
};

const articleReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_ARTICLE:
        return {
            ...state,
            loading: true,
        };
    case FETCH_ARTICLE_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_ARTICLE_DONE:
        return {
            ...state,
            loading: false,
            data: action.data,
        };
    case FETCH_ARTICLE_RELATED:
        return {
            ...state,
            relatedLoading: true,
        };
    case FETCH_ARTICLE_RELATED_FAIL:
        return {
            ...state,
            relatedLoading: true,
            relatedMessage: action.message,
        };
    case FETCH_ARTICLE_RELATED_DONE:
        return {
            ...state,
            relatedLoading: false,
            relatedList: action.list,
        };
    case FETCH_ARTICLE_MAP:
        return {
            ...state,
            mapLoading: true,
        };
    case CLEAR_ARTICLE_MAP_FILTER: 
        return {
            ...state,
            filter: {
                finance: false,
                inspiration: false,
                lifestyle: false,
                live: false,
            },
        };
    case SET_ARTICLE_MAP_FILTER: 
        return {
            ...state,
            filter: {
                ...state.filter,
                [action.field]: action.value,
            },
        };
    case FETCH_ARTICLE_MAP_FAIL:
        return {
            ...state,
            mapLoading: false,
            data: {
                ...state.data,
                locationId: '',
            }
        };
    case FETCH_ARTICLE_MAP_CENTER:
        return {
            ...state,
            mapCenter: action.center,
            mapActiveId: action.activeId,
        };
    case FETCH_ARTICLE_MAP_DONE:
        return {
            ...state,
            mapLoading: false,
            mapList: action.list,
            mapCenter: action.center,
        };
    default:
        return state;
    }
};
export default articleReducer;
