import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Types from 'common/property/furniture';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // maxWidth: 200,
        marginRight: 8,
        // marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginRight: 0,
            marginBottom: 8,
            padding: '15px 14px',
            height: 48,
            boxSizing: 'border-box',
            // marginRight: 0,
            // marginBottom: 16,
        },
        '& .MuiSelect-select': {
            [theme.breakpoints.down('sm')]: {
                padding: '0 8px 0 0',
            },
        },
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            width: 234,
        },
    },
};

const CategoryCom = props => {
    const classes = useStyles();
    const {
        // value,
        loading,
        changeValue,
    } = props;

    const [furnitureName, setFurnitureName] = React.useState([]);

    const handleChange = event => {
        changeValue(event.target.value);
        setFurnitureName(event.target.value);
    };

    return (
        <Select
            className={classes.root}
            multiple
            displayEmpty
            value={furnitureName}
            disabled={loading}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={selected => {
                if (selected.length === 0) {
                    return 'เฟอร์นิเจอร์';
                }

                return `เฟอร์นิเจอร์: ${selected.join(',')}`;
            }}
            MenuProps={MenuProps}>
            <MenuItem disabled value="">
                เฟอร์นิเจอร์
            </MenuItem>
            {Types.type.map(item => (
                <MenuItem key={item.uid} value={item.name}>
                    <Checkbox
                        color="primary"
                        checked={furnitureName.indexOf(item.name) > -1}
                    />
                    <ListItemText primary={item.name} />
                </MenuItem>
            ))}
        </Select>
    );
};

CategoryCom.propTypes = {
    // value: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    changeValue: PropTypes.func.isRequired,
};

export default CategoryCom;
