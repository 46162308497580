export default {
    type: [
        {
            uid: 'time',
            name: 'เรียงลำดับตามล่าสุด',
        },
        {
            uid: 'priceMinMax',
            name: 'ราคาต่ำ-สูง',
        },
        {
            uid: 'priceMaxMin',
            name: 'ราคาสูง-ต่ำ',
        },
        {
            uid: 'areaPriceMinMax',
            name: 'ราคาต่อตารางเมตร ต่ำ-สูง',
        },
        {
            uid: 'areaPriceMaxMin',
            name: 'ราคาต่อตารางเมตร สูง-ต่ำ',
        },
        {
            uid: 'areaSmallBig',
            name: 'ขนาดพื้นที่เล็ก-ใหญ่',
        },
        {
            uid: 'areaBigSmall',
            name: 'ขนาดพื้นที่ใหญ่-เล็ก',
        },
    ],
};
