import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';
import RoomSpec from 'components/roots/status/room';
import Price from 'components/roots/price/postPrice';
import TagPromote from 'components/roots/element/tagPromote';

const styles = theme => ({
    card: {
        borderRadius: 8,
        height: '100%',
        boxShadow: '12px 12px 40px 0 rgba(0, 32, 35, 0.1)',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    media: {
        width: '100%',
        height: 288,
        [theme.breakpoints.down('sm')]: {
            height: 246,
        },
    },
    content: {
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: '16px !important',
        [theme.breakpoints.down('sm')]: {
            padding: '16px !important',
        },
    },
    title: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 4,
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    price: {
        display: 'flex',
    },
});

function CardPaper(props) {
    const {
        classes,
        url,
        img,
        title,
        location,
        loading,
        isPromote,
        sellPrice,
        rentPrice,
        areaSize,
        floor,
        bedroomNum,
    } = props;

    return (
        <Link to={url} className={classes.link}>
            <Card className={classes.card}>
                {!loading ? (
                    <CardMedia image={img} className={classes.media}>
                        {isPromote && (
                            <Box pt={1} pl={1}>
                                <TagPromote text="ประกาศแนะนำ" />
                            </Box>
                        )}
                    </CardMedia>
                ) : (
                    <div className={classes.media} />
                )}
                <CardContent className={classes.content}>
                    <Box className={classes.title} mb={2}>
                        <Typography variant="subtitle1">
                            {loading ? (
                                <Skeleton width="100%" count={1} />
                            ) : (
                                title
                            )}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {loading ? (
                                <Skeleton width="80px" count={1} />
                            ) : (
                                location
                            )}
                        </Typography>
                    </Box>
                    <RoomSpec
                        areaSize={areaSize}
                        bedroomNum={bedroomNum}
                        floor={floor}
                    />
                    <Box className={classes.price}>
                        <Box mr={3}>
                            <Price page="post" type="sell" price={sellPrice} />
                        </Box>
                        <Price page="post" type="rent" price={rentPrice} />
                    </Box>
                </CardContent>
            </Card>
        </Link>
    );
}

CardPaper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    isPromote: PropTypes.bool,
    url: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    loading: PropTypes.bool,
};

CardPaper.defaultProps = {
    url: '',
    img: '',
    title: '',
    location: '',
    loading: true,
    isPromote: false,
};

export default withStyles(styles)(CardPaper);
