/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';

import FilterBar from '../cover/filterBar';
import NavList from './navList';
import Snackbar from './snackbar';
import SearchBar from './searchBar';
import Mobile from './mobile';
import SearchResult from './searchResult';

// Icon
import Search from './search.svg';
import WhtSearch from './whtSearch.svg';
import Close from './close.svg';
import WhtClose from './whtClose.svg';
import BluLogo from './blu.png';
import WhtLogo from './wht.svg';

const styles = theme => ({
    navbar: {
        position: 'fixed',
        top: 0,
        width: '100%',
        display: 'block',
        zIndex: 10,
        transition: 'all 300ms linear',
        WebkitTransition: 'all 300ms linear',
        MozTransition: 'all 300ms linear',
        OTransition: 'all 300ms linear',
        willChange: 'top',
    },
    background: {
        width: '100%',
        display: 'block',
        transition: 'all 300ms linear',
        WebkitTransition: 'all 300ms linear',
        MozTransition: 'all 300ms linear',
        OTransition: 'all 300ms linear',
        willChange: 'background',
    },
    navUp: {
        top: '-66px',
        [theme.breakpoints.down('sm')]: {
            top: '-55px',
        },
    },
    white: {
        backgroundColor: '#fff',
    },
    transparent: {
        backgroundColor: 'transparent',
    },
    logoInner: {
        textAlign: 'right',
    },
    searchInner: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    link: {
        textDecoration: 'none',
    },
    logo: {
        verticalAlign: 'middle',
        height: 30,
    },
    search: {
        verticalAlign: 'middle',
        width: 25,
        height: 25,
        cursor: 'pointer',
    },
    iconButton: {
        padding: 12,
    },
    openSearch: {
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.22)',
        },
    },
    searchPost: {
        backgroundColor: '#ffffff',
        boxShadow: '0 20px 20px 0 rgba(0, 32, 35, 0.05)',
    },
    searchPostRowOne: {
        display: 'flex',
        flexDirection: 'row',
    },
});

const searchClient = algoliasearch(
    'SJS3S5FAVR',
    'fbf789ef62160863f04f7f39da3e7fe7',
);

function Navbar(props) {
    const {
        classes,
        bg,
        page,
        lang,
        authLevel,
        profile,
        isSearching,
        updateSearch,
        searchLength,
        searchNumber,
        snackIsShow,
        snackMessage,
        snackStatus,
        snackClose,
    } = props;

    const [state, setState] = React.useState({
        prevScrollpos: window.pageYOffset,
        visible: true,
        searching: false,
        atTop: true,
        showFilter: page === 'project',
    });

    // Scroll
    function handleScroll() {
        const { prevScrollpos } = state;
        const currentScrollPos = window.pageYOffset;
        const atTop = currentScrollPos <= 65;
        
        const visible =
            (prevScrollpos > currentScrollPos || currentScrollPos <= 0) &&
            window.innerHeight + window.scrollY < document.body.offsetHeight;

        const showFilter = page === 'project' ? visible : (prevScrollpos > currentScrollPos && currentScrollPos > 350);

        // clear search
        updateSearch('');
        setState({
            prevScrollpos: currentScrollPos,
            visible,
            atTop,
            showFilter,
        });
    }

    // Search
    function openSearch() {
        // clear search
        updateSearch('');
        setState({
            ...state,
            searching: true,
        });
    }
    function closeSearch() {
        // clear search
        updateSearch('');
        setState({
            ...state,
            searching: false,
        });
    }

    // Color
    const innerColor = bg === 'white' || !state.atTop ? 'white' : 'transparent';

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <ClickAwayListener onClickAway={closeSearch}>
            <div
                className={clsx(
                    classes.navbar,
                    !state.visible && classes.navUp,
                )}>
                <InstantSearch
                    searchClient={searchClient}
                    indexName="frontendSearch">
                    <div
                        className={clsx(
                            classes.background,
                            bg === 'white' || !state.atTop
                                ? classes.white
                                : classes.transparent,
                        )}>
                        <Grid
                            spacing={0}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center">
                            <Hidden smDown>
                                <Grid item xs={1} className={classes.logoInner}>
                                    <Box mr={3}>
                                        <Link
                                            className={classes.link}
                                            to="/"
                                            onClick={closeSearch}>
                                            <img
                                                className={classes.logo}
                                                src={
                                                    bg === 'white' ||
                                                    !state.atTop
                                                        ? BluLogo
                                                        : WhtLogo
                                                }
                                                alt="All About Living Logo"
                                            />
                                        </Link>
                                    </Box>
                                </Grid>
                                {state.searching ? (
                                    <SearchBar
                                        updateSearch={updateSearch}
                                        bg={innerColor}
                                        lang={lang}
                                    />
                                ) : (
                                    <NavList
                                        bg={innerColor}
                                        authLevel={authLevel}
                                        profile={profile}
                                    />
                                )}
                                <Grid
                                    item
                                    xs={1}
                                    className={classes.searchInner}>
                                    <Box>
                                        {state.searching ? (
                                            <IconButton
                                                className={clsx(
                                                    classes.iconButton,
                                                    bg === 'white' ||
                                                        !state.atTop
                                                        ? ''
                                                        : classes.openSearch,
                                                )}
                                                aria-label="Search"
                                                onClick={closeSearch}>
                                                <img
                                                    className={classes.search}
                                                    src={
                                                        bg === 'white' ||
                                                        !state.atTop
                                                            ? Close
                                                            : WhtClose
                                                    }
                                                    alt="close search"
                                                />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                className={clsx(
                                                    classes.iconButton,
                                                    bg === 'white' ||
                                                        !state.atTop
                                                        ? ''
                                                        : classes.openSearch,
                                                )}
                                                aria-label="Search"
                                                onClick={openSearch}>
                                                <img
                                                    className={classes.search}
                                                    src={
                                                        bg === 'white' ||
                                                        !state.atTop
                                                            ? Search
                                                            : WhtSearch
                                                    }
                                                    alt="search"
                                                />
                                            </IconButton>
                                        )}
                                    </Box>
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Mobile
                                    lang={lang}
                                    bg={innerColor}
                                    updateSearch={updateSearch}
                                    isSearching={isSearching}
                                    searchNumber={searchNumber}
                                    authLevel={authLevel}
                                    profile={profile}
                                />
                            </Hidden>
                        </Grid>
                        {(bg === 'white' || !state.atTop) && <Divider />}
                    </div>
                    {state.visible && isSearching && (
                        <Hidden smDown>
                            <Grid
                                spacing={0}
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                style={{
                                    position: 'absolute',
                                    zIndex: 10,
                                }}>
                                <Grid item xs={10}>
                                    <SearchResult
                                        lang={lang}
                                        searchNumber={searchNumber}
                                        closeSearch={() => closeSearch()}
                                        searchLength={searchLength}
                                    />
                                </Grid>
                            </Grid>
                        </Hidden>
                    )}
                </InstantSearch>
                <Hidden smDown>
                    <div
                        style={{
                            display: ((page === 'posts' || page === 'project') && state.showFilter)
                                ? 'block'
                                : 'none'
                        }}
                    >
                        <FilterBar fullWidth />
                    </div>
                </Hidden>
                <Snackbar
                    variant={snackStatus}
                    message={snackMessage}
                    open={snackIsShow}
                    setClose={snackClose}
                />
            </div>
        </ClickAwayListener>
    );
}

Navbar.propTypes = {
    classes: PropTypes.object.isRequired,
    bg: PropTypes.string.isRequired,
    page: PropTypes.string,
    lang: PropTypes.string.isRequired,
    authLevel: PropTypes.number.isRequired,
    profile: PropTypes.object.isRequired,
    isSearching: PropTypes.bool.isRequired,
    updateSearch: PropTypes.func.isRequired,
    searchLength: PropTypes.number.isRequired,
    searchNumber: PropTypes.func.isRequired,
    snackIsShow: PropTypes.bool.isRequired,
    snackMessage: PropTypes.string.isRequired,
    snackStatus: PropTypes.string.isRequired,
    snackClose: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
    page: '',
};

export default withStyles(styles)(Navbar);
