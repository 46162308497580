import { createTheme } from '@material-ui/core/styles';

const aalvTheme = createTheme({
    palette: {
        // Element & Text
        common: {
            black: "#2e2e2e",
            white: "#fff"
        },
        background: {
            default: "#f9f9f9",
            paper: "#fff"
        },
        // Text
        primary: {
            light: "#e4f8fa",
            main: "#00abbd",
            dark: "#009cac",
            contrastText: "#fff"
        },
        secondary: {
            light: "#f8b9a9",
            main: "#f06642",
            dark: "#c55437",
            contrastText: "#fff"
        },
        error: {
            light: "#ed738f",
            main: "#de0032",
            dark: "#B60029",
            contrastText: "#fff"
        },
        text: {
            primary: "#2e2e2e",
            secondary: "#666666",
            disabled: "#c7c7c7",
            hint: "#e9e9e9"
        }
    },
    typography: {
        fontFamily: [
            'Prompt',
            '-apple-system',
            'BlinkMacSystemFont',
        ].join(','),
        h1: {
            fontSize: "2.375rem",
            fontWeight: 700,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.26,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "1.75rem",
                lineHeight: 1,
            }
        },
        h2: {
            fontSize: "1.875rem",
            fontWeight: 700,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "1.5rem",
                lineHeight: 1.42,
            }
        },
        h3: {
            fontSize: "1.875rem",
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.33,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "1.375rem",
                lineHeight: 1.45,
            }
        },
        h4: {
            fontSize: "1.375rem",
            fontWeight: 700,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.45,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "1.125rem",
                lineHeight: 1.56,
            }
        },
        h5: {
            fontSize: "1.375rem",
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.45,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "1.125rem",
                lineHeight: 1.56,
            }
        },
        subtitle1: {
            fontSize: "1.125rem",
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.56,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "1rem",
                lineHeight: 1.63,
            }
        },
        subtitle2: {
            fontSize: "1rem",
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.56,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "0.875rem",
                lineHeight: 1.71,
            }
        },
        body1: {
            fontSize: "1rem",
            fontWeight: 400,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.63,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "0.875rem",
                lineHeight: 1.71,
            }
        },
        body2: {
            fontSize: "0.875rem",
            fontWeight: 400,
            fontStyle: "normal",
            fontStretch: "normal",
            lineHeight: "1.71",
            letterSpacing: "normal",
            "@media screen and (max-width: 961px)": {
                fontSize: "0.75rem",
                lineHeight: 1.83,
            }
        },
        button: {
            fontSize: "1rem",
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.63,
            letterSpacing: 'normal',
            textAlign: 'center',
            textTransform: 'none',
            "@media screen and (max-width: 961px)": {
                fontSize: "0.875rem",
                lineHeight: 1.71,
            }
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: 400,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.83,
            letterSpacing: 'normal',
            "@media screen and (max-width: 961px)": {
                fontSize: "0.625rem",
                lineHeight: 2,
            }
        },
        overline: {
            fontSize: "0.625rem",
            fontWeight: 300,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 2,
            letterSpacing: 'normal',
            textTransform: 'none',
            "@media screen and (max-width: 961px)": {
                fontWeight: 500,
            }
        },
    },
});

export default aalvTheme;