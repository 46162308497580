import validate from 'validate.js';
import firebase from 'common/firebase';
import moment from 'moment';
import MenuLang from 'common/menu';
import { setNotFound, showSnack } from './main';

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
};

// Action
// -- CREATE/UPDATE
export const FETCH_WEBDATA = 'FETCH_WEBDATA';
export const FETCH_WEBDATA_DONE = 'FETCH_WEBDATA_DONE';
export const FETCH_WEBDATA_FAIL = 'FETCH_WEBDATA_FAIL';
export const UPDATE_WEBDATA_EMAIL = 'UPDATE_WEBDATA_EMAIL';
export const FETCH_WEBDATA_EMAIL = 'FETCH_WEBDATA_EMAIL';
export const FETCH_WEBDATA_EMAIL_DONE = 'FETCH_WEBDATA_EMAIL_DONE';
export const FETCH_WEBDATA_EMAIL_FAIL = 'FETCH_WEBDATA_EMAIL_FAIL';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_WEBDATA,
    };
};
export function fetchDone(desc) {
    return {
        type: FETCH_WEBDATA_DONE,
        desc,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_WEBDATA_FAIL,
        message,
    };
};

export function fetchSubmit() {
    return {
        type: FETCH_WEBDATA_EMAIL,
    };
};
export function fetchSubmitDone() {
    return {
        type: FETCH_WEBDATA_EMAIL_DONE,
    };
};
export function fetchSubmitFail() {
    return {
        type: FETCH_WEBDATA_EMAIL_FAIL,
    };
};

export function update(email) {
    const validateData = { email };
    const errors = validate(validateData, schema);
    return {
        type: UPDATE_WEBDATA_EMAIL,
        email,
        errors,
    };
};

// Async Action Creator
export function getData(lang,uid) {
    return async (dispatch) => {
        dispatch(fetch());

        const ref = firebase.firestore().collection("webData").doc(uid);
        try {
            const result = await ref.get();
            if (result.exists) {
                const output = result.data();
                dispatch(fetchDone(output[lang]));
            } else {
                dispatch(setNotFound(true));
            }
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function postCreate(lang, email) {
    return async (dispatch) => {
        dispatch(fetchSubmit());

        const ref = firebase.firestore().collection("adsvertiseWithUs");
        const data = {
            email,
            timestamp: moment().valueOf(),
        };
        try {
            await ref.add(data);
            dispatch(showSnack('success', MenuLang[lang].submitDone));
            dispatch(fetchSubmitDone());
        } catch (error) {
            dispatch(fetchSubmitFail());
            dispatch(showSnack('warning', error.message));
        }
    };
}
