/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/styles';
import _ from 'lodash';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import MyDialog from 'components/roots/dialog/delete';
import TypeField from 'components/roots/propertyField/type';
import PurposeField from 'components/roots/propertyField/purpose';
import ProjectSearch from 'components/roots/propertyField/ProjectSearch';
import BedroomNumber from 'components/roots/propertyField/bedroomNumber';
import BathroomNumber from 'components/roots/propertyField/bathroomNumber';
import DirectionField from 'components/roots/propertyField/direction';
import FurnitureField from 'components/roots/propertyField/furniture';
import StatusField from 'components/roots/propertyField/status';
import GalleryField from 'components/roots/galleryField';
import Map from 'components/roots/mapField';

const styles = theme => ({
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    select: {
        marginRight: 8,
    },
    deleteBtn: {
        maxWidth: 180,
        marginLeft: 'auto',
    },
    cancelBtn: {
        width: 180,
        marginLeft: 'auto',
    },
    submitBtn: {
        width: 180,
        marginLeft: 24,
    },
    leftBottom: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    rightBottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    desc: {
        position: 'relative',
        color: '#2e2e2e',
        width: '100%',
        wordWrap: 'break-word',
        margin: 0,
        '& .MuiInputBase-root': {
            margin: 0,
            fontFamily: 'Sarabun',
            fontSize: 18,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                lineHeight: 1.75,
            },
        },
    },
    sellPrice: {
        display: 'inline-flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
        '& .MuiTextField-root': {
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
            '& .MuiInputBase-root': {
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
        },
    },
});

function CreateTopic(props) {
    const {
        classes,
        location,
        // lang,
        loading,
        data,
        errors,
        touched,
        isValid,
        successMessage,

        // Create Function
        changeType,
        changeProject,
        changeGeo,
        update,
        // postCreate,
        postGallery,
        removeGallery,

        // Edit Function
        resetData,
        getEdit,
        postEdit,
        postDelete,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];
    const title = 'แก้ไขประกาศ';
    console.log(data.accept);

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return true;
        }
        return false;
    };

    const handleSubmit = () => {
        postEdit(uid, data);
    };

    // Dialog
    const [dialog, setDialog] = React.useState(false);
    const handleOpenDialog = () => {
        setDialog(true);
    };
    const handleCloseDialog = () => {
        setDialog(false);
    };

    // Get Data
    React.useEffect(() => {
        getEdit(uid);
    }, [getEdit, uid]);

    if (successMessage) {
        return <Redirect to="/my-posts" />;
    }

    return (
        <>
            <TitleComponent title={`${title} - All About Living`} />
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box mb={{ xs: 2, md: 3 }} mt={{ xs: 2, md: 3 }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to="/my-posts" className={classes.link}>
                                    <Typography
                                        variant="button"
                                        color="textSecondary">
                                        ประกาศของฉัน
                                    </Typography>
                                </Link>
                                <Typography
                                    variant="button"
                                    color="textPrimary"
                                    style={{ fontWeight: 600 }}>
                                    {title}
                                </Typography>
                            </Breadcrumbs>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end">
                    <Grid item xs={6}>
                        <Box mb={{ xs: 2, md: 3 }}>
                            <Typography variant="h2" color="textPrimary">
                                {title}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box
                            mb={{ xs: 2, md: 3 }}
                            className={classes.deleteBtn}>
                            <Button
                                variant="outlined"
                                color="default"
                                disabled={loading}
                                onClick={() => handleOpenDialog()}>
                                ลบประกาศ
                            </Button>
                            <MyDialog
                                isOpen={dialog}
                                handleClose={() => handleCloseDialog()}>
                                <Box style={{ textAlign: 'center' }} mb={2}>
                                    <Typography variant="h2">
                                        ลบประกาศ
                                    </Typography>
                                </Box>
                                <Box style={{ textAlign: 'center' }} mb={4}>
                                    <Typography variant="body1">
                                        คุณต้องการลบประกาศนี้หรือไม่?
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        textAlign: 'center',
                                        display: 'flex',
                                    }}>
                                    <Box
                                        style={{ width: '50%' }}
                                        mr={{ xs: 1, md: 1.5 }}>
                                        <Button
                                            variant="outlined"
                                            color="default"
                                            onClick={() =>
                                                handleCloseDialog()
                                            }>
                                            ยกเลิก
                                        </Button>
                                    </Box>
                                    <Box
                                        style={{ width: '50%' }}
                                        ml={{ xs: 1, md: 1.5 }}>
                                        <Button
                                            variant="contained"
                                            noShadow
                                            color="primary"
                                            onClick={() =>
                                                postDelete(uid)
                                            }>
                                            ลบ
                                        </Button>
                                    </Box>
                                </Box>
                            </MyDialog>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box mb={1}>
                            <Box mb={{ xs: 2, md: 3 }}>
                                <TextField
                                    error={hasError('title')}
                                    helperText={
                                        hasError('title')
                                            ? errors.title[0]
                                            : null
                                    }
                                    disabled={loading}
                                    fullWidth
                                    required
                                    label="หัวข้อประกาศ"
                                    className={classes.textField}
                                    type="text"
                                    name="title"
                                    variant="outlined"
                                    onChange={event =>
                                        update(
                                            data,
                                            event.target.name,
                                            event.target.value,
                                        )
                                    }
                                    value={data.title}
                                />
                            </Box>
                            <Box mb={{ xs: 2, md: 3 }}>
                                <TextField
                                    error={hasError('desc')}
                                    helperText={
                                        hasError('desc') ? errors.desc[0] : null
                                    }
                                    disabled={loading}
                                    fullWidth
                                    multiline
                                    rows={5}
                                    rowsMax={50}
                                    label="รายละเอียดของประกาศ"
                                    className={clsx(
                                        classes.textField,
                                        classes.desc,
                                    )}
                                    type="text"
                                    name="desc"
                                    variant="outlined"
                                    onChange={event =>
                                        update(
                                            data,
                                            event.target.name,
                                            event.target.value,
                                        )
                                    }
                                    value={data.desc}
                                />
                            </Box>
                            <Box>
                                <PurposeField
                                    loading={loading}
                                    value={data.purpose}
                                    changeValue={newValue =>
                                        update(data, 'purpose', newValue)
                                    }
                                />
                                {(data.purpose === 'sell' ||
                                    data.purpose === 'sellrent') && (
                                    <Box
                                        mr={{ xs: 0, md: 3 }}
                                        mb={{ xs: 2, md: 3 }}
                                        className={classes.sellPrice}>
                                        <TextField
                                            label="ราคาขาย (บาท)"
                                            name="sellPrice"
                                            onChange={event =>
                                            {update(
                                                data,
                                                event.target.name,
                                                event.target.value.replace(/,/g, ''),
                                            );
                                            }
                                            }
                                            value={data.sellPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            variant="outlined"
                                            disabled={loading}
                                            required
                                        />
                                    </Box>
                                )}
                                {(data.purpose === 'rent' ||
                                    data.purpose === 'sellrent') && (
                                    <Box
                                        mr={{ xs: 0, md: 3 }}
                                        mb={{ xs: 2, md: 3 }}
                                        className={classes.sellPrice}>
                                        <TextField
                                            label="ราคาเช่า (บาท/เดือน)"
                                            name="rentPrice"
                                            onChange={event =>
                                                update(
                                                    data,
                                                    event.target.name,
                                                    event.target.value.replace(/,/g, ''),
                                                )
                                            }
                                            value={data.rentPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            variant="outlined"
                                            disabled={loading}
                                            required
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box mb={3}>
                            <Box mb={3} mt={{ xs: 4, md: 0 }}>
                                <Typography variant="h5" color="textPrimary">
                                    ข้อมูลของโครงการหรือสถานที่
                                </Typography>
                            </Box>
                            <Box mb={1}>
                                <TypeField
                                    loading={loading}
                                    value={data.type}
                                    oldData={data}
                                    changeValue={changeType}
                                />
                            </Box>
                            {data.type === 'project' && (
                                <ProjectSearch
                                    loading={loading}
                                    oldData={data}
                                    update={proId => changeProject(proId, data)}
                                    selectedValue={data.projectId}
                                />
                            )}
                            {data.type === 'personal' && (
                                <Box mt={3}>
                                    <Map
                                        loading={loading}
                                        category="live"
                                        lat={data.location.lat}
                                        lng={data.location.lng}
                                        changeGeo={(lat, lng) =>
                                            changeGeo(lat, lng, data)
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <Box mb={3}>
                                <Typography variant="h5" color="textPrimary">
                                    ข้อมูลของอสังหาฯ
                                </Typography>
                            </Box>
                            <Box mb={1}>
                                <Box
                                    mr={{ xs: 0, md: 3 }}
                                    mb={{ xs: 2, md: 3 }}
                                    className={classes.sellPrice}>
                                    <TextField
                                        label="ขนาดที่ดิน (ตร.วา)"
                                        name="landSize"
                                        type="number"
                                        value={data.landSize}
                                        variant="outlined"
                                        disabled={loading}
                                        onChange={event =>
                                            update(
                                                data,
                                                event.target.name,
                                                event.target.value,
                                            )
                                        }
                                    />
                                </Box>
                                <Box
                                    mr={{ xs: 0, md: 3 }}
                                    mb={{ xs: 2, md: 3 }}
                                    className={classes.sellPrice}>
                                    <TextField
                                        label="พื้นที่ใช้สอย (ตร.ม.)"
                                        name="areaSize"
                                        onChange={event =>
                                            update(
                                                data,
                                                event.target.name,
                                                event.target.value,
                                            )
                                        }
                                        type="number"
                                        value={data.areaSize}
                                        variant="outlined"
                                        disabled={loading}
                                        required
                                    />
                                </Box>
                                <BedroomNumber
                                    loading={loading}
                                    value={data.bedroomNum}
                                    changeValue={newValue =>
                                        update(data, 'bedroomNum', newValue)
                                    }
                                />
                                <BathroomNumber
                                    loading={loading}
                                    value={data.toiletNum}
                                    changeValue={newValue =>
                                        update(data, 'toiletNum', newValue)
                                    }
                                />
                                <Box
                                    mr={{ xs: 0, md: 3 }}
                                    mb={{ xs: 2, md: 3 }}
                                    className={classes.sellPrice}>
                                    <TextField
                                        label="ชั้น"
                                        name="floor"
                                        onChange={event =>
                                            update(
                                                data,
                                                event.target.name,
                                                event.target.value,
                                            )
                                        }
                                        type="number"
                                        value={data.floor}
                                        variant="outlined"
                                        disabled={loading}
                                    />
                                </Box>
                                <DirectionField
                                    loading={loading}
                                    value={data.direction}
                                    changeValue={newValue =>
                                        update(data, 'direction', newValue)
                                    }
                                />
                                <FurnitureField
                                    loading={loading}
                                    value={data.furniture}
                                    changeValue={newValue =>
                                        update(data, 'furniture', newValue)
                                    }
                                />
                                <StatusField
                                    loading={loading}
                                    value={data.projectStatus}
                                    changeValue={newValue =>
                                        update(data, 'projectStatus', newValue)
                                    }
                                />
                            </Box>
                        </Box>
                        <Box mb={5}>
                            <Box mb={3} mt={{ xs: 2, md: 0 }}>
                                <Typography variant="h5" color="textPrimary">
                                    อัพโหลดรูปภาพ (อัปโหลดรูปภาพได้สูงสุด 10
                                    รูป)
                                </Typography>
                            </Box>
                            <GalleryField
                                list={data.gallery}
                                oldData={data}
                                postGallery={postGallery}
                                removeGallery={removeGallery}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box
                            className={classes.leftBottom}
                            mb={{ xs: 3, md: 0 }}>
                            <FormControlLabel
                                className={classes.select}
                                control={
                                    <Checkbox
                                        checked={data.accept}
                                        onChange={() =>
                                            update(data, 'accept', !data.accept)
                                        }
                                        color="primary"
                                        disabled={loading}
                                    />
                                }
                                label="ยอมรับ"
                            />
                            <Link
                                to="/termAndCondition"
                                className={classes.link}>
                                <Typography variant="subtitle2" color="primary">
                                    ข้อกำหนดและเงื่อนไขการลงประกาศ
                                </Typography>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            className={classes.rightBottom}
                            mb={{ xs: 1, md: 0 }}
                            mt={{ xs: 1, md: 0 }}>
                            <Box className={classes.cancelBtn}>
                                <Link to="/my-posts" className={classes.link}>
                                    <Button
                                        variant="outlined"
                                        color="default"
                                        disabled={loading}
                                        onClick={() => resetData()}>
                                        ยกเลิก
                                    </Button>
                                </Link>
                            </Box>
                            <Box className={classes.submitBtn}>
                                <Button
                                    variant="contained"
                                    noShadow
                                    color="primary"
                                    onClick={() => handleSubmit()}
                                    disabled={
                                        loading || !data.accept || !isValid
                                    }>
                                    บันทึก
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box mb={{ xs: 0, md: 5 }} />
        </>
    );
}

CreateTopic.propTypes = {
    successMessage: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    resetData: PropTypes.func.isRequired,
    getEdit: PropTypes.func.isRequired,
    postEdit: PropTypes.func.isRequired,
    postDelete: PropTypes.func.isRequired,
    changeType: PropTypes.func.isRequired,
    changeProject: PropTypes.func.isRequired,
    changeGeo: PropTypes.func.isRequired,
    postGallery: PropTypes.func.isRequired,
    removeGallery: PropTypes.func.isRequired,
};

CreateTopic.defaultProps = {
    errors: {},
};

export default withStyles(styles)(withRouter(CreateTopic));
