import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'components/theme';
import Routes from './Routes';
import history from './history';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <Routes />
            </Router>
        </ThemeProvider>
    );
}

export default App;