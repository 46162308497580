/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import {
    resetAuth,
    updateSignUp,
    postSignUp,
    updateSignIn,
    postSignIn,
    updateForgot,
    postForgot,
    googleSignIn,
    facebookSignIn,
} from 'actions/auth';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import MyDialog from 'components/roots/dialog';
import MailIcon from '@material-ui/icons/Mail';
import { Button } from 'components/roots/button';

import DialogHeader from './header';
import DialogFooter from './footer';
import LoginForm from './loginForm';
import RegisterForm from './registerForm';
import ForgotPassForm from './forgotPassForm';

function LoginDialog(props) {
    const {
        isOpen,
        name,
        handleClose,
        handleChangePage,
        // -- state
        loading,
        googleLoading,
        facebookLoading,
        signUp,
        errors,
        touched,
        isValid,
        signIn,
        signInErrors,
        signInTouched,
        signInIsValid,
        forgotEmail,
        forgotErrors,
        forgotIsValid,
        // -- function
        reset,
        updateRegister,
        postRegister,
        updateLogin,
        postLogin,
        updateForgotPass,
        postForgotPass,
        postGoogleSignIn,
        postFacebookSignIn,
    } = props;

    let buttonText = 'เข้าสู่ระบบ';
    if (name === 'register') {
        buttonText = 'ลงทะเบียนด้วย Email';
    }
    if (name === 'forgotPass') {
        buttonText = 'ส่งอีเมล';
    }

    const handleSubmit = () => {
        if (name === 'register') {
            handleChangePage('registerForm');
        }
        if (name === 'registerForm') {
            postRegister(signUp);
        }
        if (name === 'login'){
            postLogin(signIn);
        }
        if (name === 'forgotPass') {
            postForgotPass(forgotEmail);
        }
    };

    return (
        <MyDialog
            isOpen={isOpen}
            handleClose={() => handleClose()}
        >
            <DialogHeader
                name={name}
                googleLoading={googleLoading}
                facebookLoading={facebookLoading}
                handleChangePage={handleChangePage}
                googleSignIn={postGoogleSignIn}
                facebookSignIn={postFacebookSignIn}
                handleClose={() => handleClose()}
            />
            <Box p={{xs: '0 16px', md: 0}}>
                {  
                    name === 'registerForm' && 
                        <RegisterForm 
                            loading={loading}
                            data={signUp}
                            errors={errors}
                            touched={touched}
                            update={updateRegister}
                        /> 
                }
                { 
                    name === 'login' && 
                        <LoginForm 
                            loading={loading}
                            data={signIn}
                            errors={signInErrors}
                            touched={signInTouched}
                            update={updateLogin}
                            handleChangePage={handleChangePage}
                        /> 
                }
                {
                    name === "forgotPass" &&
                        <ForgotPassForm
                            loading={loading}
                            email={forgotEmail}
                            errors={forgotErrors}
                            update={updateForgotPass}
                        />
                }
                <Box mb={3} style={{ textAlign: 'center' }}>
                    <Button
                        color="primary"
                        variant="contained"
                        noShadow
                        onClick={() => handleSubmit()}
                        disabled={
                            (!isValid && name === 'registerForm') 
                            || 
                            (!signInIsValid && name === 'login') 
                            || 
                            (!forgotIsValid && name === 'forgotPass')
                            ||
                            loading
                            ||
                            googleLoading
                            ||
                            facebookLoading
                        }
                        loading={loading}
                    >
                        {
                            name === 'register' &&
                            <MailIcon
                                style={{
                                    fontSize: 29,
                                    marginRight: 8,
                                }}
                            />
                        }
                        {buttonText}
                    </Button>
                </Box>
                <DialogFooter 
                    name={name}
                    handleChangePage={handleChangePage}
                    reset={reset}
                />
            </Box>
        </MyDialog>
    );
}

LoginDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    googleLoading: PropTypes.bool.isRequired,
    facebookLoading: PropTypes.bool.isRequired,
    signUp: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    signIn: PropTypes.object.isRequired,
    signInErrors: PropTypes.object.isRequired,
    signInTouched: PropTypes.object.isRequired,
    signInIsValid: PropTypes.bool.isRequired,
    forgotEmail: PropTypes.string.isRequired,
    forgotErrors: PropTypes.object.isRequired,
    forgotIsValid: PropTypes.bool.isRequired,
    // function
    reset: PropTypes.func.isRequired,
    updateRegister: PropTypes.func.isRequired,
    postRegister: PropTypes.func.isRequired,
    updateLogin: PropTypes.func.isRequired,
    postLogin: PropTypes.func.isRequired,
    updateForgotPass: PropTypes.func.isRequired,
    postForgotPass: PropTypes.func.isRequired,
    postGoogleSignIn: PropTypes.func.isRequired,
    postFacebookSignIn: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: state.auth.loading,
    googleLoading: state.auth.googleLoading,
    facebookLoading: state.auth.facebookLoading,
    signUp: state.auth.signUp,
    errors: state.auth.signUpErrors,
    touched: state.auth.signUpTouched,
    isValid: state.auth.signUpIsValid,
    signIn: state.auth.signIn,
    signInErrors: state.auth.signInErrors,
    signInTouched: state.auth.signInTouched,
    signInIsValid: state.auth.signInIsValid,
    forgotEmail: state.auth.forgotEmail,
    forgotErrors: state.auth.forgotErrors,
    forgotIsValid: state.auth.forgotIsValid,
});

const mapDispatchToProps = {
    reset: resetAuth,
    updateRegister: updateSignUp,
    postRegister: postSignUp,
    updateLogin: updateSignIn,
    postLogin: postSignIn,
    updateForgotPass: updateForgot,
    postForgotPass: postForgot,
    postGoogleSignIn: googleSignIn,
    postFacebookSignIn: facebookSignIn,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginDialog);