/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMyOpenList, toggleCheck } from 'actions/posts';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from 'react-loading-skeleton';
import DeleteDialog from 'components/roots/dialog/delete';
import PromoteDialog from 'components/roots/dialog/promote';
import { Button } from 'components/roots/button';
import { TagPin } from 'components/roots/element';
import Price from 'components/roots/price/postPrice';
import Progress from 'components/roots/progress/post';

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        boxShadow: 'none',
        width: '100%',
        padding: '0 0 33px 0',
        borderBottom: '1px solid #e9e9e9',
        borderRadius: 0,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
            padding: '0 0 16px 0',
        },
    },
    media: {
        width: 384,
        height: 288,
        borderRadius: 8,
        backgroundImage:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0) 91%, rgba(0, 0, 0, 0.5))',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 4,
            width: 70,
            height: 54,
        },
    },
    mediaLoading: {
        width: 384,
        height: 288,
        borderRadius: 8,
        backgroundColor: 'rgb(238, 238, 238)',
        backgroundImage:
            'linear-gradient(90deg, rgb(238, 238, 238), rgb(245, 245, 245), rgb(238, 238, 238))',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 4,
            width: 70,
            height: 54,
        },
    },
    content: {
        padding: 0,
        paddingBottom: '0 !important',
        paddingLeft: 24,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        width: '100%',
        marginBottom: 8,
    },
    editBtn: {
        marginLeft: 8,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 4,
            marginBottom: 4,
        },
    },
    cancelBtn: {
        width: '50%',
    },
    deleteBtn: {
        width: '50%',
    },
    promoteBtn: {
        width: 180,
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    loadingLink: {
        textDecoration: 'none',
        color: 'inherit',
        pointerEvents: 'none',
        cursor: 'default',
    },
    rowOne: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    lower: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    closeStatus: {
        opacity: 0.5,
    },
    mobileBottom: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
});

function MyPost(props) {
    const {
        classes,
        uid,
        url,
        image,
        title,
        textLocation,
        sellPrice,
        rentPrice,
        createdAt,
        status,
        view,
        maxView,
        loading,
        deleteTopic,
        resetData,
        postClose,
        postBringBack,

        // promotion
        getList,
        toggleChecked,
        list,
        promoteLoading,
        lang,
        check,
    } = props;

    // Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    // Dialog
    const [dialog, setDialog] = React.useState(false);
    const handleOpenDialog = () => {
        setDialog(true);
        handleClose();
    };

    const handleCloseDialog = () => {
        setDialog(false);
    };

    // Promote
    const [promote, setPromote] = React.useState(false);
    const handleOpenPromote = () => {
        setPromote(true);
        getList(lang);
    };

    const handleClosePromote = () => {
        setPromote(false);
    };

    const handleDelete = () => {
        deleteTopic();
        setDialog(false);
    };

    // if (successMessage === 'Property actived successfully.') {
    //     return <Redirect to="/my-posts" />;
    // }

    return (
        <Card className={classes.card}>
            {!loading ? (
                <CardMedia
                    image={image}
                    className={clsx(
                        classes.media,
                        status === 'close' && classes.closeStatus,
                    )}>
                    {status === 'promote' && (
                        <Hidden smDown>
                            <Box mt={{ xs: 1, md: 1 }} ml={{ xs: 0, md: 1 }}>
                                <TagPin text="กำลังโปรโมทอยู่" />
                            </Box>
                        </Hidden>
                    )}
                </CardMedia>
            ) : (
                <div className={classes.mediaLoading} />
            )}
            <CardContent className={classes.content}>
                <Box>
                    <Box className={classes.rowOne}>
                        <Box>
                            {loading ? (
                                <Typography variant="body2">
                                    <Skeleton width="200px" count={1} />
                                </Typography>
                            ) : (
                                <Typography
                                    variant="body2"
                                    style={{ color: '#666666' }}>
                                    {`หมายเลข ${uid}`}
                                </Typography>
                            )}
                        </Box>
                        {!loading && (
                            <Box className={classes.editBtn}>
                                <IconButton size="small" onClick={handleClick}>
                                    <Hidden smDown>
                                        <MoreVertIcon fontSize="large" />
                                    </Hidden>
                                    <Hidden mdUp>
                                        <MoreVertIcon fontSize="default" />
                                    </Hidden>
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}>
                                    <Hidden mdUp>
                                        {status === 'close' && (
                                            <MenuItem
                                                onClick={() =>
                                                    postBringBack(uid, 'th')
                                                }>
                                                นำกลับมา
                                            </MenuItem>
                                        )}
                                        {status === 'open' && (
                                            <MenuItem
                                                onClick={() =>
                                                    handleOpenPromote()
                                                }>
                                                โปรโมทประกาศ
                                            </MenuItem>
                                        )}
                                    </Hidden>
                                    <Link
                                        to={`/edit-post/${uid}`}
                                        className={classes.link}
                                        onClick={() => resetData()}>
                                        <MenuItem>แก้ไข</MenuItem>
                                    </Link>
                                    {(status === 'open' ||
                                        status === 'promote') && (
                                        <MenuItem
                                            onClick={() =>
                                                postClose(uid, 'th')
                                            }>
                                            ขาย/เช่าแล้ว
                                        </MenuItem>
                                    )}
                                    <MenuItem
                                        onClick={() => handleOpenDialog()}>
                                        ลบ
                                    </MenuItem>
                                </Menu>
                                <DeleteDialog
                                    isOpen={dialog}
                                    handleClose={() => handleCloseDialog()}>
                                    <Box style={{ textAlign: 'center' }} mb={2}>
                                        <Typography variant="h2">
                                            ลบประกาศ
                                        </Typography>
                                    </Box>
                                    <Box style={{ textAlign: 'center' }} mb={4}>
                                        <Typography variant="body1">
                                            {`คุณต้องการลบประกาศหมายเลข ${uid} หรือไม่?`}
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            textAlign: 'center',
                                            display: 'flex',
                                        }}>
                                        <Box
                                            className={classes.cancelBtn}
                                            mr={{ xs: 1, md: 1.5 }}>
                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() =>
                                                    handleCloseDialog()
                                                }>
                                                ยกเลิก
                                            </Button>
                                        </Box>
                                        <Box
                                            className={classes.deleteBtn}
                                            ml={{ xs: 1, md: 1.5 }}>
                                            <Button
                                                variant="contained"
                                                noShadow
                                                color="primary"
                                                onClick={() => handleDelete()}>
                                                ลบ
                                            </Button>
                                        </Box>
                                    </Box>
                                </DeleteDialog>
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <Box className={classes.title}>
                            {loading ? (
                                <Typography variant="subtitle1">
                                    <Skeleton width="100%" count={1} />
                                </Typography>
                            ) : (
                                <Link to={url} className={classes.link}>
                                    <Typography variant="subtitle1">
                                        {title}
                                    </Typography>
                                </Link>
                            )}
                        </Box>
                        <Hidden smDown>
                            <Box mb={3}>
                                {loading ? (
                                    <Typography variant="body2">
                                        <Skeleton width="100%" count={1} />
                                    </Typography>
                                ) : (
                                    <Typography variant="body2">
                                        {textLocation}
                                    </Typography>
                                )}
                            </Box>
                        </Hidden>
                        <Box
                            mb={{ xs: 0, md: 5 }}
                            className={classes.mobileBottom}>
                            <Box style={{ display: 'inline-block' }} mr="34px">
                                <Price
                                    type="sell"
                                    price={sellPrice}
                                    loading={loading}
                                />
                            </Box>
                            <Box style={{ display: 'inline-block' }}>
                                <Hidden smDown>
                                    <Price
                                        type="rent"
                                        price={rentPrice}
                                        loading={loading}
                                    />
                                </Hidden>
                                {status === 'promote' && (
                                    <Hidden mdUp>
                                        <Progress
                                            currentView={view}
                                            maxView={maxView}
                                        />
                                    </Hidden>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* -----Promote----- */}
                <PromoteDialog
                    isOpen={promote}
                    list={list}
                    check={check}
                    toggleChecked={toggleChecked}
                    loading={promoteLoading}
                    handleClose={() => handleClosePromote()}
                />

                {/* ----------------- */}
                <Hidden smDown>
                    <Box className={classes.lower}>
                        {status === 'promote' ? (
                            <Progress currentView={view} maxView={maxView} />
                        ) : (
                            <Box className={classes.promoteBtn}>
                                {status === 'close' && (
                                    <Button
                                        variant="outlined"
                                        color="default"
                                        onClick={() =>
                                            postBringBack(uid, 'th')
                                        }>
                                        นำกลับมา
                                    </Button>
                                )}
                                {status === 'open' && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleOpenPromote()}>
                                        โปรโมทประกาศ
                                    </Button>
                                )}
                            </Box>
                        )}
                        {loading ? (
                            <Typography variant="body2">
                                <Skeleton width="100%" count={1} />
                            </Typography>
                        ) : (
                            <Typography
                                variant="body2"
                                style={{ color: '#c7c7c7' }}>
                                {createdAt}
                            </Typography>
                        )}
                    </Box>
                </Hidden>
            </CardContent>
        </Card>
    );
}

MyPost.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    uid: PropTypes.string,
    title: PropTypes.string,
    view: PropTypes.number,
    loading: PropTypes.bool,
    deleteTopic: PropTypes.func,
    resetData: PropTypes.func,
    postClose: PropTypes.func,
    image: PropTypes.string,
    textLocation: PropTypes.string,
    sellPrice: PropTypes.number,
    rentPrice: PropTypes.number,
    createdAt: PropTypes.string,
    status: PropTypes.string,
    maxView: PropTypes.number,
    postBringBack: PropTypes.func,
    getList: PropTypes.func.isRequired,
    toggleChecked: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    promoteLoading: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    check: PropTypes.bool.isRequired,
};

MyPost.defaultProps = {
    url: '',
    uid: '',
    title: '',
    image: '',
    textLocation: '',
    sellPrice: 0,
    rentPrice: 0,
    createdAt: '',
    status: '',
    view: 0,
    maxView: 0,
    loading: true,
    deleteTopic: () => console.log('Click'),
    resetData: () => console.log('Click'),
    postClose: () => console.log('Click'),
    postBringBack: () => console.log('Click'),
};

const mapStateToProps = state => ({
    lang: state.main.lang,
    list: state.posts.openList.list,
    promoteLoading: state.posts.openList.loading,
    check: state.posts.openList.check,
});

const mapDispatchToProps = {
    getList: getMyOpenList,
    toggleChecked: toggleCheck,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(MyPost));
