/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import algoliasearch from 'algoliasearch/lite';
import _empty from 'lodash/isEmpty';
// import _ from 'lodash';
import Cover from 'components/sections/cover/searchPosts';
import { HAds } from 'components/sections/ads';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import { Post } from 'components/roots/list';
import OrderField from 'components/roots/list/post/orderField';
import FilterBar from 'components/sections/cover/filterBar';

const styles = theme => ({
    list: {
        backgroundColor: '#f9f9f9',
    },
    card: {
        textAlign: 'center',
        minHeight: 'calc(100vh - 400px)',
        padding: '50px 0 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 619px)',
            padding: '50px 16px',
        },
    },
    button: {
        width: 384,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    createBtn: {
        maxWidth: 180,
        marginLeft: 'auto',
    },
    footer: {
        margin: '0 auto',
        width: 180,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto 28px auto',
        },
    },
});

function transformAlgolia(res) {
    return {
        ...res,
        img: res.thumbnail,
    };
}

const searchClient = algoliasearch(
    'SJS3S5FAVR',
    'fbf789ef62160863f04f7f39da3e7fe7',
);

const index = searchClient.initIndex('propertiesQuery');

function PostsAll(props) {
    const {
        classes,
        lang,
        loading,
        // loadingMore,
        // showLoadingMore,
        list,
        promoteList,
        cover,
        sortBy,
        purpose,
        // resetData,
        getList,
        getPromote,
        setSortedBy,
        // loadmore,
        // loadmoreTitle,
        getAllPageAds,
    } = props;

    const lastVisibleUid = list[list.length - 1]
        ? list[list.length - 1].uid
        : '';
    const initialArr = [...Array(10).keys()];

    const [listSearch, setListSearch] = React.useState([]);
    const [searchOptions, setSearchOptions] = React.useState({});
    const [pagination, setPagination] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [loadingMore, setLoadingMore] = React.useState(false);

    const listDisplayed = listSearch.length
        ? listSearch.map(transformAlgolia)
        : [];
        // : list;

    React.useEffect(() => {
        getList(purpose, sortBy, lang);
        getPromote(lang);
        getAllPageAds();
    }, [getList, sortBy, lang, purpose, getAllPageAds, getPromote]);

    const { nbPages } = pagination;

    const loadmore = () => {
        setLoadingMore(true);

        const { searchText, options } = searchOptions;
        index.search(searchText, {
            ...options,
            page: page + 1,
        }).then(res => {
            setListSearch([...listSearch, ...res.hits]);
            setPage(page + 1);
        }).finally(() => setLoadingMore(false));
    };

    const showLoadMore = page + 1 < nbPages;

    return (
        <>
            <TitleComponent title="ประกาศ - All About Living" />
            <Cover
                loading={loading}
                title={cover.title}
                desc={cover.desc}
                img={cover.img}
                getList={getList}
                setListSearch={setListSearch}
                setSearchOptions={setSearchOptions}
                setPagination={setPagination}
            />
            <Hidden mdUp>
                <Box zIndex={1} style={{ width: '100%' }}>
                    <FilterBar
                        getList={getList}
                        setListSearch={setListSearch}
                        setSearchOptions={setSearchOptions}
                        setPagination={setPagination}
                    />
                </Box>
            </Hidden>
            <HAds position="header" pageLoading={loading} />
            <div className={classes.list}>
                <Container>
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center">
                        <Grid item xs={12} md={8}>
                            <Box mb={2}>
                                <OrderField
                                    loading={loading}
                                    value={sortBy}
                                    changeValue={setSortedBy}
                                />
                            </Box>
                            {loading ? (
                                <>
                                    {initialArr.map(item => (
                                        <Post loading key={item} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {promoteList.map(item => (
                                        <Post
                                            loading={loading}
                                            url={`/post/${item.url}/${item.uid}`}
                                            image={item.img}
                                            title={item.title}
                                            textLocation={item.locationText}
                                            sellPrice={item.sellPrice ? item.sellPrice : 0}
                                            rentPrice={item.rentPrice ? item.rentPrice : 0}
                                            status={item.status}
                                            areaSize={item.areaSize}
                                            bedroomNum={item.bedroomNum}
                                            floor={item.floor}
                                        />
                                    ))}
                                    {_empty(listDisplayed)
                                        ? 
                                        <Box mt="40px" mb="30px">
                                            <Typography
                                                variant="body1"
                                                color="textSecondary"
                                                style={{ textAlign: 'center' }}
                                            >
                                                ไม่พบประกาศที่ค้นหา
                                            </Typography>
                                        </Box>
                                        : listDisplayed.map(item => (
                                            <Post
                                                key={item.uid}
                                                loading={loading}
                                                url={`/post/${item.url}/${item.uid}`}
                                                image={item.img}
                                                title={item.title}
                                                textLocation={item.locationText.th}
                                                sellPrice={item.sellPrice ? item.sellPrice : 0}
                                                rentPrice={item.rentPrice ? item.rentPrice : 0}
                                                status={item.status}
                                                areaSize={item.areaSize}
                                                bedroomNum={item.bedroomNum}
                                                floor={item.floor}
                                            />
                                        ))
                                    }
                                </>
                            )}
                        </Grid>
                        {loadingMore ? (
                            <>
                                {initialArr.map(item => (
                                    <Grid item xs={12} md={8} key={item}>
                                        <Post loading key={item} />
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <>
                                {!loading && showLoadMore && (
                                    <Grid
                                        item
                                        xs={12}
                                        md={8}
                                        style={{ textAlign: 'center' }}>
                                        <Box className={classes.footer}>
                                            <Button
                                                color="default"
                                                variant="outlined"
                                                onClick={() =>
                                                    loadmore(
                                                        purpose,
                                                        sortBy,
                                                        lang,
                                                        lastVisibleUid,
                                                    )
                                                }
                                                loading={loadingMore}
                                            >
                                                โหลดเพิ่มเติม
                                            </Button>
                                        </Box>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                </Container>
            </div>
            <HAds position="footer" pageLoading={loading} />
        </>
    );
}

PostsAll.propTypes = {
    lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    promoteList: PropTypes.array.isRequired,
    // resetData: PropTypes.func.isRequired,
    getList: PropTypes.func.isRequired,
    getPromote: PropTypes.func.isRequired,
    // loadingMore: PropTypes.bool.isRequired,
    // showLoadingMore: PropTypes.bool.isRequired,
    cover: PropTypes.object.isRequired,
    sortBy: PropTypes.string.isRequired,
    purpose: PropTypes.string.isRequired,
    // resetData,
    setSortedBy: PropTypes.func.isRequired,
    // loadmore: PropTypes.func.isRequired,
    getAllPageAds: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(PostsAll));
