import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

// Social Icon
import FB from './facebook.svg';
import IG from './instagram.svg';
import TW from './twitter.svg';

const styles = theme => ({
    social:{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    list: {
        paddingRight: 24,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        }
    },
    paddingList:{
        [theme.breakpoints.down('sm')]: {
            paddingRight: 38,
        }
    },
    link:{
        display: 'flex',      
        alignItems: 'center',    
        justifyContent: 'center',
    }
});

function Social(props) {
    const { classes } = props;

    return (
        <div className={classes.social}>
            <Box className={clsx(classes.list, classes.paddingList)}>
                <a className={classes.link} href="#test">
                    <img src={FB} alt="facebook" />
                </a>
            </Box>
            <Box className={clsx(classes.list, classes.paddingList)}>
                <a className={classes.link} href="#test">
                    <img src={TW} alt="twitter" />
                </a>
            </Box>
            <Box className={classes.list}>
                <a className={classes.link} href="#test">
                    <img src={IG} alt="instagram" />
                </a>
            </Box>
        </div>
    );
}

Social.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Social);