/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import firebase from 'common/firebase';
import { Link, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import _empty from 'lodash/isEmpty';
import _kebabCase from 'lodash/kebabCase';
import _uniqBy from 'lodash/unionBy';
import _trim from 'lodash/trim';
import _replace from 'lodash/replace';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from 'components/roots/button';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectSearchBox, connectHits } from 'react-instantsearch-dom';
import RoomIcon from '@material-ui/icons/Room';
import ApartmentIcon from '@material-ui/icons/Apartment';

import * as postsActions from 'actions/posts';
import PurposePost from './filter/purpose';
import TypePost from './filter/type';
import Furniture from './filter/furniture';
import BedroomNumber from './filter/bedroomNumber';
import BathroomNumber from './filter/bathroomNumber';
import PriceRange from './filter/price';
import AreaRange from './filter/area';

const functions = firebase.app().functions('asia-east2');

const styles = theme => ({
    bg: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#ffffff',
            padding: '16px 0px',
        },
    },
    searchPost: {
        backgroundColor: '#ffffff',
        boxShadow: '0 20px 20px 0 rgba(0, 32, 35, 0.05)',
        padding: 16,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            boxShadow: 'none',
        },
    },
    searchPostText: {
        marginRight: 8,
        flex: 1,
    },
    searchPostRowOne: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    searchPostRowOneInner: {
        display: 'flex',
        flexDirection: 'row',
        // [theme.breakpoints.down('sm')]: {
        //     flexDirection: 'column',
        // },
    },
    searchPostRowTwo: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    searchField: {
        marginRight: 8,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginBottom: 8,
        },
        '& input': {
            [theme.breakpoints.down('sm')]: {
                height: 48,
                boxSizing: 'border-box',
            },
        },
    },
    searchResult: {
        borderRadius: 4,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #e9e9e9',
        maxHeight: '80vh',
        overflow: 'scroll',
        padding: 0,
        minWidth: '30vw',
    },
    notFound: {
        boxShadow: 'none',
        border: 'none',
    },
    list:{
        padding: '12px 30px',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&:hover svg':{
            color: "#00abbd",
        }
    },
    lists: {
        padding: 0,
    },
    filterBtn: {
        width: '100%',
        maxWidth: 110,
        minWidth: 110,
        marginRight: 8,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    searchBtn: {
        width: '100%',
        maxWidth: 171,
        [theme.breakpoints.down('sm')]: {
            marginTop: 8,
            maxWidth: '100%',
        },
    },
});

const searchClient = algoliasearch(
    'SJS3S5FAVR',
    'fbf789ef62160863f04f7f39da3e7fe7',
);

const index = searchClient.initIndex('propertiesQuery');

function trimSearchText(t) {
    let text = _trim(t);
    text = _replace(text, 'เขต');
    text = _replace(text, 'แขวง');
    text = _replace(text, 'ถนน');
    text = _replace(text, 'ตำบล');
    text = _replace(text, 'ซอย');
    text = _replace(text, 'อำเภอ');

    return text;
}

function SearchBox({
    currentRefinement,
    refine,
    className,
    onFocus,
    onChange,
    onBlur,
    searchText,
}) {
    return (
        <TextField
            margin="none"
            fullWidth
            placeholder="ค้นหาประกาศโดยสถานที่, โครงการ, แบรนด์"
            className={className}
            type="text"
            name="title"
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            onChange={(e) => {
                refine(e.currentTarget.value);
                onChange(e.currentTarget.value);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            value={searchText || currentRefinement}
            autoComplete='off'
        />
    );
}

SearchBox.propTypes = {
    currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
    className: PropTypes.object.isRequired,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    searchText: PropTypes.string,
};

SearchBox.defaultProps = {
    onFocus: () => {},
    onChange: () => {},
    onBlur: () => {},
    searchText: '',
};

function ListItemLink(props) {
    return <ListItem button component={Link} {...props} />;
}

const Hits = ({ hits, lang, classList, geoResults, refine, setProject}) => {
    const history = useHistory();

    return (
        <>
            {
                _uniqBy(geoResults, 'mainText').map(res => {
                    return (
                        <ListItem
                            key={res.id}
                            className={classList} 
                            onClick={() => refine(res.mainText, res.geo)}
                        >
                            <ListItemIcon>
                                <RoomIcon />
                            </ListItemIcon>
                            <ListItemText primary={res.mainText} />
                        </ListItem>
                    );
                })
            }
            {
                hits.map(hit => {
                    const { nameth, nameen, uid } = hit;

                    let iconType = <ApartmentIcon />;
                    if (hit.type === 'location') {
                        iconType = <RoomIcon />;
                    }

                    return (
                        <ListItemLink 
                            key={hit.uid}
                            className={classList} 
                            onClick={() => {
                                refine(hit[`name${lang}`], {});
                                setProject(uid);
                            }} // {} = clear geo
                            // onClick={() => {
                            //     const url = `/project/${_kebabCase(nameth) || _kebabCase(nameen)}/${uid}`;
                            //     history.push(url);
                            // }}
                        >
                            <ListItemIcon>
                                {iconType}
                            </ListItemIcon>
                            <ListItemText primary={hit[`name${lang}`]} />
                        </ListItemLink>
                    );
                })
            }
        </>
    );
};

Hits.propTypes = {
    lang: PropTypes.string.isRequired,
    classList: PropTypes.string.isRequired,
    hits: PropTypes.array.isRequired,
    geoResults: PropTypes.array.isRequired,
    refine: PropTypes.func.isRequired,
    setProject: PropTypes.func.isRequired,
};

const CustomHits = connectHits(Hits);

function Search({
    refine,
    currentRefinement,
    classes,
    geoResults,
    searchText,
    setSearchText,
    setGeo,
    setProject,
}) {
    const [showResult, setShowResult] = React.useState(false);


    return (
        <>
            <ClickAwayListener onClickAway={() => setShowResult(false)}>
                <SearchBox
                    className={classes.searchField}
                    onFocus={() => setShowResult(true)}
                    onChange={(value) => {
                        setSearchText(value);
                        setGeo({});
                    }}
                    refine={refine}
                    currentRefinement={currentRefinement}
                    searchText={searchText}
                />
            </ClickAwayListener>
            {showResult && searchText && (
                <Grid
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                    }}>
                    <Grid item xs={10}>
                        <Paper className={classes.searchResult}>
                            <List component="nav" className={classes.lists}>
                                <CustomHits 
                                    lang='th'
                                    classList={classes.list} 
                                    geoResults={geoResults}
                                    refine={(input, inputGeo) => {
                                        refine(input);
                                        setSearchText(input);
                                        setShowResult(false);

                                        if (!_empty(inputGeo)) {
                                            setGeo(inputGeo);
                                        }
                                    }}
                                    setProject={setProject}
                                />
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

Search.propTypes = {
    classes: PropTypes.object.isRequired,
    refine: PropTypes.func.isRequired,
    currentRefinement: PropTypes.string.isRequired,
    geoResults: PropTypes.array.isRequired,
    searchText: PropTypes.string.isRequired,
    setSearchText: PropTypes.func.isRequired,
    setGeo: PropTypes.func.isRequired,
    setProject: PropTypes.func.isRequired,
};

const CustomSearch = connectSearchBox(Search);

const debouncedGeoSearch = _debounce((input, cb) => {
    const searchGeo = functions.httpsCallable('searchGeo');

    searchGeo({ input })
        .then(res => cb(res))
        .catch(err => console.error(err));
}, 500);

const geoAdapter = (raw) => ({
    id: raw.id,
    place_id: raw.place_id,
    mainText: raw.structured_formatting.main_text,
    secondaryText: raw.structured_formatting.secondary_text,
    terms: raw.terms,
    geo: {
        lat: raw.lat,
        lng: raw.lng,
        topRight: raw.topRight,
        bottomLeft: raw.bottomLeft,
    },
});

function countFilters({
    arrays = [],
    pairs = [],
}) {
    let count = 0;
    arrays.forEach(arr => {
        if (arr.length) count += 1;
    });
    pairs.forEach(pair => {
        if (pair[0] || pair[1]) count += 1;
    });
    return count;
}

function transformFurType(fur) {
    switch (fur) {
    case 'ตกแต่งครบ': return 'fullyFurnished';
    case 'มีบางส่วน': return 'haveSome';
    default: return null;
    }
}

function transformNum(num) {
    switch (num) {
    case 'สตูดิโอ': return 'studio';
    case '1': return 'one';
    case '2': return 'two';
    case '3': return 'three';
    case '4': return 'four';
    case '5+': return 'five';
    default: return null;
    }
}

function FilterBar(props) {
    const {
        classes,
        fullWidth,
        setListSearch,
        setSearchOptions,
        setPagination,
        searchData,
        setSearchData,
    } = props;

    const [rowTwo, setRowTwo] = React.useState(false);
    const [uidProject, setUidProject] = React.useState('');
    const {
        searchText,
        geo,
        geoResults,
        priceRange,
        bedroom,
        bathroom,
        furniture,
        areaRange,
        purpose,
        type,
        loading,
    } = searchData;

    React.useEffect(() => {
        if (searchText) {
            debouncedGeoSearch(trimSearchText(searchText), ({ data }) => {
                setSearchData('geoResults', data.predictions.map(geoAdapter));
            });
        }
    }, [searchText, setSearchData]);

    React.useEffect(() => {
        index.search('')
            .then(res => {
                setListSearch(res.hits);
                setPagination({
                    hitsPerPage: res.hitsPerPage,
                    nbHits: res.nbHits,
                    nbPages: res.nbPages,
                });
            });
    }, []); // eslint-disable-line

    const handleClick = () => {
        setRowTwo(!rowTwo);
    };

    const handleSearch = () => {
        setSearchData('loading', true);

        const options = {};
        let hasGeo = false;

        // geo
        if (geo.topRight && geo.bottomLeft) {
            hasGeo = true;
            const boundingBox = [
                geo.bottomLeft.lat, // topLeft
                geo.topRight.lng, // topLeft
                geo.topRight.lat, // bottomRight
                geo.bottomLeft.lng, // bottomRight
            ];
            options.insideBoundingBox = [boundingBox];
        } else if (geo.lat && geo.lng) {
            hasGeo = true;
            options.aroundLatLng = `${geo.lat} ${geo.lng}`;
        }

        // filters
        const filters = [];

        if (purpose) {
            if (purpose === 'sell' || purpose === 'rent') {
                filters.push(`purpose:${purpose} OR purpose:sellrent`);
            }
        }

        if (type) {
            filters.push(`typeSearch:${type}`);
        }

        if (furniture.length) {
            const temp = [];
            furniture.map(transformFurType)
                .forEach(fur => {
                    if (fur) {
                        temp.push(`furniture:${fur}`);
                    }
                });
            
            if (temp.length) filters.push(temp.join(' OR '));
        }

        if (bedroom.length) {
            const temp = [];
            bedroom.map(transformNum)
                .forEach(num => {
                    if (num) {
                        temp.push(`bedroomNum:${num}`);
                    }
                });
            
            if (temp.length) filters.push(temp.join(' OR '));
        }

        if (bathroom.length) {
            const temp = [];
            bathroom.map(transformNum)
                .forEach(num => {
                    if (num) {
                        temp.push(`toiletNum:${num}`);
                    }
                });
            
            if (temp.length) filters.push(temp.join(' OR '));
        }

        if (priceRange[0] || priceRange[1]) {
            const low = priceRange[0];
            const high = priceRange[1];
            const facetName = purpose === 'rent'
                ? 'rentPrice'
                : 'sellPrice';

            if (low && high) {
                filters.push(`${facetName}:${low} TO ${high}`);
            } else if (low) {
                filters.push(`${facetName} > ${low}`);
            } else if (high) {
                filters.push(`${facetName} < ${high}`);
            }
        }

        if (areaRange[0] || areaRange[1]) {
            const low = areaRange[0];
            const high = areaRange[1];

            if (low && high) {
                filters.push(`areaSize:${low} TO ${high}`);
            } else if (low) {
                filters.push(`areaSize > ${low}`);
            } else if (high) {
                filters.push(`areaSize < ${high}`);
            }
        }

        if (!_empty(uidProject)) {
            filters.push(`projectId:${uidProject}`);
        }

        options.filters = filters.map(str => `(${str})`).join(' AND ');

      
       
        setSearchOptions({
            options,
            searchText,
        });
        index.search(hasGeo || uidProject ? '' : searchText, options)
            .then(res => {

                setListSearch(res.hits);
                setPagination({
                    hitsPerPage: res.hitsPerPage,
                    nbHits: res.nbHits,
                    nbPages: res.nbPages,
                });
            })
            .finally(() => setSearchData('loading', false));
    };

    const content = (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start">
            <Grid item xs={12}>
                <div className={classes.searchPost}>
                    <Box className={classes.searchPostRowOne}>
                        <Box className={classes.searchPostText}>
                            <InstantSearch
                                searchClient={searchClient}
                                indexName="propertiesSearch"
                            >
                                <CustomSearch
                                    searchText={searchText}
                                    setSearchText={(value) => {
                                        setSearchData('searchText', value);
                                        setUidProject('');
                                    }}
                                    classes={classes}
                                    geoResults={geoResults}
                                    setGeo={(value) => {
                                        setSearchData('geo', value);
                                    }}
                                    setProject={setUidProject}
                                />
                            </InstantSearch>
                        </Box>
                        <Box className={classes.searchPostRowOneInner}>
                            <PurposePost
                                loading={false}
                                value={purpose}
                                // oldData={data}
                                // changeValue={changeType}
                                changeValue={(value) => setSearchData('purpose', value)}
                            />
                            <TypePost
                                loading={false}
                                value={type}
                                // oldData={data}
                                changeValue={value => {
                                    setSearchData('type', value);
                                }}
                            />
                            <Box className={classes.filterBtn}>
                                <Button
                                    variant="outlined"
                                    // color="default"
                                    color="primary"
                                    // disabled={loading}
                                    onClick={() => handleClick()}>
                                    ตัวกรอง: {countFilters({
                                        arrays: [bedroom, bathroom, furniture],
                                        pairs: [priceRange, areaRange],
                                    })}
                                </Button>
                            </Box>
                        </Box>
                        <Hidden smDown>
                            <Box className={classes.searchBtn}>
                                <Button
                                    variant="contained"
                                    noShadow
                                    color="primary"
                                    onClick={() => handleSearch()}
                                    loading={loading}
                                    disabled={!uidProject}
                                >
                                    ค้นหาประกาศ
                                </Button>
                            </Box>
                        </Hidden>
                    </Box>
                    <Collapse in={rowTwo}>
                        <Box
                            mt={{ xs: 1, md: 2 }}
                            className={classes.searchPostRowTwo}>
                            <PriceRange
                                value={priceRange}
                                loading={false}
                                label={
                                    (priceRange[0] || priceRange[1])
                                    && `${priceRange[0]} - ${priceRange[1]} บาท`
                                }
                                changeValue={value => setSearchData('priceRange', value)}
                                purpose={purpose}
                            />
                            <Hidden smDown>
                                <BedroomNumber
                                    value={bedroom}
                                    loading={false}
                                    changeValue={value => setSearchData('bedroom', value)}
                                />
                                <BathroomNumber
                                    value={bathroom}
                                    loading={false}
                                    changeValue={value => setSearchData('bathroom', value)}
                                />
                                <Furniture
                                    value={furniture}
                                    loading={false}
                                    changeValue={value => setSearchData('furniture', value)}
                                />
                            </Hidden>
                            <Hidden mdUp>
                                <Box
                                    className={
                                        classes.searchPostRowOneInner
                                    }>
                                    <BedroomNumber
                                        value={bedroom}
                                        loading={false}
                                        changeValue={value => setSearchData('bedroom', value)}
                                    />
                                    <BathroomNumber
                                        value={bathroom}
                                        loading={false}
                                        changeValue={value => setSearchData('bathroom', value)}
                                    />
                                    <Furniture
                                        value={furniture}
                                        loading={false}
                                        changeValue={value => setSearchData('furniture', value)}
                                    />
                                </Box>
                            </Hidden>
                            <AreaRange
                                value={areaRange}
                                loading={false}
                                label={
                                    (areaRange[0] || areaRange[1])
                                    && `${areaRange[0]} - ${areaRange[1]} ตรม`
                                }
                                changeValue={value => setSearchData('areaRange', value)}
                            />
                        </Box>
                    </Collapse>
                    <Hidden mdUp>
                        <Box className={classes.searchBtn}>
                            <Button
                                variant="contained"
                                noShadow
                                color="primary"
                                onClick={() => handleSearch()}
                                loading={loading}
                                disabled={!uidProject}
                            >
                                ค้นหาประกาศ
                            </Button>
                        </Box>
                    </Hidden>
                </div>
            </Grid>
        </Grid>
    );

    return (
        <div className={classes.bg}>
            {fullWidth
                ? content
                : (
                    <Container>
                        {content}
                    </Container>
                )
            }
        </div>
    );
}

FilterBar.propTypes = {
    classes: PropTypes.object.isRequired,
    fullWidth: PropTypes.bool,
    // getList: PropTypes.func,
    setListSearch: PropTypes.func,
    setSearchOptions: PropTypes.func,
    setPagination: PropTypes.func,
    searchData: PropTypes.object.isRequired,
    setSearchData: PropTypes.func,
};

FilterBar.defaultProps = {
    fullWidth: false,
    // getList: () => {},
    setListSearch: () => {},
    setSearchOptions: () => {},
    setPagination: () => {},
    setSearchData: () => {},
};

const mapStateToProps = (state) => {
    return {
        searchData: state.posts.searchData,
    };
};

const mapDispatchToProps = {
    setSearchData: postsActions.setSearchData
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FilterBar));
