/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    root: {
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            '& .MuiDialog-paper': {
                margin: 16,
            },
            '& .MuiDialog-paperScrollPaper':{
                maxHeight: '100%',
                width: '100%',
            }
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#fff',
        padding: 7,
    },
});

const DialogContent = withStyles((theme) => ({
    root: {
        width: 588,
        padding: '36px 100px !important',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            padding: '40px 24px 16px 24px !important',
        },
    },
}))(MuiDialogContent);

function MyDialog(props) {
    const {
        classes,
        children,
        isOpen,
        handleClose,
    } = props;

    return (
        <Dialog 
            onClose={handleClose}
            open={isOpen}
            className={classes.root} 
        >
            <DialogContent>
                <IconButton 
                    aria-label="close" 
                    className={classes.closeButton} 
                    onClick={handleClose}
                >
                    <CloseIcon  
                        style={{ color: "#666666" }}
                    />
                </IconButton>
                {children}
            </DialogContent>
        </Dialog>
    );
}

MyDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(MyDialog);