/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { 
    withRouter, 
    Link, 
    Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/styles';
import _ from 'lodash';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import TagSearch from 'components/roots/tagSearch';
import MyDialog from 'components/roots/dialog/delete';

const styles = (theme) => ({
    link:{
        textDecoration: 'none',
        color: 'inherit',
    },
    select:{
        marginRight: 8,
    },
    deleteBtn:{
        maxWidth: 180,
        marginLeft: 'auto',
    },
    cancelBtn: {
        width: 180,
        marginLeft: 'auto',
    },
    submitBtn: {
        width: 180,
        marginLeft: 24,
    },
    leftBottom:{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    rightBottom:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    desc:{
        position: 'relative',
        color: "#2e2e2e",
        width: '100%',
        wordWrap: 'break-word',
        margin: 0,
        '& .MuiInputBase-root': {
            margin: 0,
            fontFamily: 'Sarabun',
            fontSize: 18,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                lineHeight: 1.75,
            },
        }
    }
});

function CreateTopic(props) {
    const {
        classes,
        location,
        loading,
        data,
        errors,
        touched,
        isValid,
        successMessage,

        // function
        update,
        changeTag,
        removeTag,
        resetData,
        postCreate,
        getEdit,
        postEdit,
        postDelete,
    } = props;

    const { pathname } = location;
    
    const isCreatePage = pathname === '/create-topic'; 
    const title = isCreatePage ? "สร้างกระทู้" : "แก้ไขกระทู้";
    const topicId = !isCreatePage ? pathname.split("/")[2] : '';

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return true;
        }
        return false;
    };

    const handleSubmit = () => {
        if (isCreatePage){
            postCreate(data);
        }else{
            postEdit(topicId,data);
        }
    };

    // Dialog
    const [dialog, setDialog] = React.useState(false);
    const handleOpenDialog = () => {
        setDialog(true);
    };
    const handleCloseDialog = () => {
        setDialog(false);
    };

    React.useEffect(() => {
        if (!isCreatePage) {
            getEdit(topicId);
        }
    }, [getEdit, isCreatePage, topicId]);

    if (successMessage) {
        return <Redirect to="/my-topics" />;
    }

    return (
        <>
            <TitleComponent title={`${title} - All About Living`} />
            <Container>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Box mb={{ xs: 2, md: 3 }} mt={{ xs: 2, md:3 }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to="/my-topics" className={classes.link}>
                                    <Typography variant="button" color="textSecondary">กระทู้ของฉัน</Typography>
                                </Link>
                                <Typography variant="button" color="textPrimary" style={{fontWeight: 600}}>{title}</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                >
                    <Grid item xs={6}>
                        <Box mb={{ xs: 2, md: 3 }}>
                            <Typography variant="h2" color="textPrimary">{title}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {!isCreatePage && 
                            <Box mb={{ xs: 2, md: 3 }} className={classes.deleteBtn}>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    disabled={loading}
                                    onClick={() => handleOpenDialog()}
                                >
                                    ลบกระทู้
                                </Button>
                                <MyDialog
                                    isOpen={dialog}
                                    handleClose={() => handleCloseDialog()}
                                >
                                    <Box style={{ textAlign: 'center' }} mb={2}>
                                        <Typography variant="h2" >
                                            ลบกระทู้
                                        </Typography>
                                    </Box>
                                    <Box style={{ textAlign: 'center' }} mb={4}>
                                        <Typography variant="body1" >
                                            คุณต้องการลบกระทู้นี้หรือไม่?
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            textAlign: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <Box style={{ width: '50%' }} mr={{ xs: 1, md: 1.5 }}>
                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() => handleCloseDialog()}
                                            >
                                                ยกเลิก
                                            </Button>
                                        </Box>
                                        <Box style={{ width: '50%' }} ml={{ xs: 1, md: 1.5 }}>
                                            <Button
                                                variant="contained"
                                                noShadow
                                                color="primary"
                                                onClick={() => postDelete(topicId)}
                                            >
                                                ลบ
                                            </Button>
                                        </Box>
                                    </Box>
                                </MyDialog>
                            </Box>
                        }
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Box mb={{ xs: 2, md: 3 }}>
                            <TextField
                                error={hasError('title')}
                                helperText={
                                    hasError('title') ? errors.title[0] : null
                                }
                                disabled={loading}
                                fullWidth
                                label="หัวข้อ"
                                className={classes.textField}
                                type="text"
                                name="title"
                                variant="outlined"
                                onChange={(event) => update(data, event.target.name, event.target.value)}
                                value={data.title}
                            />
                        </Box>
                        <Box mb={{ xs: 0, md: 1 }}>
                            <TextField
                                error={hasError('desc')}
                                helperText={
                                    hasError('desc') ? errors.desc[0] : null
                                }
                                disabled={loading}
                                fullWidth
                                multiline
                                rows={5}
                                rowsMax={50}
                                label="รายละเอียด"
                                className={clsx(classes.textField, classes.desc)}
                                type="text"
                                name="desc"
                                variant="outlined"
                                onChange={(event) => update(data, event.target.name, event.target.value)}
                                value={data.desc}
                            />
                        </Box>
                        <Box mb={{ xs: 3, md: 4 }}>
                            <TagSearch 
                                loading={loading}
                                oldData={data}
                                // list={data.tagId}
                                selectedValue={data.tagId}
                                update={(tags) => changeTag(tags, data)}
                                remove={(index) => removeTag(index, data)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12} md={6}>
                        <Box className={classes.leftBottom} mb={{ xs: 3, md: 0 }}>
                            <FormControlLabel
                                className={classes.select}
                                control={
                                    <Checkbox
                                        checked={data.accept}
                                        onChange={()=> update(data,'accept', !data.accept)}
                                        color="primary"
                                        disabled={loading}
                                    />
                                }
                                label="ยอมรับ"
                            />
                            <Link to="/termAndCondition" className={classes.link}>
                                <Typography variant="subtitle2" color="primary">ข้อกำหนดและเงื่อนไขการตั้งกระทู้</Typography>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box 
                            className={classes.rightBottom} 
                            mb={{ xs: 1, md: 0 }} 
                            mt={{ xs: 1, md: 0 }}
                        >
                            <Box className={classes.cancelBtn}>
                                <Link to="/my-topics" className={classes.link}>
                                    <Button 
                                        variant="outlined" 
                                        color="default"
                                        disabled={loading}
                                        onClick={() => resetData()} 
                                    >
                                        ยกเลิก
                                    </Button>
                                </Link>
                            </Box>
                            <Box className={classes.submitBtn}>
                                <Button 
                                    variant="contained" 
                                    noShadow 
                                    color="primary"
                                    onClick={()=> handleSubmit()}
                                    disabled={
                                        loading 
                                        || 
                                        !data.accept 
                                        ||  
                                        !isValid
                                    }
                                >
                                    บันทึก
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box mb={{ xs:0, md:5 }}/>
        </>
    );
}

CreateTopic.propTypes = {
    successMessage: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    changeTag: PropTypes.func.isRequired,
    removeTag: PropTypes.func.isRequired,
    resetData: PropTypes.func.isRequired,
    postCreate: PropTypes.func.isRequired,
    getEdit: PropTypes.func.isRequired,
    postEdit: PropTypes.func.isRequired,
    postDelete: PropTypes.func.isRequired,
};

CreateTopic.defaultProps = {
    errors: {},
};

export default withStyles(styles)(withRouter(CreateTopic));
