import {
    UPDATE_PACKAGE,
    RESET_PACKAGE,
    FETCH_PACKAGE,
    FETCH_PACKAGE_DATA_DONE,
    FETCH_PACKAGE_DONE,
    FETCH_PACKAGE_FAIL,
    FETCH_PACKAGE_LIST_DONE,
    TOGGLE_CHECKED_PACKAGE,
    UPDATE_PACKAGE_CHECK,
} from 'actions/packages';

const initialState = {
    loading: false,
    list: [],
    packages: [],
    check: [],
    data: {
        creditcardNumber: '',
        name: '',
        expire: '',
        cvv: '',
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_PACKAGE:
        return {
            ...state,
            data: {
                ...state.data,
                [action.fieldName]: action.newValue,
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    case RESET_PACKAGE:
        return {
            ...state,
            loading: false,
            list: [],
            packages: [],
            check: [],
            data: {
                creditcardNumber: '',
                name: '',
                expire: '',
                cvv: '',
            },
            errors: {},
            isValid: false,
            successMessage: '',
            failMessage: '',
        };
    case FETCH_PACKAGE:
        return {
            ...state,
            loading: true,
            successMessage: '',
        };
    case FETCH_PACKAGE_DATA_DONE:
        return {
            ...state,
            loading: false,
            successMessage: 'Paid successfully',
        };
    case FETCH_PACKAGE_DONE:
        return {
            ...state,
            loading: false,
            successMessage: action.message,
        };
    case FETCH_PACKAGE_FAIL:
        return {
            ...state,
            loading: false,
            failMessage: action.message,
        };
    case FETCH_PACKAGE_LIST_DONE: {
        return {
            ...state,
            loading: false,
            list: action.list,
            packages: action.packages,
            check: action.check,
        };
    }
    case TOGGLE_CHECKED_PACKAGE: {
        const newCheck = [...state.check];
        newCheck[action.index] = action.number;
        return {
            ...state,
            check: newCheck,
        };
    }
    case UPDATE_PACKAGE_CHECK: {
        const newCheck = [...state.check];
        action.indexArr.forEach(number => {
            newCheck[number] = 1;
        });
        return {
            ...state,
            check: newCheck,
        };
    }
    default:
        return state;
    }
};
export default projectsReducer;
