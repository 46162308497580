/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Skeleton from 'react-loading-skeleton';
import parse from 'html-react-parser';
import TopicAvatar from 'components/roots/avatar/topic';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
        marginBottom: 12,
    },
    card:{
        borderRadius: 8,
        border: 'solid 1px #c7c7c7',
        backgroundColor: '#fff',
        boxShadow: 'none',
        padding: 24,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        },
    },
    markdownBox:{
        lineHeight: 1.8,
    },
    markdownContent:{
        position: 'relative',
        color: "#2e2e2e",
        width: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line',
        margin: 0,
        fontFamily: 'Sarabun',
        fontSize: 18,
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.67,
        letterSpacing: 'normal',
        [theme.breakpoints.down('sm')]:{
            fontSize: 16,
            lineHeight: 1.75,
        },
    },
    writer:{
        width: '100%',
    },
});

function TopicData(props) {
    const { 
        classes,
        markdown,
        loading, 
        writer,
        createdAt,
    } = props;

    return (
        <div className={classes.background}>
            <Container>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    
                    <Grid item xs={12} md={10}>
                        <Card className={classes.card}>
                            <Box className={classes.writer} mb={3}>
                                {
                                    loading ?
                                        <Skeleton width="180px" height={50} />
                                        :
                                        <TopicAvatar
                                            img={writer.img}
                                            name={writer.name}
                                            desc={createdAt}
                                        />
                                }
                            </Box>
                            <Box className={classes.markdownBox}>
                                {
                                    loading ?
                                        <Skeleton width="100%" count={3} /> :
                                        <div className={classes.markdownContent}>
                                            {parse(markdown)}
                                        </div>
                                }
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

TopicData.propTypes = {
    classes: PropTypes.object.isRequired, 
    markdown: PropTypes.string.isRequired, 
    loading: PropTypes.bool.isRequired, 
    writer: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired, 
};

export default withStyles(styles)(TopicData);