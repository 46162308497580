/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { Topic } from '../../roots/list';
import { Button } from '../../roots/button';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
    },
    footer: {
        margin: '36px auto 0 auto',
        width: 180,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '16px auto 28px auto',
        },
    },
});

function TopicsList(props) {
    const { 
        classes, 
        loading,
        loadingMore,
        showLoadingMore,
        list, 
        loadmoreTitle,
        loadmoreFunc,
    } = props;


    const initialArr = [...Array(10).keys()];

    return (
        <div className={classes.background}>
            {loading ?
                <>
                    {initialArr.map((item) =>
                        <Topic loading={loading} key={item} />
                    )}
                </>
                :
                <>
                    {list.map((item) =>
                        <Topic
                            key={item.uid}
                            loading={loading}
                            url={`/topic/${item.url}/${item.uid}`}
                            title={item.title}
                            desc={item.desc}
                            date={item.date}
                            view={item.view}
                            comment={item.comment}
                            createdBy={item.createdBy}
                            isPin={item.isPin}
                        />
                    )}
                </>
            }
            {loadingMore ?
                <>
                    {initialArr.map((item) =>
                        <Topic loading={loadingMore} key={item} />
                    )}
                </>
                :
                <>
                    {(!loading && showLoadingMore) && 
                        <Box className={classes.footer}>
                            <Button
                                color="default"
                                variant="outlined"
                                onClick={() => loadmoreFunc()}
                            >
                                {loadmoreTitle}
                            </Button>
                        </Box>
                    }
                </>
            }
        </div>
    );
}

TopicsList.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    loadingMore: PropTypes.bool.isRequired,
    loadmoreTitle: PropTypes.string.isRequired,
    showLoadingMore: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    loadmoreFunc: PropTypes.func.isRequired,
};

TopicsList.defaultProps = {
    loading: true,
};

export default withStyles(styles)(TopicsList);