import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

const styles = () => ({
    label: {
        color: '#666666',
    },
    truncatePriceText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
});

function CardPaper(props) {
    const {
        classes,
        type,
        price,
        page,
        loading,
        // lang,
    } = props;

    let text = 'ราคาเช่า';
    if (type === 'sell') {
        text = 'ราคาซื้อ';
    }
    if (type === 'sellperarea') {
        text = 'ราคาซื้อต่อ ตรม.';
    }
    if (type === 'rentperarea') {
        text = 'ราคาเช่าต่อ ตรม.';
    }

    return (
        <Box>
            {loading ? (
                <Typography variant="body2">
                    <Skeleton width="50px" count={1} />
                </Typography>
            ) : (
                <>
                    {page === 'post' ? (
                        <Typography variant="body2" className={classes.label}>
                            {text}
                        </Typography>
                    ) : (
                        <Hidden smDown>
                            <Typography
                                variant="body2"
                                className={classes.label}>
                                {text}
                            </Typography>
                        </Hidden>
                    )}
                </>
            )}
            {loading ? (
                <Typography variant="h5">
                    <Skeleton width="100px" count={1} />
                </Typography>
            ) : (
                <Typography variant="h5" className={classes.truncatePriceText}>
                    ฿ {price > 0 ? price.toLocaleString() : '-'}
                </Typography>
            )}
        </Box>
    );
}

CardPaper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    // lang: PropTypes.string,
    type: PropTypes.string,
    page: PropTypes.string,
    price: PropTypes.number,
    loading: PropTypes.bool.isRequired,
};

CardPaper.defaultProps = {
    page: 'myPost',
    type: 'sell',
    price: 0,
    // lang: 'th',
};

export default withStyles(styles)(CardPaper);
