import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from 'react-loading-skeleton';

import { TagNew } from '../element';

const styles = theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 8,
        boxShadow: '12px 12px 40px 0 rgba(0, 32, 35, 0.1), 12px 12px 40px 0 rgba(0, 32, 35, 0.1)',
        // [theme.breakpoints.down('sm')]: {
        //     borderRadius: 0,
        // }
    },
    media: {
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
        height: 518,
        borderRadius: 8,
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        boxShadow: '12px 12px 40px 0 rgba(0, 32, 35, 0.1)',
        '&:before':{
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'block',
            zIndex: 0,
            backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))',
        },
        [theme.breakpoints.down('sm')]: {
            height: 328,
            padding: theme.spacing(2),
        }
    },
    link:{
        textDecoration: 'none',
        color: 'inherit',
        width: '100%',
    }
});

function CardImage(props) {
    const { 
        classes, 
        url, 
        tag, 
        img, 
        title, 
        desc, 
        loading 
    } = props;
    return (
        <Card className={classes.card}>
            {
                !loading ? 
                
                    <Link to={url} className={classes.link}>
                        <CardMedia image={img} className={classes.media}>
                            <Box zIndex={1}>
                                {tag !== '' && <TagNew text={tag} />}
                            </Box>
                            <Box mt="8px" zIndex={1}>
                                <Typography variant="h3" style={{color: '#fff'}} >
                                    {title}
                                </Typography>
                            </Box>
                            <Hidden smDown>
                                <Box mt={1} zIndex={1}>
                                    <Typography variant="body2" style={{color: '#fff'}} >
                                        {desc}
                                    </Typography>
                                </Box>
                            </Hidden>
                        </CardMedia>
                    </Link>
                    :
                    <div className={classes.media}>
                        <Box zIndex={1}>
                            <Skeleton width={70} />
                        </Box>
                        <Box mt="8px" zIndex={1} width="100%">
                            <Typography variant="h3" style={{ color: '#fff' }} >
                                <Skeleton width="100%" count={1} />
                            </Typography>
                        </Box>
                        <Hidden smDown>
                            <Box mt={1} zIndex={1} width="100%">
                                <Typography variant="body2" style={{ color: '#fff' }} >
                                    <Skeleton width="100%" count={2} />
                                </Typography>
                            </Box>
                        </Hidden>
                    </div>
            
            }
        </Card>
    );
}

CardImage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    tag: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    loading: PropTypes.bool,
};

CardImage.defaultProps = {
    url: '',
    tag: '',
    img: '',
    title: '',
    desc: '',
    loading: true,
};

export default withStyles(styles)(CardImage);