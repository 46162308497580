import { connect } from 'react-redux';
// import { postSignOut } from 'actions/auth';
import { 
    hideSnack,
    updateSearch,
    searchNumber, 
} from 'actions/main';
import MainLayout from 'layouts/Main';

const mapStateToProps = state => ({
    authLevel: state.auth.authLevel,
    profile: state.auth.profile,
    snackIsShow: state.main.isShow,
    snackMessage: state.main.message,
    snackStatus: state.main.status,
    isSearching: state.main.isSearching,
    searchLength: state.main.searchLength,
});

const mapDispatchToProps = {
    // postSignOut,
    hideSnack,
    updateSearch,
    searchNumber,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainLayout);

