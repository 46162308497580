import { combineReducers } from 'redux';
import main from './main';
import auth from './auth';
import resetPassword from './resetPassword';
import home from './home';
import latest from './latest';
import tag from './tag';
import location from './location';
import article from './article';
import category from './category';
import subCategory from './subCategory';
import ads from './ads';
import termAndCondition from './termAndCondition';
import myAccount from './myAccount';
import topics from './topics';
import inbox from './inbox';
import posts from './posts';
import project from './project';
import packages from './packages';
import postsQuery from './postsQuery';

export default combineReducers({
    main,
    auth,
    resetPassword,
    home,
    packages,
    posts,
    postsQuery,
    latest,
    article,
    tag,
    category,
    subCategory,
    ads,
    location,
    termAndCondition,
    myAccount,
    topics,
    inbox,
    project,
});
