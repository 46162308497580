import React from 'react';
import PropTypes from 'prop-types';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
    card: {
        cursor: 'pointer',
    },
    cardLast: {
        height: 134,
        [theme.breakpoints.down('sm')]: {
            height: 67,
        },
    },
    inner:{
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        [theme.breakpoints.down('sm')]: {
            padding: 8,
            boxSizing: 'border-box',
        },
    }
});

function Cover(props) {
    const {
        classes,
        height,
        isLast,
        onClick,

        // coverText,
        // subCategory,
        // img,
        // loading,
    } = props;

    return (
        <>
            {!isLast ?
                <CardMedia 
                    className={classes.card}
                    component="img"
                    image="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/projects%2FBalmoral%20Hero%208-5.jpg?alt=media&token=a67a9d05-7836-42a6-979c-9e88ac027203"
                    height={height}
                    onClick={onClick}
                />
                :
                <CardMedia
                    className={classes.cardLast}
                    image="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/projects%2FBalmoral%20Hero%208-5.jpg?alt=media&token=a67a9d05-7836-42a6-979c-9e88ac027203"
                    height={height}
                    onClick={onClick}
                >
                    {isLast &&
                        <Box className={classes.inner}>
                            <Typography variant="button" style={{color: '#fff'}}>
                                ดูรูปภาพเพิ่มเติม
                            </Typography>
                        </Box>
                    }
                </CardMedia>
            }
        </>
    );
}

Cover.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    height: PropTypes.string.isRequired,
    isLast: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    // coverText: PropTypes.string.isRequired,
    // subCategory: PropTypes.string.isRequired,
    // img: PropTypes.string.isRequired,
    // loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Cover);