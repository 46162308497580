/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    setFilter,
    clearFilter,
} from 'actions/article';
import { Filter, Button } from '../../roots/button';
import CloseBar from './closeBar';

const styles = (theme) => ({
    filter:{
        position: 'absolute',
        paddingBottom: 25,
    },
    drawer:{
        '& .MuiDrawer-paperAnchorRight':{
            [theme.breakpoints.down('sm')]: {
                left: 0,
            },
        }
    },
    innerDrawer:{
        width: 360,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    checkList:{
        padding: theme.spacing(3),
    },
    buttons:{
        padding: theme.spacing(2),
        position: 'absolute',
        width: '100%',
        boxSizing: 'border-box',
        bottom: 0,
    },
    check:{
        marginBottom: 8,
    }
});

function FilterDrawer(props) {
    const { 
        classes, 
        container,
        filter, 
        setMapFilter,
        clearMapFilter,
    } = props;
    
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, right: open });
    };

    const handleCheck = name => event => {
        setMapFilter(name, event.target.checked);
    };

    const liveNum = filter.live ? 1 : 0;
    const financeNum = filter.finance ? 1 : 0;
    const lifestyleNum = filter.lifestyle ? 1 : 0;
    const inspirationNum = filter.inspiration ? 1 : 0;
    const number = liveNum + financeNum + lifestyleNum + inspirationNum;

    return (
        <Box className={classes.filter}>
            <Filter number={number} onClick={toggleDrawer(true)}>Filter</Filter>
            {/* Filter Drawer */}
            <Drawer
                className={classes.drawer}
                anchor="right"
                open={state.right}
                onClose={toggleDrawer(false)}
                variant="temporary"
                container={document.getElementById(container)}
                PaperProps={{ style: { position: 'absolute', zIndex: 1 } }}
                BackdropProps={{ style: { position: 'absolute', zIndex: 1 } }}
                ModalProps={{ style: { position: 'absolute', zIndex: 1 } }}
                transitionDuration={500}
                closeAfterTransition
                disableEnforceFocus
                disableAutoFocus
                disableRestoreFocus
            >
                <div className={classes.innerDrawer}>
                    <CloseBar title="ตัวกรองสถานที่" onClick={toggleDrawer(false)} />
                    <Box className={classes.checkList}>
                        <Box className={classes.check}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.finance}
                                        onChange={handleCheck('finance')}
                                        value='finance'
                                        color="primary"
                                    />
                                }
                                label="Finance"
                            />
                        </Box>
                        <Box className={classes.check}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.inspiration}
                                        onChange={handleCheck('inspiration')}
                                        value="inspiration"
                                        color="primary"
                                    />
                                }
                                label="Inspriration"
                            />
                        </Box>
                        <Box className={classes.check}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.lifestyle}
                                        onChange={handleCheck('lifestyle')}
                                        value="lifestyle"
                                        color="primary"
                                    />
                                }
                                label="Lifestyle"
                            />
                        </Box>
                        <Box className={classes.check}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.live}
                                        onChange={handleCheck('live')}
                                        value="live"
                                        color="primary"
                                    />
                                }
                                label="Live"
                            />
                        </Box>
                    </Box>
                    <Box className={classes.buttons}>
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={6}>
                                <Button color="default" variant="outlined" onClick={() => clearMapFilter()}>
                                    เคลียร์
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button color="primary" variant="contained" noShadow onClick={toggleDrawer(false)}>
                                    เลือก
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Drawer>
        </Box>                        
    );
}

FilterDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    container: PropTypes.string.isRequired,
    setMapFilter: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired, 
    clearMapFilter: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    filter: state.article.filter,
});

const mapDispatchToProps = {
    setMapFilter: setFilter,
    clearMapFilter: clearFilter,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(FilterDrawer));