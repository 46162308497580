import { connect } from 'react-redux';
import { 
    update,
    changeTag,
    removeTag,
    resetData,
    postCreate,
    getEdit,
    postEdit,
    postDelete, 
} from 'actions/topics';
import CreateTopic from 'views/topic/create';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.topics.loading,
    data: state.topics.data,
    errors: state.topics.errors,
    touched: state.topics.touched,
    isValid: state.topics.isValid,
    successMessage: state.topics.successMessage,
});

const mapDispatchToProps = {
    update,
    changeTag,
    resetData,
    postCreate,
    removeTag,
    getEdit,
    postEdit,
    postDelete,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTopic);



