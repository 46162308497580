import React from 'react';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import clsx from 'clsx';

// share icon
import Facebook from './facebook.svg';
import Twitter from './twitter.svg';
import LinkIcon from './link.svg';

const styles = () => ({
    outer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        visibility: 'visible',
        opacity: 1,
        transition: 'visibility 0.3s linear,opacity 0.3s linear',
    },
    hideOuter: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0.3s linear,opacity 0.3s linear',
    },
    ul: {
        position: 'absolute',
        marginLeft: 90,
        // opacity: 0,
        transition: 'all 0.3s linear',
    },
    ulFixed: {
        position: 'fixed',
        // opacity: 1,
        transition: 'all 0.3s linear',
        top: 150,
        marginLeft: 90,
    },
    list:{
        justifyContent: 'center',
    },
    img:{
        height: 24,
    },
    isLoading: {
        opacity: 0.3,
    },
});

function Share(props) {
    const { location, classes, hidden } = props;

    const [state, setState] = React.useState({
        copied: false,
        active: false,
        sharePosition: 0,
        fixedActive: false,
    });

    const { pathname } = location;
    const thisPageUrl = 'https://www.allaboutliving.co'.concat(pathname);

    const copiedFunc = () => {
        setState({
            ...state,
            copied: true
        });
        setTimeout(() => {
            setState({
                ...state,
                copied: false
            });
        }, 500);
    };

    // Setup initial position
    const setInitialPosition = (element) => {
        if (!state.active && element){    
            setState({
                ...state,
                sharePosition: element.getBoundingClientRect().top,
                active: true,
            });
        }
    };

    // Scroll
    function handleScroll() {
        const { sharePosition } = state;
        const currentScrollPos = window.pageYOffset;
        setState({
            ...state,
            fixedActive: currentScrollPos + 150 - sharePosition > 0,
        });
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    return (
        <div className={clsx(classes.outer, hidden && classes.hideOuter)}>
            <List 
                ref={el => setInitialPosition(el)}
                className={clsx(!state.fixedActive ? classes.ul : classes.ulFixed)} 
                disablePadding
            >
                <ListItem className={classes.list} disableGutters>
                    <FacebookShareButton url={thisPageUrl}>
                        <IconButton>
                            <img className={classes.img} src={Facebook} alt="share to facebook" />
                        </IconButton>
                    </FacebookShareButton>
                </ListItem>
                <ListItem className={classes.list} disableGutters>
                    <TwitterShareButton url={thisPageUrl}>
                        <IconButton>
                            <img className={classes.img} src={Twitter} alt="share to twitter" />
                        </IconButton>
                    </TwitterShareButton>
                </ListItem>
                <ListItem className={classes.list} disableGutters>
                    <CopyToClipboard
                        text={thisPageUrl}
                        onCopy={copiedFunc}
                    >
                        <IconButton disabled={state.copied}>
                            <img 
                                className={clsx(classes.img, state.copied && classes.isLoading)}
                                src={LinkIcon} 
                                alt="copy link" 
                            />
                        </IconButton>
                    </CopyToClipboard>
                </ListItem>
            </List>
        </div>
    );
}

Share.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    hidden: PropTypes.bool
};

Share.defaultProps = {
    location: PropTypes.shape({
        pathname: '/',
    }),
    hidden: false,
};

export default withRouter(withStyles(styles)(Share));