import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    OutlinedInput,
    MenuItem,
    Select,
} from '@material-ui/core';
import Types from 'common/property/postOrder';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#ffffff',
        width: '100%',
        maxWidth: 300,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
}));

const CategoryCom = props => {
    const classes = useStyles();
    const { value, loading, changeValue } = props;

    const handleChange = event => {
        changeValue(event.target.value);
    };

    return (
        <Select
            className={classes.root}
            disabled={loading}
            value={value}
            onChange={handleChange}
            defaultValue="none"
            input={
                <OutlinedInput
                    name="orderBy"
                    id="orderBy"
                />
            }>
            {Types.type.map(item => (
                <MenuItem key={item.uid} value={item.uid}>
                    {item.name}
                </MenuItem>
            ))}
        </Select>
    );
};

CategoryCom.propTypes = {
    value: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    changeValue: PropTypes.func.isRequired,
};

export default CategoryCom;
