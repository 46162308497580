/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import UploadAvatar from 'components/roots/avatar/upload';
import HeaderTabs from './header';

const styles = (theme) => ({
    card: {
        textAlign: 'center',
        padding: '60px 0 60px 0',
        boxSizing: 'border-box',
        maxWidth: 384,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0',
        },
    },
});

function MyAccount(props) {
    const {
        classes,
        // lang,
        isValid,
        loading,
        profile,
        errors,
        touched,
        updateProfile,
        getData,
        postProfile,
        postImage,
    } = props;

    const hasError = field => {
        if (_.has(errors, field) && _.has(touched, field)) {
            return true;
        }
        return false;
    };

    const handleChange = (event) => {
        event.persist();
        updateProfile(profile, event.target.name, event.target.value);
    };

    const handleSubmit = () => {
        postProfile(profile);
    };

    React.useEffect(() => {
        getData();
    }, [getData]);

    return (
        <>
            <TitleComponent title="My Account - Profile" />
            <Container>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Box mt={3} mb={{xs: 2, md:3 }}>
                            <Typography variant="h2" color="textPrimary">
                                ข้อมูลส่วนตัว
                            </Typography>
                        </Box>
                        <Box>
                            <HeaderTabs value={0} />

                            {/* Profile */}
                            <Box className={classes.card}>
                                <Box mb={{ xs: 7, md: 6 }} style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <UploadAvatar 
                                        name={profile.name}
                                        img={profile.photoURL}
                                        fieldName="photoURL"
                                        loading={loading}
                                        uploadFunc={postImage}
                                        oldData={profile}
                                    />
                                </Box>
                                <Box mb={{ xs: 3, md: 4 }}>
                                    <TextField
                                        error={hasError('name')}
                                        helperText={
                                            hasError('name') ? errors.name[0] : null
                                        }
                                        disabled={loading}
                                        fullWidth
                                        label="ชื่อ"
                                        className={classes.textField}
                                        type="text"
                                        name="name"
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={profile.name}
                                    />
                                </Box>
                                <Box mb={{ xs: 5, md: 4 }}>
                                    <TextField
                                        error={hasError('phoneNumber')}
                                        helperText={
                                            hasError('phoneNumber') ? errors.phoneNumber[0] : null
                                        }
                                        disabled={loading}
                                        fullWidth
                                        className={classes.textField}
                                        variant="outlined"
                                        type='number'
                                        label="เบอร์โทรศัพท์"
                                        name="phoneNumber"
                                        onChange={handleChange}
                                        value={profile.phoneNumber}
                                    />
                                </Box>
                                <Box style={{ textAlign: 'center' }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        noShadow
                                        onClick={() => handleSubmit()}
                                        disabled={!isValid || loading}
                                        loading={loading}
                                    >
                                        บันทึก
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

MyAccount.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    postProfile: PropTypes.func.isRequired,
    postImage: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(MyAccount));
