/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';
import Box from '@material-ui/core/Box';
import { HomeTopic } from '../../roots/list';
import { Readmore } from '../../roots/button';

const styles = theme => ({
    background: {
        position: 'relative',
        display: 'block',
        textAlign: 'left',
        borderRadius: 8,
        backgroundColor: '#002023',
        padding: '46px 48px 24px 48px',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            padding: '46px 16px 24px 16px',
        },
    },
});

function HomeTopicList(props) {
    const { classes, loading, list } = props;

    const initialArr = [...Array(5).keys()];

    return (
        <div className={classes.background}>
            <Box mb={2}>
                {loading ? (
                    <Typography variant="h2" style={{ width: '100%' }}>
                        <Skeleton width="100%" count={1} />
                    </Typography>
                ) : (
                    <Typography variant="h2" style={{ color: '#fff' }}>
                        เว็บบอร์ด
                    </Typography>
                )}
            </Box>
            {loading ? (
                <>
                    {initialArr.map(item => (
                        <HomeTopic loading={loading} key={item} />
                    ))}
                </>
            ) : (
                <>
                    {list.map(item => (
                        <HomeTopic
                            key={item.uid}
                            loading={loading}
                            url={`/topic/${item.url}/${item.uid}`}
                            title={item.title}
                            date={item.date}
                            view={item.view}
                            isPin={item.isPin}
                            comment={item.comment}
                        />
                    ))}
                    <Box className={classes.footer}>
                        <Link to="/webboard" style={{ textDecoration: 'none' }}>
                            <Readmore color="primary">อ่านต่อ</Readmore>
                        </Link>
                    </Box>
                </>
            )}
        </div>
    );
}

HomeTopicList.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    list: PropTypes.array.isRequired,
};

HomeTopicList.defaultProps = {
    loading: true,
};

export default withStyles(styles)(HomeTopicList);
