import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {
    RouteWithLayout,
    MainLayout,
    MainWithSearchLayout,
    MinimalLayout,
    MinimalBaseLayout,
    MinimalWithSearchLayout,
    Home,
    Latest,
    Tag,
    Location,
    Article,
    Live,
    Lifestyle,
    Inspiration,
    Finance,
    SubCategory,
    NotFound,
    ResetPassword,
    TermAndCondition,
    AdsvertiseWithUs,
    WhatIsAALV,
    PrivacyPolicy,
    Profile,
    ChangePassword,
    ChangeEmail,
    MyTopics,
    CreateTopic,
    Topics,
    Topic,
    Inbox,
    Posts,
    Post,
    Project,
    MyPosts,
    MyPostsNormal,
    MyPostsPromote,
    MyPostsSold,
    CreatePost,
    EditPost,
    PromotePost,
    PaidSuccess,
} from 'containers';

const Routes = () => {
    return (
        <Switch>
            {/* No Auth Required */}
            <RouteWithLayout exact path="/" layout={MainLayout} component={Home} />
            <RouteWithLayout exact path='/live' layout={MainLayout} component={Live} />
            <RouteWithLayout exact path='/lifestyle' layout={MainLayout} component={Lifestyle} />
            <RouteWithLayout exact path='/finance' layout={MainLayout} component={Finance} />
            <RouteWithLayout exact path='/inspiration' layout={MainLayout} component={Inspiration} />
            <RouteWithLayout exact path='/latest' layout={MinimalLayout} component={Latest} />
            <RouteWithLayout exact path='/subcategory/:subCatId' layout={MinimalLayout} component={SubCategory} />
            <RouteWithLayout exact path='/article/:articleTitle/:articleId' layout={MinimalLayout} component={Article} />
            <RouteWithLayout exact path='/draft/:articleId' layout={MinimalLayout} component={Article} />
            <RouteWithLayout exact path='/tag/:tagId' layout={MinimalLayout} component={Tag} />
            <RouteWithLayout exact path='/location/:locationId' layout={MinimalLayout} component={Location} />
            <RouteWithLayout exact path='/termAndCondition' layout={MinimalLayout} component={TermAndCondition} />
            <RouteWithLayout exact path='/adsvertiseWithUs' layout={MinimalLayout} component={AdsvertiseWithUs} />
            <RouteWithLayout exact path='/whatIsAALV' layout={MinimalLayout} component={WhatIsAALV} />
            <RouteWithLayout exact path='/privacyPolicy' layout={MinimalLayout} component={PrivacyPolicy} />
            <RouteWithLayout exact path='/webboard' layout={MinimalLayout} component={Topics} />
            <RouteWithLayout exact path='/posts' layout={MainWithSearchLayout} component={Posts} />
            <RouteWithLayout exact path='/post/:postTitle/:postId' layout={MinimalLayout} component={Post} />
            <RouteWithLayout exact path='/project/:projectId' layout={MinimalWithSearchLayout} component={Project} />
            <RouteWithLayout exact path='/topic/:topicTitle/:topicId' layout={MinimalLayout} component={Topic} />
            {/* <RouteWithLayout exact path='/project/:projectId' layout={MinimalLayout} component={Topic} /> */}
            <RouteWithLayout exact path='/not-found' layout={MinimalLayout} component={NotFound} />
            {/* Auth Required */}
            <RouteWithLayout 
                exact 
                noAuthRequired
                path='/reset-password' 
                layout={MinimalLayout} 
                component={ResetPassword} 
            />
            <RouteWithLayout 
                exact
                authRequired 
                path='/profile' 
                layout={MinimalLayout} 
                component={Profile} 
            />
            <RouteWithLayout
                exact
                authRequired
                path='/change-email'
                layout={MinimalLayout}
                component={ChangeEmail}
            />
            <RouteWithLayout 
                exact
                authRequired 
                path='/change-password' 
                layout={MinimalLayout} 
                component={ChangePassword} 
            />
            <RouteWithLayout
                exact
                authRequired
                path='/my-topics'
                layout={MinimalLayout}
                component={MyTopics}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/create-topic'
                layout={MinimalLayout}
                component={CreateTopic}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/edit-topic/:topicId'
                layout={MinimalLayout}
                component={CreateTopic}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/inbox'
                layout={MinimalLayout}
                component={Inbox}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/inbox/:inboxId'
                layout={MinimalLayout}
                component={Inbox}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/my-posts'
                layout={MinimalLayout}
                component={MyPosts}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/my-posts/normal'
                layout={MinimalLayout}
                component={MyPostsNormal}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/my-posts/promote'
                layout={MinimalLayout}
                component={MyPostsPromote}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/my-posts/sold-rented'
                layout={MinimalLayout}
                component={MyPostsSold}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/create-post'
                layout={MinimalLayout}
                component={CreatePost}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/edit-post/:postId'
                layout={MinimalLayout}
                component={EditPost}
            />
            <RouteWithLayout
                exact
                authRequired
                path='/promote-post'
                layout={MinimalBaseLayout}
                component={PromotePost}
            />
            <RouteWithLayout 
                exact 
                authRequired
                path='/paid-success' 
                layout={MinimalLayout} 
                component={PaidSuccess} 
            />
            {/* End */}
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
