/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from 'react-loading-skeleton';
import parse from 'html-react-parser';
import VisibilitySensor from 'react-visibility-sensor';
import { VSlimAds } from 'components/sections/ads';
import { TagNew, TagKeyword } from '../../roots/element';
import Writer from '../../roots/writer';
import { HShare, VShare } from '../../roots/share';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
    },
    category: {
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    date: {
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 4,
        },
    },
    adsTop: {
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        }
    },
    markdownBox:{
        marginBottom: 28,
        lineHeight: 1.8,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 22,
        },
    },
    markdownContent:{
        position: 'relative',
        color: "#2e2e2e",
        width: '100%',
        wordWrap: 'break-word',
        margin: 0,
        '& figure': {
            margin: '1rem 0',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: '0.5rem 0',
            },
            '&.image-style-side':{
                maxWidth: '50%',
                float: 'right',
                marginLeft: '1rem',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '100%',
                    float: 'none',
                    marginLeft: '0',
                },
            },
            '&.media': {
                clear: 'both',
                display: 'block',
                width: '100%',
            },
            '& figcaption':{
                color: '#c7c7c7',
                marginTop: '0.5rem',
                fontSize: "0.875rem",
                fontWeight: "normal",
                fontStyle: "normal",
                fontStretch: "normal",
                lineHeight: "1.71",
                letterSpacing: "normal",
                "@media screen and (max-width: 961px)": {
                    marginTop: '0.25rem',
                    fontSize: "0.75rem",
                    lineHeight: 1.83,
                }
            }
        },
        '& img': {
            display: 'block',
            width:'auto',
            height:'auto',
            maxHeight: 600,
            maxWidth: '100%',
            margin: '0 auto',
        },
        '& h2': {
            margin: 0,
            fontFamily: 'Prompt',
            fontSize: 24,
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: 20,
                lineHeight: 1.6,
            },
        },
        '& h3': {
            margin: 0,
            fontFamily: 'Prompt',
            fontSize: 22,
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.55,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: 18,
                lineHeight: 1.67,
            },
        },
        '& h4': {
            margin: 0,
            fontFamily: 'Prompt',
            fontSize: 18,
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                lineHeight: 1.75,
            },
        },
        '& p': {
            margin: 0,
            fontFamily: 'Sarabun',
            fontSize: 18,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]:{
                fontSize: 16,
                lineHeight: 1.75,
            },
        },
        '& ul':{
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 20,
            },
        },
        '& ol': {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 20,
            },
        },
        '& li': {
            margin: 0,
            fontFamily: 'Sarabun',
            fontSize: 18,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]:{
                fontSize: 16,
                lineHeight: 1.75,
            },
        },
        '& a': {
            color: '#00abbd',
        },
        '& blockquote': {
            margin: 0,
            borderLeft: '4px solid #00abbd',
            paddingLeft: 12,
            fontFamily: 'Prompt',
            fontSize: 18,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.56,
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]:{
                fontSize: 16,
                lineHeight: 1.75,
            },
        },
    },
    tag:{
        '& button':{
            marginRight: 8,
        }
    },
});

function Article(props) {
    const { 
        classes, 
        lang,
        category, 
        title, 
        createdAt, 
        markdown,
        loading, 
        tags,
        writer,
    } = props;

    const [state, setState] = React.useState({
        hideShare: false,
    });

    const showVShare = (isVisible) => {
        setState({
            ...state,
            hideShare: !isVisible,
        });
    };

    return (
        <VisibilitySensor
            onChange={(isVisible) => showVShare(isVisible)}
            delayedCall
            partialVisibility
            offset={{ top: 800 }}
            scrollDelay={0}
        >
            <div className={classes.background}>
                <Container>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        
                        <Grid item xs={12} md={8}>
                            <Box className={classes.category}>
                                {loading ? <Skeleton width="100px" /> : <TagNew text={category} />}
                            </Box>
                            <Box className={classes.title}>
                                <Typography variant="h3" color="textPrimary">
                                    {loading ? <Skeleton width="100%" count={2} /> : title}
                                </Typography>
                            </Box>
                            <Box className={classes.date}>
                                <Typography variant="caption" style={{ color: '#c7c7c7' }}>
                                    {loading ? <Skeleton width="80px" /> : createdAt}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="flex-start"
                        // alignItems="flex-start"
                        alignItems="stretch"
                    >
                        <Hidden smDown>
                            <Grid item xs={12} md={2}>
                                {!loading && 
                                    <VShare hidden={state.hideShare} />
                                }
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} md={8}>
                            <Box className={classes.markdownBox}>
                                {
                                    loading ? 
                                        <Skeleton width="100%" count={20} /> : 
                                        <div className={classes.markdownContent}>
                                            {parse(markdown)}
                                        </div>
                                }
                            </Box>
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={12} md={2} style={{ textAlign: 'right' }}>
                                <Box className={classes.adsTop}>
                                    <VSlimAds
                                        color="white"
                                        position="sideTop"
                                        pageLoading={loading}
                                    />
                                </Box>
                                <Box>
                                    <VSlimAds
                                        color="white"
                                        position="sideBottom"
                                        pageLoading={loading}
                                    />
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
                <Container>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={8}>
                            <Box className={classes.tag} mb={4}>
                                {
                                    loading ?
                                        <Skeleton width="150px" />
                                        :
                                        tags.map((item) => (
                                            <TagKeyword 
                                                key={item.uid} 
                                                url={`/tag/${item.uid}`} 
                                                text={item.name} 
                                            />
                                        ))
                                }
                            </Box>
                            <Hidden mdUp>
                                <Box mb={4}>
                                    { !loading && <HShare />}
                                </Box>
                            </Hidden>
                            <Divider style={{height: 1.5}} />
                            <Box className={classes.writer} mt={3} mb={4}>
                                {
                                    loading ?
                                        <Skeleton width="250px" height={80} />
                                        :
                                        <Writer
                                            lang={lang}
                                            img={writer.profile}
                                            name={writer.name}
                                            desc={writer.desc}
                                        />
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </VisibilitySensor >
    );
}

Article.propTypes = {
    classes: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired, 
    lang: PropTypes.string.isRequired, 
    title: PropTypes.string.isRequired, 
    createdAt: PropTypes.string.isRequired, 
    markdown: PropTypes.string.isRequired, 
    loading: PropTypes.bool.isRequired, 
    tags: PropTypes.array.isRequired, 
    writer: PropTypes.object.isRequired, 
};

export default withStyles(styles)(Article);