/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import { CardImgBkg, CardPaper } from '../../roots/card';
import { Readmore } from '../../roots/button';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
    },
    default: {
        backgroundColor: '#f9f9f9',
    },
    white: {
        backgroundColor: '#fff',
    },
    green: {
        backgroundColor: '#002023',
        paddingBottom: 60,
    },
    header:{
        textAlign: 'center',
        marginTop: 52,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            marginTop: 24,
        },
    },
    footer: {
        textAlign: 'center',
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            marginTop: 32,
            marginBottom: 32,
        },
    }
});

function SubCat(props) {
    const { 
        classes, 
        bgColor, 
        title, 
        cta, 
        loading,
        list, 
    } = props;

    let colorClass = classes.default;
    if (bgColor === 'white') {
        colorClass = classes.white;
    }
    if (bgColor === 'green') {
        colorClass = classes.green;
    }

    const initialArr = [...Array(5).keys()];

    return (
        <div className={clsx(classes.background, colorClass)}>
            <Box className={classes.header}>
                <Typography variant="h2" color="textPrimary" >
                    {loading ? <Skeleton width={150} /> : title}
                </Typography>
            </Box>
            <Container>
                <Grid container spacing={3}>
                    { loading ?
                        initialArr.map((item, index) => {
                            if (index < 2) {
                                return (
                                    <Grid key={item} item xs={12} md={6}>
                                        <CardImgBkg />
                                    </Grid>
                                );
                            }
                            return (
                                <Grid key={item} item xs={12} md={4}>
                                    <CardPaper />
                                </Grid>
                            );
                        })
                        :
                        list.map((item, index) => {
                            if (index < 2) {
                                return (
                                    <Grid key={item.uid} item xs={12} md={6}>
                                        <CardImgBkg 
                                            loading={loading}
                                            url={`/article/${item.url}/${item.uid}`}
                                            img={item.img}
                                            title={item.title}
                                            desc={item.desc}
                                            date={item.date}
                                        />
                                    </Grid>
                                );
                            }
                            return (
                                <Grid key={item.uid} item xs={12} md={4}>
                                    <CardPaper 
                                        loading={loading}
                                        url={`/article/${item.url}/${item.uid}`}
                                        img={item.img}
                                        title={item.title}
                                        date={item.date}
                                    />
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Container>
            <Box className={classes.footer}>
                {   loading ? 
                    <Skeleton width={140} height={50} /> 
                    : 
                    <Link to={cta} style={{textDecoration: 'none'}}>
                        <Readmore color="primary">ดูทั้งหมด</Readmore>
                    </Link>
                }
            </Box>
        </div>
    );
}

SubCat.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.string,
    title: PropTypes.string,
    cta: PropTypes.string,
    loading: PropTypes.bool,
    list: PropTypes.array,
};

SubCat.defaultProps = {
    bgColor: 'default',
    loading: true,
    cta: '',
    list: [],
    title: '',
};

export default withStyles(styles)(SubCat);