/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import { CardPaper } from '../../roots/card';

const styles = (theme) => ({
    background: {
        position: 'relative',
        display: 'block',
        paddingTop: 48,
        paddingBottom: 28,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 24,
            paddingBottom: 8,
        },
    },
    default: {
        backgroundColor: '#f9f9f9',
    },
    white: {
        backgroundColor: '#fff',
    },
    green: {
        backgroundColor: '#002023',
        paddingBottom: 60,
    },
    header: {
        textAlign: 'center',
        marginBottom: 24,
    },
});

function SuggestTopics(props) {
    const { 
        classes, 
        bgColor, 
        title,
        list,
        loading,
    } = props;

    let colorClass = classes.default;
    if (bgColor === 'white') {
        colorClass = classes.white;
    }
    if (bgColor === 'green') {
        colorClass = classes.green;
    }

    const initialArr = [...Array(3).keys()];

    return (
        <div className={clsx(classes.background, colorClass)}>
            <Box className={classes.header}>
                <Typography variant="h2" color="textPrimary" >
                    {loading ? <Skeleton width={200} /> : title}
                </Typography>
            </Box>
            <Container>
                <Grid container spacing={3}>
                    {
                        loading ?
                            initialArr.map((item) => (
                                <Grid key={item} item xs={12} md={4}>
                                    <CardPaper />
                                </Grid>
                            ))
                            :
                            list.map((item) => (
                                <Grid key={item.uid} item xs={12} md={4}>
                                    <CardPaper
                                        loading={loading}
                                        url={item && `/article/${item.url}/${item.uid}`}
                                        tag={item && item.category}
                                        img={item && item.img}
                                        title={item && item.title}
                                        date={item && item.date}
                                    />
                                </Grid>
                            ))
                    }
                </Grid>
            </Container>
        </div>
    );
}

SuggestTopics.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

SuggestTopics.defaultProps = {
    bgColor: 'default',
    loading: true,
};

export default withStyles(styles)(SuggestTopics);