import React from 'react';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import BRN from 'common/property/bedroomNumber';
import Area from './area.svg';
import Floor from './floor.svg';
import Room from './room.svg';

const styles = () => ({
    roomSpecList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    roomSpecItem: {
        marginRight: 16,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& img': {
            marginRight: 8,
        },
    },
});

function MyPost(props) {
    const { classes, areaSize, bedroomNum, floor } = props;

    const bedroomObj = _.find(BRN.type, ['uid', bedroomNum]);

    return (
        <Box className={classes.roomSpecList} mb={{ xs: 2, md: 3 }}>
            <div className={classes.roomSpecItem}>
                <img src={Area} alt="area" />
                <Typography variant="body2">
                    {areaSize ? `${areaSize} ตรม.` : '-'}
                </Typography>
            </div>
            <div className={classes.roomSpecItem}>
                <img src={Room} alt="room" />
                <Typography variant="body2">
                    {bedroomObj ? bedroomObj.name : '-'}
                </Typography>
            </div>
            <div className={classes.roomSpecItem}>
                <img src={Floor} alt="floor" />
                <Typography variant="body2">
                    {floor ? `ชั้น ${floor}` : '-'}
                </Typography>
            </div>
        </Box>
    );
}

MyPost.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    // areaSize,
    // bedroomNum,
    // floor,
};

export default withStyles(styles)(MyPost);
