import { connect } from 'react-redux';
import {
    getList,
    loadmore,
} from 'actions/latest';
import {
    getAllPageAds,
} from 'actions/ads';
import Latest from 'views/latest';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.latest.loading,
    loadingMore: state.latest.loadingMore,
    showLoadingMore: state.latest.showLoadingMore,
    list: state.latest.list,
});

const mapDispatchToProps = {
    getList,
    loadmore,
    getAllPageAds,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Latest);

