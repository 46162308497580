import {
    FETCH_SUB_CATEGORY,
    FETCH_SUB_CATEGORY_DONE,
    FETCH_SUB_CATEGORY_FAIL,
    FETCH_SUB_CATEGORY_LOADMORE,
    FETCH_SUB_CATEGORY_LOADMORE_DONE,
} from 'actions/subCategory';

const initialState = {
    loading: true,
    loadingMore: false,
    showLoadingMore: true,
    list: [],
    name: '',
    failMessage: '',
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_SUB_CATEGORY:
        return {
            ...state,
            loading: true,
            loadingMore: false,
            showLoadingMore: true,
        };
    case FETCH_SUB_CATEGORY_LOADMORE:
        return {
            ...state,
            loadingMore: true,
        };
    case FETCH_SUB_CATEGORY_FAIL:
        return {
            ...state,
            loading: true,
            failMessage: action.message,
        };
    case FETCH_SUB_CATEGORY_DONE:
        return {
            ...state,
            loading: false,
            loadingMore: false,
            showLoadingMore: action.list.length > 24,
            list: action.list,
            name: action.name,
        };
    case FETCH_SUB_CATEGORY_LOADMORE_DONE:
        return {
            ...state,
            loadingMore: false,
            showLoadingMore: action.list.length === 25,
            list: [
                ...state.list, 
                ...action.list,
            ],
        };
    default:
        return state;
    }
};
export default homeReducer;
