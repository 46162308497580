/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import { Button } from 'components/roots/button';
import Drawer from '@material-ui/core/Drawer';
import CloseBar from './closeBar';
import ListInbox from './list';
import Message from './message';

const styles = theme => ({
    card: {
        textAlign: 'center',
        padding: 0,
        boxSizing: 'border-box',
        borderRadius: 8,
        border: 'solid 1px #c7c7c7',
        boxShadow: 'none',
        minHeight: 'calc(100vh - 350px)',
        maxHeight: '85vh',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            minHeight: '350px',
            maxHeight: 'none',
            borderRadius: 0,
            border: 'none',
            borderTop: 'solid 1px #c7c7c7',
        },
    },
    left: {
        overflowY: 'scroll',
        width: '35%',
        borderRight: '1px solid #c7c7c7',
        [theme.breakpoints.down('sm')]: {
            overflowY: 'auto',
            width: '100%',
            borderRight: 'none',
        },
    },
    right: {
        width: '65%',
        backgroundColor: '#f9f9f9',
        display: 'flex',
        flexDirection: 'column',
    },
    waiting: {
        justifyContent: 'center',
    },
    header: {
        borderBottom: '1px solid #c7c7c7',
        backgroundColor: '#fff',
        padding: 16,
    },
    message: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        flexGrow: 1,
        '& *': {
            overflowAnchor: 'none',
        },
        '& #anchor': {
            overflowAnchor: 'auto',
            height: 1,
        },
    },
    footer: {
        display: 'flex',
    },
    textField: {
        display: 'inline-block',
        width: '100%',
        margin: '0 8px 0 0',
        [theme.breakpoints.down('sm')]: {
            '& label': {
                transform: 'translate(14px, 14px) scale(1)',
            },
            '& input': {
                height: 11,
            },
        },
    },
    submit: {
        display: 'inline-block',
        width: 180,
        [theme.breakpoints.down('sm')]: {
            width: 100,
        },
    },
    drawer: {
        '& .MuiDrawer-paperAnchorRight': {
            [theme.breakpoints.down('sm')]: {
                left: 0,
            },
        },
    },
    innerDrawer: {
        backgroundColor: '#f9f9f9',
        width: 360,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
    },
});

function CardChat(props) {
    const {
        classes,
        // lang,
        userId,
        userImg,
        userName,
        loading,
        list,
        chat,
        resetData,
        updateInbox,
        updateChat,
        getChat,
        postChat,
        updateInboxSeen,
        location,
        creatChatByUserId,
    } = props;

    const uid = location.pathname.split('/').slice(-1)[0];
    const listener = _.find(list, ['uid', uid]);

    const initialArr = [...Array(5).keys()];

    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = open => {
        setState({
            ...state,
            right: open,
        });
    };

    const handleClick = (img, name, listenerId, inboxId) => {
        updateInbox(img, name, listenerId);
        updateInboxSeen(inboxId);
        toggleDrawer(true);
    };

    const handleSubmit = (inboxId, text, messageArr, listenerId) => {
        postChat(inboxId, text, messageArr, listenerId);
    };

    React.useEffect(() => {
        const params = queryString.parse(location.search, {
            arrayFormat: 'comma',
        });
        const userIds = params.userId;
        if (uid !== 'inbox') {
            updateInbox(
                listener ? listener.img : '',
                listener ? listener.name : '',
                listener ? listener.members[0] : '',
            );
            getChat(uid);
        } else if (userIds && userIds.length === 2) {
            const sortedIds = userIds.sort();
            creatChatByUserId(sortedIds);
        } else {
            resetData();
        }
    }, [
        uid,
        getChat,
        resetData,
        listener,
        updateInbox,
        creatChatByUserId,
        location,
    ]);

    return (
        <Card className={classes.card}>
            <div className={classes.left}>
                {loading ? (
                    <>
                        {initialArr.map(item => (
                            <ListInbox key={item} loading={loading} />
                        ))}
                    </>
                ) : (
                    <>
                        {list.map(item => (
                            <ListInbox
                                key={item.uid}
                                loading={loading}
                                uid={item.uid}
                                img={item.img}
                                name={item.name}
                                desc={item.message}
                                date={item.timestamp}
                                seen={item.seen}
                                active={item.uid === uid}
                                onClick={() =>
                                    handleClick(
                                        item.img,
                                        item.name,
                                        item.members[0],
                                        item.uid,
                                    )
                                }
                            />
                        ))}
                    </>
                )}
            </div>
            <Hidden smDown>
                <div
                    className={clsx(
                        classes.right,
                        (!chat.isActive || loading) && classes.waiting,
                    )}>
                    {!loading && (
                        <>
                            {chat.isActive ? (
                                <>
                                    <Box className={classes.header}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textPrimary">
                                            {chat.profile.name}
                                        </Typography>
                                    </Box>
                                    <Box p={2} className={classes.message}>
                                        {chat.messages.map(mes => (
                                            <Message
                                                key={mes.uid}
                                                message={mes.message}
                                                side={
                                                    mes.createdBy === userId
                                                        ? 'right'
                                                        : 'left'
                                                }
                                                name={
                                                    mes.createdBy === userId
                                                        ? userName
                                                        : chat.profile.name
                                                }
                                                img={
                                                    mes.createdBy === userId
                                                        ? userImg
                                                        : chat.profile.img
                                                }
                                                isLast={
                                                    mes.isLast
                                                        ? mes.isLast
                                                        : false
                                                }
                                                time={mes.timestamp}
                                            />
                                        ))}
                                        <div id="anchor" />
                                    </Box>
                                    <Box
                                        p={2}
                                        pt={0}
                                        className={classes.footer}>
                                        <TextField
                                            className={classes.textField}
                                            type="text"
                                            placeholder="พิมพ์ข้อความ"
                                            fullWidth
                                            name="text"
                                            margin="normal"
                                            variant="outlined"
                                            onChange={event =>
                                                updateChat(event.target.value)
                                            }
                                            value={chat.text}
                                        />
                                        <Box className={classes.submit}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                noShadow
                                                disabled={
                                                    !chat.isValid ||
                                                    chat.loading
                                                }
                                                onClick={() =>
                                                    handleSubmit(
                                                        uid,
                                                        chat.text,
                                                        chat.messages,
                                                        chat.profile.uid,
                                                    )
                                                }
                                                loading={chat.loading}>
                                                ส่ง
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <Box className={classes.waiting}>
                                    <Typography
                                        variant="body1"
                                        style={{ color: '#c7c7c7' }}>
                                        เลือกกล่องข้อความเพื่อดูบทสนทนา
                                    </Typography>
                                </Box>
                            )}
                        </>
                    )}
                </div>
            </Hidden>
            <Hidden mdUp>
                <Drawer
                    className={classes.drawer}
                    anchor="right"
                    open={state.right}
                    onClose={() => toggleDrawer(false)}
                    variant="temporary"
                    transitionDuration={500}
                    closeAfterTransition
                    disableEnforceFocus
                    disableAutoFocus
                    disableRestoreFocus>
                    <div className={classes.innerDrawer}>
                        <CloseBar
                            title={chat.profile.name}
                            onClick={() => toggleDrawer(false)}
                        />
                        <Box p={2} className={classes.message}>
                            {chat.messages.map(mes => (
                                <Message
                                    key={mes.uid}
                                    message={mes.message}
                                    side={
                                        mes.createdBy === userId
                                            ? 'right'
                                            : 'left'
                                    }
                                    name={
                                        mes.createdBy === userId
                                            ? userName
                                            : chat.profile.name
                                    }
                                    img={
                                        mes.createdBy === userId
                                            ? userImg
                                            : chat.profile.img
                                    }
                                    isLast={mes.isLast ? mes.isLast : false}
                                    time={mes.timestamp}
                                />
                            ))}
                            <div id="anchor" />
                        </Box>
                        <Box p={2} pt={0} className={classes.footer}>
                            <TextField
                                className={classes.textField}
                                type="text"
                                placeholder="พิมพ์ข้อความ"
                                fullWidth
                                name="text"
                                margin="normal"
                                variant="outlined"
                                onChange={event =>
                                    updateChat(event.target.value)
                                }
                                value={chat.text}
                            />
                            <Box className={classes.submit}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    noShadow
                                    disabled={!chat.isValid || chat.loading}
                                    onClick={() =>
                                        handleSubmit(
                                            uid,
                                            chat.text,
                                            chat.messages,
                                            chat.profile.uid,
                                        )
                                    }
                                    loading={chat.loading}>
                                    ส่ง
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </Drawer>
            </Hidden>
        </Card>
    );
}

CardChat.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    resetData: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    userImg: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    getChat: PropTypes.func.isRequired,
    updateInbox: PropTypes.func.isRequired,
    updateChat: PropTypes.func.isRequired,
    postChat: PropTypes.func.isRequired,
    updateInboxSeen: PropTypes.func.isRequired,
    chat: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(CardChat));
