import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { OutlinedInput, MenuItem, Select } from '@material-ui/core';
import Types from 'common/property/purpose';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 135,
        minWidth: 135,
        marginRight: 8,
        // marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 120,
            minWidth: 0,
            padding: '15px 14px',
            height: 48,
            boxSizing: 'border-box',
            // marginRight: 0,
            // marginBottom: 16,
        },
        '& .MuiSelect-select': {
            [theme.breakpoints.down('sm')]: {
                padding: '0 8px 0 0',
            },
        },
    },
}));

const CategoryCom = props => {
    const classes = useStyles();
    const { value, loading, changeValue } = props;

    const handleChange = event => {
        changeValue(event.target.value);
    };

    // const inputLabel = React.useRef(null);
    // const [labelWidth, setLabelWidth] = React.useState(0);
    // React.useEffect(() => {
    //     setLabelWidth(inputLabel.current.offsetWidth);
    // }, []);

    return (
        <Select
            className={classes.root}
            disabled={loading}
            value={value}
            onChange={handleChange}
            defaultValue="sell"
            input={
                <OutlinedInput
                    // labelWidth={labelWidth}
                    name="purpose"
                    id="purpose"
                />
            }>
            {Types.type.map(item => (
                <MenuItem key={item.uid} value={item.uid}>
                    {item.name}
                </MenuItem>
            ))}
        </Select>
    );
};

CategoryCom.propTypes = {
    value: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    changeValue: PropTypes.func.isRequired,
};

export default CategoryCom;
