/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
// import _ from 'lodash';
import { HAds } from 'components/sections/ads';
import { Button } from 'components/roots/button';
import TitleComponent from 'components/roots/titleComponent';
import { Post } from 'components/roots/list';
import OrderField from 'components/roots/list/post/orderField';
import FilterBar from 'components/sections/cover/filterBar';
import ProjectDetail from 'components/sections/detail/project/index';

const styles = theme => ({
    list: {
        backgroundColor: '#f9f9f9',
    },
    card: {
        textAlign: 'center',
        minHeight: 'calc(100vh - 400px)',
        padding: '50px 0 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 619px)',
            padding: '50px 16px',
        },
    },
    button: {
        width: 384,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    createBtn: {
        maxWidth: 180,
        marginLeft: 'auto',
    },
    footer: {
        margin: '0 auto',
        width: 180,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto 28px auto',
        },
    },
});

function PostsAll(props) {
    const {
        classes,
        // list,
        resetData,
        // lang,
        loading,
        // getList,
        // loadingMore,
        // showLoadingMore,
        // list,
        // loadmoreTitle,
        // loadmoreFunc,
    } = props;

    // React.useEffect(() => {
    //     getEmail();
    // }, [getEmail]);

    return (
        <>
            <TitleComponent title="โครงการ - All About Living" />
            <HAds color="white" position="header" pageLoading={loading} />
            <Hidden mdUp>
                <Box zIndex={1} style={{ width: '100%' }}>
                    <FilterBar />
                </Box>
            </Hidden>
            <ProjectDetail />
            <div className={classes.list}>
                <Container>
                    <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center">
                        <Grid item xs={12} md={8}>
                            <Box mb={2}>
                                <OrderField
                                    loading={loading}
                                    // value={data.projectStatus}
                                    changeValue={
                                        newValue => console.log('xxx')
                                        // update(data, 'projectStatus', newValue)
                                    }
                                />
                            </Box>
                            <Post
                                loading={loading}
                                url={`/post/no-title/${0}`}
                                image="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/properties%2F23183b50-355e-11ea-b5db-b71b063bd7d8-6_optimized.jpg?alt=media&token=692cd651-f8b1-47ca-9b32-2b4879b1ce7f"
                                title="คอนโด ศุภาลัยซิตี้รีสอร์ท ชลบุรี ห้องมมมม..."
                                textLocation="วัฒนา, กรุงเทพ"
                                sellPrice={10000000}
                                rentPrice={1000}
                                status="promote"
                                resetData={resetData}
                            />
                            <Post
                                loading={loading}
                                url={`/post/no-title/${0}`}
                                image="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/properties%2F23183b50-355e-11ea-b5db-b71b063bd7d8-6_optimized.jpg?alt=media&token=692cd651-f8b1-47ca-9b32-2b4879b1ce7f"
                                title="คอนโด ศุภาลัยซิตี้รีสอร์ท ชลบุรี ห้องมมมม..."
                                textLocation="วัฒนา, กรุงเทพ"
                                sellPrice={10000000}
                                rentPrice={1000}
                                status="promote"
                                resetData={resetData}
                            />
                            <Post
                                loading={loading}
                                url={`/post/no-title/${0}`}
                                image="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/properties%2F23183b50-355e-11ea-b5db-b71b063bd7d8-6_optimized.jpg?alt=media&token=692cd651-f8b1-47ca-9b32-2b4879b1ce7f"
                                title="คอนโด ศุภาลัยซิตี้รีสอร์ท ชลบุรี ห้องมมมม..."
                                textLocation="วัฒนา, กรุงเทพ"
                                sellPrice={10000000}
                                rentPrice={1000}
                                resetData={resetData}
                            />
                            <Post
                                loading={loading}
                                url={`/post/no-title/${0}`}
                                image="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/properties%2F23183b50-355e-11ea-b5db-b71b063bd7d8-6_optimized.jpg?alt=media&token=692cd651-f8b1-47ca-9b32-2b4879b1ce7f"
                                title="คอนโด ศุภาลัยซิตี้รีสอร์ท ชลบุรี ห้องมมมม..."
                                textLocation="วัฒนา, กรุงเทพ"
                                sellPrice={10000000}
                                rentPrice={1000}
                                resetData={resetData}
                            />
                            <Post
                                loading={loading}
                                url={`/post/no-title/${0}`}
                                image="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/properties%2F23183b50-355e-11ea-b5db-b71b063bd7d8-6_optimized.jpg?alt=media&token=692cd651-f8b1-47ca-9b32-2b4879b1ce7f"
                                title="คอนโด ศุภาลัยซิตี้รีสอร์ท ชลบุรี ห้องมมมม..."
                                textLocation="วัฒนา, กรุงเทพ"
                                sellPrice={10000000}
                                rentPrice={1000}
                                resetData={resetData}
                            />
                            <Post
                                loading={loading}
                                url={`/post/no-title/${0}`}
                                image="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/properties%2F23183b50-355e-11ea-b5db-b71b063bd7d8-6_optimized.jpg?alt=media&token=692cd651-f8b1-47ca-9b32-2b4879b1ce7f"
                                title="คอนโด ศุภาลัยซิตี้รีสอร์ท ชลบุรี ห้องมมมม..."
                                textLocation="วัฒนา, กรุงเทพ"
                                sellPrice={10000000}
                                rentPrice={1000}
                                resetData={resetData}
                            />
                            <Post
                                loading={loading}
                                url={`/post/no-title/${0}`}
                                image="https://firebasestorage.googleapis.com/v0/b/all-about-living-staging-10b66.appspot.com/o/properties%2F23183b50-355e-11ea-b5db-b71b063bd7d8-6_optimized.jpg?alt=media&token=692cd651-f8b1-47ca-9b32-2b4879b1ce7f"
                                title="คอนโด ศุภาลัยซิตี้รีสอร์ท ชลบุรี ห้องมมมม..."
                                textLocation="วัฒนา, กรุงเทพ"
                                sellPrice={10000000}
                                rentPrice={1000}
                                resetData={resetData}
                            />
                        </Grid>
                        {/* {!loading && showLoadingMore && ( */}
                        <Grid
                            item
                            xs={12}
                            md={8}
                            style={{ textAlign: 'center' }}>
                            <Box className={classes.footer}>
                                <Button
                                    color="default"
                                    variant="outlined"
                                    // onClick={() => loadmoreFunc()}
                                >
                                    {/* {loadmoreTitle} */}
                                    โหลดเพิ่มเติม
                                </Button>
                            </Box>
                        </Grid>
                        {/* )} */}
                    </Grid>
                </Container>
            </div>
            <HAds position="footer" pageLoading={loading} />
        </>
    );
}

PostsAll.propTypes = {
    // lang: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    list: PropTypes.array.isRequired,
    resetData: PropTypes.func.isRequired,
    // getList: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(PostsAll));
