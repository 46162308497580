import {
    SET_PROPERTY_TYPE,
    SET_PROPERTY_PROJECT,
    SET_PROPERTY_GEO,
    UPLOAD_PROPERTY_GALLERY,
    UPLOAD_PROPERTY_GALLERY_DONE,
    REMOVE_PROPERTY_GALLERY,
    UPDATE_PROPERTY,
    FETCH_PROPERTY,
    FETCH_OPEN_PROPERTY,
    FETCH_PROPERTY_DATA_DONE,
    FETCH_PROPERTY_DONE,
    FETCH_PROPERTY_FAIL,
    RESET_PROPERTY,
    FETCH_PROPERTY_LIST_DONE,
    FETCH_OPEN_PROPERTY_LIST_DONE,
    DELETE_PROPERTY,
    UPDATE_PROPERTY_INDEX,
    TOGGLE_CHECKED_PROPERTY,
    SET_SEARCH_DATA,
} from 'actions/posts';

const initialState = {
    loading: false,
    sortBy: '',
    list: [],
    packages: [],
    loadingMore: false,
    showLoadingMore: true,
    startIndex: 1,
    endIndex: 25,
    openList: {
        loading: false,
        list: [],
        check: [],
    },
    data: {
        type: '',
        projectId: {},
        location: {
            lat: 13.7563,
            lng: 100.5018,
        },
        title: '',
        desc: '',
        purpose: '',
        sellPrice: '',
        rentPrice: '',
        landSize: '',
        areaSize: '',
        bedroomNum: '',
        toiletNum: '',
        floor: '',
        furniture: '',
        direction: '',
        projectStatus: '',
        gallery: [],
        status: 'open',
        packageId: '',
        startPromoteAt: 0,
        reachAfterPromote: 0,
        maximumReachPromote: 0,
        createdBy: '',
        createdAt: '',
        updatedAt: '',
        accept: false,
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
    searchData: {
        searchText: '',
        geo: {},
        geoResults: [],
        priceRange: [],
        bedroom: [],
        bathroom: [],
        furniture: [],
        areaRange: [],
        purpose: 'sell',
        type: 'condo',
        loading: false,
    },
};

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_SEARCH_DATA: {
        return {
            ...state,
            searchData: {
                ...state.searchData,
                [action.key]: action.value,
            },
        };
    }
    case RESET_PROPERTY:
        return {
            ...initialState,
            data: {
                type: '',
                projectId: '',
                location: {
                    lat: 13.7563,
                    lng: 100.5018,
                },
                title: '',
                desc: '',
                purpose: '',
                sellPrice: '',
                rentPrice: '',
                landSize: '',
                areaSize: '',
                bedroomNum: '',
                toiletNum: '',
                floor: '',
                furniture: '',
                direction: '',
                projectStatus: '',
                gallery: [],
                status: 'open',
                startPromoteAt: 0,
                reachAfterPromote: 0,
                maximumReachPromote: 0,
                createdBy: '',
                createdAt: '',
                updatedAt: '',
            },
        };
    case SET_PROPERTY_TYPE:
        return {
            ...state,
            data: {
                ...state.data,
                type: action.typeId,
                projectId: action.typeId === 'project' ? '' : 'location',
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    case SET_PROPERTY_PROJECT:
        return {
            ...state,
            data: {
                ...state.data,
                projectId: action.proId,
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    case SET_PROPERTY_GEO:
        return {
            ...state,
            data: {
                ...state.data,
                location: {
                    lat: action.lat,
                    lng: action.lng,
                },
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    case UPDATE_PROPERTY:
        if (
            action.fieldName === 'sellPrice' &&
                state.data.purpose === 'sell'
        ) {
            return {
                ...state,
                data: {
                    ...state.data,
                    rentPrice: 'xxx',
                    [action.fieldName]: action.newValue,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        }
        if (
            action.fieldName === 'rentPrice' &&
                state.data.purpose === 'rent'
        ) {
            return {
                ...state,
                data: {
                    ...state.data,
                    sellPrice: 'xxx',
                    [action.fieldName]: action.newValue,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        }
        return {
            ...state,
            data: {
                ...state.data,
                [action.fieldName]: action.newValue,
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    case FETCH_PROPERTY:
        return {
            ...state,
            loading: true,
            loadingMore: false,
            showLoadingMore: true,
        };
    case FETCH_OPEN_PROPERTY:
        return {
            ...state,
            openList: {
                loading: true,
                list: [],
                check: [],
            },
        };
    case FETCH_PROPERTY_DATA_DONE:
        return {
            ...state,
            loading: false,
            data: action.data,
        };
    case FETCH_PROPERTY_DONE:
        return {
            ...state,
            loading: false,
            successMessage: action.message,
        };
    case FETCH_PROPERTY_FAIL:
        return {
            ...state,
            loading: false,
            failMessage: action.message,
        };
    case FETCH_PROPERTY_LIST_DONE:
        return {
            ...state,
            loading: false,
            list: action.list,
            loadingMore: false,
            showLoadingMore: action.list.length < action.numberOfDocs,
            startIndex: 1,
            endIndex: action.list.length,
            packages: action.packages,
        };
    case FETCH_OPEN_PROPERTY_LIST_DONE: {
        const checkList = new Array(action.list.length).fill(false);
        return {
            ...state,
            openList: {
                loading: false,
                list: action.list,
                check: checkList,
            },
        };
    }
    case TOGGLE_CHECKED_PROPERTY: {
        const newCheck = [...state.openList.check];
        newCheck[action.index] = !newCheck[action.index];
        return {
            ...state,
            openList: {
                ...state.openList,
                check: newCheck,
            },
        };
    }
    case UPDATE_PROPERTY_INDEX:
        return {
            ...state,
            startIndex: action.startIndex,
        };
    case DELETE_PROPERTY:{
        const index = state.list.findIndex(data => data.uid === action.uid);
        const newList = [...state.list];
        newList.splice(index, 1);
        return {
            ...state,
            list: newList,
        };
    }
    case UPLOAD_PROPERTY_GALLERY:{
        const galList = [...state.data.gallery];
        galList.push('loading');
        return {
            ...state,
            data: {
                ...state.data,
                gallery: galList,
            },
        };
    }
    case UPLOAD_PROPERTY_GALLERY_DONE:{
        const galList2 = [...state.data.gallery];
        galList2[action.index] = action.imgURL;
        return {
            ...state,
            data: {
                ...state.data,
                gallery: galList2,
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    }
    case REMOVE_PROPERTY_GALLERY:{
        const galList3 = [...state.data.gallery];
        galList3.splice(action.index, 1);
        return {
            ...state,
            data: {
                ...state.data,
                gallery: galList3,
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    } 
    default:
        return state;
    }
};
export default projectsReducer;
