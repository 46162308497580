export default {
    th: {
        live: 'Live',
        lifestyle: 'Lifestyle',
        finance: 'Finance',
        inspiration: 'Inspiration',
        webboard: 'เว็บบอร์ด',
        post: 'ประกาศซื้อขาย-อสังหาฯ',
        login: 'เข้าสู่ระบบ',
        register: 'ลงทะเบียน',
        termAndCondition: 'เงื่อนไขการใช้งาน',
        adsvertiseWithUs: 'ลงโฆษณากับเรา',
        whatIsAALV: 'All about living คืออะไร?',
        privacyPolicy: 'นโยบายความเป็นส่วนตัว',
        latest: 'บทความล่าสุด',
        suggestion: 'บทความแนะนำ',
        related: 'บทความที่เกี่ยวข้อง',
        map: 'แผนที่',
        aboutWriter: 'เกี่ยวกับผู้เขียน',
        notFound: 'เราไม่พบหน้าที่คุณกำลังมองหาอยู่',
        paidFail: 'ชำระเงินไม่สำเร็จ',
        paidSuccess: 'ชำระเงินสำเร็จ',
        backHome: 'กลับสู่หน้าแรก',
        loadmore: 'ดูรายละเอียดเพิ่มเติม',
        search: 'ค้นหาบทความโดยชื่อ แท็ก หรือสถานที่',
        suggestionSearch: 'เนื้อหาแนะนำ',
        submit: 'ส่ง',
        submitDone: 'ส่งแล้ว',
        emailPlaceHolder: 'กรุณากรอกอีเมล',
        subCategory: {
            review: 'รีวิวโครงการ',
            location: 'สถานที่',
            prNews: 'ข่าวประชาสัมพันธ์',
            airline: 'สายการบิน',
            travel: 'ท่องเที่ยว',
            eat: 'อาหาร',
            investment: 'การลงทุน',
            news: 'ข่าวสาร',
            livingForward: 'Living Forward',
            businessTalk: 'พูดคุยธุรกิจ',
            homeDecor: 'ตกแต่งบ้าน',
            others: 'อื่นๆ',
        },
    },
    en: {
        live: 'Live',
        lifestyle: 'Lifestyle',
        finance: 'Finance',
        inspiration: 'Inspiration',
        webboard: 'Board',
        post: 'Sell & Rent',
        login: 'Sign In',
        register: 'Sign Up',
        termAndCondition: 'Term and condition',
        adsvertiseWithUs: 'Advertising with us',
        whatIsAALV: 'What is All about living?',
        privacyPolicy: 'Privacy policy',
        latest: 'Latest Articles',
        suggestion: 'Recommended Articles',
        related: 'บทความที่เกี่ยวข้อง',
        map: 'Map',
        aboutWriter: 'About Writer',
        notFound: "This page doesn't exist",
        paidFail: 'ชำระเงินไม่สำเร็จ',
        paidSuccess: 'ชำระเงินสำเร็จ',
        backHome: 'Back to home page',
        loadmore: 'Read more',
        search: 'ค้นหาประกาศโดยสถานที่, โครงการ, แบรนด์',
        suggestionSearch: 'Suggestion Content',
        submit: 'Submit',
        submitDone: 'Sent',
        emailPlaceHolder: 'Please fill your email',
        subCategory: {
            review: 'Review',
            location: 'Location',
            prNews: 'PR News',
            airline: 'Airline',
            travel: 'Travel',
            eat: 'Eat',
            investment: 'Investment',
            news: 'News',
            livingForward: 'Living Forward',
            businessTalk: 'Business Talk',
            homeDecor: 'Home Decor',
            others: 'Others',
        },
    },
    ch: {
        live: 'Live',
        lifestyle: 'Lifestyle',
        finance: 'Finance',
        inspiration: 'Inspiration',
        webboard: 'Board',
        post: 'Sell & Rent',
        login: 'Sign In',
        register: 'Sign Up',
        termAndCondition: 'Term and condition',
        adsvertiseWithUs: 'Advertising with us',
        whatIsAALV: 'What is All about living?',
        privacyPolicy: 'Security',
        latest: 'Latest Articles',
        suggestion: 'Recommended Articles',
        related: 'บทความที่เกี่ยวข้อง',
        map: 'Map',
        aboutWriter: 'About Writer',
        notFound: "This page doesn't exist",
        paidFail: 'ชำระเงินไม่สำเร็จ',
        paidSuccess: 'ชำระเงินสำเร็จ',
        backHome: 'Back to home page',
        loadmore: 'Read more',
        search: 'ค้นหาประกาศโดยสถานที่, โครงการ, แบรนด์',
        suggestionSearch: 'Suggestion Content',
        submit: 'Submit',
        submitDone: 'Sent',
        emailPlaceHolder: 'Please fill your email',
        subCategory: {
            review: 'Review',
            location: 'Location',
            prNews: 'PR News',
            airline: 'Airline',
            travel: 'Travel',
            eat: 'Eat',
            investment: 'Investment',
            news: 'News',
            livingForward: 'Living Forward',
            businessTalk: 'Business Talk',
            homeDecor: 'Home Decor',
            others: 'Others',
        },
    },
    jp: {
        live: 'Live',
        lifestyle: 'Lifestyle',
        finance: 'Finance',
        inspiration: 'Inspiration',
        webboard: 'Board',
        post: 'Sell & Rent',
        login: 'Sign In',
        register: 'Sign Up',
        termAndCondition: 'Term and condition',
        adsvertiseWithUs: 'Advertising with us',
        whatIsAALV: 'What is All about living?',
        privacyPolicy: 'Security',
        latest: 'Latest Articles',
        suggestion: 'Recommended Articles',
        related: 'บทความที่เกี่ยวข้อง',
        map: 'Map',
        aboutWriter: 'About Writer',
        notFound: "This page doesn't exist",
        paidFail: 'ชำระเงินไม่สำเร็จ',
        paidSuccess: 'ชำระเงินสำเร็จ',
        backHome: 'Back to home page',
        loadmore: 'Read more',
        search: 'ค้นหาประกาศโดยสถานที่, โครงการ, แบรนด์',
        suggestionSearch: 'Suggestion Content',
        submit: 'Submit',
        submitDone: 'Sent',
        emailPlaceHolder: 'Please fill your email',
        subCategory: {
            review: 'Review',
            location: 'Location',
            prNews: 'PR News',
            airline: 'Airline',
            travel: 'Travel',
            eat: 'Eat',
            investment: 'Investment',
            news: 'News',
            livingForward: 'Living Forward',
            businessTalk: 'Business Talk',
            homeDecor: 'Home Decor',
            others: 'Others',
        },
    },
};
