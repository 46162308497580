export default {
    type: [
        {
            uid: '10',
            name: '10 ตรม',
        },
        {
            uid: '20',
            name: '20 ตรม',
        },
        {
            uid: '30',
            name: '30 ตรม',
        },
        {
            uid: '40',
            name: '40 ตรม',
        },
        {
            uid: '50',
            name: '50 ตรม',
        },
        {
            uid: '60',
            name: '60 ตรม',
        },
        {
            uid: '70',
            name: '70 ตรม',
        },
        {
            uid: '80',
            name: '80 ตรม',
        },
        {
            uid: '150',
            name: '150 ตรม',
        },
        {
            uid: '200',
            name: '200 ตรม',
        },
        {
            uid: '300',
            name: '300 ตรม',
        },
        {
            uid: '400',
            name: '400 ตรม',
        },
        {
            uid: '500',
            name: '500 ตรม',
        },
        {
            uid: '1000',
            name: '1000 ตรม',
        },
    ],
};
