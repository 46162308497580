import _ from 'lodash';
import firebase from 'common/firebase';
import { setNotFound } from './main';


const functions = firebase.app().functions('asia-east2');

// Action
// -- CREATE/UPDATE
export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_LOCATION_DONE = 'FETCH_LOCATION_DONE';
export const FETCH_LOCATION_FAIL = 'FETCH_LOCATION_FAIL';
export const FETCH_LOCATION_LOADMORE = 'FETCH_LOCATION_LOADMORE';
export const FETCH_LOCATION_LOADMORE_DONE = 'FETCH_LOCATION_LOADMORE_DONE';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_LOCATION,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_LOCATION_LOADMORE,
    };
};
export function fetchDone(data) {
    return {
        type: FETCH_LOCATION_DONE,
        list: data.list,
        name: data.name,
    };
};
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_LOCATION_LOADMORE_DONE,
        list: data.list,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_LOCATION_FAIL,
        message,
    };
};

// Async Action Creator
export function getList(lang, uid) {
    return async (dispatch) => {
        dispatch(fetch());

        const tagGetList = functions.httpsCallable('getArticleListByLocationId');
        try {
            const result = await tagGetList({ lang, uid });
            const output = result.data;
            if (!_.isEmpty(output)){
                dispatch(fetchDone(output));
            }else{
                dispatch(setNotFound(true));
            }
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function loadmore(lang, uid, lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const tagLoadmore = functions.httpsCallable('locationLoadmore');
        try {
            const result = await tagLoadmore({ lang, uid, lastVisibleUid });
            const output = {
                list: result.data,
            };
            dispatch(fetchLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

