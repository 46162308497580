import TH from './th.svg';
import EN from './en.svg';
import CH from './ch.svg';
import JP from './jp.svg';

export default {
    th:{
        name: 'ไทย',
        simName: 'Thai',
        icon: TH,
    },
    en:{
        name: 'Eng',
        simName: 'English',
        icon: EN,
    },
    ch:{
        name: '中国',
        simName: 'Chinese',
        icon: CH,
    },
    jp:{
        name: '日本人',
        simName: 'Japanese',
        icon: JP,
    },
};