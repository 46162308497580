import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from 'react-loading-skeleton';
import DateViewComment from 'components/roots/status/DateViewComment';
import MyDialog from 'components/roots/dialog/delete';
import { Button } from 'components/roots/button';

const styles = theme => ({
    card: {
        boxShadow: 'none',
        width: '100%',
        padding: '0 0 8px 0',
        borderBottom: '1px solid #e9e9e9',
        borderRadius: 0,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '8px',
        }
    },
    header: {
        display: 'flex',
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        }
    },
    content: {
        width: '100%',
    },
    title: {
        width: '100%',
        marginBottom: 8,
    },
    editBtn: {
        marginLeft: 8,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 4,
            marginBottom: 4,
        }
    },
    cancelBtn: {
        width: '50%',
    },
    deleteBtn: {
        width: '50%',
    },
    link:{
        textDecoration: 'none',
        color: 'inherit',
    },
    loadingLink:{
        textDecoration: 'none',
        color: 'inherit',
        pointerEvents: 'none',
        cursor: 'default',
    }
});

function MyTopic(props) {
    const { 
        classes, 
        uid,
        url,   
        title, 
        desc, 
        date,
        view,
        comment,
        loading,
        deleteTopic, 
        resetData,
    } = props;

    // Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    // Dialog
    const [dialog, setDialog] = React.useState(false);
    const handleOpenDialog = () => {
        setDialog(true);
        handleClose();
    };

    const handleCloseDialog = () => {
        setDialog(false);
    };

    return (
        <Card className={classes.card}>
            <Box className={classes.header}>
                <Box className={classes.content}>
                    <Box className={classes.title}>
                        {loading ? 
                            <Typography variant="subtitle1" >
                                <Skeleton width="100%" count={1} />
                            </Typography> 
                            : 
                            <Link to={url} className={classes.link}>
                                <Typography variant="subtitle1" >
                                    {title}
                                </Typography>
                            </Link>
                        }
                    </Box>
                    <Hidden smDown>
                        <Typography variant="body2" color="textSecondary" >
                            {loading ? <Skeleton width="100%" count={2} /> : desc}
                        </Typography>
                    </Hidden>
                </Box>
                {!loading &&
                    <Box className={classes.editBtn}>
                        <IconButton size="small" onClick={handleClick}>
                            <Hidden smDown>
                                <MoreVertIcon fontSize="large" />
                            </Hidden>
                            <Hidden mdUp>
                                <MoreVertIcon fontSize='default' />
                            </Hidden>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <Link 
                                to={`/edit-topic/${uid}`} 
                                className={classes.link}
                                onClick={() => resetData()}
                            >
                                <MenuItem>แก้ไข</MenuItem>
                            </Link>
                            <MenuItem onClick={() => handleOpenDialog()}>ลบ</MenuItem>
                        </Menu>
                    </Box>
                }
            </Box>
            <DateViewComment
                date={date}
                view={view}
                comment={comment}
                loading={loading}
            />
            <MyDialog 
                isOpen={dialog}
                handleClose={() => handleCloseDialog()}
            >
                <Box style={{textAlign: 'center'}} mb={2}>
                    <Typography variant="h2" >
                        ลบกระทู้
                    </Typography>
                </Box>
                <Box style={{ textAlign: 'center' }} mb={4}>
                    <Typography variant="body1" >
                        คุณต้องการลบกระทู้นี้หรือไม่?
                    </Typography>
                </Box>
                <Box
                    style={{ 
                        textAlign: 'center',
                        display: 'flex', 
                    }}
                >
                    <Box className={classes.cancelBtn} mr={{ xs: 1, md:1.5 }}>
                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() => handleCloseDialog()}
                        >
                            ยกเลิก
                        </Button>
                    </Box>
                    <Box className={classes.deleteBtn} ml={{ xs: 1, md: 1.5 }}>
                        <Button
                            variant="contained"
                            noShadow
                            color="primary"
                            onClick={() => deleteTopic()}
                        >
                            ลบ
                        </Button>
                    </Box>
                </Box>
            </MyDialog>
        </Card>
    );
}

MyTopic.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
    url: PropTypes.string,
    uid: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    date: PropTypes.string,
    view: PropTypes.number,
    comment: PropTypes.number,
    loading: PropTypes.bool,
    deleteTopic: PropTypes.func,
    resetData: PropTypes.func,
};

MyTopic.defaultProps = {
    url: '',
    uid: '',
    title: '',
    desc: '',
    date: '',
    view: 0,
    comment: 0,
    loading: true,
    deleteTopic: () => console.log('Click'),
    resetData: () => console.log('Click'),
};

export default withStyles(styles)(MyTopic);