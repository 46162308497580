import _ from 'lodash';
import firebase from 'common/firebase';
import { setNotFound } from './main';


const functions = firebase.app().functions('asia-east2');

// Action
// -- CREATE/UPDATE
export const FETCH_TAG = 'FETCH_TAG';
export const FETCH_TAG_DONE = 'FETCH_TAG_DONE';
export const FETCH_TAG_FAIL = 'FETCH_TAG_FAIL';
export const FETCH_TAG_LOADMORE = 'FETCH_TAG_LOADMORE';
export const FETCH_TAG_LOADMORE_DONE = 'FETCH_TAG_LOADMORE_DONE';
export const FETCH_TAG_TOPIC = 'FETCH_TAG_TOPIC';
export const FETCH_TAG_TOPIC_DONE = 'FETCH_TAG_TOPIC_DONE';
export const FETCH_TAG_TOPIC_LOADMORE = 'FETCH_TAG_TOPIC_LOADMORE';
export const FETCH_TAG_TOPIC_LOADMORE_DONE = 'FETCH_TAG_TOPIC_LOADMORE_DONE';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_TAG,
    };
};
export function fetchLoadmore() {
    return {
        type: FETCH_TAG_LOADMORE,
    };
};
export function fetchDone(data) {
    return {
        type: FETCH_TAG_DONE,
        list: data.list,
        name: data.name,
    };
};
export function fetchLoadmoreDone(data) {
    return {
        type: FETCH_TAG_LOADMORE_DONE,
        list: data.list,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_TAG_FAIL,
        message,
    };
};
export function fetchTopic() {
    return {
        type: FETCH_TAG_TOPIC,
    };
};
export function fetchTopicLoadmore() {
    return {
        type: FETCH_TAG_TOPIC_LOADMORE,
    };
};
export function fetchTopicDone(list) {
    return {
        type: FETCH_TAG_TOPIC_DONE,
        list,
    };
};
export function fetchTopicLoadmoreDone(list) {
    return {
        type: FETCH_TAG_TOPIC_LOADMORE_DONE,
        list,
    };
};

// Async Action Creator
export function getList(lang, uid) {
    return async (dispatch) => {
        dispatch(fetch());

        const tagGetList = functions.httpsCallable('getArticleListByTagId');
        try {
            const result = await tagGetList({ lang, tagId:uid });
            const output = result.data;
            if (!_.isEmpty(output)){
                dispatch(fetchDone(output));
            }else{
                dispatch(setNotFound(true));
            }
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function loadmore(lang, tagId, lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchLoadmore());

        const tagLoadmore = functions.httpsCallable('tagLoadmore');
        try {
            const result = await tagLoadmore({ lang, tagId, lastVisibleUid });
            const output = {
                list: result.data,
            };
            dispatch(fetchLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function getTopicList(uid) {
    return async (dispatch) => {
        dispatch(fetchTopic());

        const tagGetList = functions.httpsCallable('getTopicListByTagId');
        try {
            const result = await tagGetList({ tagId: uid });
            const output = result.data;
            dispatch(fetchTopicDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function loadmoreTopic(tagId, lastVisibleUid) {
    return async (dispatch) => {
        dispatch(fetchTopicLoadmore());

        const tagLoadmore = functions.httpsCallable('tagLoadmoreTopic');
        try {
            const result = await tagLoadmore({ tagId, lastVisibleUid });
            const output = result.data;
            console.log(output);
            dispatch(fetchTopicLoadmoreDone(output));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

