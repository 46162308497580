import { connect } from 'react-redux';
import {
    getData,
    update,
    postCreate,
} from 'actions/termAndCondition';
import {
    getAllPageAds,
} from 'actions/ads';
import TermAndConditionPage from 'views/termAndCondition';

const mapStateToProps = state => ({
    lang: state.main.lang,
    loading: state.termAndCondition.loading,
    desc: state.termAndCondition.desc,
    email: state.termAndCondition.email,
    isValid: state.termAndCondition.isValid,
    submitLoading: state.termAndCondition.submitLoading,
});

const mapDispatchToProps = {
    getData,
    update,
    getAllPageAds,
    postCreate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TermAndConditionPage);

