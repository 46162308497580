/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import algoliasearch from 'algoliasearch/lite';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';
import _ from 'lodash';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CloseIcon from '@material-ui/icons/Close';
import AddProjectDialog from 'containers/views/ProjectCreate';

const searchClient = algoliasearch(
    'SJS3S5FAVR',
    'fbf789ef62160863f04f7f39da3e7fe7',
);

const styles = () => ({
    noOptions: {
        padding: '8px 0',
    },
    notFoundList: {
        position: 'absolute',
        width: '100%',
        margin: 0,
        borderRadius: 4,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        border: 'solid 1px #e9e9e9',
        top: '-5px',
        zIndex: 2,
    },
    addLocation: {
        cursor: 'pointer',
        color: '#00abbd',
        '& svg': {
            color: '#00abbd',
        },
    },
    icon: {
        minWidth: 0,
        marginRight: 15,
    },
    list: {
        // padding: '0',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        '&:hover svg': {
            color: '#00abbd',
        },
    },
});

function NoOptionText(props) {
    const { classes, setOpen } = props;

    return (
        <div style={{ position: 'relative' }}>
            <List className={classes.notFoundList}>
                <ListItem>
                    <ListItemIcon className={classes.icon}>
                        <CloseIcon />
                    </ListItemIcon>
                    <ListItemText primary="ไม่พบสถานที่ที่คุณค้นหา" />
                </ListItem>
                <ListItem
                    className={classes.addLocation}
                    button
                    onClick={() => setOpen()}>
                    <ListItemIcon className={classes.icon}>
                        <LocationCityIcon />
                    </ListItemIcon>
                    <ListItemText primary="เพิ่มสถานที่เอง" />
                </ListItem>
            </List>
        </div>
    );
}

const MyAutocomplete = ({
    classList,
    loading,
    oldData,
    update,
    selectedValue,
    hits,
    refine,
}) => {
    const unselected = _(hits)
        .differenceBy(selectedValue, 'uid')
        .map(
            _.partial(_.pick, _, 'uid', 'nameTh', 'nameEn', 'nameCh', 'nameJp'),
        )
        .value();

    // No Opt
    const showNoOptText = unselected.length === 0 && !selectedValue;

    // Modal
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <Autocomplete
                className={classList.searchResult}
                classes={{
                    noOptions: classList.noOptions,
                }}
                freeSolo
                loading={loading}
                options={unselected}
                renderOption={option =>
                    option.nameTh ? (
                        <Box className={classList.list}>
                            <ListItemIcon className={classList.icon}>
                                <LocationCityIcon />
                            </ListItemIcon>
                            <ListItemText primary={option.nameTh} />
                        </Box>
                    ) : (
                        ''
                    )
                }
                filterSelectedOptions
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="เลือกโครงการ"
                        margin="normal"
                        fullWidth
                        required
                        disabled={loading}
                        onChange={event => {
                            refine(event.currentTarget.value);
                        }}
                    />
                )}
                value={selectedValue}
                getOptionLabel={option => (option.nameTh ? option.nameTh : '')}
                onChange={(event, value) => {
                    update(value);
                }}
            />
            {showNoOptText && (
                <NoOptionText classes={classList} setOpen={handleOpen} />
            )}
            <AddProjectDialog
                onClose={handleClose}
                open={open}
                loading={loading}
                oldData={oldData}
            />
        </>
    );
};
const ConnectedAutocomplete = connectAutoComplete(MyAutocomplete);

function ProjectSearch(props) {
    const { classes, loading, oldData, update, selectedValue } = props;

    return (
        <InstantSearch indexName="projects" searchClient={searchClient}>
            <ConnectedAutocomplete
                classList={classes}
                loading={loading}
                oldData={oldData}
                update={update}
                selectedValue={selectedValue}
            />
        </InstantSearch>
    );
}

NoOptionText.propTypes = {
    classes: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired,
    // setClose: PropTypes.func.isRequired,
};

MyAutocomplete.propTypes = {
    classList: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    hits: PropTypes.array.isRequired,
    refine: PropTypes.func.isRequired,
    selectedValue: PropTypes.any,
};

MyAutocomplete.defaultProps = {
    selectedValue: '',
};

ProjectSearch.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    oldData: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
    selectedValue: PropTypes.any,
};

ProjectSearch.defaultProps = {
    selectedValue: '',
};

export default withStyles(styles)(ProjectSearch);
