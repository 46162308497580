import firebase from 'common/firebase';
import moment from 'moment';
import _has from 'lodash/has';
import _get from 'lodash/get';

const functions = firebase.app().functions('asia-east2');

// Action
// -- CREATE/UPDATE
export const FETCH_HOME = 'FETCH_HOME';
export const FETCH_HOME_DONE = 'FETCH_HOME_DONE';
export const FETCH_HOME_FAIL = 'FETCH_HOME_FAIL';
export const FETCH_HOME_TOPICS = 'FETCH_HOME_TOPICS';
export const FETCH_HOME_TOPICS_DONE = 'FETCH_HOME_TOPICS_DONE';
export const FETCH_HOME_TOPICS_FAIL = 'FETCH_HOME_TOPICS_FAIL';
export const FETCH_HOME_POSTS = 'FETCH_HOME_POSTS';
export const FETCH_HOME_POSTS_DONE = 'FETCH_HOME_POSTS_DONE';
export const FETCH_HOME_POSTS_FAIL = 'FETCH_HOME_FAIL';
export const CHECK_IS_NEW_USER = 'CHECK_IS_NEW_USER';
export const CHECK_IS_NEW_USER_DONE = 'CHECK_IS_NEW_USER_DONE';
export const CHECK_IS_NEW_USER_FAIL = 'CHECK_IS_NEW_USER_FAIL';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_HOME,
    };
}
export function fetchDone(data) {
    return {
        type: FETCH_HOME_DONE,
        cover: data.cover,
        topics: data.topics,
        latest: data.latest,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_HOME_FAIL,
        message,
    };
}
export function fetchTopics() {
    return {
        type: FETCH_HOME_TOPICS,
    };
}
export function fetchTopicsDone(list) {
    return {
        type: FETCH_HOME_TOPICS_DONE,
        list
    };
}
export function fetchTopicsFail(message) {
    return {
        type: FETCH_HOME_TOPICS_FAIL,
        message,
    };
}
export function fetchPosts() {
    return {
        type: FETCH_HOME_POSTS,
    };
}
export function fetchPostsDone(list) {
    return {
        type: FETCH_HOME_POSTS_DONE,
        list
    };
}
export function fetchPostsFail(message) {
    return {
        type: FETCH_HOME_POSTS_FAIL,
        message,
    };
}

export function checkIsNewUser() {
    return {
        type: CHECK_IS_NEW_USER,
    };
}

export function checkIsNewUserDone(message) {
    return {
        type: CHECK_IS_NEW_USER_DONE,
        message,
    };
}

export function checkIsNewUserFail(message) {
    return {
        type: CHECK_IS_NEW_USER_FAIL,
        message,
    };
}



// Async Action Creator
export function getList(lang) {
    return async dispatch => {
        dispatch(fetch());

        const homeGetList = functions.httpsCallable('homeGetList');
        try {
            const result = await homeGetList(lang);
            const output = {
                cover: result.data.cover,
                topics: result.data.topics,
                latest: result.data.latest,
            };
            dispatch(fetchDone(output));
        } catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}

export function getTopicList() {
    return async dispatch => {
        dispatch(fetchTopics());

        const getListAPI = functions.httpsCallable('getTopicList');
        return getListAPI()
            .then(result => {
                dispatch(fetchTopicsDone(result.data.list));
            })
            .catch(error => {
                dispatch(fetchTopicsFail(error.message));
            });
    };
}

export function getPostList(lang) {
    return async dispatch => {
        dispatch(fetchPosts());
        const getListAPI = functions.httpsCallable('getPostList');
        try {
            const result = await getListAPI({
                purpose: 'sell',
                sortBy: 'time',
                lang,
            });
            const output = result.data.list;
            dispatch(fetchPostsDone(output));
        } catch (error) {
            console.log(error);
            dispatch(fetchPostsFail(error.message));
        }
    };
}

export function getCheckIsNewUser() {
    return async dispatch => {
        dispatch(checkIsNewUser());
        try {
            firebase.auth().getRedirectResult().then(async result => {
                const isNewUser = _get(result, 'additionalUserInfo.isNewUser', undefined);
                console.log(`isNewUser`, isNewUser);
                if (result === null 
                    || (_has(result, 'additionalUserInfo.isNewUser') 
                    && !isNewUser
                    )
                ) {
                    dispatch(checkIsNewUserDone('not new user'));
                } else if(isNewUser) {
                    const { uid = '' } = await firebase.auth().currentUser;
                    const ref = firebase.firestore().collection("users").doc(uid);
                    const inputData = {
                        phoneNumber: null,
                        isAgency: false,
                        timestamp: moment().valueOf(),
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    };
                    await ref.set(inputData);
                    dispatch(checkIsNewUserDone('create new user success'));
                }
                // dispatch(checkIsNewUserFail(error.message));
            });
        } catch (error) {
            console.log(error);
            dispatch(checkIsNewUserFail(error.message));
        }
    };
}
