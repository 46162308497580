import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    CardMedia,
    CardContent,
    CardActions,
    Button,
    CircularProgress,
    Typography,
    Box,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 2,
    },
    media: {
        backgroundColor: 'rgba(0, 171, 189, 0.1)',
        width: '100%',
        height: 'auto',
        boxSizing: 'border-box',
        borderRadius: 0,
    },
    outer: {
        backgroundColor: '#e9e9e9',
        boxSizing: 'border-box',
        borderRadius: 0,
        textAlign: 'center',
        maxWidth: '100%',
        position: 'relative',
        height: 0,
    },
    textInImg: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
    },
    action: {
        padding: '0 0 8px 0',
    },
    uploadButton: {
        marginRight: theme.spacing(1),
    },
}));

const ImageField = props => {
    const classes = useStyles();
    const {
        title,
        width,
        height,
        loading,
        url,
        uploadFunc,
        oldData,
        fieldName,
        removeFunc,
    } = props;

    const handleSelect = event => {
        const file = event.target.files[0];
        uploadFunc(file, oldData, fieldName);
    };

    return (
        <>
            <CardContent style={{ padding: '8px 0 16px 0' }}>
                <div>
                    <Box mt={1} mb={2}>
                        <Typography color="textPrimary" variant="h5">
                            {title}
                        </Typography>
                    </Box>
                    <div
                        style={{
                            position: 'relative',
                            display: 'inline-block',
                            maxWidth: '100%',
                        }}>
                        {loading && (
                            <CircularProgress
                                className={classes.progress}
                                color="inherit"
                            />
                        )}
                        {!url ? (
                            <div
                                className={classes.outer}
                                style={{
                                    width,
                                    paddingBottom: `${(height * 100) / width}%`,
                                }}>
                                <Typography
                                    variant="body1"
                                    className={classes.textInImg}>
                                    {`Recomended size ${width}x${height} px `}
                                </Typography>
                            </div>
                        ) : (
                            <CardMedia
                                component="img"
                                src={url}
                                className={classes.media}
                                style={{
                                    maxWidth: width,
                                    maxHeight: height,
                                }}
                            />
                        )}
                    </div>
                </div>
            </CardContent>
            <CardActions className={classes.action}>
                <label htmlFor={fieldName}>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id={fieldName}
                        name={fieldName}
                        type="file"
                        disabled={loading}
                        onChange={handleSelect}
                    />
                    <Button
                        className={classes.uploadButton}
                        color="primary"
                        variant="text"
                        component="div"
                        htmlFor={fieldName}
                        disabled={loading}>
                        Upload
                    </Button>
                </label>
                <Button
                    variant="text"
                    disabled={loading}
                    onClick={() => removeFunc()}>
                    Remove
                </Button>
            </CardActions>
        </>
    );
};

export default ImageField;
